import React from "react";
import { dispatchPutDirty } from "../../../../../store/referencePage/reference/actions";
import { dispatchSetResizingMode } from "../../../../../store/referencePage/toolbarActions";
import { SpacerWrap, SpacerBox } from "./styles";
import { useDrag } from "react-use-gesture";
import { useDrop } from "react-dnd";
import { ItemTypes } from "../itemTypes";
import { BoxSpacerProps } from "./types";
import { resizeBox } from "../../stateManager";

export const BoxSpacer: React.FC<BoxSpacerProps> = ({
  rowId,
  pos,
  resizable,
  state,
}) => {
  const [{ isOver }, drop] = useDrop({
    accept: [`${ItemTypes.ITEM}${0}`, `${ItemTypes.NEW_ITEM}${0}`],
    drop: () => ({ rowId, pos, type: `${ItemTypes.POS}${0}` }),
    collect: (monitor) => ({
      isOver: monitor.isOver({ shallow: true }),
    }),
  });

  const bind = useDrag(({ delta: [dx, dy], cancel, last, first }) => {
    if (first) {
      if (!resizable) {
        cancel && cancel();
        return;
      }
      dispatchSetResizingMode(true);
    }

    if (last) {
      dispatchPutDirty(state);
      dispatchSetResizingMode(false);
    }
    resizeBox(state, rowId, pos, dx);
  });
  return (
    <SpacerWrap isOver={isOver} resizable={resizable}>
      <SpacerBox ref={drop} {...bind()} />
    </SpacerWrap>
  );
};

export default BoxSpacer;
