import * as React from "react";

import {IconOptionProps} from "./types";
import {Icons} from "../../../../assets/Icons";
import {OptionProps} from "@blueprintjs/core";
import {default as Filter} from "../../../../assets/Icons/Filter";

export const defaultHryOpts: IconOptionProps[] = [
    {
        icon: <Icons.Division />,
        label: "Component",
        value: "ByComponent"
    },
    {
        icon: <Icons.World />,
        label: "Location",
        value: "ByLocation"
    }
]

export const tabs: IconOptionProps[] = [
    {
        icon: <Icons.World />,
        label: "Geography",
        value: "geography"
    },
    {
        icon: <Icons.Division/>,
        label: "Components",
        value: "components"
    },
    {
        icon: <Icons.ProjectData/>,
        label: "Attributes",
        value: "attributes"
    },
    {
        icon: <Icons.Filter/>,
        label: "Flow",
        value: "flow"
    },
    {
        icon: <Icons.Filter/>,
        label: "Charts",
        value: "charts"
    },
    {
        icon: <Icons.Filter/>,
        label: "Treemap",
        value: "treemap"
    }
/*    {
        icon: <Icons.Filter/>,
        label: "Flow",
        value: "sankey"
    },
    {
        label: "Treemap",
        value: "treemap",
    },
    {
        label: "Drilldown",
        value: "drilldown"
    }*/
]

/*export const defaultNetworkOpts: IHierarchyOption[] = [
    {
        name: "Org Chart",
        value: "OrgChart"
    },
    {
        name: "Location",
        value: "Location"
    }
]*/

/*
export const defaultAttrOpts: IAttributeOption[] = [
    {
        name: "People",
        value: "Person"
    },
    {
        name: "Process",
        value: "Process"
    },
    {
        name: "Technology",
        value: "Technology"
    }
]*/
