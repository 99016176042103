import styled from "styled-components";
import { SpacerWrapProps } from "./types";
import { ApplyStyles, ApplyStylesProps } from "../../styles";
export const spacerWidth = 30;
export const spacerMargin = 3;

export const ItemView = styled(ApplyStyles)<ApplyStylesProps>`
  height: 100%;
  margin: 0;
  border-radius: 5px;
  line-height: 20px;
  text-align: center;
  box-sizing: border-box;
  & > *:last-child {
    margin-bottom: 0;
  }
`;

export const ItemContentWrap = styled.div`
  padding: 5px;
  width: 100%;
  height: 100%;
`;

export const SpacerWrap = styled.div<SpacerWrapProps>`
  position: absolute;
  z-index: 1;
  width: ${spacerWidth}px;
  min-width: ${spacerWidth}px;
  left: ${(props) =>
    props.first
      ? `-${spacerWidth * 0.5}px`
      : `calc(100% - ${spacerWidth * 0.5 - spacerMargin}px)`};
  height: 100%;
`;

export const OverSpacerWrap = styled(SpacerWrap)<SpacerWrapProps>`
  position: absolute;
  background: repeating-linear-gradient(
    -55deg,
    #4a90e2,
    #4a90e2 5px,
    #fff 5px,
    #fff 10px
  );
`;

export const ItemWrap = styled.div`
  flex-basis: calc(50% - 5px);
  flex-grow: 1;
  margin: 1px 2.5px 1px 2.5px;
  position: relative;
  user-select: none;
  display: flex;
  flex: row nowrap;
`;

export const ItemContent = styled.div`
  padding: 0 5px 0 5px;
  display: flex;
  flex-flow: row wrap;
`;

export const ItemContentOld = styled.div`
  width: 100%;
  padding: 0 5px 0 5px;
  justify-items: stretch;
  display: grid;
  overflow: hidden;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: min-content;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
`;
