import useAuth from "../../auth/context/useAuth";
import AllocationsApi from "./AllocationsApi";
import { useMemo } from "react";

export function useAllocationsApi(): AllocationsApi {
  const auth = useAuth();

  return useMemo(() => {
    const token = auth.token as string;

    return new AllocationsApi(token);
  }, [auth]);
}

export default useAllocationsApi;
