import * as React from "react";
import { Route, RouteProps } from "react-router-dom";
import isAuthed from "../context/isAuthed";

export const PrivateRoute: React.FC<RouteProps> = (props) => {
  isAuthed();

  return <Route {...props} />;
};

export default PrivateRoute;
