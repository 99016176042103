import React, { useState, useRef, useEffect } from "react";
import { Button } from "@blueprintjs/core";
import { StyledDialog, TableTools } from "./styles";
import useModelsApi from "../../../../api/useModelsApi";
import { DataWrap } from "../../../../../projectspaces/components/ProjectsView";
import { HotTable } from "@handsontable/react";
import Handsontable from "handsontable";
import Core from "handsontable";
import AutoSizer from "react-virtualized-auto-sizer";
import AttributeModel from "../../../../../components/AttributeModel";
import ExtraTools from "../RefTable/ExtraTools";
import { AppToaster } from "../../../../../components";

interface AttributeDisplay {
  display: boolean;
  uuid: string;
  allocation?: boolean;
  defaultSelectedTabId?: string | undefined;
}

type TableColumnsType = {
  data: string;
  type: string;
  source?: Array<string>;
  readonly?: boolean;
  className: string;
};

interface ColumnObject {
  name: string;
  key: string;
  type?: string;
}

const ValueSelectorDialog: React.FC<{
  isOpen: any;
  _ATTRIBUTE: string;
  onClose: any;
  projectUuid: string;
  modelUuid: string;
  childId: string;
  componentName: string;
  editMode: boolean;
  setEditMode: any;
}> = (props) => {
  const modelsApi = useModelsApi();

  const [displayAttributeModel, setDisplayAttributeModel] = useState<
    AttributeDisplay
  >({
    display: false,
    uuid: "",
  });
  const [tableRows, setTableRows] = useState<Array<{ [key: string]: any }>>([]);
  const [tableColumns, setTableColumns] = useState<Array<TableColumnsType>>([]);
  const [tableHeaders, setTableHeaders] = useState<
    Array<{ name: string; key: string; type: string }>
  >([]);

  const [filterString, setFilterString] = useState<string>(); // used as a param for the endpoint
  const [sortString, setSortString] = useState<string>();
  const [searchString, setSearchString] = useState<string>();
  const [selectList, setSelectList] = useState<
    Array<{ uuid: string; allocated: boolean }>
  >([]);

  const hot = useRef<HotTable>(null);

  useEffect(() => {
    if (props._ATTRIBUTE) {
      modelsApi
        .getCandidates(
          props.modelUuid,
          props._ATTRIBUTE,
          props.childId,
          false,
          searchString,
          filterString,
          sortString
        )
        .then((response) => {
          const rows = response?.data?.results.map((result: any) => {
            const obj = result.labels;
            obj.uuid = result.uuid;
            obj["partial"] = result.partial;

            const linkKeys = Object.keys(result.links);
            linkKeys.map((key: string) => {
              obj[key] = result.links[key];
              return null;
            });

            obj.allocated = result.allocated;

            return obj;
          });

          setTableRows(rows);
        });

      modelsApi.getSchemaAttributes().then((response) => {
        const temp_arr = [];
        temp_arr.push(response?.data);

        const attributeIndex = temp_arr[0].findIndex(
          (attr) => attr?.kind === props._ATTRIBUTE
        );
        if (attributeIndex > -1) {
          const columns = response?.data[attributeIndex]?.properties;
          const columnsKeys = Object.keys(columns);

          const defaultColumns = columnsKeys.map((key: string) => ({
            data: key,
            type:
              columns[key].type === "link"
                ? "dropdown"
                : columns[key].type !== "number"
                ? "text"
                : "numeric",
            renderer:
              columns[key].type === "link" ? "link.render" : "display.render",
            readOnly: true,
            className: "",
          }));

          const headersName = columnsKeys.map((key: string) => ({
            name: columns[key]?.meta.name,
            key: key,
            type: columns[key].type,
          }));

          // add model allocations column
          headersName.push({
            name: "Model Allocations",
            key: "split",
            type: "link",
          });
          defaultColumns.push({
            data: "split",
            renderer: "allocation.render",
            type: "text",
            readOnly: true,
            className: "",
          });

          headersName.unshift({
            name: "",
            key: "allocated",
            type: "checkbox",
          });
          defaultColumns.unshift({
            data: "allocated",
            renderer: "checkbox",
            type: "checkbox",
            readOnly: false,
            className: "input-checkbox",
          });
          setTableHeaders(headersName);
          setTableColumns(defaultColumns);
        }
      });
    }
  }, [
    modelsApi,
    props._ATTRIBUTE,
    props.modelUuid,
    filterString,
    searchString,
    sortString,
    props.childId,
  ]);

  (function (Handsontable) {
    function customRenderer(
      instance: Core,
      td: HTMLTableCellElement,
      row: number,
      col: number,
      prop: React.ReactText,
      value: any,
      cellProperties: Handsontable.CellProperties
    ) {
      let button;
      if (tableRows[row] && tableRows[row].partial) {
        td.classList.add("partial-cell");
        cellProperties.className = "partial-cell";
        cellProperties.readOnly = true;
      }

      if (
        value?.label !== "-" &&
        value?.label !== null &&
        value?.label !== undefined
      ) {
        button = document.createElement("button");
        button.innerHTML = value?.label;
        button.className = "link-btn";

        if (value?.label !== "Null") {
          Handsontable.dom.addEvent(button, "mousedown", function (e: any) {
            e.preventDefault(); // prevent selection quirk
            setDisplayAttributeModel({
              uuid: value?.uuid,
              display: true,
            });
          });
        }

        Handsontable.dom.empty(td);
        td.appendChild(button);
        td.className = "link-cell";
        cellProperties.readOnly = true;
      } else {
        Handsontable.renderers.TextRenderer(
          instance,
          td,
          row,
          col,
          prop,
          value?.label,
          cellProperties
        );
      }
    }

    // Register an alias
    Handsontable.renderers.registerRenderer("link.render", customRenderer);
  })(Handsontable);

  (function (Handsontable) {
    function customRenderer(
      instance: Core,
      td: HTMLTableCellElement,
      row: number,
      col: number,
      prop: React.ReactText,
      value: any,
      cellProperties: Handsontable.CellProperties
    ) {
      if (value && value.length > 0) {
        Handsontable.dom.empty(td);
        for (let i = 0; i < value.length; i++) {
          if (value[i].name !== null) {
            let button;
            button = document.createElement("button");
            button.innerHTML = value[i]?.name;
            button.className = "link-btn";
            button.id = value?.id;

            Handsontable.dom.addEvent(button, "mousedown", function (e: any) {
              e.preventDefault(); // prevent selection quirk
              setDisplayAttributeModel({
                uuid: e.target?.id,
                display: true,
                allocation: true,
              });
            });

            Handsontable.dom.empty(td);
            td.appendChild(button);
            td.className = "link-cell";
            cellProperties.readOnly = true;
          } else {
            td.innerHTML = "";
          }
        }
      } else {
        td.innerHTML = value;
      }

      if (tableRows[row]?.partial) {
        td.classList.add("partial-cell");
        cellProperties.className = "partial-cell";
        cellProperties.readOnly = true;
      }
    }

    // Register an alias
    Handsontable.renderers.registerRenderer(
      "allocation.render",
      customRenderer
    );
  })(Handsontable);

  (function (Handsontable) {
    function customRenderer(
      instance: Core,
      td: HTMLTableCellElement,
      row: number,
      col: number,
      prop: React.ReactText,
      value: any,
      cellProperties: Handsontable.CellProperties
    ) {
      if (tableRows && row < tableRows.length) {
        if (tableRows[row]) {
          const value = tableRows[row][prop];
          td.innerHTML = value;

          if (tableRows[row].partial) {
            td.classList.add("partial-cell");
            cellProperties.className = "partial-cell";
            cellProperties.readOnly = false;
          }
        }
      } else {
        td.innerHTML = value;
      }
    }

    // Register an alias
    Handsontable.renderers.registerRenderer("display.render", customRenderer);
  })(Handsontable);

  function handleEdit(changes: any, source: string) {
    if (source === "edit") {
      const rowIndex = changes[0][0];
      const key = changes[0][1];
      const newValue = changes[0][3];
      const uuid = tableRows[rowIndex]?.uuid;

      // make sure that edit was done for the allocated checkbox
      if (key === "allocated") {
        const uuidExists = selectList.findIndex((o) => o?.uuid === uuid);

        if (uuidExists > -1) {
          // change state here
          const selectListCopy = [...selectList];
          selectListCopy[uuidExists] = {
            ...selectListCopy[uuidExists],
            allocated: newValue,
          };
          setSelectList(selectListCopy);
        } else {
          setSelectList((selectList) => [
            ...selectList,
            { uuid: uuid, allocated: newValue },
          ]);
        }
      }
    }
  }

  function handleValueSelect() {
    // send changes to the backend
    let payloadArray = [];

    if (selectList.length > 0) {
      for (let i = 0; i < selectList.length; i++) {
        const payloadObject = {
          attributeUuid: selectList[i]?.uuid,
          componentId: props.childId,
          allocated: selectList[i]?.allocated,
        };
        payloadArray.push(payloadObject);
      }

      let trueCount = payloadArray.filter((obj) => obj.allocated).length;
      let falseCount = payloadArray.filter((obj) => !obj.allocated).length;

      modelsApi
        .updateManyAllocations(props.modelUuid, payloadArray)
        .then((response) => {
          if (response.statusText === "OK") {
            AppToaster.show({
              message:
                trueCount >= falseCount
                  ? "Values were successfully added"
                  : "Values were successfully removed",
              intent: "success",
            });

            // re-render attribute reference table onClose
            props.onClose();
          }
        })
        .catch((error) => {
          console.log(error);
          AppToaster.show({
            message: "Something went wrong, please try again!",
            intent: "danger",
          });

          console.log(error);
          props.onClose();
        });
    } else {
      props.onClose();
    }
  }

  return (
    <StyledDialog
      isOpen={props.isOpen}
      onClose={props.onClose}
      title=""
      autoFocus={false}
    >
      <div className="dialog-wrap">
        <header>
          <div>
            <h1>{props.componentName}</h1>
            <h2>{props._ATTRIBUTE}</h2>
          </div>
        </header>

        {tableHeaders && (
          <TableTools>
            <ExtraTools
              tableHeaders={tableHeaders}
              setFilterString={setFilterString}
              setSortString={setSortString}
              setSearchString={setSearchString}
            />

            <Button
              text="Save"
              className="save-btn"
              onClick={handleValueSelect}
            />
          </TableTools>
        )}

        {tableHeaders && tableRows && tableRows.length > 0 && (
          <DataWrap className="dialog-table">
            <AutoSizer>
              {({ height, width }) => (
                <HotTable
                  ref={hot}
                  className="data-table"
                  columns={tableColumns}
                  data={tableRows}
                  colHeaders={(index: any) => {
                    return tableHeaders[index]?.name;
                  }}
                  width={width}
                  licenseKey="non-commercial-and-evaluation"
                  stretchH="all"
                  manualColumnResize
                  height={height}
                  rowHeights={28}
                  maxRows={100}
                  rowHeaders={true}
                  afterChange={(changes, source) => handleEdit(changes, source)}
                  disableVisualSelection={["area", "header"]}
                />
              )}
            </AutoSizer>
          </DataWrap>
        )}
        {displayAttributeModel?.display === true && props.modelUuid && (
          <AttributeModel
            modelUuid={props.modelUuid}
            uuid={displayAttributeModel?.uuid}
            isOpen={displayAttributeModel?.display}
            allocation={displayAttributeModel?.allocation}
            defaultSelectedTabId={displayAttributeModel?.defaultSelectedTabId}
            setDisplayAttributeModel={setDisplayAttributeModel}
            columnNames={tableHeaders}
          />
        )}
      </div>
    </StyledDialog>
  );
};
export default ValueSelectorDialog;
