import { Dialog } from "@blueprintjs/core";
import styled from "styled-components";

export const StyledDialog = styled(Dialog)`
  background: #fff;
  height: 82vh;
  overflow: auto;
  width: 80vw;
  min-width: 770px;

  & > .bp3-dialog-header {
    box-shadow: none;
  }

  .dialog-wrap {
    padding: 12px 20px;
    height: 100%;
    display: flex;
    flex-direction: column;
    width: 100%;
  }

  header {
    display: flex;

    h1 {
      font-size: 28px;
      margin: 0;
    }

    h2 {
      font-size: 24px;
      margin: 0;
      color: #6d7278;
    }
  }

  .table-wrap {
    margin-top: 32px;

    &.table-wrap {
      display: flex;
      margin: 0 16px;
      flex-direction: column;
      margin-top: 42px;
      flex: 1;

      .table-pagination {
        margin-left: auto;
        margin-bottom: 6px;
      }
    }
  }
`;

export const TableTools = styled.div`
  display: flex;
  flex-direction: row;
  align-items: center;
  height: 32px;
  margin-bottom: 6px;
  margin-top: 12px;

  .tools-search {
    display: flex;
    align-items: center;
    margin-right: 12px;

    & > span {
      top: unset;
    }

    svg {
      margin: 0 4px;
    }

    & > input:focus {
      box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0),
        inset 0 0 0 1px rgba(16, 22, 26, 0.15),
        inset 0 1px 1px rgba(16, 22, 26, 0.2);
    }
  }

  & > svg {
    margin-right: 8px;
    cursor: pointer;
  }

  .sort-dropdown {
    width: max-content;
  }

  /* STYLING FOR BLUEPRINTJS COMPONENTS */
  .filter-wrap {
    align-items: center;
  }

  .filter-reset {
    margin: 0 10px;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  .filter-accept {
    margin-right: 10px;
    cursor: pointer;

    &:hover {
      opacity: 0.8;
    }
  }

  .filter-btn,
  .sort-btn {
    border-radius: 0;
  }

  .filter-search {
    align-items: center;
    display: flex;
    flex: 1 1 auto;
    font-size: 14px;
    padding: 0 10px;

    & > input:focus {
      box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0),
        inset 0 0 0 1px rgba(16, 22, 26, 0.15),
        inset 0 1px 1px rgba(16, 22, 26, 0.2);
    }
  }

  &.delta-tools {
    display: flex;
    flex-direction: column;

    & > .delta-pages {
      margin-left: auto;
    }
  }

  & > .save-btn {
    margin-left: auto;
    margin-top: auto;
    height: 38px;
    width: 92px;
    background: #061d38;
    background-color: #061d38;
    color: #fff;
    font-weight: 500;
    border: none !important;
    box-shadow: none !important;

    &:hover {
      background-color: #061d38;
      opacity: 0.9;
    }
  }
`;
