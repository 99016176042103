import React, { useRef, useState } from "react";
// @ts-ignore
import createSingleLinePlugin from "draft-js-single-line-plugin";
import { TextViewWrap } from "./styles";
import { TextViewProps } from "./types";
import { checkNameDublicate } from "../../stateManager";
import Editor from "draft-js-plugins-editor";
import { EditorState, ContentState } from "draft-js";
import "draft-js/dist/Draft.css";
import { AppToaster } from "../../../../../components";
// const singleLinePlugin = createSingleLinePlugin({ stripEntities: true });
const singleLinePlugin = createSingleLinePlugin();
const plugins = [singleLinePlugin];

const View: React.FC<TextViewProps> = ({ state, text, onBlur }) => {
  const editorRef = useRef(null);
  const [isFocused, setFocused] = useState(false);
  const [editorState, setEditorState] = React.useState(() =>
    EditorState.createWithContent(ContentState.createFromText(text))
  );
  const handleBlur = () => {
    const newText = editorState.getCurrentContent().getPlainText();
    if (checkNameDublicate(state, newText)) onBlur(newText);
    else {
      AppToaster.show({
        message: `name "${newText}" already exists`,
        intent: "danger",
      });

      setEditorState(() =>
        EditorState.createWithContent(ContentState.createFromText(text))
      );
    }
    setFocused(false);
  };
  const handleFocus = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    (editorRef.current as any).focus();
    setFocused(true);
    event.preventDefault();
  };

  return (
    <TextViewWrap onDoubleClick={handleFocus}>
      <div style={{ pointerEvents: isFocused ? "auto" : "none" }}>
        <Editor
          ref={editorRef}
          plugins={plugins}
          blockRenderMap={singleLinePlugin.blockRenderMap}
          editorState={editorState}
          onChange={setEditorState}
          textAlignment="center"
          onBlur={handleBlur}
        />
      </div>
    </TextViewWrap>
  );
};

export default View;
