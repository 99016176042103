import * as React from "react";
import {useEffect, useState} from "react";
import {AttributesResult, CubeViewProps} from "../types";
import {SankeyPanel} from "../panels/SankeyPanel";
import {STreeMap} from "../../../../@satsui/components/STreeMap/STreeMap";
import useModelsApi from "../../../api/useModelsApi";
import SChart from "../../../../@satsui/components/SChart/SChart";


export const AttributesTab = ({modelUuid, query}: CubeViewProps) => {
    const modelsApi = useModelsApi();

    const [result, setResult] = useState<AttributesResult>()

    useEffect(() => {
        modelsApi.cubeAttributes(modelUuid, query).then((response) => {
            setResult(response?.data);
        });
    }, [modelsApi, query]);

    return <div>
        {
            result?.byKind.map(chart => {
                return <div key={chart.name}>
                    {/*<pre>
                     {JSON.stringify(divisionChart,null,2)}
                 </pre>*/}
                    <SChart {...chart} />
                </div>
            })
        }

        <STreeMap data={result?.attributeMap}/>
    </div>
}