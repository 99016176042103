import styled from "styled-components";
import { Dialog } from "@blueprintjs/core";

export const StyledDialog = styled(Dialog)`
  background-color: #fff;
  background: #fff;
  height: 62vh;
  overflow: auto;
  z-index: 999999;

  & > .bp3-dialog-header {
    box-shadow: none;

    & > h4 {
      font-size: 21px;

      span:first-of-type {
        color: #4a90e2;
      }

      span:last-of-type {
        color: #6d7278;
      }
    }
  }

  .bp3-tab-list {
    box-shadow: 0 1px 0 rgba(16, 22, 26, 0.15);
    padding: 0 5px 12px 20px;
  }

  .custom-tab-btn {
    font-size: 16px;
    cursor: default;
    font-weight: 500;

    &:focus {
      outline: none;
    }
  }

  .bp3-tab[aria-selected="true"] {
    box-shadow: none;
    color: inherit;
  }

  .info-panel-wrap {
    padding: 0 20px;
    overflow-y: auto;
    display: grid;
    grid-template-columns: 1fr 110px;
    grid-column-gap: 26%;

    .info-content {
      display: flex;
      flex-direction: column;
    }

    input:read-only {
      color: #6d7278;
      box-shadow: none;
      border: none;
      padding: 0;
    }

    .formatted-input {
      appearance: none;
      background: #ffffff;
      border: none;
      border-radius: 3px;
      box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0),
        inset 0 0 0 1px rgba(16, 22, 26, 0.15),
        inset 0 1px 1px rgba(16, 22, 26, 0.2);
      color: #182026;
      font-size: 14px;
      font-weight: 400;
      height: 30px;
      line-height: 30px;
      outline: none;
      padding: 0 10px;
      transition: box-shadow 100ms cubic-bezier(0.4, 1, 0.75, 0.9);
      vertical-align: middle;
      width: 100%;
    }

    .save-info-btn {
      margin-top: auto;
      background: #061d38;
      color: #fff;
      font-weight: 500;
      border: none !important;
      box-shadow: none !important;
    }
  }

  .input-btn {
    padding: 0;
    padding-right: 6px;
  }
`;
