import styled from "styled-components";

const ListWrap = styled.div`
  display: grid;
  grid-template-columns: 280px 1fr;
  min-height: 100vh;
  width: 100vw;
  max-width: 100%;
  box-sizing: border-box;
  background-color: #fff;

  &.delta-view,
  &.projectdata-view,
  &.comparison-view {
    max-height: 100vh;
    overflow: hidden;
  }

  /* testing - styling for blueprint components */
  & > #filter-value {
    background-color: red;
  }
`;

const ListContent = styled.div`
  display: grid;
  grid-template-rows: 94px 1fr;

  &.comparison-wrap {
    overflow: hidden;
    min-height: 100vh;
    height: 100vh;
  }

  &.delta-content {
    grid-template-rows: 124px 1fr;
    overflow: auto;
    height: 100vh;
  }

  &.projectdata-content {
    height: 100vh;
    max-height: 100vh;
    overflow: hidden;
  }
`;

const ClientsTopbar = styled.div`
  padding: 16px 0;
  display: flex;
  align-items: center;
  border-bottom: 2px solid #f3f3f3;

  & > h2 {
    margin: 0;
    margin: 0 18px;
    font-size: 24px;
    line-height: 1;
  }

  & > .delta-breadcrumbs-wrap {
    display: flex;
    flex-direction: column;
  }
`;

const TopbarLeft = styled.div`
  margin-left: auto;
  margin-right: 16px;
  display: flex;
  align-items: center;

  & > p {
    margin: 0;
    font-size: 16px;
    font-weight: 500;
    text-transform: uppercase;
    margin-right: 24px;
    cursor: pointer;
  }

  .topbar-user {
    display: flex;
    flex-direction: column;
    align-items: center;
  }

  .user-img {
    height: 48px;
    width: 48px;
    margin-bottom: 8px;
  }

  .user-text {
    font-weight: 500;
  }
`;

const ClientsList = styled.div`
  padding: 24px 32px;
  display: flex;
  flex-direction: column;

  &.members-view {
    overflow: hidden;
    display: block;
  }

  &.comparison-content {
    overflow-y: auto;
  }

  & > .settings-delete {
    margin: 0;
  }

  &.projectdata-table {
    overflow: auto;
  }
`;

const ClientsTools = styled.div`
  font-size: 18px;
  display: flex;
  flex-direction: column;

  .tools-btn {
    margin: 0;
    padding: 4px 0;
  }

  &.project-data-tools {
    flex-direction: row;
    align-items: center;
    height: 32px;

    & > h2 {
      margin-right: 12px;
    }

    & > svg {
      margin-right: 8px;
      cursor: pointer;
    }

    .project-data-search {
      display: flex;
      align-items: center;
      margin-right: 12px;

      input {
        border-radius: 6px;
      }

      & > span {
        top: unset;
      }

      svg {
        margin: 0 4px;
      }

      & > input:focus {
        box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0),
          inset 0 0 0 1px rgba(16, 22, 26, 0.15),
          inset 0 1px 1px rgba(16, 22, 26, 0.2);
      }
    }

    .edit-data {
      margin-left: auto;
      margin-right: 12px;
      background: #4a90e2;
      border: none;
      color: #fff;
      font-weight: 500;
      min-width: 60px;
      font-size: 14px;
      letter-spacing: 0.025em;
      border-radius: 0;
      box-shadow: none;

      &:hover {
        opacity: 0.93;
      }
    }

    .sort-dropdown {
      width: max-content;
    }

    /* STYLING FOR BLUEPRINTJS COMPONENTS */
    .filter-wrap {
      align-items: center;
    }

    .filter-reset {
      margin: 0 10px;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }

    .filter-accept {
      margin-right: 10px;
      cursor: pointer;

      &:hover {
        opacity: 0.8;
      }
    }

    .filter-btn,
    .sort-btn {
      border-radius: 0;
    }

    .filter-search {
      align-items: center;
      display: flex;
      flex: 1 1 auto;
      font-size: 14px;
      padding: 0 10px;

      & > input:focus {
        box-shadow: 0 0 0 0 rgba(19, 124, 189, 0), 0 0 0 0 rgba(19, 124, 189, 0),
          inset 0 0 0 1px rgba(16, 22, 26, 0.15),
          inset 0 1px 1px rgba(16, 22, 26, 0.2);
      }
    }
  }

  &.delta-tools {
    display: flex;
    flex-direction: column;

    & > .delta-pages {
      margin-left: auto;
    }
  }
`;

const ToolsWrap = styled.div`
  margin-top: 24px;
  display: flex;
  align-items: center;
  justify-items: center;

  & > svg {
    margin-left: 48px;
    margin-right: 16px;
    cursor: pointer;
    height: 100%;
  }
`;

const CardsWrap = styled.div`
  margin-top: 32px;
  display: grid;
  grid-template-columns: repeat(3, minmax(0, 1fr));
  grid-gap: 16px;

  & > h3.no-clients {
    margin: auto;
    text-align: center;
    font-size: 27px;
    color: #a5a5a5;
    grid-column: span 3 / span 3;
  }

  &.attr-wrap {
    grid-template-columns: repeat(4, minmax(0, 1fr));
    grid-gap: 32px;
  }
`;

const MembersGrid = styled.div`
  font-size: 16px;
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 8px;
  max-width: 360px;
  margin-bottom: 24px;
  padding: 0 48px 0 8px;
  overflow-y: auto;
  max-height: 80px;
`;

export const ProjectBtnWrap = styled.div`
  display: grid;
  grid-template-columns: 130px 75px;
  grid-gap: 16px;
`;

// used by clientspaceSettings view

const SettingsWrap = styled.div`
  margin-bottom: 32px;

  .rename-wrap {
    display: flex;
    align-items: center;

    input {
      margin-bottom: 0;
    }

    & > button {
      margin: 0;
      margin-left: 32px;
    }
  }
`;

const SettingsHeader = styled.h2`
	margin: 0;
	color-inherit;
	font-weight: 500;
	font-size: 21px;
	margin-bottom: 6px;
`;

const DetailsWrap = styled.div`
  margin-bottom: 32px;
`;

const DetailsHeader = styled.h3`
  margin: 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 1;
  margin-bottom: 8px;
`;

const DetailsValue = styled.p`
  margin: 0;
  color: #4a90e2;
`;

export {
  ListWrap,
  ListContent,
  ClientsTopbar,
  TopbarLeft,
  ClientsList,
  ClientsTools,
  ToolsWrap,
  CardsWrap,
  MembersGrid,
  SettingsHeader,
  DetailsHeader,
  DetailsWrap,
  DetailsValue,
  SettingsWrap,
};
