import { default as AlignCenter } from "./AlignCenter";
import { default as AlignLeft } from "./AlignLeft";
import { default as AlignRight } from "./AlignRight";
import { default as Bold } from "./Bold";
import { default as Fill } from "./Fill";
import { default as Italic } from "./Italic";
import { default as LetterA } from "./LetterA";
import { default as Underline } from "./Underline";

export const Icons = {
  AlignCenter,
  AlignLeft,
  AlignRight,
  Bold,
  Fill,
  Italic,
  LetterA,
  Underline,
};
