import React from "react";
import useLogout from "../auth/context/useLogout";
import { Dropdown, DropdownItem } from ".";

const UserProfile = (props: any) => {
  const logout = useLogout();

  return (
    <div className="topbar-user user-profile">
      <Dropdown
        right={true}
        toggleIcon={true}
        title={<span className="user-text">{props.profile.name}</span>}
      >
        <DropdownItem>Settings</DropdownItem>
        <DropdownItem onClick={logout}>Sign out</DropdownItem>
      </Dropdown>
    </div>
  );
};
export default UserProfile;
