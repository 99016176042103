import * as React from "react";
import {useEffect, useState} from "react";
import {CubeViewProps, TreemapResult} from "../types";
import useModelsApi from "../../../api/useModelsApi";
import {STreeMap} from "../../../../@satsui/components/STreeMap/STreeMap";

export const ChartsTab = ({modelUuid, query}: CubeViewProps) => {
    const modelsApi = useModelsApi();

    const [result, setResult] = useState<TreemapResult>()

    useEffect(() => {
        modelsApi.cubeTreemap(modelUuid, "Component", query).then((response) => {
            setResult(response?.data);
        });
    }, [modelsApi, query]);

    return <div>
        Charts
    </div>
}