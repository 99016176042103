import React, { useEffect, useState } from "react";
import styled from "styled-components";
import { Dialog, Tab, Tabs } from "@blueprintjs/core";
import useModelApi from "../models/api/useModelsApi";
import { Attribute } from "../models/api/model";

const StyledDialog = styled(Dialog)`
  background-color: #fff;
  background: #fff;
  height: 510px;

  & > .bp3-dialog-header {
    box-shadow: none;

    & > h4 {
      font-size: 21px;
    }
  }

  .bp3-tab-list {
    box-shadow: 0 1px 0 rgba(16, 22, 26, 0.15);
    padding: 0 5px 12px 20px;
  }

  .custom-tab-btn {
    font-size: 16px;

    &:focus {
      outline: none;
    }
  }

  .info-panel-wrap {
    padding: 0 5px 0 20px;
    overflow-y: auto;

    & > .info-detail {
      margin-bottom: 20px;
    }

    h5 {
      font-size: 16px;
      margin: 0;
    }

    p {
      margin: 0;
    }
  }

  .allocation-panel-wrap {
    td:first-child,
    th:first-child {
      width: 50%;
    }
  }

  .associations-panel-wrap {
    td,
    th {
      max-width: 33%;
    }
  }

  .allocation-panel-wrap,
  .associations-panel-wrap {
    overflow-y: auto;
    max-height: 380px;

    & > table {
      width: 100%;
      border-collapse: collapse;
      border-style: hidden;
      border-top: solid 2px #f3f3f3;
    }

    th {
      font-weight: 500;
    }

    td,
    th {
      text-align: left;
      padding: 12px 18px;
      border: solid 2px #f3f3f3;
    }

    .data-chip {
      background-color: #f3f3f3;
      padding: 3px 5px;
      display: inline-block;
      color: inherit;
      text-decoration: none;
      border: none;
      margin-right: 8px;
      margin-bottom: 6px;
    }
  }
`;

const AttributeModel = (props: any) => {
  const {
    modelUuid,
    uuid,
    isOpen,
    setDisplayAttributeModel,
    columnNames,
    defaultSelectedTabId,
    allocation,
  } = props;
  const modelsApi = useModelApi();

  const [infoData, setInfoData] = useState<Attribute>();
  const [selectedTab, setSelectedTab] = useState<string>();

  useEffect(() => {
    modelsApi.getAttribute(modelUuid, uuid).then((response) => {
      const data = response?.data;
      modelsApi.getAttributeAssociations(modelUuid, uuid).then((response) => {
        data.associations = response?.data;
        setInfoData(data);
      });
    });
  }, [modelsApi, uuid, modelUuid]);

  return (
    <StyledDialog
      isOpen={isOpen}
      onClose={() => setDisplayAttributeModel({ display: false, uuid: uuid })}
      title={
        infoData?.properties?.name
          ? infoData?.kind + " / " + infoData?.properties?.name
          : infoData?.kind
      }
    >
      <div className="dialog-wrap">
        <Tabs
          id="TabsExample"
          onChange={(navbarTabId: any) => setSelectedTab(navbarTabId)}
          selectedTabId={selectedTab}
          defaultSelectedTabId={defaultSelectedTabId}
        >
          <Tab
            id="info-p"
            className="custom-tab-btn"
            title="Info"
            panel={
              <InfoPanel
                columnNames={columnNames}
                data={allocation ? infoData?.labels : infoData?.properties}
              />
            }
            panelClassName="info-panel-wrap"
          />
          <Tab
            id="allocation-p"
            className="custom-tab-btn"
            title={`Allocation (${
              allocation
                ? infoData?.split.length
                : infoData?.allocations?.length || "0"
            })`}
            panel={
              <AllocationPanel
                data={allocation ? infoData?.split : infoData?.allocations}
              />
            }
            panelClassName="allocation-panel-wrap"
          />
          <Tab
            id="associations-p"
            className="custom-tab-btn"
            title={`Associations (${infoData?.associations?.length || "0"})`}
            panel={<AssociationsPanel data={infoData?.associations} />}
            panelClassName="associations-panel-wrap"
          />
        </Tabs>
      </div>
    </StyledDialog>
  );
};
export default AttributeModel;

const InfoPanel = (props: any) => {
  const { columnNames, data } = props;
  return data
    ? columnNames.map((col: any) =>
        data.hasOwnProperty(col?.key) ? (
          <div className="info-detail">
            <h5>{col?.name}</h5>
            <p>{data[col?.key]}</p>
          </div>
        ) : null
      )
    : "loading..."; // TODO: add some spinner/skeleton component
};

const AllocationPanel = (props: any) => {
  const { data } = props;

  return (
    <table>
      <thead>
        <tr>
          <th>Allocation</th>
          <th>Models</th>
        </tr>
      </thead>
      <tbody>
        {data?.length > 0
          ? data.map((allocation: any) => {
              return (
                <tr>
                  <td>{allocation?.name}</td>
                  <td>
                    {allocation?.models
                      ? allocation?.models.map((model: any) => (
                          <span className="data-chip">{model?.name}</span>
                        ))
                      : ""}
                  </td>
                </tr>
              );
            })
          : null}
      </tbody>
    </table>
  );
};

const AssociationsPanel = (props: any) => {
  const { data } = props;

  return (
    <table>
      <thead>
        <tr>
          <th>Association</th>
          <th>Type</th>
          <th>Models</th>
        </tr>
      </thead>
      <tbody>
        {data?.length > 0
          ? data.map((association: any) => {
              return (
                <tr>
                  <td>{association?.name}</td>
                  <td>{association?.kind}</td>
                  <td>
                    {association?.models.map((model: any) => (
                      <span className="data-chip">{model?.name}</span>
                    ))}
                  </td>
                </tr>
              );
            })
          : null}
      </tbody>
    </table>
  );
};
