import { createBrowserHistory } from "history";
import { APP_MOUNT_URI } from "./config";

const history = createBrowserHistory({
  basename: APP_MOUNT_URI,
});

history.listen((_location, action) => {
  if (["PUSH"].includes(action)) {
    window.scroll({
      behavior: "smooth",
      top: 0,
    });
  }
});

export { history };
