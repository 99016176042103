import Axios, { AxiosInstance, AxiosPromise } from "axios";
import qs from "querystring"

import {
    Model,
    DeletePrinciple,
    DeleteModel,
    Pyramid,
    TreeModel,
    SchemaAttribute,
    KindAttribute,
    AttributeSummary,
    Principle,
    Component,
    PartialAllocation,
    ManyAllocations,
    EndpointMsg,
    AllocationRow,
    Attribute,
    Associations, GraphView
} from "./model";
import { API_URI } from "../../config";
import {NewModel, ProjectsApi} from "../../projectspaces/api";
import {
  CubeOptions,
  CubeQuery,
  BreakdownResult,
  SankeyResult, GeographyResult, By, AttributesResult, ComponentsResult, TreemapResult, ChartsResult
} from "../views/CubeView/types";
import {DrilldownResult} from "../views/CubeView/drilldown/types";

const paramsSerializer = (params: any) => {
  return qs.stringify(params)
}

class ModelsApi {
  static context = API_URI + "api/v1/models";
  static schemas = API_URI + "api/v1/schemas";
  static allocations = API_URI + "api/v1/allocations";

  client: AxiosInstance;

  constructor(token: string) {
    this.client = Axios.create({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  createModel(uuid: string, payload: NewModel): AxiosPromise<Model> {
    return this.client.post(`${ProjectsApi.base}/projects/${uuid}/models`, payload);
  }

  updateModel(uuid: string, descriptionValue: string): AxiosPromise<Model> {
    return this.client.put(`${ModelsApi.context}/${uuid}`, {
      properties: {
        description: descriptionValue,
      },
    });
  }

  getModel(uuid: string): AxiosPromise<Model> {
    return this.client.get(`${ProjectsApi.base}/models/${uuid}`);
  }

  getModelChildren(uuid: string): AxiosPromise<Record<string, Component>> {
    return this.client.get(
      `${ModelsApi.context}/${uuid}/children?kind=Division,BusinessUnit,Function,SubFunction`
    );
  }

  putModelChildren(uuid: string, children: Array<Component>) {
    return this.client.put(`${ModelsApi.context}/${uuid}/children`, children);
  }

  deleteModelChildren(
    uuid: string,
    ids: string[],
    execute: boolean
  ): AxiosPromise<Model> {
    return this.client.delete(`${ModelsApi.context}/${uuid}/children`, {
      data: { ids: ids, execute },
    });
  }

  addModelChildren(uuid: string, children: Array<Component>) {
    return this.client.post(`${ModelsApi.context}/${uuid}/children`);
  }

  addModelChild(uuid: string, child: Component) {
    return this.client.post(`${ModelsApi.context}/${uuid}/children`, child);
  }

  setModelChildren(uuid: string, children: Array<Component>) {
    return this.client.put(`${ModelsApi.context}/${uuid}`, { children });
  }

  getPrinciples(uuid: string): AxiosPromise<Array<Principle>> {
    return this.client.get(
      `${ModelsApi.context}/${uuid}/children?kind=Principle`
    );
  }

  createPrinciple(
    uuid: string,
    kindValue: string,
    textValue: string
  ): AxiosPromise<Model> {
    return this.client.post(`${ModelsApi.context}/${uuid}/children`, {
      kind: kindValue,
      properties: {
        text: textValue,
      },
    });
  }

  updateModelMet(
    uuid: string,
    metValue: boolean,
    principleID: string
  ): AxiosPromise<Model> {
    return this.client.put(
      `${ModelsApi.context}/${uuid}/children/${principleID}`,
      {
        properties: {
          met: metValue,
        },
      }
    );
  }

  deletePrinciple(
    uuid: string,
    principleID: string
  ): AxiosPromise<DeletePrinciple> {
    return this.client.delete(
      `${ModelsApi.context}/${uuid}/children/${principleID}`
    );
  }

  deleteModel(uuid: string): AxiosPromise<DeleteModel> {
    return this.client.delete(`${ModelsApi.context}/${uuid}`);
  }

  getPyramidView(uuid: string): AxiosPromise<Pyramid> {
    return this.client.get(`${ModelsApi.context}/${uuid}/views/pyramid`);
  }

  getTreeView(uuid: string): AxiosPromise<Array<TreeModel>> {
    return this.client.get(`${ModelsApi.context}/${uuid}/views/tree`);
  }

  searchTreeView(uuid: string, search: string): AxiosPromise<Array<TreeModel>> {
    return this.client.get(
      `${ModelsApi.context}/${uuid}/views/tree?search=${search}`
    );
  }

  getHierarchy(
      uuid: string
  ): AxiosPromise<any> {
    return this.client.get(`${ModelsApi.context}/${uuid}/hierarchy`);
  }

  getModelTree(
      uuid: string
  ): AxiosPromise<any> {
    return this.client.get(`${ModelsApi.context}/${uuid}/tree`);
  }

  getOrgChart(
      uuid: string
  ): AxiosPromise<GraphView> {
    return this.client.get(`${ModelsApi.context}/${uuid}/org-chart`);
  }

  cubeOptions(
      uuid: string
  ): AxiosPromise<CubeOptions> {
    return this.client.get(`${ModelsApi.context}/${uuid}/cube/options`);
  }

  cubeGeography(
      uuid: string,
      data: CubeQuery
  ): AxiosPromise<GeographyResult> {
    return this.client.post<GeographyResult>(`${ModelsApi.context}/${uuid}/cube/geography`, data);
  }

  cubeComponents(
      uuid: string,
      data: CubeQuery
  ): AxiosPromise<ComponentsResult> {
    return this.client.post<ComponentsResult>(`${ModelsApi.context}/${uuid}/cube/components`, data);
  }

  cubeAttributes(
      uuid: string,
      data: CubeQuery
  ): AxiosPromise<AttributesResult> {
    return this.client.post<AttributesResult>(`${ModelsApi.context}/${uuid}/cube/attributes`, data);
  }

  cubeTable(
      uuid: string,
      data: CubeQuery
  ): AxiosPromise<DrilldownResult> {
    return this.client.post(`${ModelsApi.context}/${uuid}/cube/table`, data);
  }

  cubeSankey(
      uuid: string,
      breakdown: By,
      data: CubeQuery
  ): AxiosPromise<SankeyResult> {
    return this.client.post<SankeyResult>(`${ModelsApi.context}/${uuid}/cube/sankey?breakdown=${breakdown}`, data);
  }

  cubeTreemap(
      uuid: string,
      breakdown: By,
      data: CubeQuery
  ): AxiosPromise<TreemapResult> {
    return this.client.post<TreemapResult>(`${ModelsApi.context}/${uuid}/cube/treemap?breakdown=${breakdown}`, data);
  }

  cubeCharts(
      uuid: string,
      breakdown: By,
      data: CubeQuery
  ): AxiosPromise<ChartsResult> {
    return this.client.post<ChartsResult>(`${ModelsApi.context}/${uuid}/cube/charts?breakdown=${breakdown}`, data);
  }

  getSatsuiControls(
      uuid: string
  ): AxiosPromise<any> {
    return this.client.get(`${ProjectsApi.context}/${uuid}/satsui/controls`);
  }

  getSatsui(
      uuid: string
  ): AxiosPromise<GraphView> {
    return this.client.get(`${ProjectsApi.context}/${uuid}/satsui`);
  }

  getSchemaAttributes(): AxiosPromise<Array<SchemaAttribute>> {
    return this.client.get(`${ModelsApi.schemas}?type=attribute`);
  }

  getAttributes(
    uuid: string,
    kind: string,
    allocatedTo?: string,
    limit?: number,
    offset?: number,
    filter?: string,
    sort?: string,
    search?: string
  ): AxiosPromise<KindAttribute> {
    const params = {
      kind,
      allocatedTo,
      limit,
      offset,
      filter,
      sort,
      search,
    };

    return this.client.get(`${ModelsApi.context}/${uuid}/attributes`, {
      params,
    });
  }

  getAttributesSummary(
    uuid: string,
    allocatedTo?: string
  ): AxiosPromise<Array<AttributeSummary>> {
    const params = {
      allocatedTo,
    };

    return this.client.get(`${ModelsApi.context}/${uuid}/attributes/summary`, {
      params,
    });
  }

  getAttribute(
    modelUuid: string,
    attributeUuid: string
  ): AxiosPromise<Attribute> {
    return this.client.get(
      `${ModelsApi.context}/${modelUuid}/attributes/${attributeUuid}`
    );
  }

  updateModelChild(
    uuid: string,
    childId: string,
    descriptionValue?: string,
    nameValue?: string
  ): AxiosPromise<Component> {
    return this.client.put(`${ModelsApi.context}/${uuid}/children/${childId}`, {
      properties: {
        description: descriptionValue,
        name: nameValue,
      },
    });
  }

  createPartialAllocations(
    uuid: string,
    payload: PartialAllocation
  ): AxiosPromise<KindAttribute> {
    return this.client.post(`${ModelsApi.context}/${uuid}/attributes`, payload);
  }

  updateManyAllocations(
    uuid: string,
    payload: Array<ManyAllocations>
  ): AxiosPromise<EndpointMsg> {
    return this.client.put(
      `${ModelsApi.context}/${uuid}/allocations2`,
      payload
    );
  }

  createAllocationRow(
    uuid: string,
    payload: {
      id: string;
      kind: string;
    }
  ): AxiosPromise<AllocationRow> {
    return this.client.post(
      `${ModelsApi.context}/${uuid}/allocations`,
      payload
    );
  }

  createAttributeRow(
    uuid: string,
    payload: {
      id: string;
      kind: string;
      properties: { [key: string]: any };
      split?: { [key: string]: number };
    }
  ): AxiosPromise<AllocationRow> {
    return this.client.post(`${ModelsApi.context}/${uuid}/attributes`, payload);
  }

  updateAttributeCell(
    uuid: string,
    attributeUuid: string,
    payload: {
      id: string;
      kind: string;
      properties: { [key: string]: any };
      partial?: boolean;
      split?: { [key: string]: number };
    }
  ): AxiosPromise<AllocationRow> {
    return this.client.put(
      `${ModelsApi.context}/${uuid}/attributes/${attributeUuid}`,
      payload
    );
  }

  updateManyAttributeCells(
    uuid: string,
    payload: Array<{
      uuid?: string | null;
      id?: string;
      attributeUuid?: string;
      kind: string | null;
      properties: { [key: string]: any };
      partial?: boolean;
      split?: { [key: string]: number };
    }>
  ): AxiosPromise<AllocationRow> {
    return this.client.put(`${ModelsApi.context}/${uuid}/attributes`, payload);
  }

  updateAllocationCell(
    uuid: string,
    payload: {
      properties: { [key: string]: any };
    }
  ): AxiosPromise<AllocationRow> {
    return this.client.put(`${ModelsApi.allocations}/${uuid}`, payload);
  }

  updateAllocationCell2(
    uuid: string,
    payload: Array<{
      attributeUuid: string;
      properties: { [key: string]: any };
      componentId?: string;
      allocated?: string;
    }>
  ): AxiosPromise<Array<AllocationRow>> {
    return this.client.put(
      `${ModelsApi.context}/${uuid}/allocations2`,
      payload
    );
  }

  updateManyAllocationCells(
    uuid: string,
    payload: Array<{
      id?: any;
      properties: { [key: string]: any };
    }>
  ): AxiosPromise<AllocationRow> {
    return this.client.put(`${ModelsApi.context}/${uuid}/allocations`, payload);
  }

  getCandidates(
    uuid: string,
    kind?: string,
    allocatedTo?: string,
    partial?: boolean,
    search?: string,
    filter?: string,
    sort?: string
  ): AxiosPromise<KindAttribute> {
    const params = {
      kind,
      allocatedTo,
      partial,
      search,
      filter,
      sort,
    };
    return this.client.get(`${ModelsApi.context}/${uuid}/candidates`, {
      params,
    });
  }

  removeRowAttribute(
    modelUuid: string,
    attributeUuid: string
  ): AxiosPromise<EndpointMsg> {
    return this.client.delete(
      `${ModelsApi.context}/${modelUuid}/attributes/${attributeUuid}`
    );
  }

  getAttributeAssociations(
    modelUuid: string,
    attributeUuid: string
  ): AxiosPromise<Array<Associations>> {
    return this.client.get(
      `${ModelsApi.context}/${modelUuid}/attributes/${attributeUuid}/associations`
    );
  }
}

export default ModelsApi;
