import axios, { AxiosPromise } from "axios";

import { AuthResult, Login, Signup } from "./model";
import { API_URI } from "../../config";

class AuthApi {
  static context = API_URI + "api/v1/auth";

  signup(payload: Signup): AxiosPromise<AuthResult> {
    return axios.post(`${AuthApi.context}/signup`, payload);
  }

  login(payload: Login): AxiosPromise<AuthResult> {
    return axios.post(`${AuthApi.context}/login`, payload);
  }
}

export default AuthApi;
