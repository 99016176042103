import {Button, FormGroup, MenuItem, OptionProps, Radio, RadioGroup} from "@blueprintjs/core";
import * as React from "react";
import {useEffect, useState} from "react";
import styled from "styled-components";
import {By, CubeOptions, CubeQuery} from "../types";
import {Icons} from "../../../../assets/Icons";
import {defaultHryOpts} from "./defaults";
import {HierarchySelect} from "./HierarchySelect";
import {InsightsPage} from "../CubeView";
import {defaultQuery} from "../index";
import {NodeTreeView2} from "../components/NodeTreeView2";
import {ItemRenderer, Select} from "@blueprintjs/select";
import {IconOptionProps} from "./types";
import {PageSelect} from "./PageSelect";
import {TreeSelect} from "antd";

const FilterColumn = styled.div<{ collapsed: boolean }>`
        border-right: ${(p) => (p.collapsed ? "none" : "2px solid #f3f3f3")};   
        padding: ${(p) => (p.collapsed ? "0 2px" : "12px 12px 0")};
        // width: ${(p) => (p.collapsed ? "24px" : "264px")};
        width: 100%;
        overflow-y: auto;
        
        & > h4 {
            text-transform: uppercase;
            font-size: 12px;
            font-weight: bold;
            margin: 0;
            margin-top: 12px;
            padding: 0 6px;
            color: #6d7278;
            letter-spacing: 0.035em;
          }
    `


export type QueryPanelProps = {
    page: InsightsPage
    tabs: OptionProps[]
    setSelectedPage: (p: InsightsPage) => void
    options: CubeOptions
    queryChanged: (q: CubeQuery) => void
}

const iconSize = 20
const labelFontSize = 16

/*const ComponentsLabel = () => <Label>
    <Icons.Division size={iconSize} style={{verticalAlign: "middle"}}/> <span
    style={{fontSize: labelFontSize, verticalAlign: "middle", paddingLeft: 4}}>Components</span>
</Label>*/

const QueryInputLabel = ({icon, text}: { icon?: JSX.Element, text: string }) =>
    <span>
        {icon ? <span style={{width: iconSize, height: iconSize}}>
            {icon}
        </span>: <></>}
        <span style={{verticalAlign: "middle", fontSize: labelFontSize, paddingLeft: 8 + (!icon ? -8 : 0)}}>
            {text}
        </span>
    </span>

/*const AttributesLabel = () => <p>
    <Icons.ProjectData size={iconSize} style={{}}/> <span
    style={{fontSize: labelFontSize, verticalAlign: "middle", paddingLeft: 4}}>Attributes</span>
</p>*/

const HSelect = Select.ofType<IconOptionProps>()

function handleStringChange(handler: (value: string) => void) {
    return (event: React.FormEvent<HTMLElement>) => handler((event.target as HTMLInputElement).value);
}

const renderOption: ItemRenderer<OptionProps> = (opt, {handleClick, modifiers, query}) => {
    if (!modifiers.matchesPredicate) {
        return null;
    }
    const text = `${opt.label}`;
    return (
        <MenuItem
            active={modifiers.active}
            disabled={modifiers.disabled}
            // label={opt.name.toString()}
            key={opt.value}
            onClick={handleClick}
            text={text}
            // text={highlightText(text, query)}
        />
    );
};

export const QueryPanel = ({page, tabs, setSelectedPage, options, queryChanged}: QueryPanelProps) => {
    const [query, setQuery] = useState<CubeQuery>(defaultQuery)

    const [drilldown, setDrilldown] = useState<string | undefined>(undefined)
    // const [by, setBy] = useState<By>("Component")

    const [component, setComponents] = useState<string[]>([])
    const [location, setLocations] = useState<string[]>([])
    const [attribute, setAttributes] = useState<string[]>([])

    useEffect(
        () => {
            const q = {
                drilldown,
                filters: {
                    component,
                    location,
                    attribute
                }
            }
            handleQueryChange(q)
        },
            [drilldown, component, location, attribute]
    )

    const handleQueryChange = (query: CubeQuery) => {
        setQuery(query)
        queryChanged(query)
    }

    const handleByChange = (id: string) => {
        const q = {
            ...query,
            by: id as By
        }

        setQuery(q)
        queryChanged(q)
    }

    const showLabel = `Show me`

    const hierarchyLabel = `${page} by`

    // const handleRadioChange: ChangeEventHandler<HTMLInputElement> = (event) => {
    //     handleByChange(event.target.value)
    // }

    const handlePanelSelect = (p: IconOptionProps) => {
        setSelectedPage(p.value as InsightsPage)
    }

    const handleRadioChange = handleStringChange(value => handleByChange( value))

/*    const hSelect2 = <RadioGroup
        label={<h3>Drilldown by</h3>}
        options={options.by.options}

        onChange={handleRadioChange}

        // onChange={(e) => handleByChange(e.)}
        selectedValue={query.by}
    >
        {/!*<Radio label="Component" value="ByComponent" />*!/}
        {/!*<Radio label="Location" value="ByLocation" />*!/}
    </RadioGroup>*/

    return <FilterColumn collapsed={false}>
        <h2 className="mx-auto">
            Insights
        </h2>

        <h3 className="mx-auto">
            Drilldown
        </h3>

        <FormGroup
            // label={<QueryInputLabel icon={<Icons.ProjectData/>} text="Drilldown"/>}
            // labelFor="select"
            // labelInfo="(many)"
            // helperText="Helper text with details..."
        >
            <TreeSelect
                treeData={options.drilldown.options}
                value={query.drilldown}
                onChange={() => {}}
                placeholder="any"

                multiple={false}

                // treeCheckable
                showCheckedStrategy={"SHOW_PARENT"}
                // treeLine={true}

                style={{width: "100%"}}
            />
        </FormGroup>

        {/*{hSelect2}*/}

        {/*<h3>Drilldown by</h3>*/}
        {/*<FormGroup
            label={<QueryInputLabel icon={<Icons.ProjectData/>} text="Drilldown by"/>}
            labelFor="select"
            // labelInfo="(many)"
            // helperText="Helper text with details..."
        >
            <NodeTreeView2 selection={[query.drilldown]} nodes={options.drilldown.options} onChange={setAttributes}/>
        </FormGroup>*/}

        <h3 className="mx-auto">
            Such that
        </h3>

        <FormGroup
            label={<QueryInputLabel icon={<Icons.ProjectData/>} text="Attribute is"/>}
            labelFor="select"
            // labelInfo="(many)"
            // helperText="Helper text with details..."
        >
            <NodeTreeView2 selection={query.filters.attribute} nodes={options.attributes.options} onChange={setAttributes}/>
        </FormGroup>

        <FormGroup
            label={<QueryInputLabel icon={<Icons.Division/>} text="in Component(s)"/>}
            labelFor="select"
            // labelInfo="(many)"
            // helperText="Helper text with details..."
        >
            <NodeTreeView2 selection={query.filters.component} nodes={options.components.options} onChange={setComponents}/>
        </FormGroup>


        <FormGroup
            label={<QueryInputLabel icon={<Icons.World/>} text="in Location(s)"/>}
            labelFor="select"
            // labelInfo="(many)"
            // helperText="Helper text with details..."
        >
            <NodeTreeView2 selection={query.filters.location} nodes={options.locations.options} onChange={setLocations}/>
        </FormGroup>

        {/*<pre>
            {JSON.stringify(query, null, 2)}
        </pre>*/}
    </FilterColumn>
}