import styled from "styled-components";

export const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid #d4d4d4;
  padding: 24px 16px;
  height: auto;
  min-height: 15px;
  line-height: 1;
  font-size: 16px;
  grid-column: 1/5;
  grid-row: 1/3;

  h3 {
    margin: 0;
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 500;
  }

  & > div {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .total-num {
    font-size: 36px;
    font-weight: bold;
    line-height: 1.625;
    letter-spacing: 0;
  }

  .total-text {
    font-weight: 500;
    line-height: 1.35;
    letter-spacing: 0.025em;
  }

  .total-model {
    color: #9c9c9c;
  }

  .location-chart-wrap {
    width: 100%;
    height: 100%;
  }

  .location-label {
    font-size: 14px;
    font-weight: 500;
  }

  .location-tooltip {
    background-color: #fff;
    border: 1px solid #d4d4d4;
    color: #0f1a28;
    border-radius: 4px;
    padding: 8px 12px;
    font-size: 14px;

    & > p {
      margin: 0;
      font-weight: 500;
    }

    .tooltip-added {
      color: #7ed321;
      margin-bottom: 6px;
    }

    .tooltip-removed {
      color: #d0021b;
      margin-bottom: 6px;
    }

    &::after {
      content: "";
      position: absolute;
      top: 98%;
      left: 50%;
      margin-left: -6px;
      border-width: 6px;
      border-style: solid;
      border-color: #fff transparent transparent transparent;
    }
  }
`;
