import * as React from "react";
import { useEffect, useState, useRef } from "react";
import useAuth from "../../../auth/context/useAuth";
import { RouteComponentProps } from "react-router";
import {
  ClientSwitcher,
  UserProfile,
  Pagination,
  NavLogo,
} from "../../../components";
import {
  ListWrap,
  ListContent,
  ClientsTopbar,
  ClientsList,
  TopbarLeft,
  ClientsTools,
} from "../../../clientspaces/components/CsListView";
import useProjectsApi from "../../api/useProjectsApi";
import { Project } from "../../api/model";
import { Icons } from "../../../assets/Icons";
import {
  DataWrap,
  DeltaNav,
  SectionList,
  RadioWrap,
  TableError,
  ViewSelect,
  DeltaBreadcrumb,
} from "../../components/ProjectsView";
import { SchemaAttribute, Model } from "../../../models/api/model";
import useModelsApi from "../../../models/api/useModelsApi";
import DeltaTree from "../../components/DeltaTree";
import { DeltaColumns } from "../../components/DeltaColumns";
import { Link } from "react-router-dom";
import { HotTable } from "@handsontable/react";
import Handsontable from "handsontable";
import Core from "handsontable";
import AutoSizer from "react-virtualized-auto-sizer";
import AttributeModel from "../../../components/AttributeModel";

type ViewProps = RouteComponentProps<{
  project: string;
}>;

type TableColumnsType = {
  data: string;
  type: string;
  source?: Array<string>;
  readOnly?: boolean;
};

interface AttributeDisplay {
  display: boolean;
  uuid: string;
  allocation?: boolean;
  defaultSelectedTabId?: string | undefined;
}

export const View: React.FC<ViewProps> = ({ match, location, history }) => {
  const auth = useAuth();
  const projectsApi = useProjectsApi();

  const params = new URLSearchParams(location?.search);
  const OFFSET = parseInt(params.get("offset") || "0", 10);

  const modelsApi = useModelsApi();

  const [activeProject, setActiveProject] = useState<Project>();
  const [totalPages, setTotalPages] = useState(1);

  const [displayModelsList, setDisplayModelsList] = useState(true);
  const [modelsList, setModelsList] = useState<Array<Model>>([]);
  const [selectedModel, setSelectedModel] = useState("");
  const [displayAttributesList, setDisplayAttributesList] = useState(true);
  const [attributesList, setAttributesList] = useState<Array<SchemaAttribute>>(
    []
  );
  const [selectedAttribute, setSelectedAttribute] = useState("");
  const [displayAnalyseList, setDisplayAnalyseList] = useState(true);
  const [displayComponentsList, setDisplayComponentsList] = useState(true);
  const [selectedComponent, setSelectedComponent] = useState();
  const [refreshTree, setRefreshTree] = useState(false);
  const [isLoaded, setIsLoaded] = useState(false);
  const [breadcrumbModel, setBreadcrumbModel] = useState("");
  const [breadcrumbAttribute, setBreadcrumbAttribute] = useState("");
  const [breadcrumbComponent, setBreadcrumbComponent] = useState("");

  const [tableRows, setTableRows] = useState<Array<{ [key: string]: any }>>([]);
  const [tableColumns, setTableColumns] = useState<Array<TableColumnsType>>([]);
  const [tableHeaders, setTableHeaders] = useState<
    Array<{ name: string; key: string; type: string }>
  >([]);
  const hot = useRef<any>(null);
  const [hiddenColumns, setHiddenColumns] = useState<Array<number>>([]);

  const [displayAttributeModel, setDisplayAttributeModel] = useState<
    AttributeDisplay
  >({
    display: false,
    uuid: "",
  });

  useEffect(() => {
    projectsApi.getModels(match?.params?.project).then((response) => {
      if (response?.data.length > 0) {
        // select first model by default
        setBreadcrumbModel(response?.data[0].name);
        setSelectedModel(response?.data[0].uuid);
      }
      setModelsList(response?.data);
    });
  }, [projectsApi, match]);

  useEffect(() => {
    if (selectedAttribute) {
      modelsApi.getSchemaAttributes().then((response) => {
        setAttributesList(response?.data);
        const temp_arr = [];
        temp_arr.push(response?.data);

        const attributeIndex = temp_arr[0].findIndex(
          (attr) => attr?.kind === selectedAttribute
        );

        if (attributeIndex > -1) {
          const columns = response?.data[attributeIndex]?.properties;
          const columnsKeys = Object.keys(columns);

          const defaultColumns = columnsKeys.map((key: string) => ({
            data: key,
            type:
              columns[key].type === "link"
                ? "dropdown"
                : columns[key].type !== "number"
                ? "autocomplete"
                : "numeric",
            renderer:
              columns[key].type === "link" ? "link.render" : "display.render",
            readOnly: true,
          }));

          const headersName = columnsKeys.map((key: string) => ({
            name: columns[key]?.meta.name,
            key: key,
            type: columns[key].type,
          }));

          // add model allocations column
          headersName.push({
            name: "Model Allocations",
            key: "split",
            type: "link",
          });
          defaultColumns.push({
            data: "split",
            renderer: "allocation.render",
            type: "text",
            readOnly: true,
          });

          setTableHeaders(headersName);
          setTableColumns(defaultColumns);
        }
      });
    }
  }, [modelsApi, match, selectedAttribute]);

  useEffect(() => {
    if (selectedModel.length > 0 && !isLoaded) {
      modelsApi.getAttributesSummary(selectedModel).then((response) => {
        const summary = response?.data;
        if (summary) {
          /// find the first attribute with data insterted
          // and save it as a selected attribute by default
          for (let i = 0; i < summary.length; i++) {
            if (summary[i].total > 0) {
              setBreadcrumbAttribute(
                summary[i].entityMeta.plural
                  ? summary[i].entityMeta.plural
                  : summary[i].entityMeta.name
              );
              setSelectedAttribute(summary[i].kind);
              setIsLoaded(true);

              break;
            }

            // if all the attributes don't have any data in them at all
            // then select the first one by default
            if (i === summary.length - 1) {
              setSelectedAttribute(summary[0].kind);
              setIsLoaded(true);

              break;
            }
          }
        }
      });
    }
    //eslint-disable-next-line
  }, [modelsApi, selectedModel, isLoaded, attributesList]);

  useEffect(() => {
    projectsApi
      .getProject(match?.params?.project)
      .then((response) => {
        setActiveProject(response?.data);
      })
      .catch((error) => console.log(error));
  }, [projectsApi, match]);

  useEffect(() => {
    const getTableData = () => {
      if (selectedModel && selectedAttribute) {
        modelsApi
          .getAttributes(
            selectedModel,
            selectedAttribute,
            undefined,
            100,
            OFFSET
          )
          .then((response) => {
            setTotalPages(Math.ceil(response?.data.total / 50));
            const rows = response?.data?.results.map((result: any) => {
              const obj = result.labels;
              obj.uuid = result.uuid;

              const linkKeys = Object.keys(result.links);
              linkKeys.map((key: string) => {
                obj[key] = result.links[key];
                return null;
              });

              obj["split"] = result.split.map((model: any) => ({
                name: model.name,
                uuid: result.uuid,
              }));

              obj["partial"] = result.partial;

              return obj;
            });

            setTableRows(rows);
          });
      }
    };
    getTableData();
  }, [modelsApi, selectedModel, selectedAttribute, selectedComponent, OFFSET]);

  function handlePageChange(page: number) {
    history.push(`${match.url}?offset=${page * 50 - 50}`);
  }

  // user can select which columns to display
  function handleColumnsChange(e: any) {
    e.persist();

    if (e.target.checked) {
      const temp_arr = Array.from(hiddenColumns);
      const index = temp_arr.indexOf(parseInt(e.target.value));
      if (index > -1) {
        temp_arr.splice(index, 1);
        setHiddenColumns(temp_arr);
      }
    } else {
      setHiddenColumns((column: any) =>
        column.concat(parseInt(e.target.value))
      );
    }
  }

  (function (Handsontable) {
    function customRenderer(
      instance: Core,
      td: HTMLTableCellElement,
      row: number,
      col: number,
      prop: React.ReactText,
      value: any,
      cellProperties: Handsontable.CellProperties
    ) {
      let button;
      if (
        value?.label !== "-" &&
        value?.label !== null &&
        value?.label !== undefined
      ) {
        button = document.createElement("button");
        button.innerHTML = value?.label;
        button.className = "link-btn";

        if (value?.label !== "Null") {
          Handsontable.dom.addEvent(button, "mousedown", function (e: any) {
            e.preventDefault(); // prevent selection quirk
            setDisplayAttributeModel({
              uuid: value?.uuid,
              display: true,
            });
          });
        }

        Handsontable.dom.empty(td);
        td.appendChild(button);
        td.className = "link-cell";
        cellProperties.readOnly = true;

        if (tableRows[row].partial) {
          td.classList.add("partial-cell");
          cellProperties.className = "partial-cell";
          cellProperties.readOnly = true;
        }
      } else {
        Handsontable.renderers.TextRenderer(
          instance,
          td,
          row,
          col,
          prop,
          value?.label,
          cellProperties
        );
      }
    }

    // Register an alias
    Handsontable.renderers.registerRenderer("link.render", customRenderer);
  })(Handsontable);

  (function (Handsontable) {
    function customRenderer(
      instance: Core,
      td: HTMLTableCellElement,
      row: number,
      col: number,
      prop: React.ReactText,
      value: any,
      cellProperties: Handsontable.CellProperties
    ) {
      if (value && value.length > 0) {
        Handsontable.dom.empty(td);
        for (let i = 0; i < value.length; i++) {
          if (value[i].name !== null) {
            let button;
            button = document.createElement("button");
            button.innerHTML = value[i]?.name;
            button.className = "link-btn";
            button.id = value?.id;

            Handsontable.dom.addEvent(button, "mousedown", function (e: any) {
              e.preventDefault(); // prevent selection quirk
              setDisplayAttributeModel({
                uuid: e.target?.id,
                display: true,
                allocation: true,
              });
            });

            Handsontable.dom.empty(td);
            td.appendChild(button);
            td.className = "link-cell";
            cellProperties.readOnly = true;
          } else {
            td.innerHTML = "";
          }
        }
      } else {
        td.innerHTML = value;
      }

      if (tableRows[row]?.partial) {
        td.classList.add("partial-cell");
        cellProperties.className = "partial-cell";
        cellProperties.readOnly = true;
      }
    }

    // Register an alias
    Handsontable.renderers.registerRenderer(
      "allocation.render",
      customRenderer
    );
  })(Handsontable);

  (function (Handsontable) {
    function customRenderer(
      instance: Core,
      td: HTMLTableCellElement,
      row: number,
      col: number,
      prop: React.ReactText,
      value: any,
      cellProperties: Handsontable.CellProperties
    ) {
      if (tableRows && row < tableRows.length) {
        if (tableRows[row]) {
          const value = tableRows[row][prop];
          td.innerHTML = value;

          if (tableRows[row].partial) {
            td.classList.add("partial-cell");
            cellProperties.className = "partial-cell";
            cellProperties.readOnly = false;
          }
        }
      } else {
        td.innerHTML = value;
      }
    }

    // Register an alias
    Handsontable.renderers.registerRenderer("display.render", customRenderer);
  })(Handsontable);

  return (
    <ListWrap className="delta-view">
      <DeltaNav className="delta-nav">
        <Link to="/">
          <NavLogo />
        </Link>

        <div className="nav-section">
          <h3
            onClick={() =>
              setDisplayModelsList((displayModelsList) => !displayModelsList)
            }
          >
            Models
            {displayModelsList ? (
              <Icons.ArrowDown size={18} />
            ) : (
              <Icons.ArrowLeft size={18} />
            )}
          </h3>
          {displayModelsList && (
            <SectionList
              onChange={(e: any) => {
                setSelectedModel(e.target.value);
                setSelectedComponent(undefined); // reset components tree
                setRefreshTree((refreshTree) => !refreshTree); // re-render the tree component
                setBreadcrumbModel(e.target.id);
              }}
            >
              {modelsList.length > 0 &&
                modelsList.map((m: any) => {
                  return (
                    <RadioWrap key={m.id}>
                      <label htmlFor={"model-" + m.id}>
                        {m.name}
                        <input
                          type="radio"
                          name="model-radio"
                          value={m.uuid}
                          id={m.name}
                          checked={m.uuid === selectedModel}
                        />
                      </label>
                    </RadioWrap>
                  );
                })}
            </SectionList>
          )}
        </div>

        <div className="nav-section">
          <h3
            onClick={() =>
              setDisplayAttributesList(
                (displayAttributesList) => !displayAttributesList
              )
            }
          >
            Attributes
            {displayAttributesList ? (
              <Icons.ArrowDown size={18} />
            ) : (
              <Icons.ArrowLeft size={18} />
            )}
          </h3>
          {displayAttributesList && (
            <SectionList
              onChange={(e: any) => {
                // save attribute name to the breadcrumb list object
                setBreadcrumbAttribute(e.target.id);
                setHiddenColumns([]); // reset columns
                setSelectedAttribute(e.target.value);

                setSelectedComponent(undefined);
                setRefreshTree((refreshTree) => !refreshTree); // re-render the tree component
              }}
            >
              {attributesList.length > 0 &&
                attributesList.map((attr: any, i: number) => {
                  const attrName = attr?.meta.plural
                    ? attr?.meta.plural
                    : attr?.meta.name;

                  return (
                    <RadioWrap key={i}>
                      <label htmlFor={attrName}>
                        {attrName}
                        <input
                          type="radio"
                          name="attr-radio"
                          id={attrName}
                          value={attr.kind}
                          checked={
                            attr?.kind === selectedAttribute ? true : false
                          } // todo: fix console error?
                        />
                      </label>
                    </RadioWrap>
                  );
                })}
            </SectionList>
          )}
        </div>

        <div className="nav-section">
          <h3
            onClick={() =>
              setDisplayAnalyseList((displayAnalyseList) => !displayAnalyseList)
            }
          >
            Analyse
            {displayAnalyseList ? (
              <Icons.ArrowDown size={18} />
            ) : (
              <Icons.ArrowLeft size={18} />
            )}
          </h3>
          {displayAnalyseList && (
            <DeltaColumns
              title="Columns"
              className="col-dropdown"
              selectedAttribute={selectedAttribute}
              attributesList={attributesList}
              hiddenColumns={hiddenColumns}
              handleColumnsChange={(e: any) => handleColumnsChange(e)}
            />
          )}
        </div>

        <div className="nav-section">
          <h3
            onClick={() =>
              setDisplayComponentsList(
                (displayComponentsList) => !displayComponentsList
              )
            }
          >
            Components
            {displayComponentsList ? (
              <Icons.ArrowDown size={18} />
            ) : (
              <Icons.ArrowLeft size={18} />
            )}
          </h3>
          {displayComponentsList && selectedModel.length > 0 ? (
            <DeltaTree
              ID={selectedModel}
              refreshTree={refreshTree}
              setSelectedComponent={setSelectedComponent}
              setBreadcrumbComponent={setBreadcrumbComponent}
              selectedComponent={selectedComponent}
            />
          ) : null}
        </div>
      </DeltaNav>

      <ListContent className="delta-content">
        <ClientsTopbar>
          <div className="delta-breadcrumbs-wrap">
            <ClientSwitcher
              clientspaceUuid={activeProject?.clientspace?.uuid}
              projectData={activeProject}
            />
            <DeltaBreadcrumb>
              {breadcrumbModel
                ? breadcrumbComponent
                  ? breadcrumbModel +
                    " / " +
                    breadcrumbComponent +
                    " / " +
                    breadcrumbAttribute
                  : breadcrumbModel + " / " + breadcrumbAttribute
                : null}
            </DeltaBreadcrumb>
          </div>

          <TopbarLeft>
            {auth.profile && <UserProfile profile={auth.profile} />}
          </TopbarLeft>
        </ClientsTopbar>
        <ClientsList>
          <ClientsTools className="delta-tools">
            <ViewSelect>
              <Link to={match.url} className="active">
                Data
              </Link>
              <Link to={`/projects=${activeProject?.id}/delta-comparison`}>
                Comparison
              </Link>
            </ViewSelect>

            <Pagination
              handlePageChange={handlePageChange}
              totalPages={totalPages}
              className="delta-pages"
            />
          </ClientsTools>
          {selectedModel.length > 0 && selectedAttribute.length > 0 ? (
            tableHeaders && tableRows && tableRows.length > 0 ? (
              <DataWrap className="data-with-table">
                <AutoSizer>
                  {({ height, width }) => (
                    <HotTable
                      ref={hot}
                      className="data-table"
                      columns={tableColumns}
                      data={tableRows}
                      colHeaders={(index: any) => {
                        return tableHeaders[index]?.name;
                      }}
                      width={width}
                      licenseKey="non-commercial-and-evaluation"
                      stretchH="all"
                      manualColumnResize
                      height={height}
                      rowHeights={28}
                      maxRows={100}
                      rowHeaders={true}
                      hiddenColumns={{ columns: hiddenColumns }}
                    />
                  )}
                </AutoSizer>
              </DataWrap>
            ) : (
              <TableError>Sorry, there is no data to display.</TableError>
            )
          ) : (
            <TableError>
              To view data, select attribute and model first
            </TableError>
          )}
          {displayAttributeModel?.display === true && selectedModel && (
            <AttributeModel
              modelUuid={selectedModel}
              uuid={displayAttributeModel?.uuid}
              isOpen={displayAttributeModel?.display}
              allocation={displayAttributeModel?.allocation}
              defaultSelectedTabId={displayAttributeModel?.defaultSelectedTabId}
              setDisplayAttributeModel={setDisplayAttributeModel}
              columnNames={tableHeaders}
            />
          )}
        </ClientsList>
      </ListContent>
    </ListWrap>
  );
};

export default View;
