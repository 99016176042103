import { connect, ConnectedProps } from "react-redux";
import { StoreType } from "../../../../../store";
import {
  setToolbar,
  setEditMode,
} from "../../../../../store/referencePage/toolbarActions";
import { thunkRemoveChildren } from "../../../../../store/referencePage/reference/actions";
import { ComponentStyle } from "../../../../../store/referencePage/reference/commonTypes";
import Toolbar from "./Toolbar";

const mapStateToProps = (state: StoreType) => ({
  toolbarProps: state.toolbar,
  state: state.reference,
});

const mapDispatchToProps = {
  setToolbar: (style: ComponentStyle) => setToolbar(style),
  setEditMode: (mode: boolean) => setEditMode(mode),
  removeChildren: (ids: string[], nestingLevel: number) =>
    thunkRemoveChildren(ids, nestingLevel),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type Props = ConnectedProps<typeof connector>;

export default connector(Toolbar);
