import Axios, { AxiosInstance, AxiosPromise } from "axios";

import {
  Project,
  NewModel,
  UpdateProject,
  DeleteProject,
  ProjectMember,
  DeleteMember,
  AttributesSummary,
  KindAttribute,
  KindAttributeArray,
} from "./model";
import { API_URI } from "../../config";
import { Model } from "../../models/api/model";
import {NewProject} from "../../clientspaces/api";

class ProjectsApi {
  static base = API_URI + "api/v1";
  static context = API_URI + "api/v1/projects";
  static models = API_URI + "api/v1/models";

  client: AxiosInstance;

  constructor(token: string) {
    this.client = Axios.create({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  createProject(payload: NewProject): AxiosPromise<Project> {
    return this.client.post(`${ProjectsApi.context}`, payload);
  }

  deleteProject(uuid: string): AxiosPromise<DeleteProject> {
    return this.client.delete(`${ProjectsApi.context}/${uuid}`);
  }

  getProjects(
      search?: string,
      sortString?: { [field: string]: string }
  ): AxiosPromise<Array<Project>> {
    const sort = sortString
        ? Object.keys(sortString)
            .map((field) => {
              const order = sortString[field];
              return `${field}+${order}`;
            })
            .join(",")
        : null;

    const params = {
      search,
      sort,
    };
    return this.client.get(`${ProjectsApi.context}`, {
      params,
    });
  }

  getProject(uuid: string): AxiosPromise<Project> {
    return this.client.get(`${ProjectsApi.context}/${uuid}`);
  }

  getModels(
    uuid: string,
    search?: string,
    sortString?: { [field: string]: string }
  ): AxiosPromise<Array<Model>> {
    const sort = sortString
      ? Object.keys(sortString)
          .map((field) => {
            const order = sortString[field];
            return `${field}+${order}`;
          })
          .join(",")
      : null;

    const params = {
      search,
      sort,
    };

    return this.client.get(`${ProjectsApi.context}/${uuid}/models`, {
      params,
    });
  }

  updateProject(uuid: string, payload: UpdateProject): AxiosPromise<Project> {
    return this.client.put(`${ProjectsApi.context}/${uuid}`, payload);
  }



  getProjectMembers(uuid: string): AxiosPromise<Array<ProjectMember>> {
    return this.client.get(`${ProjectsApi.context}/${uuid}/users`);
  }

  deleteProjectMember(
    uuid: string,
    userId: string
  ): AxiosPromise<DeleteMember> {
    return this.client.delete(`${ProjectsApi.context}/${uuid}/users/${userId}`);
  }

  updateRole(
    uuid: string,
    userId: string,
    roleName: string
  ): AxiosPromise<ProjectMember> {
    return this.client.put(
      `${ProjectsApi.context}/${uuid}/users/${userId}/roles/${roleName}`
    );
  }

  getAttributesSummary(uuid: string): AxiosPromise<Array<AttributesSummary>> {
    return this.client.get(`${ProjectsApi.context}/${uuid}/attributes/summary`);
  }

  getAttribute(
    uuid: string,
    kind?: string,
    limit?: number,
    offset?: number,
    filter?: string,
    sort?: string,
    search?: string
  ): AxiosPromise<KindAttributeArray> {
    const params = {
      kind,
      limit,
      offset,
      filter,
      sort,
      search,
    };

    return this.client.get(`${ProjectsApi.context}/${uuid}/attributes`, {
      params,
    });
  }

  getAttributesWithAllocations(
    modelUuid: string,
    kind: string,
    limit?: number,
    offset?: number,
    filter?: string,
    sort?: string,
    search?: string
  ): AxiosPromise<KindAttribute> {
    const params = {
      kind,
      limit,
      offset,
      filter,
      sort,
      search,
    };
    return this.client.get(`${ProjectsApi.models}/${modelUuid}/allocations`, {
      params,
    });
  }
}

export default ProjectsApi;
