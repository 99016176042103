import React, {useMemo, useRef} from "react";
import TreeMap, {ColorModel} from "react-d3-treemap";

import "react-d3-treemap/dist/react.d3.treemap.css";
import {STreeMapProps} from "./types";
import {useResizeDetector} from "react-resize-detector";

// #4C5866', '#1E2839

const options = {
    minColor: "white",
    midColor: "#4C5866",
    maxColor: "#1E2839",

    // minHighlightColor: 'white',
    // midHighlightColor: 'white',
    // maxHighlightColor: 'white',

    headerHeight: 15,
    fontColor: "white",
    fontSize: 16,
    showScale: true
};

export interface TreeMapInPutData {
    name: string;
    value?: number;
    children?: Array<TreeMapInPutData>;
    className?: string;
}

const height = 800

export function STreeMap({data}: STreeMapProps) {
    // window.alert(JSON.stringify(data, null, 2))

    const {width, height, ref} = useResizeDetector();

    const dataWithLoading = useMemo(
        () => data ? data : {name: "Loading"},
        [data]
    )

    const Map = () => {


        return <TreeMap<TreeMapInPutData>
            id="myTreeMap"
            width={width}
            height={height}
            data={dataWithLoading}

            // onZoom={(level, id, breadcrumbs) => window.alert(`${level}, ${id}, ${JSON.stringify(breadcrumbs, null, 2)}`)}
            // data={flareData}
            // data={funcData}
            levelsToDisplay={3}
            colorModel={ColorModel.Value}
            // valueUnit={"MB"}
            // numberOfChildrenPlacement={NumberOfChildrenPlacement.TopRight}
            hideNumberOfChildren={true}

            nodeStyle={{
                // borderRadius: 5,
                // borderColor: "white",
                fontSize: 12,
                paddingTop: 2,
                // paddingBottom: 8,
                paddingLeft: 5,
                paddingRight: 5,
            }}
            // paddingInner={8}


            svgStyle={{
                // width: "100%"
                width,
                height
            }}
        />
    }

    const map = useMemo(
        () => <Map/>,

        [dataWithLoading, width, height]
    )

    //

    return <div ref={r => ref.current = r} style={{width: "100%", height: "600px", paddingBottom: "60px"}}>
        {map}
    </div>

    /*return (
        <div style={{paddingLeft: "1rem", paddingRight: "2rem"}}>
            {map}
            <pre>
                {JSON.stringify(data, null, 2)}
            </pre>
        </div>
    )*/

    /*return <Chart
        chartType="TreeMap"
        width="100%"
        height="400px"
        data={data}
        options={options}
    />*/
}