import {
  SET_TOOLBAR,
  SET_EDIT_MODE,
  SET_RESIZE_MODE,
  ToolbarAction,
  toolbarState,
} from "./toolbarTypes";

const initialState: toolbarState = {
  style: {
    bold: false,
    italic: false,
    underlined: false,
    textColor: "#000000",
    backgroundColor: "#ffffff",
    borderColor: "#000000",
  },
  editMode: false,
  resizingMode: false,
};

export function toolbarReducer(
  state = initialState,
  action: ToolbarAction
): toolbarState {
  switch (action.type) {
    case SET_TOOLBAR:
      return { ...state, style: { ...action.style } };
    case SET_EDIT_MODE:
      return { ...state, editMode: action.editable };
    case SET_RESIZE_MODE:
      return { ...state, resizingMode: action.resizing };
    default:
      return state;
  }
}
