import React from "react";
import { AttributesColumnProps } from "./types";
import { AttributesColumnWrap } from "./styles";
import { Card, Elevation } from "@blueprintjs/core";
import { useHistory } from "react-router-dom";

export const AttributesColumn: React.FC<AttributesColumnProps> = (Props) => {
  const { attributesData } = Props;
  const history = useHistory();

  return (
    <AttributesColumnWrap>
      {attributesData &&
        attributesData.map((attr: any, i: number) => (
          <Card
            className="attr-card"
            elevation={Elevation.TWO}
            key={i}
            interactive
            onClick={() =>
              history.push(
                `${history.location.pathname}?attribute=${attr?.kind}`
              )
            }
          >
            <h2>
              {attr?.entityMeta?.plural
                ? attr?.entityMeta?.plural
                : attr?.entityMeta?.name}
            </h2>
            <div className="attr-data">
              <div className="data-wrap">
                <span className="data-header">total:</span>
                <span className="data-value">{attr?.total}</span>
              </div>
            </div>
          </Card>
        ))}
    </AttributesColumnWrap>
  );
};

export default AttributesColumn;
