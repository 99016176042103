import { connect, ConnectedProps } from "react-redux";
import { StoreType } from "../../../store";
import {
  setApis,
  setModels,
  setSource,
  setTarget,
  loadSummary,
  loadModels,
  loadSelectedDetails,
  setSelectedKind,
} from "../../../store/delta/actions";
import DeltaComparison from "./View";

const mapStateToProps = (state: StoreType) => ({
  state: state.delta,
});

const mapDispatchToProps = {
  setApis,
  setModels,
  loadSummary,
  loadModels,
  setSource,
  setTarget,
  loadSelectedDetails,
  setSelectedKind,
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type Props = ConnectedProps<typeof connector>;

export default connector(DeltaComparison);
