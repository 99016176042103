import React from "react"

import {useContext, useEffect, useMemo, useState} from "react";
import {Model} from "../../../models/api";
import {Loading} from "../types";
import useModelsApi from "../../../models/api/useModelsApi";
import {CacheContext} from "../CacheContext";


export function useModel(uuid: string): Loading<Model> {
    const modelsApi = useModelsApi()

    const {state, dispatch} = useContext(CacheContext)

    const model = useMemo(
        () => state.models[uuid],
        [uuid, state.models]
    )

    const [isLoading, setLoading] = useState<boolean>(false)

    useEffect(() => {
        setLoading(true)

        modelsApi.getModel(uuid)
            .then(response => {
                dispatch({type: 'SET_MODEL', data: response.data})
                setLoading(false)
            })
    }, [modelsApi, uuid])

    return useMemo(
        () => ({data: model, isLoading}), [model, isLoading]
    )
}