import { createContext } from "react";
import { UserProfile } from "../../user/api";

export interface Auth {
  signup: (
    username: string,
    password: string,
    firstName: string,
    lastName: string
  ) => Promise<any>;
  login: (username: string, password: string) => Promise<any>;
  logout: (params: any) => Promise<void | string>;
  token: string | null;
  profile?: UserProfile;
}

const AuthContext: React.Context<Auth | undefined> = createContext<
  Auth | undefined
>(undefined);

AuthContext.displayName = "AuthContext";

export default AuthContext;
