import Axios, { AxiosInstance, AxiosPromise } from "axios";
import { EndpointMsg } from "./model";
import { API_URI } from "../../config";

class AllocationsApi {
  static context = API_URI + "api/v1/allocations";

  client: AxiosInstance;

  constructor(token: string) {
    this.client = Axios.create({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  deallocateFromComponent(
    uuid: string,
    childId: string
  ): AxiosPromise<EndpointMsg> {
    return this.client.delete(
      `${AllocationsApi.context}/${uuid}/components/${childId}`
    );
  }

  removeRow(uuid: string): AxiosPromise<EndpointMsg> {
    return this.client.delete(`${AllocationsApi.context}/${uuid}`);
  }
}

export default AllocationsApi;
