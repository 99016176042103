import * as React from "react";
import { useEffect, useState } from "react";
import useAuth from "../../../auth/context/useAuth";
import { RouteComponentProps } from "react-router";
import { UserProfile, ClientSwitcher } from "../../../components";
import {
  ListWrap,
  ListContent,
  ClientsTopbar,
  ClientsList,
  TopbarLeft,
} from "../../../clientspaces/components/CsListView";
import useProjectsApi from "../../api/useProjectsApi";
import { Project, ProjectMember } from "../../api/model";
import { MembersTable } from "../../components/MembersTable";
import ProjectsNav from "../../components/ProjectsNav";

type ViewProps = RouteComponentProps<{
  project: string;
}>;

export const View: React.FC<ViewProps> = ({ match }) => {
  const auth = useAuth();
  const projectsApi = useProjectsApi();
  const [activeProject, setActiveProject] = useState<Project>();
  const [projectMembers, setProjectMembers] = useState<Array<ProjectMember>>(
    []
  );
  const [isAdmin, setIsAdmin] = useState(false);
  const [refresh, setRefresh] = useState(false);

  useEffect(() => {
    projectsApi
      .getProject(match?.params?.project)
      .then((response) => {
        setActiveProject(response?.data);
      })
      .catch((error) => console.log(error));
  }, [projectsApi, match]);

  useEffect(() => {
    setIsAdmin(false);

    projectsApi
      .getProjectMembers(match?.params?.project)
      .then((response) => {
        setProjectMembers(response?.data);

        const userObj = response?.data.filter(
          (user: any) => user.id === auth?.profile?.id
        );
        if (userObj[0]?.role === "Owner" || userObj[0]?.role === "Admin") {
          setIsAdmin(true);
        }
      })
      .catch((error) => console.log(error));
  }, [projectsApi, match, auth, refresh]);

  return (
    <ListWrap>
      <ProjectsNav match={match} active="project-members" />

      <ListContent>
        <ClientsTopbar>
          <ClientSwitcher
            clientspaceUuid={activeProject?.clientspace?.uuid}
            projectData={activeProject}
          />

          <TopbarLeft>
            {auth.profile && <UserProfile profile={auth.profile} />}
          </TopbarLeft>
        </ClientsTopbar>
        <ClientsList>
          <MembersTable
            users={projectMembers}
            admin={isAdmin}
            projectID={match?.params.project}
            refresh={() => setRefresh((refresh) => !refresh)}
          />
        </ClientsList>
      </ListContent>
    </ListWrap>
  );
};

export default View;
