import { SET_STATE, CLEAR_DIRTY, StateAction } from "./types";
import {
  State,
  dublicateState,
} from "../../../models/views/DraggableReference/stateManager";
import { ComponentTypesByName } from "./commonTypes";

const initialState: State = {
  rowsOrder: [],
  rows: {},
  components: {
    [ComponentTypesByName.Division]: {},
    [ComponentTypesByName.BusinessUnit]: {},
    [ComponentTypesByName.Function]: {},
    [ComponentTypesByName.SubFunction]: {},
  },
  selected: { nestingLevel: -1, content: [] },
  dirty: {},
};

export function referenceReducer(
  state = initialState,
  action: StateAction
): State {
  switch (action.type) {
    case SET_STATE:
      return action.state;
    case CLEAR_DIRTY:
      const newState = dublicateState(state);
      newState.dirty = {};
      return newState;
    default:
      return state;
  }
}
