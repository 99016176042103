import React from "react";
import Box from "../Box";
import { RowView } from "./styles";
import { State, getBoxWidth } from "../../stateManager";
import RowSpacer from "./RowSpacer";

export const Row: React.FC<{
  id: string;
  content: Array<string>;
  pos: number;
  state: State;
  editable: boolean;
  last?: boolean;
  isResizing: boolean;
}> = ({ id, content, state, pos, editable, last, isResizing }) => {
  return (
    <React.Fragment>
      {pos === 0 && <RowSpacer pos={pos} />}
      <RowView>
        {content.map((boxId, index) => {
          const boxData = state.components.Division[boxId];
          return (
            <Box
              key={boxData.id}
              id={boxData.id}
              content={boxData.content}
              state={state}
              rowId={id}
              position={index}
              name={boxData.name}
              width={getBoxWidth(state, id, boxData.id)}
              last={index === content.length - 1}
              style={boxData.style}
              // isTextBox={boxData.isTextBox}
              editable={editable}
              isResizing={isResizing}
            />
          );
        })}
      </RowView>
      <RowSpacer pos={pos + 1} last={last} />
    </React.Fragment>
  );
};

export default Row;
