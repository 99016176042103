import { useHistory, useLocation } from "react-router";
import { useAuth } from "./useAuth";
import { useCallback } from "react";
import { defaultAuthParams } from "./provider";

export const useSignup = (): Signup => {
  const authProvider = useAuth();
  const location = useLocation();
  const locationState = location.state as any;
  const history = useHistory();
  const nextPathName = locationState && locationState.nextPathname;

  return useCallback(
    (
      { email, password, firstName, lastName },
      pathName = defaultAuthParams.afterSignupUrl
    ) => {
      return authProvider
        .signup(email, password, firstName, lastName)
        .then((response) => {
          history.push(nextPathName || pathName);

          return response;
        });
    },
    [authProvider, history, nextPathName]
  );
};

type Signup = (params: any, pathName?: string) => Promise<any>;

export default useSignup;
