import React, {useEffect, useState} from "react";
import useModelsApi from "../../../api/useModelsApi";
import {CubeViewProps, GeographyResult} from "../types";
import SWorldMap from "../../../../@satsui/components/SWorldMap/SWorldMap";
import {DAG} from "../../../../@satsui/components/SSankeyChart/types";
import {SankeyPanel} from "../panels/SankeyPanel";
import styled from "styled-components";

export const GeographyTab = ({modelUuid, query}: CubeViewProps) => {
    const modelsApi = useModelsApi();

    const [geo, setGeo] = useState<GeographyResult>()

    const [dag, setDag] = useState<DAG>()

    useEffect(() => {
        modelsApi.cubeGeography(modelUuid, query).then((response) => {
            setGeo(response?.data);
        });
    }, [modelsApi, query]);

    useEffect(() => {
        if (!geo) return;

    }, [geo])

    const WorldWrap = styled.div`
        display: flex;
        justify-content: center;        
    `

    return (
        <WorldWrap>
            <SWorldMap {...geo as GeographyResult} />
        </WorldWrap>
    )
}