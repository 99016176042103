import React from "react";
import styled from "styled-components";

const StyledLogo = styled.div<{ column?: boolean }>`
  padding: 16px 16px 16px 21px;
  text-decoration: none;
  height: 94px;
  box-sizing: border-box;
  display: flex;
  flex-direction: ${(p) => (p.column ? "column" : "row")};
  justify-content: ${(p) => (p.column ? "center" : "flex-start")};
  align-items: ${(p) => (p.column ? "flex-start" : "center")};

  & > img {
    height: 42px;
  }

  & > a {
    display: flex;
    margin-top: ${(p) => (p.column ? "8px" : "0")};
    margin-left: ${(p) => (p.column ? "0" : "auto")};
    align-items: center;
    height: 21px;
    color: #9c9c9c;
    font-size: 14px;
    font-weight: 500;
  }
`;

const NavLogo = (props: any) => {
  const { column } = props;

  return (
    <StyledLogo column={column}>
      <img src={require("../assets/logo.svg")} alt="" />
    </StyledLogo>
  );
};
export default NavLogo;
