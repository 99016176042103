import * as React from "react";
import { Route, Switch } from "react-router-dom";
import { HTML5Backend } from "react-dnd-html5-backend";
import { DndProvider } from "react-dnd";

import { SignupPage } from "../../auth/views/Signup";
import { SignupProfilePage } from "../../auth/views/SetupProfile";
import { LoginPage } from "../../auth/views/Login";
import NotFound from "../../components/templates/NotFound";
import { ClientspaceHomePage } from "../../clientspaces/views/ClientspaceHome";
import PrivateRoute from "../../auth/components/PrivateRoute";
import { clientspaceHomeUrl } from "../../clientspaces";
import { loginUrl, signupProfile, signupUrl } from "../../auth";

import {
  projectspaceHomeUrl,
  projectspaceSettingsUrl,
  projectspaceMembersUrl,
  projectspaceDataUrl,
  deltaViewUrl,
  comparisonViewUrl,
} from "../../projectspaces/";
import { ProjectspaceSettings } from "../../projectspaces/views/ProjectspaceSettings";
import { ProjectspaceHome } from "../../projectspaces/views/ProjectspaceHome";
import { ProjectspaceMembers } from "../../projectspaces/views/ProjectspaceMembers";
import { ProjectspaceData } from "../../projectspaces/views/ProjectspaceData";

import {
  dashboardUrl,
  pyramidUrl,
  referenceUrl,
  draggableReferenceUrl,
  dashboardAttributesUrl,
  modelNetworkUrl,
  modelChartsUrl,
  modelInsightsUrl,
  modelSatsuiUrl
} from "../../models";
import { Dashboard } from "../../models/views/Dashboard";
import { Pyramid } from "../../models/views/Pyramid";
import { DeltaView } from "../../projectspaces/views/DeltaView";
import Reference from "../../models/views/Reference";
import DraggableReference from "../../models/views/DraggableReference";
import { DashboardAttributes } from "../../models/views/Attributes";
import DeltaComparison from "../../projectspaces/views/DeltaComparison";
import {ModelNetworkView} from "../../models/views/NetworkView";
import ModelChartsView from "../../models/views/ChartView";
import {SatsuiView} from "../../models/views/SatsuiView";
import {ModelCubeView} from "../../models/views/CubeView";

export const Routes: React.FC = () => (
  <DndProvider backend={HTML5Backend}>
    <Switch>
      {/* auth views routes */}
      <Route exact path={signupUrl} component={SignupPage} />
      <PrivateRoute exact path={signupProfile} component={SignupProfilePage} />
      <Route exact path={loginUrl} component={LoginPage} />

      {/* models views routes */}
      <PrivateRoute exact path={dashboardUrl} component={Dashboard} />
      <PrivateRoute exact path={pyramidUrl} component={Pyramid} />
      <PrivateRoute exact path={referenceUrl} component={Reference} />
      <PrivateRoute
        exact
        path={draggableReferenceUrl}
        component={DraggableReference}
      />
      <PrivateRoute
        exact
        path={dashboardAttributesUrl}
        component={DashboardAttributes}
      />

      <PrivateRoute
          exact
          path={modelInsightsUrl}
          component={ModelCubeView}
      />

      <PrivateRoute
          exact
          path={modelNetworkUrl}
          component={ModelNetworkView}
      />

      <PrivateRoute
          exact
          path={modelChartsUrl}
          component={ModelChartsView}
      />

      <PrivateRoute
          exact
          path={modelSatsuiUrl}
          component={SatsuiView}
      />

      {/* clientspaces views routes */}
      {/* projects views routes */}
      <PrivateRoute
        exact
        path={clientspaceHomeUrl}
        component={ClientspaceHomePage}
      />
      <PrivateRoute
        exact
        path={projectspaceHomeUrl}
        component={ProjectspaceHome}
      />
      <PrivateRoute
        exact
        path={projectspaceMembersUrl}
        component={ProjectspaceMembers}
      />
      <PrivateRoute
        exact
        path={projectspaceSettingsUrl}
        component={ProjectspaceSettings}
      />
      <PrivateRoute
        exact
        path={projectspaceDataUrl}
        component={ProjectspaceData}
      />
      <PrivateRoute exact path={deltaViewUrl} component={DeltaView} />
      <PrivateRoute
        exact
        path={comparisonViewUrl}
        component={DeltaComparison}
      />

      {/* other views routes */}
      <PrivateRoute exact path={deltaViewUrl} component={DeltaView} />
      <Route component={NotFound} />
    </Switch>
  </DndProvider>
);

export default Routes;
