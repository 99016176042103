import useAuth from "../../auth/context/useAuth";
import SynthesisApi from "./SynthesisApi";
import { useMemo } from "react";

export function useSynthesisApi(): SynthesisApi {
  const auth = useAuth();

  return useMemo(() => {
    const token = auth.token as string;

    return new SynthesisApi(token);
  }, [auth]);
}

export default useSynthesisApi;
