import React, { useEffect, useState, useRef } from "react";
import DashBoardConstant from "../Reference/DashBoardConstant";
import {
  ComponentDragReferenceWrap,
  DataWrap,
  TopText,
  ReferenceBreadcrumb,
} from "./styles";
import AttributesColumn from "./components/AttributesColumn";
import TreeMenu from "../../components/TreeMenu/TreeMenu";
import Item from "./components/Item";
import { AttributeSummary, Model, Component } from "../../../models/api/model";
import NewComponentsMenu from "./components/NewComponentsMenu";
import TextareaAutosize from "react-textarea-autosize";
import { InputGroup } from "@blueprintjs/core";
import { AppToaster } from "../../../components";
import { Icons } from "../../../assets/Icons";
import { Link } from "react-router-dom";

// import useAuth from "../../../auth/context/useAuth";
import useModelsApi from "../../api/useModelsApi";

import { RouteComponentProps, useHistory } from "react-router";
import {
  //setOnStateChange,
  //setDivReference,
  copySelected,
  pasteCopied,
  //OnMount,
  //OnUnmount,
  setSelectedStyle,
  getNestingLevelContent,
  getNestingLevelByName,
  removeSelected,
} from "./stateManager";
import { Props } from "./";
import { ComponentName } from "../../../store/referencePage/reference/commonTypes";
import ReferenceContent from "./ReferenceContent";
import ConfirmDelete from "./components/ConfirmDelete";
import AttributeReference from "./AttributeReference";
import { useLocation } from "react-router-dom";

// import { dispatchGetModel } from "../../../store/referencePage/coap/coapActions";
type ViewProps = Props &
  RouteComponentProps<{
    modelUuid: string;
    childId: string;
  }>;

export const View: React.FC<ViewProps> = (props) => {
  const history = useHistory();
  const { reference } = props;
  //const { setState } = props;
  const [divisionAttributes, setDivisionAttributes] = useState<
    Array<AttributeSummary>
  >([]);
  const [modelData, setModelData] = useState<Model>(); // not sure if this is needed, maybe just use componentData?
  const [componentData, setComponentData] = useState<Component>();
  const [componentDescription, setComponentDescription] = useState<string>();
  const [breadcrumbData, setBreadcrumbData] = useState<Array<string>>([]);
  const [componentName, setComponentName] = useState<string>();
  const [breadcrumbKeys, setBreadcrumbKeys] = useState<Array<string>>([]);

  const divRef = useRef<HTMLDivElement>(null);
  const getModel = props.thunkGetModel;
  // const auth = useAuth();
  const modelsApi = useModelsApi();
  const location = useLocation();
  const params = new URLSearchParams(location?.search);
  const ATTRIBUTE = params.get("attribute");
  const nestingLevel =
    getNestingLevelByName(componentData?.kind as ComponentName) + 1;

  useEffect(() => {
    props.setModelUuid(props.match.params.modelUuid, modelsApi);
  }, [props, modelsApi]);
  useEffect(() => {
    getModel();
  }, [getModel, props.match.params.modelUuid, props.match.params.childId]);
  /*
  useEffect(() => {
    setOnStateChange(setState);
    setDivReference(divRef);
    OnMount(componentData?.kind as ComponentName, props.match.params.childId);
    return () => {
      OnUnmount();
    };
  }, [setState, componentData, props.match.params.childId]);*/

  useEffect(() => {
    if (props.match?.params?.childId && !ATTRIBUTE) {
      modelsApi
        .getAttributesSummary(
          props.match?.params.modelUuid,
          props.match?.params?.childId
        )
        .then((response) => setDivisionAttributes(response?.data));
    }
  }, [modelsApi, props.match, ATTRIBUTE]);

  useEffect(() => {
    setComponentData(undefined);
    modelsApi.getModel(props.match.params.modelUuid).then((response) => {
      const DATA = response?.data;
      if (DATA?.children) {
        const component = DATA?.children[props.match.params.childId];
        if (component) {
          setComponentData(component);
          setComponentName(component?.properties?.name);
          setComponentDescription(component?.properties.description || "");
        } else {
          history.goBack();
          AppToaster.show({
            message: "That component doest not exists anymore!",
            intent: "danger",
          });
        }
      }
      setModelData(DATA);
    });
  }, [modelsApi, props.match, history]);

  function getComponentContent(id: string, type: ComponentName) {
    const level = nestingLevel - 1;
    if (!reference) return [];
    const groupContent = getNestingLevelContent(reference, level);
    const nextLayerGroupContent = getNestingLevelContent(reference, level + 1);
    if (!groupContent || !nextLayerGroupContent) return [];
    const content = groupContent[id]?.content.map((itemId, i) => {
      const itemData = nextLayerGroupContent[itemId];
      return (
        <Item
          key={itemId}
          boxId={id}
          id={itemId}
          pos={i}
          name={itemData.name}
          state={reference}
          styles={itemData.style}
          editable={props.editable}
          content={itemData.content}
          isResizing={false}
          nestingLevel={nestingLevel}
        />
      );
    });
    return content;
  }
  const onDelete = (ids: string[], nestingLevel: number) => {
    removeSelected(reference, ids, nestingLevel);
  };
  function handleDescriptionChange(e: any) {
    // don't make a new-line in a textarea when user clicks `ENTER` button
    // but make an API call to update the description
    // to make new-line create ENTER with SHIFT
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();

      modelsApi
        .updateModelChild(
          props.match?.params.modelUuid,
          props.match?.params.childId,
          componentDescription
        )
        .then((response) => {
          if (response.statusText === "OK") {
            AppToaster.show({
              message: "Description was successfully updated!",
              intent: "success",
            });
          }
        })
        .catch((error) => {
          AppToaster.show({
            message: "Something went wrong, please try again!",
            intent: "danger",
          });

          console.log(error);
        });
    }
  }

  function handleComponentNameChange(e: any) {
    e.preventDefault();

    modelsApi
      .updateModelChild(
        props.match?.params.modelUuid,
        props.match?.params.childId,
        undefined,
        componentName
      )
      .then((response) => {
        if (response.statusText === "OK") {
          AppToaster.show({
            message: "Component name was successfully updated!",
            intent: "success",
          });
        }
      })
      .catch((error) => {
        AppToaster.show({
          message: "Something went wrong, please try again!",
          intent: "danger",
        });

        console.log(error);
      });
  }

  return (
    <DashBoardConstant
      match={props.match}
      onCopy={copySelected}
      onPaste={pasteCopied}
      onStyle={setSelectedStyle}
      withTree
      modelInfo={modelData}
    >
      {nestingLevel >= 0 && (
        <React.Fragment>
          <TreeMenu
            ID={props.match?.params.modelUuid}
            childId={props.match?.params.childId}
            setBreadcrumbData={setBreadcrumbData}
            withAttribute={ATTRIBUTE}
            setBreadcrumbKeys={setBreadcrumbKeys}
          >
            <NewComponentsMenu
              state={props.reference}
              editable={props.editable}
              nestingLevel={nestingLevel}
            />
          </TreeMenu>

          {ATTRIBUTE ? (
            <AttributeReference
              _ATTRIBUTE={ATTRIBUTE}
              breadcrumbData={breadcrumbData}
              breadcrumbKeys={breadcrumbKeys}
            />
          ) : (
            componentData && (
              <ReferenceContent
                state={reference}
                nestingLevel={nestingLevel}
                parentId={componentData?.id as string}
              >
                <ConfirmDelete onDelete={onDelete} />
                <TopText>
                  <Link
                    to={`/models/${props.match?.params.modelUuid}/reference`}
                    className="back-btn"
                  >
                    <Icons.ArrowLeft size={21} />
                    <span>Back</span>
                  </Link>
                  <ReferenceBreadcrumb>
                    <li>
                      <Link to={`/projects/${modelData?.project?.uuid}`}>
                        {modelData?.project?.name}
                      </Link>
                    </li>
                    <li className="breadcrumb-sep">/</li>
                    {breadcrumbData &&
                      breadcrumbData.map((name, index) => (
                        <>
                          <li>
                            <Link
                              to={`/models/${modelData?.uuid}/components/${breadcrumbKeys[index]}`}
                            >
                              {name}
                            </Link>
                          </li>
                          <li className="breadcrumb-sep">/</li>
                        </>
                      ))}
                    <li>
                      <Link to={props.match.url}>
                        {componentData?.properties.name}
                      </Link>
                    </li>
                  </ReferenceBreadcrumb>

                  <AttributesColumn attributesData={divisionAttributes} />

                  <form onSubmit={handleComponentNameChange}>
                    <InputGroup
                      value={componentName}
                      onChange={(e: any) => setComponentName(e.target.value)}
                      className="component-name"
                      large
                      readOnly={props.editable ? false : true}
                    />
                  </form>
                  <TextareaAutosize
                    className="desc-area"
                    placeholder="Add a description here"
                    value={componentDescription && componentDescription}
                    onChange={(e: any) =>
                      setComponentDescription(e.target.value)
                    }
                    onKeyDown={handleDescriptionChange}
                    maxLength={300}
                    readOnly={props.editable ? false : true}
                  />
                </TopText>

                <DataWrap>
                  <ComponentDragReferenceWrap ref={divRef}>
                    {getComponentContent(
                      componentData?.id as string,
                      componentData?.kind as ComponentName
                    )}
                  </ComponentDragReferenceWrap>
                </DataWrap>
              </ReferenceContent>
            )
          )}
        </React.Fragment>
      )}
    </DashBoardConstant>
  );
};

export default View;
