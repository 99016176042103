import { connect, ConnectedProps } from "react-redux";
import { StoreType } from "../../../../../store";
import { thunkRemoveChildren } from "../../../../../store/referencePage/reference/actions";
import {
  openConfirmDelete,
  closeConfirmDelete,
} from "../../../../../store/referencePage/reference/confirmDeleteActions";
import ConfirmDelete from "./ConfirmDelete";

const mapStateToProps = (state: StoreType) => ({
  state: state.confirmDelete,
  reference: state.reference,
});

const mapDispatchToProps = {
  removeChildren: (ids: string[], nestingLevel: number) =>
    thunkRemoveChildren(ids, nestingLevel, true),
  openConfirmDelete: () => openConfirmDelete(),
  closeConfirmDelete: () => closeConfirmDelete(),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type Props = ConnectedProps<typeof connector>;

export default connector(ConfirmDelete);
