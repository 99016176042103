import React from "react";
import styled from "styled-components";

const SVG = (props: Props): JSX.Element => {
  return (
    <svg viewBox="0 0 24 24" width="1em" height="1em" {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M4 8h16V5H4v3zm10 11v-9h-4v9h4zm2 0h4v-9h-4v9zm-8 0v-9H4v9h4zM3 3h18a1 1 0 011 1v16a1 1 0 01-1 1H3a1 1 0 01-1-1V4a1 1 0 011-1z" />
    </svg>
  );
};

type Props = {
  onClick?: any;
  size?: number;
} & React.SVGProps<SVGSVGElement>;
type StyledProps = {
  size?: number;
};
const SvgDashboard = styled(SVG)<StyledProps>`
  vertical-align: middle;
  height: ${(props) => (props.size ? props.size + "px" : "16px")};
  width: ${(props) => (props.size ? props.size + "px" : "16px")};
  fill: currentColor;
`;
export default SvgDashboard;
