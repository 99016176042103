import React from "react"
import {GeoCountryProps} from "./types";
import {geoPath} from "d3-geo";
import {useHover} from "../../hooks";

export const SGeoCountry: React.FC<GeoCountryProps> = (
    {
        id,
        projection,
        feature,
        fillColor,
        value,
        onClick
    }
) => {
    const d = geoPath().projection(projection)(feature) as (string | undefined)

    const [hoverRef, isHovered] = useHover<SVGPathElement>();

    const handleClick = () => {
        if(!onClick) return;
        onClick(id)
    }

    // const fillColor = isHovered ? coral : someGrey
    const fillOpacity = isHovered ? 0.4 : 1

    return <path
        ref={hoverRef}

        key={ `path-${ id }` }
        d={ d }
        // fill={ `rgba(38,50,56,${ 1 / geographies.length * i})` }
        fill={fillColor}
        fillOpacity={fillOpacity}
        stroke="#FFFFFF"
        strokeWidth={ 0.5 }
        onClick={handleClick}
    />
}