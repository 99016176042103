import React from "react";
import immer from "immer";
import {CacheAction, CacheContextType, CacheState} from "./types";
import noop from "noop-ts";

export const initialState: CacheState = {
    schemas: {},
    models: {}
};

const Reducer = (state: CacheState, action: CacheAction): CacheState => {
    switch (action.type) {
        case "SET_SCHEMA":
            return immer(state, (draftState) => {
                draftState.schemas[action.data.kind] = action.data
            });
        case "SET_SCHEMAS":
            return immer(state, (draftState) => {
                action.data.forEach(d => {
                    draftState.schemas[d.kind] = d
                })
            });
        case "SET_MODEL":
            return immer(state, (draftState) => {
                draftState.models[action.data.uuid] = action.data
            });
    }
};

export const CacheContext = React.createContext<CacheContextType>({
    state: initialState,
    dispatch: noop
});

export const CacheContextProvider: React.FC = ({children}) => {
    const [state, dispatch] = React.useReducer(Reducer, initialState)

    const ctx = {state, dispatch}

    return <CacheContext.Provider value={ctx}>
        {children}
    </CacheContext.Provider>
};
