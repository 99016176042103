import {
  State,
  RowInterface,
  BoxInterface,
  ItemInterface,
  CopiedComponents,
} from "./types";
import {
  dispatchPutChildren,
  dispatchPutDirty,
} from "../../../../store/referencePage/reference/actions";
import { setToolbar } from "../../../../store/referencePage/toolbarActions";
import { spacerWidth } from "../components/Box/styles";
import store from "../../../../store/";
import {
  ComponentName,
  ComponentTypesByName,
  ComponentStyle,
} from "../../../../store/referencePage/reference/commonTypes";

const nestingLevels = [
  ComponentTypesByName.Division,
  ComponentTypesByName.BusinessUnit,
  ComponentTypesByName.Function,
  ComponentTypesByName.SubFunction,
];

export const initialStyle: ComponentStyle = {
  bold: false,
  italic: false,
  underlined: false,
  textColor: "#000000",
  backgroundColor: "#ffffff",
  borderColor: "#000000",
};

let copied: CopiedComponents = {
  nestingLevel: -1,
  components: {
    [ComponentTypesByName.Division]: {},
    [ComponentTypesByName.BusinessUnit]: {},
    [ComponentTypesByName.Function]: {},
    [ComponentTypesByName.SubFunction]: {},
  },
};
var isCoap: boolean;
var topLevelIndex: number;
var mainComponentId: string | undefined;

export function IsCoap() {
  return isCoap;
}
let onStateCb: onStateCB;
export function setOnStateChange(cb: onStateCB) {
  onStateCb = cb;
}

export function dublicateState(state: State): State {
  return {
    rowsOrder: [...state.rowsOrder],
    rows: { ...state.rows },
    components: {
      [ComponentTypesByName.Division]: {
        ...state.components[ComponentTypesByName.Division],
      },
      [ComponentTypesByName.BusinessUnit]: {
        ...state.components[ComponentTypesByName.BusinessUnit],
      },
      [ComponentTypesByName.Function]: {
        ...state.components[ComponentTypesByName.Function],
      },
      [ComponentTypesByName.SubFunction]: {
        ...state.components[ComponentTypesByName.SubFunction],
      },
    },
    selected: { ...state.selected, content: [...state.selected.content] },
    dirty: { ...state.dirty },
  };
}

export function getNestingLevelByName(type: ComponentName) {
  return nestingLevels.indexOf(type);
}
export function getNestingLevelContent(state: State, nestingLevel: number) {
  return state.components[nestingLevels[nestingLevel]];
}

export function hasContent(state: State) {
  return state.rowsOrder.length > 0;
}

export function componentHasContent(
  state: State,
  nestingLevel: number,
  id: string
) {
  return getNestingLevelContent(state, nestingLevel)[id].content.length > 0;
}

export function tryCreateFirstComponent(oldState: State, type: string) {
  const state = dublicateState(oldState);
  if (state.rowsOrder.length === 0) {
    const row: RowInterface = { id: getID(), content: [] };
    const boxId = createNewBox(state);
    row.content.push(boxId);
    state.rows[row.id] = row;
    state.rowsOrder.push(row.id);
    onStateCb(state);
    dispatchPutChildren([boxId]);
  }
}

export function swapBoxes(oldState: State, boxId1: string, boxId2: string) {
  const state = dublicateState(oldState);
  const firstBoxRowId = Object.keys(state.rows).find(
    (key) => state.rows[key].content.indexOf(boxId1) >= 0
  );
  const secondBoxRowId = Object.keys(state.rows).find(
    (key) => state.rows[key].content.indexOf(boxId2) >= 0
  );
  if (!firstBoxRowId || !secondBoxRowId) return;
  const boxIndex1 = state.rows[firstBoxRowId].content.indexOf(boxId1);
  const boxIndex2 = state.rows[secondBoxRowId].content.indexOf(boxId2);
  [
    state.rows[firstBoxRowId].content[boxIndex1],
    state.rows[secondBoxRowId].content[boxIndex2],
  ] = [
    state.rows[secondBoxRowId].content[boxIndex2],
    state.rows[firstBoxRowId].content[boxIndex1],
  ];
  dispatchPutChildren([boxId1, boxId2]);
  onStateCb(state);
}

export function swapItems(
  oldState: State,
  nestingLevel: number,
  id1: string,
  id2: string
) {
  const state = dublicateState(oldState);
  const parentComponents = state.components[nestingLevels[nestingLevel - 1]];
  const sameLevelComponents = state.components[nestingLevels[nestingLevel]];
  const firstItemParentId = Object.keys(parentComponents).find(
    (key) => parentComponents[key].content.indexOf(id1) >= 0
  );
  const secondItemParentId = Object.keys(parentComponents).find(
    (key) => parentComponents[key].content.indexOf(id2) >= 0
  );
  if (!firstItemParentId || !secondItemParentId) return;
  const itemIndex1 = parentComponents[firstItemParentId].content.indexOf(id1);
  const itemIndex2 = parentComponents[secondItemParentId].content.indexOf(id2);
  [
    parentComponents[firstItemParentId].content[itemIndex1],
    parentComponents[secondItemParentId].content[itemIndex2],
  ] = [
    parentComponents[secondItemParentId].content[itemIndex2],
    parentComponents[firstItemParentId].content[itemIndex1],
  ];
  sameLevelComponents[id1].parent = secondItemParentId;
  sameLevelComponents[id2].parent = firstItemParentId;
  dispatchPutChildren([id1, id2]);
  onStateCb(state);
}

export function transferItemToParent(
  oldState: State,
  itemNestingLevel: number,
  itemId: string,
  parentId: string,
  pos: number
) {
  const state = dublicateState(oldState);
  const parentComponents =
    state.components[nestingLevels[itemNestingLevel - 1]];
  const toTransfer = [];
  if (state.selected.content.indexOf(itemId) >= 0)
    toTransfer.push(...state.selected.content);
  else toTransfer.push(itemId);
  toTransfer.forEach((id) => {
    state.dirty[id] = true;
    const itemBoxId = Object.keys(parentComponents).find(
      (key) => parentComponents[key].content.indexOf(id) >= 0
    );
    if (itemBoxId) {
      const itemIndex = parentComponents[itemBoxId].content.indexOf(id);
      parentComponents[itemBoxId].content.splice(itemIndex, 1);
    }
    setItemParent(state, itemNestingLevel, id, parentId, pos);
  });
  dispatchPutDirty(state);
  onStateCb(state);
}

function removeRow(state: State, rowId: string) {
  const pos = state.rowsOrder.indexOf(rowId);
  console.log(pos, state.rowsOrder.length);
  for (let i = pos; i < state.rowsOrder.length; i++) {
    state.rows[state.rowsOrder[i]].content.forEach((id) => {
      state.dirty[id] = true;
    });
  }
  state.rowsOrder.splice(pos, 1);
  delete state.rows[rowId];
}

function removeBoxFromParentRow(
  state: State,
  boxId: string,
  removeEmptyRow: boolean = true
) {
  const boxRowId = Object.keys(state.rows).find(
    (key) => state.rows[key].content.indexOf(boxId) >= 0
  );
  if (!boxRowId) return false;
  const content = state.rows[boxRowId].content;
  const boxIndex = content.indexOf(boxId);
  content.splice(boxIndex, 1);
  for (let i = boxIndex; i < content.length; i++) {
    state.dirty[content[i]] = true;
  }
  if (state.rows[boxRowId].content.length === 0 && removeEmptyRow) {
    removeRow(state, boxRowId);
  }
}

export function removeItemFromParent(
  state: State,
  nestingLevel: number,
  itemId: string
) {
  const parentComponents = state.components[nestingLevels[nestingLevel - 1]];
  const ItemBoxId = Object.keys(parentComponents).find(
    (key) => parentComponents[key].content.indexOf(itemId) >= 0
  );
  if (!ItemBoxId) return false;
  const itemIndex = parentComponents[ItemBoxId].content.indexOf(itemId);
  parentComponents[ItemBoxId].content.splice(itemIndex, 1);
}

export function transferBoxToRow(
  oldState: State,
  boxId: string,
  rowId: string,
  pos: number
) {
  const state = dublicateState(oldState);
  const toTransfer = [];
  if (state.selected.content.indexOf(boxId) >= 0)
    toTransfer.push(...state.selected.content);
  else toTransfer.push(boxId);
  toTransfer.forEach((id) => {
    state.dirty[id] = true;
    const boxRowId = Object.keys(state.rows).find(
      (key) => state.rows[key].content.indexOf(id) >= 0
    );
    removeBoxFromParentRow(state, id, false);
    state.rows[rowId].content.splice(pos, 0, id);
    if (!boxRowId) return;
    if (state.rows[boxRowId].content.length === 0) removeRow(state, boxRowId);
  });
  dispatchPutDirty(state);
  onStateCb(state);
}

export function transferBoxToNewRow(
  oldState: State,
  boxId: string,
  pos: number
) {
  const state = dublicateState(oldState);
  const row: RowInterface = { id: getID(), content: [] };
  const toTransfer = [];
  if (state.selected.content.indexOf(boxId) >= 0)
    toTransfer.push(...state.selected.content);
  else toTransfer.push(boxId);
  toTransfer.forEach((id) => {
    removeBoxFromParentRow(state, id);
    row.content.push(id);
  });
  state.rowsOrder.splice(pos, 0, row.id);
  state.rows[row.id] = row;
  for (let i = pos + 1; i < state.rowsOrder.length; i++)
    toTransfer.push(...state.rows[state.rowsOrder[i]].content);
  dispatchPutChildren(toTransfer, state);
  onStateCb(state);
}

let lastBoxNumber = 1;
export function createNewBox(state: State) {
  const id = getID();
  const box: BoxInterface = {
    id,
    content: [],
    name: `Division ${++lastBoxNumber}`,
    grow: 1,
    parent: undefined,
    type: ComponentTypesByName.Division,
    style: { ...initialStyle },
  };
  while (!checkNameDublicate(state, box.name)) {
    box.name = `Division ${++lastBoxNumber}`;
    console.log(box.name);
  }
  state.components[ComponentTypesByName.Division][box.id] = box;
  return box.id;
}

let lastTextNumber = 1;
export function createNewTextBox(state: State) {
  const id = getID();
  const box: BoxInterface = {
    id,
    content: [],
    name: `Text ${++lastTextNumber}`,
    isTextBox: true,
    grow: 1,
    parent: undefined,
    type: ComponentTypesByName.Division,
    style: { ...initialStyle },
  };
  while (!checkNameDublicate(state, box.name)) {
    box.name = `Division ${++lastTextNumber}`;
  }
  state.components[ComponentTypesByName.Division][box.id] = box;
  return box.id;
}

let lastItemNumber = 1;
export function createNewItem(state: State, nestingLevel: number) {
  const id = getID();
  const type = nestingLevels[nestingLevel];
  const prefix = nestingLevels[nestingLevel];
  const item: ItemInterface = {
    id,
    name: `${prefix} ${++lastItemNumber}`,
    style: { ...initialStyle },
    content: [],
    parent: undefined,
    type,
  };
  if (nestingLevel === getNestingLevelByName(ComponentTypesByName.Function))
    item.style.backgroundColor = "#F2F2F2";
  while (!checkNameDublicate(state, item.name)) {
    item.name = `${type} ${++lastTextNumber}`;
  }
  state.components[prefix][item.id] = item;
  return item.id;
}

export function setTextBoxRotation(state: State, id: string, rotation: number) {
  state = dublicateState(state);
  (state.components[ComponentTypesByName.Division][
    id
  ] as BoxInterface).rotation = rotation;
  onStateCb(state);
}

export function removeSelected(
  oldState: State,
  selected: string[],
  nestingLevel: number
) {
  const state = dublicateState(oldState);
  if (nestingLevel === 0) {
    selected.forEach((id: string) => {
      removeBoxFromParentRow(state, id);
      delete state.components[ComponentTypesByName.Division][id];
    });
  } else {
    selected.forEach((id: string) => {
      removeItemFromParent(state, nestingLevel, id);
      delete getNestingLevelContent(state, nestingLevel)[id];
    });
  }
  state.selected.content = [];
  onStateCb(state);
}

let divElement: HTMLDivElement | null = null;
let divWidth = 1;
export function resizeBox(
  oldState: State,
  rowId: string,
  pos: number,
  deltaPx: number
) {
  const state = dublicateState(oldState);
  const divisions = state.components[ComponentTypesByName.Division];
  const row = state.rows[rowId];
  const totalGrow = getRowTotalGrow(state, rowId);
  const leftBox = divisions[row.content[pos - 1]] as BoxInterface;
  const rightBox = divisions[row.content[pos]] as BoxInterface;
  const unitWidth = divWidth / totalGrow;
  if (deltaPx > 0) {
    const newGrow =
      Math.max(rightBox.grow * unitWidth - deltaPx, 160) / unitWidth;
    let delta = newGrow - rightBox.grow;
    leftBox.grow -= delta;
    rightBox.grow += delta;
  } else {
    const newGrow =
      Math.max(leftBox.grow * unitWidth + deltaPx, 160) / unitWidth;
    const delta = newGrow - leftBox.grow;
    leftBox.grow += delta;
    rightBox.grow -= delta;
  }
  state.dirty[leftBox.id] = true;
  state.dirty[rightBox.id] = true;
  onStateCb(state);
}

export function getComponentById(state: State, id: string) {
  return Object.keys(state.components).reduce<
    BoxInterface | ItemInterface | null
  >((comp, groupId) => comp || state.components[groupId][id], null) as
    | BoxInterface
    | ItemInterface;
}

export function setSelectedComponent(
  oldState: State,
  id: string,
  nestingLevel: number
) {
  const state = dublicateState(oldState);
  state.selected.content = [id];
  state.selected.nestingLevel = nestingLevel;
  const selected = getComponentById(state, id);
  if (selected.style) store.dispatch(setToolbar({ ...selected.style }));
  onStateCb(state);
}

export function addSelectedComponent(
  oldState: State,
  id: string,
  nestingLevel: number
) {
  const state = dublicateState(oldState);
  if (state.selected.nestingLevel !== nestingLevel) {
    state.selected.content = [];
    state.selected.nestingLevel = nestingLevel;
  }
  if (isSelected(state, id)) {
    state.selected.content.splice(state.selected.content.indexOf(id), 1);
  } else {
    state.selected.content.push(id);
  }
  onStateCb(state);
}

export function isSelected(state: State, id: string) {
  return state.selected.content.indexOf(id) >= 0;
}

export function getRowTotalGrow(state: State, rowId: string) {
  const boxes = state.rows[rowId].content.map(
    (id) => state.components[ComponentTypesByName.Division][id] as BoxInterface
  );
  return boxes.reduce((total, b) => total + b.grow, 0);
}

export function getBoxWidth(state: State, rowId: string, boxId: string) {
  const total = getRowTotalGrow(state, rowId);
  const width = divWidth - spacerWidth * (state.rows[rowId].content.length + 1);
  // return (
  //   Math.floor((width * state.boxes[boxId].grow) / total / spacerWidth) *
  //   spacerWidth
  // );
  return (
    (width *
      (state.components[ComponentTypesByName.Division][boxId] as BoxInterface)
        .grow) /
    total
  );
}

export function copySelected(state: State) {
  copied = {
    nestingLevel: state.selected.nestingLevel,
    components: {},
  };
  const copyComponents = (nestingLevel: number, content: string[]) => {
    copied.components[nestingLevels[nestingLevel]] =
      copied.components[nestingLevels[nestingLevel]] || {};
    const compGroup = state.components[nestingLevels[nestingLevel]];
    const copiedGroup: Record<string, BoxInterface | ItemInterface> =
      copied.components[nestingLevels[nestingLevel]];
    content.forEach((id) => {
      const comp = compGroup[id];
      copiedGroup[id] = { ...comp, content: [...comp.content] };
      if (comp.content.length > 0) {
        copyComponents(nestingLevel + 1, comp.content);
      }
    });
  };
  copyComponents(state.selected.nestingLevel, state.selected.content);
  console.log(copied);
}

function setItemParent(
  state: State,
  nestingLevel: number,
  itemId: string,
  parentId: string,
  pos: number | null = null
) {
  const parentComponents = state.components[nestingLevels[nestingLevel - 1]];
  const sameLevelComponents = state.components[nestingLevels[nestingLevel]];
  pos = pos === null ? parentComponents[parentId].content.length : pos;
  sameLevelComponents[itemId].parent = parentId;
  const parentContent = parentComponents[parentId].content;
  for (let i = pos; i < parentContent.length; i++)
    state.dirty[parentContent[i]] = true;
  console.log(parentId, parentComponents, pos, itemId);
  parentComponents[parentId].content.splice(pos, 0, itemId);
}

export function clearSelected(oldState: State) {
  const state = dublicateState(oldState);
  state.selected.content = [];
  onStateCb(state);
}

export function pasteCopied(oldState: State) {
  const state = dublicateState(oldState);
  const ids: string[] = [];
  const pastCopiedItems = (
    state: State,
    nestingLevel: number,
    parent: ItemInterface | BoxInterface
  ) => {
    const content = parent.content;
    const copiedContent = copied.components[nestingLevels[nestingLevel]];
    console.log(nestingLevel, content, copiedContent);
    content.forEach((id) => {
      const comp = copiedContent[id];
      console.log(id, comp);
      const newComp = {
        ...comp,
        content: [...comp.content],
        id: getID(),
        name: copyName(comp.name),
        parent: parent.id,
      };
      parent.content[parent.content.indexOf(id)] = newComp.id;
      getNestingLevelContent(state, nestingLevel)[newComp.id] = newComp;
      ids.push(newComp.id);
      if (newComp.content.length > 0)
        pastCopiedItems(state, nestingLevel + 1, newComp);
    });
  };
  if (copied.nestingLevel === 0) {
    state.selected.content = [];
    const row: RowInterface = { id: getID(), content: [] };
    Object.keys(copied.components[ComponentTypesByName.Division]).forEach(
      (boxId) => {
        const box = copied.components[ComponentTypesByName.Division][
          boxId
        ] as BoxInterface;
        const newBox: BoxInterface = {
          ...box,
          id: getID(),
          content: [...box.content],
          name: copyName(box.name),
        };
        state.selected.nestingLevel = copied.nestingLevel;
        state.selected.content.push(newBox.id);
        ids.push(newBox.id);
        row.content.push(newBox.id);
        getNestingLevelContent(state, copied.nestingLevel)[newBox.id] = newBox;
        pastCopiedItems(state, copied.nestingLevel + 1, newBox);
      }
    );
    state.rowsOrder.splice(state.rowsOrder.length, 0, row.id);
    state.rows[row.id] = row;
  } else {
    let parent: ItemInterface | BoxInterface;
    if (
      state.selected.nestingLevel < copied.nestingLevel &&
      state.selected.content.length === 1
    ) {
      parent = getNestingLevelContent(state, state.selected.nestingLevel)[
        state.selected.content[0]
      ];
    } else {
      if (isCoap) {
        const newBoxId = createNewBox(state);
        const row: RowInterface = { id: getID(), content: [] };
        row.content.push(newBoxId);
        state.rowsOrder.splice(state.rowsOrder.length, 0, row.id);
        state.rows[row.id] = row;
        parent = getNestingLevelContent(state, 0)[newBoxId];
        state.selected.nestingLevel = 0;
      } else {
        parent = getNestingLevelContent(state, topLevelIndex)[
          mainComponentId as string
        ];
        state.selected.nestingLevel = topLevelIndex;
      }
    }
    for (
      let i = state.selected.nestingLevel + 1;
      i < copied.nestingLevel;
      i++
    ) {
      ids.push(parent.id);
      const newItemId = createNewItem(state, i);
      setItemParent(state, i, newItemId, parent.id);
      parent = getNestingLevelContent(state, i)[newItemId];
    }
    ids.push(parent.id);
    state.selected.content = [];
    state.selected.nestingLevel = copied.nestingLevel;
    const copiedRootContent =
      copied.components[nestingLevels[copied.nestingLevel]];
    Object.keys(copiedRootContent).forEach((itemId) => {
      const item = copiedRootContent[itemId] as ItemInterface;
      const newItem: ItemInterface = {
        ...item,
        id: getID(),
        content: [...item.content],
        name: copyName(item.name),
        parent: parent.id,
      };
      state.selected.content.push(newItem.id);
      parent.content.push(newItem.id);
      ids.push(newItem.id);
      getNestingLevelContent(state, copied.nestingLevel)[newItem.id] = newItem;
      pastCopiedItems(state, copied.nestingLevel + 1, newItem);
    });
  }

  dispatchPutChildren(ids, state);
  copySelected(state);
  onStateCb(state);
}

export function setSelectedStyle(oldState: State, style: ComponentStyle) {
  const state = dublicateState(oldState);
  let content = getNestingLevelContent(state, state.selected.nestingLevel);
  state.selected.content.forEach((id) => {
    content[id].style = { ...content[id].style, ...style };
  });
  dispatchPutChildren(state.selected.content);
  onStateCb(state);
}

export function setDivReference(divRef: React.RefObject<HTMLDivElement>) {
  if (divRef.current) {
    divElement = divRef.current;
    divWidth = divElement.getBoundingClientRect().width;
  }
}

export function checkNameDublicate(state: State, newName: string) {
  return Object.values(state.components).every((group) =>
    Object.values(group).every((comp) => comp.name !== newName)
  );
}

export function setCompName(
  oldState: State,
  nestingLevel: number,
  id: string,
  name: string
) {
  const state = dublicateState(oldState);
  console.log(nestingLevel, state.components[nestingLevels[nestingLevel]]);
  state.components[nestingLevels[nestingLevel]][id].name = name;
  dispatchPutChildren([id], state);
  onStateCb(state);
}

export function getID() {
  return "" + Math.random().toString(36).substr(2, 9);
}

function copyName(name: string) {
  return `Copy of ${name}`;
}

export type onStateCB = (state: State) => void;

function KeyCheck(event: KeyboardEvent) {
  const key = event.key;
  if (key === "Delete" || key === "Backspace") {
  } else if (event.ctrlKey) {
    switch (key) {
      case "c":
        copySelected(store.getState().reference);
        break;
      case "v":
        pasteCopied(store.getState().reference);
        break;
    }
  }
}

function onClick(event: MouseEvent) {
  if (!event.defaultPrevented && !event.ctrlKey) {
    clearSelected(store.getState().reference);
  }
}

export function OnMount(
  topLevelComponent?: ComponentName,
  componentId?: string
) {
  mainComponentId = componentId;
  topLevelIndex = topLevelComponent
    ? nestingLevels.indexOf(topLevelComponent)
    : -1;
  copied = {
    nestingLevel: -1,
    components: {
      [ComponentTypesByName.Division]: {},
      [ComponentTypesByName.BusinessUnit]: {},
      [ComponentTypesByName.Function]: {},
      [ComponentTypesByName.SubFunction]: {},
    },
  };
  isCoap = topLevelIndex === -1;
  document.addEventListener("keydown", KeyCheck);
  document.addEventListener("click", onClick);
}

export function OnUnmount() {
  document.removeEventListener("keydown", KeyCheck);
  document.removeEventListener("click", onClick);
}
