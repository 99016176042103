    import React from "react"
import styled from "styled-components";

export type InsightsWrapperProps = {
    queryPanel: React.ReactNode
    drilldownPanel: React.ReactNode
    children: React.ReactNode
}

const Wrap = styled.div`
    display: flex;
`

const QueryWrap = styled.div`
    // border: 1px red solid;
    width: 13%;
`

const BreakWrap = styled.div`
    // border: 1px red solid;
    width: 70%;
    padding-left: 40px;
    padding-right: 40px;
`

const DrillWrap = styled.div`
    // border: 1px red solid;
    width: 17%;    
`

const InsightsPanel = ({queryPanel, drilldownPanel, children}: InsightsWrapperProps) => {
    return <Wrap>
        <QueryWrap>
            {queryPanel}
        </QueryWrap>
        <BreakWrap>
            {children}
        </BreakWrap>
        <DrillWrap>
            {drilldownPanel}
        </DrillWrap>
    </Wrap>
}

export default InsightsPanel