import React, {useEffect, useMemo, useState} from "react";
import useModelsApi from "../../../api/useModelsApi";
import {By, CubeViewProps, SankeyResult} from "../types";
import SSankeyChart from "../../../../@satsui/components/SSankeyChart/SSankeyChart";
import {SLinkExtra, SNodeExtra} from "../../../../@satsui/components/SSankeyChart/types";


export type SankeyPanelProps = {
    breakdown: By
} & CubeViewProps

export const SankeyPanel = ({modelUuid, breakdown, query}: SankeyPanelProps) => {
    const modelsApi = useModelsApi();

    const [result, setResult] = useState<SankeyResult | undefined>(undefined)

    useEffect(() => {
        modelsApi.cubeSankey(modelUuid, breakdown, query).then((response) => {
            setResult(response?.data)
        });
    }, [modelsApi, modelUuid, breakdown, query]);

    const mkDag = () => {
        if(!result?.network) return;
        const network = result.network

        const nodes: SNodeExtra[] = network.nodes.map(sNode => {
            return {
                nodeId: sNode.data.id as string,
                name: sNode.data.label as string
            }
        })

        const links: SLinkExtra[] = network.edges.map(sEdge => {
            return {
                source: sEdge.data.source,
                target: sEdge.data.target,
                value: sEdge.data.value,
                uom: "string",
                kind: sEdge.data.label as string
            }
        })

        return {
            nodes,
            links
        }
    }

    const dag = useMemo(mkDag, [result?.network])

    return <div>
        <div>
            {dag && <SSankeyChart data={dag} />}
        </div>

        {/*<pre>
            {JSON.stringify(result?.network, null, 2)}
        </pre>*/}
    </div>
}