import Axios, { AxiosInstance, AxiosPromise } from "axios";

import {} from "./model";
import { API_URI } from "../../config";
import { SynthesisSummary } from "./model";

class SynthesisApi {
  static context = API_URI + "api/v1/delta";

  client: AxiosInstance;

  constructor(token: string) {
    this.client = Axios.create({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  getSummary(source: string, target: string): AxiosPromise<SynthesisSummary> {
    return this.client.get(
      `${SynthesisApi.context}/summary/${source}/${target}`
    );
  }

  getDetailed(
    source: string,
    target: string,
    attribute: string
  ): AxiosPromise<SynthesisSummary> {
    return this.client.get(
      `${SynthesisApi.context}/detailed/${source}/${target}/${attribute}`
    );
  }
}

export default SynthesisApi;
