import React, { useEffect } from "react";
import styled from "styled-components";
import ButtonText from "./ButtonText";
import Button from "./Button";
import { Icons } from "../assets/Icons";

const ModalOverlay = styled.div`
  position: absolute;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  background-color: rgba(0, 0, 0, 0.4);
  z-index: 999999;
`;

const StyledModal = styled.div`
  background-color: #fff;
  padding: 32px 80px;
  width: 440px;
  border-radius: 4px;
  box-shadow: 0 10px 15px -3px rgba(0, 0, 0, 0.1),
    0 4px 6px -2px rgba(0, 0, 0, 0.05);
  position: relative;
  box-sizing: content-box;

  & > .close-icon {
    top: 0;
    right: 0;
    position: absolute;
    padding: 8px;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  }
`;

const ModalHeader = styled.h2<{ titleSize?: string }>`
  font-size: ${(p) => p.titleSize || "21px"};
  font-weight: 700;
  margin: 16px 0;
  letter-spacing: 0.035em;
`;

const SimpleModalWrap = styled.div`
  display: flex;
  flex-direction: column;

  & > div {
    display: flex;
    margin-left: auto;
    margin-top: 40px;
  }

  .cancel-btn,
  .accept-btn {
    display: block !important;
    margin: 0;
    line-height: 1;
  }

  .cancel-btn {
    margin-right: 24px;
  }
`;

const SimpleModalText = styled.p`
  margin: 0;
`;

export const SimpleModal = (props: any) => {
  useEffect(() => {
    // scroll to the top of a page
    window.scrollTo(0, 0);
    // hide scroll on a whole page
    const body = document.getElementsByTagName("body")[0];
    body.style.overflow = "hidden";

    return () => {
      // before Modal component is destroyed from DOM, re-enable scroll for a whole page
      body.style.overflow = "auto";
    };
  }, []);

  return (
    <ModalOverlay>
      <StyledModal>
        {props.closeIcon && (
          <Icons.Close className="close-icon" onClick={props.closeHandler} />
        )}
        <ModalHeader titleSize={props.titleSize}>{props.title}</ModalHeader>
        <SimpleModalWrap>
          <SimpleModalText>{props.text}</SimpleModalText>

          <div>
            <ButtonText
              as="button"
              className="cancel-btn"
              onClick={props.closeHandler}
            >
              {props.closeText}
            </ButtonText>

            <Button
              type="button"
              w="130px"
              notrounded={true}
              className="accept-btn"
              onClick={props.acceptHandler}
            >
              {props.acceptText}
            </Button>
          </div>
        </SimpleModalWrap>
      </StyledModal>
    </ModalOverlay>
  );
};
