import React from "react";
import {
  DashboardWrap,
  DashboardHeader,
  HeaderBtnWrap,
  DashboardTools,
  DashboardMain,
  DashboardNav,
} from "../../components/DashboardView";
import { Button, UserProfile } from "../../../components";
import { Icons } from "../../../assets/Icons";
import { Link } from "react-router-dom";
import { State } from "../DraggableReference/stateManager";
import Toolbar from "./components/Toolbar";
import useAuth from "../../../auth/context/useAuth";
import { Model } from "../../api/model";
import { ReactComponent as Logo } from "../../../assets/logo.svg";

import { match } from "react-router";

interface MatchParams extends match {
  params: {
    modelUuid: string;
    projectId?: string;
  };
}
export const DashBoardConstant: React.FC<{
  match: MatchParams;
  onCopy?: (state: State) => void;
  onPaste?: (state: State) => void;
  onStyle?: (state: State, style: any) => void;
  children: React.ReactNode;
  withTree?: boolean;
  modelInfo: Model | undefined;
  hideToolbar?: boolean;
}> = (Props) => {
  const auth = useAuth();

  return (
    <DashboardWrap>
      <DashboardHeader>
        <DashboardTools>
          <Link to="/">
            <Logo className="opteq-logo" />
          </Link>

          <ul className="breadcrumb-list">
            <li>
              <Link to={`/projects/${Props.modelInfo?.projectUuid}`}>
                {Props.modelInfo?.project.name}
              </Link>
            </li>
            <li className="breadcrumb-sep">/</li>
            <li>
              <Link to={`/models/${Props.modelInfo?.uuid}`}>
                {Props.modelInfo?.name}
              </Link>
            </li>
          </ul>

          {!Props.hideToolbar && (
            <Toolbar
              onCopy={Props.onCopy}
              onPaste={Props.onPaste}
              onStyle={Props.onStyle}
            ></Toolbar>
          )}
        </DashboardTools>

        <HeaderBtnWrap>
          <Button
            className="dashboard-feedback"
            href="mailto:feedback@opteq.co"
            as="a"
            bgcolor="#52B788"
          >
            Give feedback
          </Button>

          {auth.profile && <UserProfile profile={auth.profile} size="32" />}
        </HeaderBtnWrap>
      </DashboardHeader>

      <DashboardMain
        className={Props?.withTree ? "dashboard-withtree" : "dashboard-wrap"}
      >
        <DashboardNav>
          <ul>
            <li>
              <Link to={`/models/${Props.match.params?.modelUuid}`}>
                <Icons.Dashboard size={24} />
                <span>Principles</span>
              </Link>
            </li>
            <li>
              <Link to={`/models/${Props.match.params?.modelUuid}/reference`}>
                <Icons.ReferencePage size={24} />
                <span>Functional Model</span>
              </Link>
            </li>
            {/* hidden for now
						<li>
              <Link to={`/models/${Props.match.params?.modelUuid}/pyramid`}>
                <Icons.Pyramid size={24} />
                <span>Pyramid</span>
              </Link>
						</li>
						*/}
            <li>
              <Link to={`/models/${Props.match.params?.modelUuid}/attributes`}>
                <Icons.ProjectData size={24} />
                <span>Attributes</span>
              </Link>
            </li>

            <li>
              <Link to={`/models/${Props.match.params?.modelUuid}/insights`}>
                <Icons.Activity size={24} />
                <span>Insights</span>
              </Link>
            </li>

            {/*<li>
              <Link to={`/models/${Props.match.params?.modelUuid}/hierarchy`}>
                <Icons.Pyramid size={24} />
                <span>Hierarchy</span>
              </Link>
            </li>*/}

            <li>
              <Link to={`/models/${Props.match.params?.modelUuid}/network`}>
                <Icons.OrgChart size={24} />
                <span>Network</span>
              </Link>
            </li>
            <li>
              <Link to={`/models/${Props.match.params?.modelUuid}/charts`}>
                <Icons.World size={24} />
                <span>Charts</span>
              </Link>
            </li>
            <li>
              <Link to={`/models/${Props.match.params?.modelUuid}/satsui`}>
                <Icons.Eye size={24} />
                <span>Satsui</span>
              </Link>
            </li>
            <li>
              <Link to={`/projects/${Props.modelInfo?.projectUuid}/delta-data`}>
                <Icons.Delta size={24} />
                <span>Delta</span>
              </Link>
            </li>
          </ul>
        </DashboardNav>
        {Props.children}
      </DashboardMain>
    </DashboardWrap>
  );
};

export default DashBoardConstant;
