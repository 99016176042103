import React, {useEffect, useRef} from 'react'
import * as d3 from 'd3';
import * as d3Sankey from 'd3-sankey';
import {DAG, SLinkExtra, SNode, SNodeExtra} from "./types";
import {useResizeDetector} from "react-resize-detector";

import ColorHash from 'color-hash'

const colorHash = new ColorHash({hue: [ {min: 30, max: 90}, {min: 180, max: 210}, {min: 270, max: 285} ]});


export type SankeyProps = {
    data: DAG
}

const width = 600
const height = 600



// type SNode = SankeyNode<SankeyNodeProps, SankeyNodeProps>
// type SLink = SankeyLink<SankeyLinkProps, SankeyLinkProps>
// type SGraph = {
//     units: string
// } & SankeyGraph<SankeyNodeProps, SankeyLinkProps>

const edgeColor: string = "red"


const SSankeyChart: React.FC<SankeyProps> = ({data}: SankeyProps) => {
    const d3Container = useRef(null);

    const {width, height, ref} = useResizeDetector();

    // window.alert("ss")

    /* The useEffect Hook is for running side effects outside of React,
   for instance inserting elements into the DOM using D3 */
    useEffect(
        () => {
            // window.alert("a")
            if (!data && d3Container.current) return;
            // window.alert("b")

            // window.alert(`svg: ${d3.select(d3Container.current)}`)

            const svgEl = d3.select(d3Container.current);
            svgEl.selectAll("*").remove(); // Clear svg content before adding new elements


            const svg = svgEl,
                width = +svg.attr("width"),
                height = +svg.attr("height");

            const sankey = d3Sankey.sankey<SNodeExtra, SLinkExtra>()
                .nodeAlign(d3Sankey.sankeyJustify)
                .nodeId(d => d.nodeId)
                .nodeWidth(20)
                .nodePadding(10)
                .extent([[1, 1], [width - 1, height - 6]]);

            const {nodes, links} = sankey({
                nodes: data.nodes.map(d => Object.assign({}, d)),
                links: data.links.map(d => Object.assign({}, d))
            });

            const formatNumber = d3.format(",.0f"),
                format = function (d: any) {
                    return formatNumber(d) + " TWh";
                }
                // color = d3.scaleOrdinal(d3.schemeCategory10);

            const color = (d: SNode) => {
                const color = d3.scaleOrdinal(d3.schemeCategory10);
                // return color(d.category === undefined ? d.name : d.category);
                return color(d.name);
            }

            const edgeColor = (d: SLinkExtra) => {
                const color = d3.scaleOrdinal(d3.schemeCategory10);
                // return color(d.category === undefined ? d.name : d.category);
                return color(d.target);
            }


            // vertical bars
            svg.append("g")
                .attr("stroke", "#000")
                .selectAll("rect")
                .data(nodes)
                .join("rect")
                .attr("x", d => d.x0 as number)
                .attr("y", d => d.y0 as number)
                .attr("height", d => (d.y1 as number) - (d.y0 as number))
                .attr("width", d => (d.x1 as number) - (d.x0 as number))
                .attr("fill", color)
                .append("title")
                .text(d => `${d.name}\n${format(d.value)}`);


            const link = svg.append("g")
                // .attr("font-family", "sans-serif")
                // .attr("font-size", 10)
                // .selectAll("text")
                // .data(links)
                // .join("text")

                .attr("fill", "none")
                .attr("stroke-opacity", 0.5)
                .selectAll("g")
                .data(links)
                .join("g")
                .style("mix-blend-mode", "multiply")


            // edegcolor


            link.append("path")
                .attr("d", d3Sankey.sankeyLinkHorizontal())
                /*.attr("stroke", d => {
                    const [r,g,b] = colorHash.hsl(d.kind)

                    // return `rgb(${r}, ${g}, ${b})`
                    return `rgb(${r}, ${g}, ${b})`
                    // return "lightGreen"
                })*/

                .attr("stroke", edgeColor)

                // .attr("stroke-width", d => 24)
                .attr("stroke-width", d => d.width as number);

            link.append("title")
                .text((d) => `${(d.source as SNode).name} → ${(d.target as SNode).name}\n${format(d.value)}`);

            svg.append("g")
                .attr("font-family", "sans-serif")
                .attr("font-size", 10)
                .selectAll("text")
                .data(nodes)
                .join("text")
                .attr("x", d => (d.x0 as number) < width / 2 ? (d.x1 as number) + 6 : (d.x0 as number) - 6)
                .attr("y", d => ((d.y1 as number) + (d.y0 as number)) / 2)
                .attr("dy", "0.35em")
                .attr("text-anchor", d => (d.x0 as number) < width / 2 ? "start" : "end")
                .text(d => d.name);

        },

        /*
            useEffect has a dependency array (below). It's a list of dependency
            variables for this useEffect block. The block will run after mount
            and whenever any of these variables change. We still have to check
            if the variables are valid, but we do not have to compare old props
            to next props to decide whether to rerender.
        */
        [data, d3Container.current, width, height, ref]
    )


    return (<div ref={r => ref.current = r} style={{width: "100%", height: "600px"}}>
        <svg
            width={width}
            height={height}
            ref={d3Container}
            style={{
                width,height,
                margin: "0 auto"
            }}
        />
    </div>)
}

export default SSankeyChart