import React from "react";
import styled from "styled-components";

const CheckWrap = styled.label`
  position: relative;
  color: #061d38;
  user-select: none;
  cursor: pointer;
  font-size: 14px;

  text-align: center;
  world-break: break-all;
  display: flex;

  span {
    background-color: #f1f1f0;
    padding: 12px;
    display: flex;
    border-radius: 4px;
    flex-wrap: wrap;
    word-break: break-word;
    width: 100%;
    justify-content: center;
    align-items: center;

    &:hover {
      background-color: #061d38;
      color: #fff;
    }
  }

  input:checked + span {
    background-color: #061d38;
    color: #fff;
  }
`;

const StyledCheckCard = styled.input`
  display: none;
`;

const CheckboxCard = (props: any) => {
  return (
    <CheckWrap>
      <StyledCheckCard
        type="checkbox"
        name={props.name}
        value={props.value}
        onChange={props.onChange}
      />
      <span>{props.value}</span>
    </CheckWrap>
  );
};
export default CheckboxCard;
