import { default as Activity } from './Activity';
import { default as ArrowDown } from './ArrowDown';
import { default as ArrowGo } from './ArrowGo';
import { default as ArrowLeft } from './ArrowLeft';
import { default as ArrowRight } from './ArrowRight';
import { default as ArrowUp } from './ArrowUp';
import { default as BorderColor } from './BorderColor';
import { default as BusinessUnit } from './BusinessUnit';
import { default as Check } from './Check';
import { default as Clients } from './Clients';
import { default as Close } from './Close';
import { default as CloseCircle } from './CloseCircle';
import { default as Copy } from './Copy';
import { default as Dashboard } from './Dashboard';
import { default as Delta } from './Delta';
import { default as Division } from './Division';
import { default as Edit } from './Edit';
import { default as Eye } from './Eye';
import { default as Filter } from './Filter';
import { default as Function } from './Function';
import { default as Models } from './Models';
import { default as More } from './More';
import { default as OrgChart } from './OrgChart';
import { default as Paste } from './Paste';
import { default as ProjectData } from './ProjectData';
import { default as Projects } from './Projects';
import { default as Pyramid } from './Pyramid';
import { default as ReferencePage } from './ReferencePage';
import { default as Search } from './Search';
import { default as SecurityLock } from './SecurityLock';
import { default as Settings } from './Settings';
import { default as Sort } from './Sort';
import { default as SubFunction } from './SubFunction';
import { default as Team } from './Team';
import { default as TextBox } from './TextBox';
import { default as Trash } from './Trash';
import { default as World } from './World';

    export const Icons = {
      Activity,
  ArrowDown,
  ArrowGo,
  ArrowLeft,
  ArrowRight,
  ArrowUp,
  BorderColor,
  BusinessUnit,
  Check,
  Clients,
  Close,
  CloseCircle,
  Copy,
  Dashboard,
  Delta,
  Division,
  Edit,
  Eye,
  Filter,
  Function,
  Models,
  More,
  OrgChart,
  Paste,
  ProjectData,
  Projects,
  Pyramid,
  ReferencePage,
  Search,
  SecurityLock,
  Settings,
  Sort,
  SubFunction,
  Team,
  TextBox,
  Trash,
  World
    };
  