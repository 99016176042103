import React from "react";
import styled from "styled-components";

const SVG = (props: Props): JSX.Element => {
  return (
    <svg
      className="Trash_svg__icon Trash_svg__icon-tabler Trash_svg__icon-tabler-trash"
      width="1em"
      height="1em"
      viewBox="0 0 24 24"
      strokeWidth={1.5}
      stroke="#2c3e50"
      fill="none"
      strokeLinecap="round"
      strokeLinejoin="round"
      {...props}
    >
      <path d="M0 0h24v24H0z" stroke="none" />
      <path d="M4 7h16M10 11v6M14 11v6M5 7l1 12a2 2 0 002 2h8a2 2 0 002-2l1-12M9 7V4a1 1 0 011-1h4a1 1 0 011 1v3" />
    </svg>
  );
};

type Props = {
  onClick?: any;
  size?: number;
} & React.SVGProps<SVGSVGElement>;
type StyledProps = {
  size?: number;
};
const SvgTrash = styled(SVG)<StyledProps>`
  vertical-align: middle;
  height: ${(props) => (props.size ? props.size + "px" : "16px")};
  width: ${(props) => (props.size ? props.size + "px" : "16px")};
`;
export default SvgTrash;
