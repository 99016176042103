import React, { useState, useRef, useLayoutEffect } from "react";
import styled from "styled-components";
import { Icons } from "../assets/Icons";

const StyledDropdown = styled.div<{
  textAlign?: string;
  left?: boolean;
  right?: boolean;
  ref?: any;
}>`
  font-size: 16px;
  position: relative;

  ul {
    display: flex;
    flex-direction: column;
    margin: 0;
    padding: 0;
    list-style: none;
    background-color: #fefdfe;
    border: 1px solid #f3f3f3;
    margin-top: 4px;
    position: absolute;
    right: ${(p) => (p.right ? 0 : "unset")};
    left: ${(p) => (p.left ? 0 : "unset")};
    min-width: 65px;
    box-shadow: 0 4px 6px -1px rgba(0, 0, 0, 0.1),
      0 2px 4px -1px rgba(0, 0, 0, 0.06);
    z-index: 99999;
    width: 100%;
  }

  li {
    text-align: ${(p) => p.textAlign || "center"};
  }
`;

const DropdownHeader = styled.div`
  display: flex;
  align-items: center;
  cursor: pointer;
  justify-items: center;
`;

export const DropdownItem = styled.button`
  margin: 0;
  padding: 0;
  background: none;
  color: inherit;
  border: none;
  padding: 0;
  font: inherit;
  cursor: pointer;
  outline: inherit;
  width: 100%;
  padding: 12px 8px;
  text-align: inherit;

  &:hover {
    background-color: #f3f3f3;
  }
`;

export const Dropdown = (props: any) => {
  const [menuOpen, setMenuOpen] = useState(false);
  const node = useRef<HTMLDivElement>(null);

  const clickOutside = (e: any) => {
    // inside click
    if (node?.current?.contains(e.target)) return;
    // outside click
    setMenuOpen(false);
  };

  useLayoutEffect(() => {
    if (menuOpen) {
      document.addEventListener("mousedown", clickOutside);
    } else {
      document.removeEventListener("mousedown", clickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", clickOutside);
    };
  }, [menuOpen]);

  return (
    <StyledDropdown
      left={props.left}
      right={props.right}
      className={props.className}
      textAlign={props.textAlign}
      ref={node}
    >
      <DropdownHeader onClick={() => setMenuOpen((menuOpen) => !menuOpen)}>
        {props.title}
        {props.toggleIcon ? (
          menuOpen ? (
            <Icons.ArrowUp size={24} />
          ) : (
            <Icons.ArrowDown size={24} />
          )
        ) : null}
      </DropdownHeader>

      {menuOpen && (
        <ul>
          {props.children &&
            React.Children.map(props.children, (child) => {
              return (
                <li onClick={() => setMenuOpen((menuOpen) => !menuOpen)}>
                  {child}
                </li>
              );
            })}
        </ul>
      )}
    </StyledDropdown>
  );
};
