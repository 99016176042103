import React from "react";
import { StyledCard } from "./styles";

const TotalCard = (props: any) => {
  const { targetModel, data, kindPlural } = props;
  return (
    <StyledCard className="total-attr">
      <h3>Total {kindPlural}</h3>
      <div>
        <span className="total-num">{data?.total.tgt.count}</span>
        <span className="total-text">
          in <span className="total-model">{targetModel}</span> model
        </span>
      </div>
    </StyledCard>
  );
};

export default TotalCard;
