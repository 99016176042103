import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Input, Button, TextArea, AppToaster } from "../../../components";
import { MembersGrid } from "../../../clientspaces/components/CsListView";
import useProjectsApi from "../../api/useProjectsApi";
import { Icons } from "../../../assets/Icons";
import { Dialog } from "@blueprintjs/core";
import useModelsApi from "../../../models/api/useModelsApi";

const StyledDialog = styled(Dialog)`
  background-color: #fff;
  background: #fff;

  & > .bp3-dialog-header {
    box-shadow: none;
    padding: 16px 5px 16px 24px;

    & > h4 {
      font-size: 21px;
    }
  }

  .dialog-content {
    padding: 16px 24px;
  }
`;

const MemberCard = styled.div`
  font-size: 12px;
  display: inline-flex;

  align-items: center;
  word-break: break-all;
  cursor: pointer;

  & > span {
    color: #4a90e2;
    font-weight: bold;
    margin-left: 8px;
  }

  & > svg {
    min-width: 14px;
  }
`;

const NewModel = (props: any) => {
  const [modelName, setModelName] = useState("");
  const [newMember, setNewMember] = useState("");
  const [modelDesc, setModelDesc] = useState("");
  const [members, setMembers] = useState<Array<string>>([]);
  const [modelOwner, setModelOwner] = useState("");

  useEffect(() => {
    setModelOwner(props.owner?.name);
  }, [props.owner]);

  const modelsApi = useModelsApi();

  const memberToList = (e: any) => {
    e.preventDefault(); // don't refresh page
    if (newMember.length > 0 && !members.includes(newMember)) {
      setMembers((members) => members.concat(newMember));
    }

    setNewMember("");
  };

  const deleteMemberFromList = (target: string) => {
    const INDEX = members.indexOf(target);

    if (INDEX >= 0) {
      setMembers(members.filter((item) => item !== target));
    }
  };

  function doNewModel(e: any) {
    e.preventDefault();

    const API_OBJ = {
      properties: {
        name: modelName,

        description: modelDesc.length > 0 ? modelDesc : undefined,
      },
      kind: "OpModel",
      corporate: {
        kind: "Corporate",
      },
      invites: members,
    };

    return modelsApi
      .createModel(props.projectID, API_OBJ)
      .then((response) => {
        if (response.statusText === "OK") {
          AppToaster.show({
            message: `${modelName} model was successfully created!`,
            intent: "success",
          });

          props.refresh();
          props.onClose();
        }
      })
      .catch((error) => {
        // notify user that model was not created
        AppToaster.show({
          message: "Something went wrong, please try again!",
          intent: "danger",
        });

        // close modal and console log error
        props.onClose();
        console.log(error);
      });
  }

  return (
    <StyledDialog
      title="New model"
      onClose={props.onClose}
      isOpen={props.isOpen}
    >
      <div className="dialog-content">
        <Input
          label="Model name"
          id="input-modelName"
          type="text"
          value={modelName}
          onChange={(e: any) => {
            setModelName(e.target.value);
          }}
        />

        <form onSubmit={memberToList}>
          <Input
            label="Invite team members"
            id="input-members"
            type="email"
            value={newMember}
            onChange={(e: any) => {
              setNewMember(e.target.value);
            }}
          />
        </form>
        <MembersGrid>
          {members.map((member: string, i: number) => {
            return (
              <MemberCard key={i} onClick={() => deleteMemberFromList(member)}>
                <Icons.Close size={14} />
                <span>{member}</span>
              </MemberCard>
            );
          })}
        </MembersGrid>

        <TextArea
          label="Model description"
          id="model-description"
          value={modelDesc}
          onChange={(e: any) => setModelDesc(e.target.value)}
          maxlength="300"
        />

        {
          // TODO: add error for 300 characters limit for description
        }

        <Input
          label="Model owner"
          id="input-modelOwner"
          type="text"
          value={modelOwner}
          readonly
        />

        <Button
          type="submit"
          disabled={!modelName || !modelOwner}
          onClick={doNewModel}
        >
          Create model
        </Button>
      </div>
    </StyledDialog>
  );
};
export default NewModel;
