import styled from "styled-components";
import { BoxSpacerWrapProps, BoxNameViewProps } from "./types";
import { ApplyStyles, ApplyStylesProps } from "../../styles";
export const spacerWidth = 6;
export const expandedSpacerWidth = 30;
// const spacerHeight = spacerWidth * 0.5;

export const BoxWrap = styled(ApplyStyles)<ApplyStylesProps>`
  flex-grow: 1;
  text-align: center;
  border-radius: 5px;
  padding: 5px;
  box-sizing: border-box;
`;

export const BoxView = styled.div`
  height: 100%;
  width: 100%;
`;

export const BoxNameWrap = styled.div<BoxNameViewProps>`
  width: 100%;
  text-align: center;
  height: 40px;
  line-height: 40px;
  font-weight: ${(props) => (props.thick ? "550" : "inherit")};
`;

export const SpacerBox = styled.div`
  position: absolute;
  background: repeating-linear-gradient(
    -55deg,
    #4a90e2,
    #4a90e2 5px,
    #fff 5px,
    #fff 10px
  );
  height: 100%;
  opacity: 1;
  left: ${-expandedSpacerWidth * 0.5 + spacerWidth * 0.5}px;
  min-width: ${expandedSpacerWidth}px;
  width: ${expandedSpacerWidth}px;
`;

export const SpacerWrap = styled.div<BoxSpacerWrapProps>`
  z-index: 999;
  position: relative;
  opacity: ${(props) => +props.isOver};
  min-width: ${spacerWidth}px;
  width: ${spacerWidth}px;
  margin: 5px 0px 5px 0px;
  ${(props) =>
    props.resizable
      ? `
      &:hover {
        opacity: 1;
        cursor: col-resize;
      }
  `
      : ""}
`;
