import React, { useState } from "react";
import {
  ButtonGroup,
  Popover,
  Button,
  Menu,
  MenuItem,
  InputGroup,
  Position,
} from "@blueprintjs/core";
import { Icons } from "../../../../../assets/Icons";

interface ToolsProps {
  tableHeaders: Array<{ name: string; key: string; type: string }>;
  setFilterString: any;
  setSortString: any;
  setSearchString: any;
}
interface ColumnObject {
  name: string;
  key: string;
  type?: string;
}

const ExtraTools: React.FC<ToolsProps> = (props) => {
  const {
    tableHeaders,
    setFilterString,
    setSortString,
    setSearchString,
  } = props;

  const [filterColumn, setFilterColumn] = useState<ColumnObject>();
  const [filterOp, setFilterOp] = useState<ColumnObject>();
  const [filterSearch, setFilterSearch] = useState<string>();

  const [sortColumn, setSortColumn] = useState<ColumnObject>();
  const [sortOp, setSortOp] = useState<ColumnObject>();
  const [searchValue, setSearchValue] = useState("");

  function selectOpHandler(key: string, name: string) {
    setFilterOp({
      name: name,
      key: key,
    });
  }

  function selectColumnHandler(column: any) {
    setFilterColumn({
      name: column.name,
      key: column.key,
    });
  }

  function resetFilterHandler() {
    setFilterOp(undefined);
    setFilterColumn(undefined);
    setFilterString(undefined);
  }

  function doFilter() {
    const searchParam = !filterSearch ? "null" : "'" + filterSearch + "'";
    setFilterString(
      filterColumn?.key + "." + filterOp?.key + "." + searchParam
    );
  }

  // used for sorting
  function selectSortColumnHandler(column: any) {
    setSortColumn({
      name: column.name,
      key: column.key,
      type: column.type,
    });
  }

  function selectSortOpHandler(key: string, name: string) {
    setSortOp({
      name: name,
      key: key,
    });
  }

  function resetSortHandler() {
    setSortColumn(undefined);
    setSortOp(undefined);
    setSortString(undefined);
  }

  function doSort() {
    setSortString(sortColumn?.key + "+" + sortOp?.key);
  }

  function doSearch(e: any) {
    e.preventDefault();
    if (searchValue.length <= 0) {
      setSearchString(undefined);
    }

    setSearchString(searchValue);
  }

  return (
    <>
      <form onSubmit={(e: any) => doSearch(e)}>
        <InputGroup
          leftElement={<Icons.Search size={16} />}
          className="tools-search"
          value={searchValue}
          onChange={(e: any) => setSearchValue(e.target.value)}
          type="search"
        />
      </form>
      <ButtonGroup style={{ minWidth: 120 }}>
        <Popover position={Position.BOTTOM_LEFT} minimal usePortal={false}>
          <Button
            icon={<Icons.Filter size={18} />}
            text="Filter"
            minimal
            style={{
              backgroundColor: filterColumn || filterOp ? "#4a90e2" : "inherit",
              color: filterColumn || filterOp ? "#fff" : "inherit",
            }}
            className="filter-btn"
          />

          <ButtonGroup style={{ minWidth: 120 }} className="filter-wrap">
            {filterColumn || filterOp ? (
              <Button
                icon={<Icons.Close size={12} />}
                minimal
                onClick={resetFilterHandler}
              />
            ) : null}

            <Popover position={Position.BOTTOM_LEFT}>
              <Button
                text={filterColumn ? filterColumn?.name : "Column:"}
                minimal
              />

              <Menu>
                {tableHeaders.length > 0 &&
                  tableHeaders.map((col: any) => (
                    <MenuItem
                      text={col.name}
                      key={col.key}
                      onClick={() => selectColumnHandler(col)}
                      active={filterColumn?.key === col.key}
                      shouldDismissPopover={false}
                    />
                  ))}
              </Menu>
            </Popover>

            <Popover position={Position.BOTTOM_LEFT}>
              <Button text={filterOp ? filterOp?.name : "Filter by:"} minimal />
              <Menu>
                <MenuItem
                  text="contains"
                  onClick={() => selectOpHandler("contains", "contains")}
                  active={filterOp?.key === "contains"}
                  shouldDismissPopover={false}
                />
                <MenuItem
                  text="does not contain"
                  onClick={() =>
                    selectOpHandler("!contains", "does not contain")
                  }
                  active={filterOp?.key === "!contains"}
                  shouldDismissPopover={false}
                />
                <MenuItem
                  text="is empty"
                  onClick={() => selectOpHandler("is", "is empty")}
                  active={filterOp?.key === "is"}
                  shouldDismissPopover={false}
                />
                <MenuItem
                  text="is not empty"
                  onClick={() => selectOpHandler("!is", "is not empty")}
                  active={filterOp?.key === "!is"}
                  shouldDismissPopover={false}
                />
              </Menu>
            </Popover>

            <InputGroup
              small
              placeholder="Start typing"
              className="filter-search"
              value={filterSearch}
              onChange={(e: any) => setFilterSearch(e.target.value)}
            />
            {filterColumn && filterOp ? (
              <Button
                icon={<Icons.Check size={21} />}
                minimal
                onClick={doFilter}
              />
            ) : null}
          </ButtonGroup>
        </Popover>
        <Popover position={Position.BOTTOM_LEFT} minimal usePortal={false}>
          <Button
            icon={<Icons.Sort size={18} />}
            text="Sort"
            minimal
            className="sort-btn"
            style={{
              backgroundColor: sortColumn || sortOp ? "#4a90e2" : "inherit",
              color: sortColumn || sortOp ? "#fff" : "inherit",
            }}
          />
          <ButtonGroup style={{ minWidth: 120 }} className="filter-wrap">
            {sortColumn || sortOp ? (
              <Icons.Close
                size={12}
                onClick={resetSortHandler}
                className="filter-reset"
              />
            ) : null}

            <Popover position={Position.BOTTOM_LEFT}>
              <Button
                text={sortColumn?.name ? sortColumn?.name : "Column:"}
                minimal
              />

              <Menu>
                {tableHeaders.length > 0 &&
                  tableHeaders.map((col: any) => (
                    <MenuItem
                      text={col.name}
                      key={col.key}
                      onClick={() => selectSortColumnHandler(col)}
                      active={sortColumn?.key === col.key}
                      shouldDismissPopover={false}
                    />
                  ))}
              </Menu>
            </Popover>

            <Popover position={Position.BOTTOM_LEFT}>
              <Button text={sortOp ? sortOp?.name : "Sort by:"} minimal />
              <Menu>
                {sortColumn?.type === "number" ? (
                  <>
                    <MenuItem
                      text="Lowest to Highest"
                      onClick={() =>
                        selectSortOpHandler("asc", "Lowest to Highest")
                      }
                      active={sortOp?.key === "asc"}
                      shouldDismissPopover={false}
                    />
                    <MenuItem
                      text="Highest to Lowest"
                      onClick={() =>
                        selectSortOpHandler("desc", "Highest to Lowest")
                      }
                      active={sortOp?.key === "desc"}
                      shouldDismissPopover={false}
                    />
                  </>
                ) : (
                  <>
                    <MenuItem
                      text="A to Z"
                      onClick={() => selectSortOpHandler("asc", "A to Z")}
                      active={sortOp?.key === "asc"}
                      shouldDismissPopover={false}
                    />
                    <MenuItem
                      text="Z to A"
                      onClick={() => selectSortOpHandler("desc", "Z to A")}
                      active={sortOp?.key === "desc"}
                      shouldDismissPopover={false}
                    />
                  </>
                )}
              </Menu>
            </Popover>

            {sortColumn && sortOp ? (
              <Button
                icon={<Icons.Check size={21} />}
                minimal
                onClick={doSort}
              />
            ) : null}
          </ButtonGroup>
        </Popover>
      </ButtonGroup>
    </>
  );
};
export default ExtraTools;
