import styled from "styled-components";

export const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid #d4d4d4;
  padding: 24px 16px;
  height: auto;
  grid-column: 5/7;
  grid-row: 3/5;

  h3 {
    margin: 0;
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 500;
    display: flex;
    align-items: center;

    .popover-btn {
      margin-left: 2px;
      color: #4a90e2;
      font-weight: 500;
      padding: 3px 5px;
      font-size: 18px;
      min-height: unset;
      min-width: unset;
      text-transform: capitalize;
    }
  }

  .role-name {
    font-size: 14px;
    font-weight: 500;
    color: #fff;
    fill: #fff;
  }

  & > div {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .property-table-wrap {
    overflow-y: auto;
    height: 100%;

    & > table {
      width: 100%;
      border-collapse: collapse;
      border-style: hidden;
    }

    tr {
      border: solid 2px #f3f3f3;
    }

    th {
      font-weight: 500;
      text-transform: capitalize;
    }

    td,
    th {
      text-align: left;
      padding: 12px 4px;
    }
  }
`;
