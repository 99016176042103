import styled from "styled-components";

export const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid #d4d4d4;
  padding: 24px 16px;
  height: auto;
  grid-column: 1/5;
  grid-row: 3/5;

  h3 {
    margin: 0;
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 500;
    display: flex;
    align-items: center;

    .popover-btn {
      margin-left: 2px;
      color: #4a90e2;
      font-weight: 500;
      padding: 3px 5px;
      font-size: 18px;
      min-height: unset;
      min-width: unset;
      text-transform: capitalize;
    }
  }

  .level-name {
    font-size: 13px;
    font-weight: 500;
    color: #000;
    fill: #000;
  }

  & > div {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .treemap-chart-wrap {
    width: 100%;
    height: 100%;
  }
`;
