import React, { useState, useRef, useLayoutEffect, useEffect } from "react";
import styled from "styled-components";
import { Icons } from "../../assets/Icons";

const ColumnsWrap = styled.div<{ ref?: any }>`
  width: 100%;
  cursor: pointer;
  user-select: none;
  margin-top: 8px;
`;

const DropdownHeader = styled.div`
  display: flex;
  font-size: 15px;
  font-weight: 500;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  margin-bottom: 4px;
`;

const ColumnsList = styled.div`
  display: flex;
  flex-direction: column;
  overflow: hidden;
  white-space: nowrap;
  border-radius: 4px;
  font-size: 15px;

  & > label {
    padding: 4px 16px;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
  }

  & > span {
    padding: 4px 16px;
    font-weight: 500;
    font-size: 14px;
  }
`;

export const DeltaColumns = (props: any) => {
  const {
    title,
    className,
    selectedAttribute,
    handleColumnsChange,
    attributesList,
    hiddenColumns,
  } = props;
  const [menuOpen, setMenuOpen] = useState(false);
  const [columnsList, setColumnsList] = useState<Array<any>>([]);
  const node = useRef<HTMLDivElement>(null);

  const clickOutside = (e: any) => {
    // inside click
    if (node?.current?.contains(e.target)) return;
    // outside click
    setMenuOpen(false);
  };

  useLayoutEffect(() => {
    if (menuOpen) {
      document.addEventListener("mousedown", clickOutside);
    } else {
      document.removeEventListener("mousedown", clickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", clickOutside);
    };
  }, [menuOpen]);

  useEffect(() => {
    const INDEX = attributesList.findIndex(
      (attr: any) => attr.kind === selectedAttribute
    );

    if (INDEX > -1) {
      attributesList[INDEX].properties["split"] = {
        type: "link",
        meta: {
          name: "Model Allocation",
        },
      };

      setColumnsList(Object.values(attributesList[INDEX]?.properties));
    }
  }, [attributesList, selectedAttribute]);

  return (
    <ColumnsWrap className={className} ref={node}>
      <DropdownHeader onClick={() => setMenuOpen((menuOpen) => !menuOpen)}>
        {
          <>
            {menuOpen ? (
              <Icons.ArrowDown size={18} />
            ) : (
              <Icons.ArrowRight size={18} />
            )}
            <span>{title}</span>
          </>
        }
      </DropdownHeader>

      {menuOpen && (
        <ColumnsList>
          {columnsList.length > 0 ? (
            columnsList.map((column: any, i: number) => (
              <label
                className="dropdown-item"
                htmlFor={"check" + column?.meta.name}
              >
                {column?.meta.name}
                <input
                  id={"check" + column?.meta.name}
                  type="checkbox"
                  value={i}
                  name="selected-columns"
                  onChange={(e: any) => handleColumnsChange(e)}
                  checked={hiddenColumns.indexOf(i) > -1 ? false : true}
                />
              </label>
            ))
          ) : (
            <span>Select attribute first</span>
          )}
        </ColumnsList>
      )}
    </ColumnsWrap>
  );
};
