import React, {useEffect, useState} from "react"
import useModelsApi from "../../../api/useModelsApi";
import useProjectsApi from "../../../../projectspaces/api/useProjectsApi";


type ModelControlProps = {
    projectUuid?: string
}

export const SatsuiControl = ({projectUuid}: ModelControlProps) => {
    // const {data: model} = useModel(modelUuid)

    const projectsApi = useProjectsApi()
    const modelsApi = useModelsApi()

    const [controls, setControls] = useState<any>(undefined)

    useEffect(() => {
        if (!projectUuid) return;

        modelsApi.getSatsuiControls(projectUuid).then((response) => {
            setControls(response?.data);
        });
    }, [projectUuid, modelsApi]);

    const create = () => {

    }

    const update = (modelUuid: string) => () => {

    }

    const clone = (modelUuid: string) => () => {

    }

    const snapshot = (modelUuid: string) => () => {

    }

    const Rows = () => controls.models.map((model: any) => {
        return <tr>
            <td>{model.name}</td>
            <td>
                <button onClick={update(model.uuid)}>Update</button>
                <button onClick={clone(model.uuid)}>Clone</button>
                <button onClick={snapshot(model.uuid)}>Snapshot</button>
            </td>
        </tr>
    })

    return (<>
        <button onClick={create}>Create Model</button>
                <table>
                    <thead>
                    <tr>
                        <th>Model</th>
                        <th>Operations</th>
                    </tr>
                    </thead>
                    <tbody>
                    {controls ? <Rows/> : <></>}
                    </tbody>
                </table>
    </>)
}