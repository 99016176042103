import useAuth from "../../auth/context/useAuth";
import { UserApi } from "./index";
import { useMemo } from "react";

export function useUserApi(): UserApi {
  const auth = useAuth();

  return useMemo(() => {
    const token = auth.token as string;

    return new UserApi(token);
  }, [auth]);
}

export default useUserApi;
