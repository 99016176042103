import React, { useState } from "react";
import { useHistory } from "react-router";
import { RouteComponentProps } from "react-router";
import useUserApi from "../../../user/api/useUserApi";
import { ReactComponent as Logo } from "../../../assets/logo.svg";
import AuthFooter from "../../components/AuthFooter";
import {
  AuthWrap,
  Form,
  Label,
  StepDiv,
  StepWrap,
  RadioGroup,
} from "../../components/AuthForms";
import { Button, RadioCard, CheckboxCard } from "../../../components";
import { clientspaceHomeUrl } from "../../../clientspaces/";
import { Icons } from "../../../assets/Icons";
import { Card, Elevation } from "@blueprintjs/core";

type ViewProps = RouteComponentProps<any>;

export const View: React.FC<ViewProps> = () => {
  const [headcount, setHeadcount] = useState("");
  const [specialisation, setSpecialisation] = useState<Array<string>>([]);
  const [experience, setExperience] = useState("");

  const userApi = useUserApi();
  const history = useHistory();

  function doProfile(e: any) {
    e.preventDefault(); // don't refresh page on form submit

    const metaObj = {
      headcount: headcount,
      specialisation: specialisation,
      experience: experience,
    };

    history.push(clientspaceHomeUrl);
    return userApi.updateProfile({ meta: metaObj }).catch((error) => {
      console.log(error);
    });
  }

  function handleCheck(e: any) {
    e.persist();

    if (e.target.checked) {
      setSpecialisation((specialisation) =>
        specialisation.concat(e.target.value)
      );
    } else {
      const temp_arr = Array.from(specialisation);
      const index = temp_arr.indexOf(e.target.value);
      if (index !== -1) {
        temp_arr.splice(index, 1);
        setSpecialisation(temp_arr);
      }
    }
  }

  return (
    <AuthWrap>
      <Logo className="opteq-logo" />
      <Card className="auth-card" elevation={Elevation.THREE}>
        <Icons.SecurityLock className="card-icon" size={21} />

        <StepDiv>
          <StepWrap>
            <i>1</i>
            <span>Create an account</span>
          </StepWrap>

          <StepWrap className="active">
            <i>2</i>
            <span>Setup profile</span>
          </StepWrap>
        </StepDiv>

        <Form onSubmit={doProfile}>
          <Label>How many people work at your company?</Label>
          <RadioGroup>
            <RadioCard
              name="headcount"
              value="10-50"
              onChange={(e: any) => {
                setHeadcount(e.target.value);
              }}
            />
            <RadioCard
              name="headcount"
              value="50-100"
              onChange={(e: any) => {
                setHeadcount(e.target.value);
              }}
            />
            <RadioCard
              name="headcount"
              value="100-250"
              onChange={(e: any) => {
                setHeadcount(e.target.value);
              }}
            />
            <RadioCard
              name="headcount"
              value="500+"
              onChange={(e: any) => {
                setHeadcount(e.target.value);
              }}
            />
          </RadioGroup>

          <Label>What do you specialise in? Chose one or more.</Label>
          <RadioGroup className="specs-group">
            <CheckboxCard
              value="Aerospace and defence"
              name="specialisation"
              onChange={(e: any) => handleCheck(e)}
            />
            <CheckboxCard
              value="Energy and mining"
              name="specialisation"
              onChange={(e: any) => handleCheck(e)}
            />
            <CheckboxCard
              value="Financial services and banking"
              name="specialisation"
              onChange={(e: any) => handleCheck(e)}
            />
            <CheckboxCard
              value="Government and public sector"
              name="specialisation"
              onChange={(e: any) => handleCheck(e)}
            />
            <CheckboxCard
              value="Healthcare and pharmacology"
              name="specialisation"
              onChange={(e: any) => handleCheck(e)}
            />
            <CheckboxCard
              value="Hospitality and leisure"
              name="specialisation"
              onChange={(e: any) => handleCheck(e)}
            />
            <CheckboxCard
              value="Media and entertainment"
              name="specialisation"
              onChange={(e: any) => handleCheck(e)}
            />
            <CheckboxCard
              value="Technology and telecommunications"
              name="specialisation"
              onChange={(e: any) => handleCheck(e)}
            />
            <CheckboxCard
              value="Transportation and logistics"
              name="specialisation"
              onChange={(e: any) => handleCheck(e)}
            />
          </RadioGroup>

          <Label>How many years of professional experience do you have?</Label>
          <RadioGroup>
            <RadioCard
              label="< 2"
              name="experience"
              value="<2"
              onChange={(e: any) => {
                setExperience(e.target.value);
              }}
            />
            <RadioCard
              label="3 - 5"
              name="experience"
              value="3-5"
              onChange={(e: any) => {
                setExperience(e.target.value);
              }}
            />
            <RadioCard
              label="6 - 10"
              name="experience"
              value="6-10"
              onChange={(e: any) => {
                setExperience(e.target.value);
              }}
            />
            <RadioCard
              label="11+"
              name="experience"
              value="11+"
              onChange={(e: any) => {
                setExperience(e.target.value);
              }}
            />
          </RadioGroup>

          <Button
            type="submit"
            disabled={!headcount || !experience || specialisation.length <= 0}
            onClick={doProfile}
          >
            Save and continue
          </Button>
        </Form>
      </Card>
      <AuthFooter />
    </AuthWrap>
  );
};

export default View;
