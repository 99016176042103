import React from "react";
import { ToolbarOptionView } from "./styles";
import { SwitchOptionProps } from "./types";

const View: React.FC<SwitchOptionProps> = (Props) => {
  const onMouseUp = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    Props.onchange(Props.name, !Props.value);
  };
  return (
    <ToolbarOptionView
      id="ignoreDeselect"
      checked={Props.value}
      onMouseUp={onMouseUp}
      className={Props.className}
    >
      {Props.children}
    </ToolbarOptionView>
  );
};

export default View;
