import * as React from "react";
import { useEffect, useState } from "react";
import useAuth from "../../../auth/context/useAuth";
import { RouteComponentProps } from "react-router";
import { ClientSwitcher, UserProfile, NavLogo } from "../../../components";
import {
  ListWrap,
  ListContent,
  ClientsTopbar,
  ClientsList,
  TopbarLeft,
  ClientsTools,
} from "../../../clientspaces/components/CsListView";
import useProjectsApi from "../../api/useProjectsApi";
import { Project } from "../../api/model";
import {
  DataWrap,
  DeltaNav,
  RadioWrap,
  ViewSelect,
} from "../../components/ProjectsView";
import { Link } from "react-router-dom";
import useSynthesisApi from "../../api/useSynthesisApi";
import SummaryCard from "../../components/SummaryCard";
import { SummaryCardWrap, ChartsWrap } from "../../components/SynthesisView";
import TotalCard from "./components/TotalCard";
import SalaryCard from "./components/SalaryCard";
import LocationCard from "./components/LocationCard";
import TotalByLevel from "./components/TotalByLevel";
import TotalByProperty from "./components/TotalByProperty";
import { Props } from "./";

type ViewProps = Props &
  RouteComponentProps<{
    project: string;
  }>;

export const View: React.FC<ViewProps> = ({
  state,
  match,
  setApis,
  loadModels,
  loadSummary,
  loadSelectedDetails,
  setTarget,
  setSource,
  setSelectedKind,
}) => {
  const auth = useAuth();
  const projectsApi = useProjectsApi();
  const synthesisApi = useSynthesisApi();

  const [activeProject, setActiveProject] = useState<Project>();
  useEffect(() => {
    setApis(projectsApi, synthesisApi);
  }, [projectsApi, synthesisApi, setApis]);
  useEffect(() => {
    match?.params?.project && loadModels(match?.params?.project);
  }, [match, loadModels]);
  useEffect(() => {
    if (state.srcModelId && state.tgtModelId)
      loadSummary(state.srcModelId, state.tgtModelId);
  }, [loadSummary, state.srcModelId, state.tgtModelId]);

  useEffect(() => {
    projectsApi
      .getProject(match?.params?.project)
      .then((response) => {
        setActiveProject(response?.data);
      })
      .catch((error) => console.log(error));
  }, [projectsApi, match]);

  useEffect(() => {
    loadSelectedDetails();
  }, [state.selectedKind, loadSelectedDetails]);

  const getModelsRadioDiv = (isSrc: boolean) => {
    const prefix = isSrc ? "src-" : "tgt-";
    const compareModeId = isSrc ? state.srcModelId : state.tgtModelId;
    const onChange = (e: React.FormEvent<HTMLDivElement>) => {
      const value = (e.target as any).value;
      const model = state.models.find((m) => m.uuid === value);
      model &&
        (isSrc
          ? setSource(model?.uuid, model?.name)
          : setTarget(model?.uuid, model?.name));
    };
    return (
      <div className="nav-section" key={prefix + "div"}>
        <h3>{isSrc ? "Source" : "Target"}</h3>
        {state.models.map((m: any) => (
          <RadioWrap key={prefix + m.uuid}>
            <label htmlFor={prefix + m.uuid}>
              {m.name}
              <input
                key={prefix + m.uuid}
                type="radio"
                value={m.uuid}
                id={prefix + m.uuid}
                checked={m.uuid === compareModeId}
                onChange={onChange}
              />
            </label>
          </RadioWrap>
        ))}
      </div>
    );
  };

  return (
    <ListWrap className="comparison-view">
      <DeltaNav className="synthesis-nav">
        <Link to={`/projects/${activeProject?.uuid}`}>
          <NavLogo />
        </Link>

        <div className="nav-section">
          <h3>Models</h3>
          <p>Select two models to compare</p>
        </div>
        {getModelsRadioDiv(true)}
        {getModelsRadioDiv(false)}
      </DeltaNav>

      <ListContent className="comparison-wrap">
        <ClientsTopbar>
          <ClientSwitcher
            ID={activeProject?.clientspace?.uuid}
            route={activeProject?.name + ", Synthesis"}
          />

          <TopbarLeft>
            <p>Help</p>
            {auth.profile && <UserProfile profile={auth.profile} />}
          </TopbarLeft>
        </ClientsTopbar>
        <ClientsList className="comparison-content">
          <ClientsTools className="synthesis-tools">
            <ViewSelect>
              <Link to={`/projects/${activeProject?.uuid}/delta-data`}>
                Data
              </Link>
              <Link to={match.url} className="active">
                Comparison
              </Link>
            </ViewSelect>
          </ClientsTools>

          <DataWrap>
            <SummaryCardWrap>
              {Object.keys(state.summary.byKind).map((key: string) => {
                const value = state.summary.byKind[key];
                return (
                  <SummaryCard
                    key={key}
                    id={key}
                    name={value?.meta?.name}
                    base={value?.base}
                    target={value?.target}
                    delta={value?.delta}
                    plural={value?.meta?.plural}
                    isSelected={key === state.selectedKind?.kind}
                    setSelectedKind={setSelectedKind}
                  />
                );
              })}
            </SummaryCardWrap>

            <ChartsWrap>
              <LocationCard
                kindPlural={state.selectedKind?.plural}
                data={state.kindDetails}
              />

              <TotalCard
                kindPlural={state.selectedKind?.plural}
                targetModel={state.tgtModelName}
                data={state.kindDetails}
              />
              <SalaryCard
                kindPlural={state.selectedKind?.plural}
                data={state.kindDetails}
                srcModel={state.srcModelName}
                tgtModel={state.tgtModelName}
              />

              <TotalByLevel
                data={state.kindDetails}
                kindPlural={state.selectedKind?.plural}
              />
              <TotalByProperty
                data={state.kindDetails}
                kind={state.selectedKind?.kind}
                kindPlural={state.selectedKind?.plural}
              />
            </ChartsWrap>
          </DataWrap>
        </ClientsList>
      </ListContent>
    </ListWrap>
  );
};

export default View;
