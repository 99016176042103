import React from "react";
import styled from "styled-components";

interface Props {
  readonly bgcolor: string;
  readonly textcolor: string;
  readonly w: string;
  readonly notrounded: boolean;
}

const StyledButton = styled.button<Props>`
  background: ${(p) => p.bgcolor || "#061D38"};
  border: 1px solid transparent;
  border-radius: ${(p) => (!p.notrounded ? "4px" : "none")};
  padding: 8px 14px;
  color: ${(p) => p.textcolor || "#fff"};
  font-weight: 500;
  width: ${(p) => p.w || "100%"};
  font-size: 14px;
  letter-spacing: 0.025em;
  margin: 0 auto;
  margin-top: 14px;
  cursor: pointer;
  display: flex;
  justify-content: center;
  align-items: center;
  text-decoration: none;

  &:hover {
    opacity: 0.93;
    color: ${(p) => p.textcolor || "#fff"};
  }

  &:disabled {
    cursor: not-allowed;
    opacity: 0.55;
    color: #c1c6cc;
  }
`;

const Button = (props: any) => {
  return <StyledButton {...props}>{props.children}</StyledButton>;
};
export default Button;
