import { combineReducers } from "redux";
import referenceReducers from "./referencePage";
import deltaReducers from "./delta";
import { createStore, applyMiddleware } from "redux";
import thunk from "redux-thunk";

const rootReducer = combineReducers({ ...referenceReducers, ...deltaReducers });

export type StoreType = ReturnType<typeof rootReducer>;

const store = createStore(rootReducer, applyMiddleware(thunk));

export default store;
