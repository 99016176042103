import styled from "styled-components";

export const SettingsWrap = styled.div`
  margin-bottom: 32px;

  #input-projectName {
    margin-bottom: 32px;
  }

  #project-description {
    width: 420px;
    box-sizing: border-box;
  }

  .settings-btn-wrap {
    background-color: red;
  }

  p {
    margin: 0;
  }

  .settings-permissions {
    display: flex;
    flex-direction: column;
    font-size: 18px;
    letter-spacing: 0.025em;
    margin-bottom: 32px;

    input {
      margin-right: 16px;
      margin-top: 24px;
    }
  }
`;

export const SettingsHeader = styled.h2`
	margin: 0;
	color-inherit;
	font-weight: 500;
	font-size: 21px;
	margin-bottom: 4px;
`;

export const SettingsSubHeader = styled.h4`
	margin: 0;
	color-inherit;
	font-weight: 500;
	font-size: 18px;
	margin-bottom: 4px;
`;

export const DetailsWrap = styled.div`
  margin-bottom: 32px;
`;

export const DetailsHeader = styled.h3`
  margin: 0;
  font-weight: 400;
  font-size: 18px;
  line-height: 1;
  margin-bottom: 8px;
`;

export const DetailsValue = styled.p`
  margin: 0;
  color: #4a90e2;
`;

export const SettingsView = styled.div`
  padding: 24px 36px;
  display: flex;
  flex-direction: column;

  .settings-btn-wrap {
    display: flex;

    & > .settings-delete {
      margin-right: 16px;
    }

    & > button {
      margin: 0;
    }
  }
`;

export const DataWrap = styled.div`
  margin-top: 24px;

  .link-cell {
    vertical-align: middle;
  }

  .handsontable .htAutocompleteArrow {
    font-size: 0 !important;
    width: 0 !important;
    height: 0;
    border-left: 5px solid transparent;
    border-right: 5px solid transparent;
    border-top: 5px solid #000;
    margin-top: 5px;
    display: none;
  }

  .link-btn {
    background-color: #4a90e2;
    color: #fff;
    padding: 3px 5px;
    display: inline-block;
    text-decoration: none;
    border: none;
    cursor: pointer;
    min-width: 40px;
    height: 21px;
    margin-right: 4px;
  }

  &.data-with-table {
    flex: 1;
    width: 100%;
  }

  &.functional-md-table {
    padding: 0 16px 24px 16px;
    width: 98% !important;
    margin-top: 0;
    flex: 1;
  }

  .partial-cell {
    background-color: orange;
    border-color: #fff;
    color: #fff;
  }

  &.table-padding {
    padding: 0 16px 24px 16px;
    width: 98% !important;
    margin-top: 0;
  }

  &.dialog-table {
    padding: 0 !important;
    width: 100% !important;
    margin-top: 12px;
    height: 100%;
  }

  .input-checkbox {
    text-align: center;
  }
`;

// delta view components
export const DeltaNav = styled.div`
  background-color: #fefdfe;
  border-right: 2px solid #f3f3f3;
  padding-bottom: 16px;

  h3 {
    font-size: 18px;
    cursor: pointer;
    margin: 0;
    margin-bottom: 2px;
    padding: 0 16px;
    user-select: none;

    &:hover {
      opacity: 0.8;
    }
  }

  & > .nav-section {
    margin-bottom: 24px;

    & > .col-dropdown {
      padding: 0 16px;
    }

    & > p {
      margin: 0;
      padding: 0 16px;
      font-size: 14px;
      color: #a5a5a5;
      font-weight: 500;
    }
  }

  &.delta-nav {
    overflow-y: auto;
    height: 100vh;
  }
`;

export const SectionList = styled.div`
  display: flex;
  flex-direction: column;
`;

export const RadioWrap = styled.div`
  display: flex;
  padding: 4px 16px;

  & > label {
    user-select: none;
    text-align: left;
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
    cursor: pointer;
  }
`;

export const TableError = styled.h2`
  text-align: center;
  font-size: 24px;
  color: #a5a5a5;
  margin: 0;
`;

export const ViewSelect = styled.div`
  display: flex;
  margin: 0 auto;
  column-gap: 36px;

  & > a {
    margin: 0;
    font-size: 18px;
    font-weight: bold;
    cursor: pointer;
    color: #777;

    &.active {
      color: #061d38;
    }
  }
`;

export const DeltaBreadcrumb = styled.h3`
  font-size: 21px;
  font-weight: 500;
  line-height: 1;
  margin: 0;
  opacity: 0.75;
  margin-left: 32px;
  margin-top: 8px;
`;

export const ToolBtn = styled.button`
  position: relative;
  cursor: pointer;
  background: none;
  outline: none;
  border: none;
  padding: none;

  & > svg {
    margin-right: 6px;
    font-size: 14px;
  }
`;
