import { ComponentStyle } from "./reference/commonTypes";
export const SET_TOOLBAR = "SET_TOOLBAR";
export const SET_EDIT_MODE = "SET_EDIT_MODE";
export const SET_RESIZE_MODE = "SET_RESIZE_MODE";

export interface SetToolbarAction {
  type: typeof SET_TOOLBAR;
  style: ComponentStyle;
}

export interface SetEditMode {
  type: typeof SET_EDIT_MODE;
  editable: boolean;
}

export interface SetResizeMode {
  type: typeof SET_RESIZE_MODE;
  resizing: boolean;
}

export interface toolbarState {
  style: ComponentStyle;
  editMode: boolean;
  resizingMode: boolean;
}

export type ToolbarAction = SetToolbarAction | SetEditMode | SetResizeMode;
