import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  Dropdown,
  DropdownItem,
  SimpleModal,
  AppToaster,
} from "../../components";
import dayjs from "dayjs";
import InfoModel from "../views/ProjectspaceHome/InfoModel";
import useModelsApi from "../../models/api/useModelsApi";
import { Icons } from "../../assets/Icons";

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  color: #061d38;
  box-shadow: 0px 0px 9px -2px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  padding: 32px 24px 12px;
  height: 160px;
  line-height: 1;
  font-size: 16px;
  position: relative;
  box-sizing: content-box;

  h3 {
    margin: 0;
    font-size: 24px;
    margin-bottom: 10px;
  }

  a {
    text-decoration: none;
    color: inherit;
    overflow: hidden;

    :hover {
      opacity: 0.7;
    }
  }

  p {
    margin: 0;
    color: #6d7278;
    letter-spacing: 0.035em;
    line-height: 1.5;
    overflow: hidden;
    word-break: break-all;

    &.card-date {
      font-weight: 500;
      font-size: 14px;
    }
  }

  a > div {
    margin-top: 12px;
    gap: 8px;
  }

  & > .more-i {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    padding: 6px 12px 0;
  }
`;

const ModelCard = (props: any) => {
  const {
    name,
    description,
    uuid,
    createdAt,
    users,
    profileID,
  } = props?.modelDetails;

  const [infoModal, setInfoModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  const modelsApi = useModelsApi();

  useEffect(() => {
    const userObj = users.filter((user: any) => user.id === profileID);
    if (userObj[0]?.role === "Owner" || userObj[0]?.role === "Admin")
      setIsAdmin(true);
  }, [users, profileID]);

  function doDelete() {
    modelsApi
      .deleteModel(uuid)
      .then((response) => {
        if (response.statusText === "OK") {
          AppToaster.show({
            message: "Model was successfully deleted!",
            intent: "success",
          });

          setDeleteModal(false);
          props.refresh();
        }
      })
      .catch((error) => {
        AppToaster.show({
          message: "Something went wrong, please try again!",
          intent: "danger",
        });

        console.log(error);
      });
  }

  const newDate = dayjs(createdAt).format("DD MMMM YYYY");
  return (
    <>
      <StyledCard>
        <Link to={`/models/${uuid}`}>
          <h3>{name}</h3>
          <p className="card-date">Created on {newDate}</p>

          <div>
            <p>{description}</p>
          </div>
        </Link>

        <Dropdown
          left={true}
          textAlign="left"
          className="more-i"
          title={<Icons.More size={21} />}
        >
          <DropdownItem onClick={() => setInfoModal(true)}>Info</DropdownItem>
          {!isAdmin && (
            <DropdownItem
              onClick={() => {
                setDeleteModal((deleteModal) => !deleteModal);
              }}
            >
              Delete
            </DropdownItem>
          )}
        </Dropdown>
      </StyledCard>

      {infoModal && (
        <InfoModel
          ID={uuid}
          title={name}
          onClose={() => setInfoModal(false)}
          isOpen={infoModal}
          modelDetails={props.modelDetails}
        />
      )}
      {deleteModal && (
        <SimpleModal
          title="Delete model"
          refresh={props.refresh}
          closeIcon={true}
          closeHandler={() => setDeleteModal((deleteModal) => !deleteModal)}
          closeText="Cancel"
          acceptHandler={() => doDelete()}
          acceptText="Delete"
          text="Are you sure you want to delete this Model? All components will be permanently deleted and team members informed."
        />
      )}
    </>
  );
};
export default ModelCard;
