import * as React from "react";
import { useEffect, useState } from "react";
import useAuth from "../../../auth/context/useAuth";
import { RouteComponentProps } from "react-router";
import {
  Button,
  Input,
  Select,
  ClientSwitcher,
  UserProfile,
} from "../../../components";
import {
  ListWrap,
  ListContent,
  ClientsTopbar,
  ClientsList,
  TopbarLeft,
  ClientsTools,
  ToolsWrap,
  ProjectBtnWrap,
  CardsWrap,
} from "../../../clientspaces/components/CsListView";
import useProjectsApi from "../../api/useProjectsApi";
import { Project } from "../../api/model";
import { Model } from "../../../models/api/model";
import ModelCard from "../../components/ModelCard";
import NewModel from "./NewModel";
import { Icons } from "../../../assets/Icons";
import ProjectsNav from "../../components/ProjectsNav";

type ViewProps = RouteComponentProps<{
  project: string;
}>;

export const View: React.FC<ViewProps> = ({ match }) => {
  const auth = useAuth();
  const projectsApi = useProjectsApi();

  const [activeProject, setActiveProject] = useState<Project>();
  const [openSearch, setOpenSearch] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [sortValue, setSortValue] = useState("");
  const [modelsList, setModelsList] = useState<Array<Model>>([]);
  const [refresh, setRefresh] = useState(false);
  const [newModelModal, setNewModelModal] = useState(false);

  useEffect(() => {
    projectsApi
      .getProject(match?.params?.project)
      .then((response) => {
        setActiveProject(response?.data);
      })
      .catch((error) => console.log(error));
  }, [projectsApi, match]);

  useEffect(() => {
    projectsApi
      .getModels(match?.params?.project)
      .then((response) => setModelsList(response?.data));
  }, [projectsApi, match, refresh]);

  const doSearch = (value: string, e?: any) => {
    e?.preventDefault();

    projectsApi
      .getModels(match?.params.project, value)
      .then((response) => setModelsList(response?.data));
  };

  const doSort = (value: string) => {
    let sort = {};

    if (value === "Date created") {
      sort = {
        createdAt: "desc",
      };
    } else if (value === "Alphabetically") {
      sort = {
        name: "asc",
      };
    }

    projectsApi
      .getModels(match?.params.project, undefined, sort)
      .then((response) => setModelsList(response?.data));
  };

  return (
    <ListWrap>
      {newModelModal && (
        <NewModel
          refresh={() => setRefresh((refresh) => !refresh)}
          onClose={() => setNewModelModal(false)}
          isOpen={newModelModal}
          owner={auth?.profile}
          projectID={match?.params?.project}
        />
      )}

      <ProjectsNav match={match} active="project-home" />

      <ListContent>
        <ClientsTopbar>
          <ClientSwitcher
            clientspaceUuid={activeProject?.clientspace?.uuid}
            projectData={activeProject}
          />

          <TopbarLeft>
            {auth.profile && <UserProfile profile={auth.profile} />}
          </TopbarLeft>
        </ClientsTopbar>
        <ClientsList>
          <ClientsTools>
            <ProjectBtnWrap>
              <Button
                type="button"
                bgcolor="#4a90e2"
                w="130px"
                className="new-model-btn tools-btn"
                onClick={() =>
                  setNewModelModal((newModelModal) => !newModelModal)
                }
              >
                New model
              </Button>

              <Button type="button" w="75px" className="share-btn tools-btn">
                Share
              </Button>
            </ProjectBtnWrap>
            <ToolsWrap>
              <Select
                label="Sort by:"
                placeholder="not selected"
                data={["Date created", "Alphabetically"]}
                direction="row"
                absolute={true}
                hideable={true}
                value={sortValue}
                onClick={(value: any) => {
                  setSortValue(value);
                  doSort(value);
                }}
              />

              <Icons.Search
                size={21}
                onClick={() => setOpenSearch((openSearch) => !openSearch)}
              />
              {openSearch && (
                <form onSubmit={(e: any) => doSearch(searchValue, e)}>
                  <Input
                    mbottom="none"
                    value={searchValue}
                    onChange={(e: any) => {
                      setSearchValue(e.target.value);
                      doSearch(searchValue);
                    }}
                  />
                </form>
              )}
            </ToolsWrap>
          </ClientsTools>
          <CardsWrap
            style={{ height: modelsList.length > 0 ? "auto" : "100%" }}
          >
            {modelsList.length > 0 ? (
              modelsList.map((model) => {
                return (
                  <ModelCard
                    modelDetails={model}
                    key={model.uuid}
                    refresh={() => setRefresh((refresh) => !refresh)}
                    profileID={auth?.profile?.id}
                  />
                );
              })
            ) : (
              <h3 className="no-clients">
                To get started, click the
                <br />
                New model button
              </h3>
            )}
          </CardsWrap>
        </ClientsList>
      </ListContent>
    </ListWrap>
  );
};

export default View;
