import React from "react";
import { Dialog } from "@blueprintjs/core";
import styled from "styled-components";
import dayjs from "dayjs";

const StyledDialog = styled(Dialog)`
  background-color: #fff;
  background: #fff;

  & > .bp3-dialog-header {
    box-shadow: none;
    padding: 16px 5px 16px 24px;

    & > h4 {
      font-size: 21px;
    }
  }

  .dialog-content {
    padding: 16px 24px;
  }
`;

const DataWrap = styled.div`
  margin-bottom: 32px;

  :last-of-type {
    margin-bottom: 0;
  }
`;

const DataLabel = styled.h3`
  margin: 0;
  font-weight: 400;
  font-size: 20px;
  line-height: 1;
  margin-bottom: 8px;
`;

const DataValue = styled.p`
  margin: 0;
  color: #4a90e2;
`;

const DataUsers = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-gap: 8px;
  max-width: 240px;
  margin-bottom: 24px;
  overflow-y: auto;
  max-height: 80px;
`;

const InfoModel = (props: any) => {
  const {
    description,
    clientspace,
    project,
    createdBy,
    createdAt,
    users,
  } = props.modelDetails;

  const newDate = dayjs(createdAt).format("DD MMMM YYYY");
  return (
    <StyledDialog
      title={props.title}
      onClose={props.onClose}
      isOpen={props.isOpen}
    >
      <div className="dialog-content">
        <DataWrap>
          <DataLabel>Description</DataLabel>
          <DataValue>{description}</DataValue>
        </DataWrap>
        <DataWrap>
          <DataLabel>Company name</DataLabel>
          <DataValue>{clientspace?.name}</DataValue>
        </DataWrap>
        <DataWrap>
          <DataLabel>Project name</DataLabel>
          <DataValue>{project.name}</DataValue>
        </DataWrap>
        <DataWrap>
          <DataLabel>Created by</DataLabel>
          <DataValue>{createdBy.name}</DataValue>
        </DataWrap>
        <DataWrap>
          <DataLabel>Date created</DataLabel>
          <DataValue>{newDate}</DataValue>
        </DataWrap>
        <DataWrap>
          <DataLabel>Team members</DataLabel>
          <DataUsers>
            {users &&
              users.map((e: any) => (
                <DataValue key={e.uuid}>{e.name}</DataValue>
              ))}
          </DataUsers>
        </DataWrap>
      </div>
    </StyledDialog>
  );
};
export default InfoModel;
