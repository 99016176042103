import React from "react";
import { StyledCard } from "./styles";
import {
  BarChart,
  XAxis,
  YAxis,
  Tooltip,
  Bar,
  LabelList,
  ResponsiveContainer,
} from "recharts";
// recharts doesn't export the default tooltip,
// but it's located in the package lib so you can get to it anyways

const LocationCard = (props: any) => {
  const { kindPlural, data } = props;
  const getData = () => {
    if (!data?.byLocation) return [];
    const objKeys = Object.keys(data?.byLocation);

    if (objKeys) {
      const treeData = objKeys.map((loc: any) => {
        const added = data?.byLocation[loc]?.delta?.added;
        const removed = data?.byLocation[loc]?.delta?.removed;
        const net = data?.byLocation[loc]?.delta?.net;
        return {
          name: loc,
          added: added,
          removed: removed,
          net: net === 0 ? net : net > 0 ? "+" + net : net,
        };
      });
      return treeData;
    }
    return [];
  };

  const CustomTooltip = (props: any) => {
    const { payload, active } = props;
    if (!active) return;
    const data = payload ? payload[0].payload : {};

    return (
      <div className="location-tooltip">
        <p className="tooltip-added">{`${data?.added} ${kindPlural} added`}</p>
        <p className="tooltip-removed">{`${data?.removed} ${kindPlural} removed`}</p>
        <p className="tooltip-delta">{`Delta: ${data?.net}`}</p>
      </div>
    );
  };

  return (
    <StyledCard>
      <h3>Location</h3>
      <div className="location-chart-wrap">
        <ResponsiveContainer width="100%" height="100%">
          <BarChart
            data={getData()}
            layout="vertical"
            barSize={16}
            margin={{ left: 42, right: 12 }}
          >
            <XAxis type="number" hide />
            <YAxis
              dataKey="name"
              type="category"
              tick={{ fontSize: 14 }}
              tickLine={false}
              axisLine={false}
              tickMargin={12}
            />
            <Tooltip
              content={(payload: any) => CustomTooltip(payload)}
              wrapperStyle={{ marginTop: "-92px" }}
            />
            <Bar
              dataKey="added"
              fill="#7ED321"
              stackId="a"
              className="location-bar"
            />
            <Bar dataKey="removed" fill="#D0021B" stackId="a">
              <LabelList
                position="insideRight"
                dataKey="net"
                className="location-label"
              />
            </Bar>
          </BarChart>
        </ResponsiveContainer>
      </div>
    </StyledCard>
  );
};

export default LocationCard;
