import React from "react";
import styled from "styled-components";
import { Dropdown, DropdownItem, AppToaster } from "../../components";
import dayjs from "dayjs";
import useModelsApi from "../api/useModelsApi";
import { Icons } from "../../assets/Icons";
import { Card, Elevation } from "@blueprintjs/core";

const StyledCard = styled(Card)`
  display: flex;
  padding: 8px 12px;
  height: 116px;
  position: relative;

  & > .card-content {
    max-height: 100%;
    display: flex;
    flex-direction: column;
    overflow: hidden;
  }

  h3 {
    margin: 0;
    font-size: 21px;
    margin: 8px 0 4px 0;
    overflow: hidden;
    display: flex;
    align-items: center;
    cursor: pointer;
    overflow: hidden;

    & > .name-text {
      max-height: 100%;
    }
  }

  .card-date {
    font-weight: 500;
    font-size: 12px;
    margin: 0;

    & > span {
      color: #9c9c9c;
    }
  }

  & > .more-i {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    padding: 6px 12px 0;
  }
`;

const Number = styled.i`
  font-style: normal;
  font-weight: bold;
  font-size: 18px;
  top: 0;
  left: 0;
`;

const MetIcon = styled.span<{ met: boolean }>`
  display: block;
  min-height: 12px;
  min-width: 12px;
  background-color: ${(p) => (p.met ? "#7ED321" : "#f5a624")};
  border-radius: 9999px;
  margin-right: 8px;
`;

const PrincipleCard = (props: any) => {
  const {
    name,
    createdAt,
    number,
    met,
    refresh,
    principleID,
    modelUuid,
  } = props;
  const modelsApi = useModelsApi();

  const newDate = dayjs(createdAt).format("DD MMMM YYYY");

  function doChangeMet() {
    modelsApi
      .updateModelMet(modelUuid, !met, principleID)
      .then((response) => {
        if (response.statusText === "OK") {
          AppToaster.show({
            message: "Design Principle was successfully updated!",
            intent: "success",
          });

          refresh();
        }
      })
      .catch((error) => {
        AppToaster.show({
          message: "Something went wrong, please try again!",
          intent: "danger",
        });

        console.log(error);
      });
  }

  function doDelete() {
    modelsApi
      .deletePrinciple(modelUuid, principleID)
      .then((response) => {
        if (response.statusText === "OK") {
          AppToaster.show({
            message: "Design Principle was successfully deleted!",
            intent: "success",
          });

          refresh();
        }
      })
      .catch((error) => {
        AppToaster.show({
          message: "Something went wrong, please try again!",
          intent: "danger",
        });

        console.log(error);
      });
  }

  return (
    <StyledCard elevation={Elevation.TWO} interactive>
      <div className="card-content">
        <Number>{number}</Number>

        <h3 onClick={() => doChangeMet()}>
          <MetIcon met={met} />
          <span className="name-text">{name}</span>
        </h3>
        <p className="card-date">
          <span>Created at</span> {newDate}
        </p>
      </div>

      <Dropdown
        left={true}
        textAlign="left"
        className="more-i"
        title={<Icons.More size={21} />}
      >
        <DropdownItem onClick={doDelete}>Delete</DropdownItem>
      </Dropdown>
    </StyledCard>
  );
};
export default PrincipleCard;
