export interface ComponentBounds {
  x: number;
  y: number;
  w: number;
  h: number;
}

export const ComponentNames = [
  "Division",
  "BusinessUnit",
  "Function",
  "SubFunction",
  "TextLabel",
] as const;
export type ComponentName = typeof ComponentNames[number];

export const ComponentTypesByName: { [x: string]: ComponentName } = {
  Division: "Division",
  BusinessUnit: "BusinessUnit",
  Function: "Function",
  SubFunction: "SubFunction",
  TextLabel: "TextLabel",
};

export const ComponentsHierarchy = {
  [ComponentTypesByName.Function]: ComponentTypesByName.BusinessUnit,
  [ComponentTypesByName.BusinessUnit]: ComponentTypesByName.Division,
  [ComponentTypesByName.Division]: "coap",
};

export interface ComponentStyle {
  bold: boolean;
  italic: boolean;
  underlined: boolean;
  textColor: string;
  backgroundColor: string;
  borderColor: string;
}
