import React from "react";
import styled from "styled-components";

const SVG = (props: Props): JSX.Element => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 7 8" {...props}>
      <path
        d="M3.04.127a.899.899 0 01.92 0l2.546 1.502c.09.053.17.121.239.2.161.187.255.434.255.697v2.948c0 .374-.19.717-.494.897L3.96 7.873a.899.899 0 01-.92 0L.494 6.371A1.038 1.038 0 010 5.474V2.526c0-.263.094-.51.255-.697a.968.968 0 01.24-.2L3.04.127zm.613.598a.3.3 0 00-.306 0L1.095 2.053 3.5 3.602l2.405-1.548L3.653.724zm2.71 1.83L3.819 4.193v2.985l2.38-1.405a.346.346 0 00.166-.3V2.556zM3.183 7.178V4.193L.636 2.555v2.919c0 .124.063.239.165.3l2.38 1.404z"
        fill="currentColor"
        fillRule="nonzero"
      />
    </svg>
  );
};

type Props = {
  onClick?: any;
  size?: number;
} & React.SVGProps<SVGSVGElement>;
type StyledProps = {
  size?: number;
};
const SvgDivision = styled(SVG)<StyledProps>`
  vertical-align: middle;
  height: ${(props) => (props.size ? props.size + "px" : "16px")};
  width: ${(props) => (props.size ? props.size + "px" : "16px")};
  fill: currentColor;
`;
export default SvgDivision;
