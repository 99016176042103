import React from "react"
import {GeoMarkerProps} from "./types";
import {useHover} from "../../hooks";

const coral = "rgb(255, 127, 80)"

export const SGeoMarker: React.FC<GeoMarkerProps> = (
    {
        id,
        projection,
        coordinates,
        value,
        onClick
    }
) => {
    const [x,y] = projection(coordinates) || [0,0]

    const [hoverRef, isHovered] = useHover<SVGCircleElement>();

    const handleClick = () => {
        if(!onClick) return;
        onClick(id)
    }

    const handleEnter = () => {

    }

    const handleExit = () => {

    }

    const fillColor = isHovered ? coral : "black"

    return <circle
        ref={hoverRef}

        key={`marker-${id}`}
        cx={x}
        cy={y}
        r={value}
        fill={fillColor}
        stroke="#FFFFFF"
        className="marker"
        onClick={handleClick}
    />
}