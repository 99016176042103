import { Model } from "../../models/api/model";
import { SynthesisSummary } from "../../projectspaces/api/model";
import SynthesisApi from "../../projectspaces/api/SynthesisApi";
import { ProjectsApi } from "../../projectspaces/api";

export const SET_SUMMARY = "SET_DELTA_SUMMARY";
export const SET_MODELS = "SET_DELTA_MODELS";
export const SET_TARGET_MODEL = "SET_DELTA_TARGET";
export const SET_SOURCE_MODEL = "SET_DELTA_SOURCE";
export const SET_APIS = "SET_APIS";
export const SET_SELECTED_KIND = "SET_DELTA_SELECTED_KIND";
export const SET_SELECTED_DETAILS = "SET_DELTA_SELECTED_DETAILS";

export interface DeltaState {
  summary: SynthesisSummary;
  kindDetails?: SynthesisSummary;
  selectedKind?: {
    kind: string;
    plural: string;
  };
  models: Array<Model>;
  srcModelId?: string;
  srcModelName?: string;
  tgtModelId?: string;
  tgtModelName?: string;
  projectApi?: ProjectsApi;
  deltaApi?: SynthesisApi;
}

export interface SetSummaryAction {
  type: typeof SET_SUMMARY;
  summary: SynthesisSummary;
}

export interface SetModelsAction {
  type: typeof SET_MODELS;
  models: Array<Model>;
}

export interface SetTargetModelAction {
  type: typeof SET_TARGET_MODEL;
  modelId: string;
  modelName: string;
}

export interface SetSourceModelAction {
  type: typeof SET_SOURCE_MODEL;
  modelId: string;
  modelName: string;
}

export interface SetApisAction {
  type: typeof SET_APIS;
  projectApi: ProjectsApi;
  deltaApi: SynthesisApi;
}

export interface SetSelectedKindAction {
  type: typeof SET_SELECTED_KIND;
  selectedKind: {
    kind: string;
    plural: string;
  };
}

export interface SetSelectedDetailsAction {
  type: typeof SET_SELECTED_DETAILS;
  details: SynthesisSummary;
}

export type DeltaAction =
  | SetSummaryAction
  | SetModelsAction
  | SetTargetModelAction
  | SetSourceModelAction
  | SetApisAction
  | SetSelectedKindAction
  | SetSelectedDetailsAction;
