import React, { useState } from "react";
import { StyledCard } from "./styles";
import {
  Button,
  Popover,
  PopoverInteractionKind,
  Position,
  Classes,
} from "@blueprintjs/core";
import { useEffect } from "react";

const TotalByProperty = ({ data, kindPlural, kind }: any) => {
  const [property, setPropertyId] = useState<string>("");
  const [properties, setProperties] = useState<string[]>([]);
  useEffect(() => {
    setPropertyId(data ? Object.keys(data.byProperty)[0] : "");
    setProperties(data ? Object.keys(data.byProperty) : []);
  }, [data]);

  const handlePropertyChange = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setPropertyId((event.currentTarget as any).id);
  };

  return (
    <StyledCard>
      <h3>
        Total {kindPlural} by:
        {property && (
          <Popover
            interactionKind={PopoverInteractionKind.CLICK}
            className="property-popover"
            position={Position.BOTTOM}
          >
            <Button
              minimal
              fill
              text={property?.replace(/([A-Z])/g, " $1").trim()}
              className="popover-btn"
            />
            <div style={{ padding: "12px 15px" }}>
              {properties.map((key) => (
                <Button
                  key={`byProperty-${key}`}
                  id={key}
                  onClick={handlePropertyChange}
                  active={key === property ? true : false}
                  minimal
                  fill
                  style={{ marginBottom: 6, textTransform: "capitalize" }}
                  className={Classes.POPOVER_DISMISS}
                >
                  {key?.replace(/([A-Z])/g, " $1").trim()}
                </Button>
              ))}
            </div>
          </Popover>
        )}
      </h3>

      <div className="property-table-wrap">
        {property && data.byProperty[property].length > 0 && (
          <table>
            <thead>
              <tr>
                <th>{property}</th>
                <th>Count</th>
                {kind === "Person" && <th>Loaded Cost</th>}
              </tr>
            </thead>
            <tbody>
              {data.byProperty[property].map((cardProp: any) => {
                return (
                  <tr>
                    <td>{cardProp?.label}</td>
                    <td>{cardProp?.tgt?.count}</td>
                    {kind === "Person" && (
                      <td>{cardProp?.tgt?.aggregates?.loadedCost}</td>
                    )}
                  </tr>
                );
              })}
            </tbody>
          </table>
        )}
      </div>
    </StyledCard>
  );
};

export default TotalByProperty;
