import React from "react";
import styled from "styled-components";

const SVG = (props: Props): JSX.Element => {
  return (
    <svg width="1em" height="1em" viewBox="0 0 22 19" {...props}>
      <g fill="none" fillRule="evenodd">
        <path d="M-1-2h24v24H-1z" />
        <path
          d="M11.866 1l9.526 16.5a1 1 0 01-.866 1.5H1.474a1 1 0 01-.866-1.5L10.134 1a1 1 0 011.732 0zm-8.66 16h15.588L11 3.5 3.206 17z"
          fill="currentColor"
          fillRule="nonzero"
        />
      </g>
    </svg>
  );
};

type Props = {
  onClick?: any;
  size?: number;
} & React.SVGProps<SVGSVGElement>;
type StyledProps = {
  size?: number;
};
const SvgDelta = styled(SVG)<StyledProps>`
  vertical-align: middle;
  height: ${(props) => (props.size ? props.size + "px" : "16px")};
  width: ${(props) => (props.size ? props.size + "px" : "16px")};
  fill: currentColor;
`;
export default SvgDelta;
