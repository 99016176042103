import {
  CLOSE_CONFIRM_DELETE,
  OPEN_CONFIRM_DELETE,
  SET_CONFIRM_DELETE,
  ConfirmDeleteState,
  ConfirmDeleteAction,
} from "./types";

export function setConfirmDelete(
  state: ConfirmDeleteState
): ConfirmDeleteAction {
  return {
    type: SET_CONFIRM_DELETE,
    state,
  };
}

export function closeConfirmDelete(): ConfirmDeleteAction {
  return {
    type: CLOSE_CONFIRM_DELETE,
  };
}

export function openConfirmDelete(): ConfirmDeleteAction {
  return {
    type: OPEN_CONFIRM_DELETE,
  };
}
