import React, { useCallback } from "react";
import { TextLabelProps } from "./types";
import {
  circleRadius,
  Dash,
  DashCircleWrap,
  dashSize,
  TextLabelWrapper,
  TextViewWrap,
} from "./styles";
import Circle from "./Circle";
import { DragSourceMonitor, useDrag } from "react-dnd";
import { ItemTypes } from "../itemTypes";
import TextView from "../TextView";
import { setCompName, setTextBoxRotation } from "../../stateManager";
import { useSpring } from "react-spring";

const deltaAngle = 5;

export const TextLabel: React.FC<TextLabelProps> = ({
  id,
  state,
  text,
  selected,
}) => {
  const divRef = React.useRef<HTMLDivElement>(null);
  const [springProps, setSpring] = useSpring(() => ({
    temprotation: 0,
  }));
  const [, drag] = useDrag({
    item: { id, type: ItemTypes.TEXT_LABEL },
    end: (item: { id: string } | undefined, monitor: DragSourceMonitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        // switch (dropResult.type) {
        //   case ItemTypes.BOX:
        //     swapBoxes(state, item.id, dropResult.boxId);
        //     break;
        //   case ItemTypes.ROW_POS:
        //     break;
        //   case ItemTypes.DRAG_ROW_POS:
        //     break;
        // }
      }
    },
    canDrag: !selected,
    collect: (monitor) => ({}),
  });
  const onTextChange = (text: string) => {
    setCompName(state, 0, id, text);
  };
  const onRotate = (rot: number) => {
    setSpring({
      temprotation: Math.floor(rot / deltaAngle) * deltaAngle,
      immediate: true,
    });
  };
  const applyChanges = () => {
    setTextBoxRotation(state, id, springProps.temprotation.get());
  };
  const getCirclePos = useCallback((): [number, number] => {
    const bounds = divRef.current?.getBoundingClientRect() || new DOMRect();
    let length = dashSize + circleRadius + bounds.width * 0.5;
    let rot = (springProps.temprotation.get() * Math.PI) / 180;
    return [length * Math.cos(rot), length * Math.sin(rot)];
  }, [springProps]);
  return (
    <TextLabelWrapper ref={drag}>
      <DashCircleWrap>
        <TextViewWrap ref={divRef} {...springProps} selected={selected}>
          <TextView text={text} onBlur={onTextChange} state={state} />
          {selected && (
            <React.Fragment>
              <Dash />
              <Circle
                rotateCallback={onRotate}
                initPosition={getCirclePos()}
                onRotateEnd={applyChanges}
              />
            </React.Fragment>
          )}
        </TextViewWrap>
      </DashCircleWrap>
    </TextLabelWrapper>
  );
};

export default TextLabel;
