import React from "react";
import { BoxNameWrap } from "./styles";
import { setCompName } from "../../stateManager";
import { BoxNameProps } from "./types";
import TextView from "../TextView";

export const BoxName: React.FC<BoxNameProps> = ({
  id,
  name,
  state,
  thick,
  selected,
}) => {
  const onTextChange = (text: string) => {
    setCompName(state, 0, id, text);
  };
  return (
    <BoxNameWrap thick={thick}>
      <TextView text={name} onBlur={onTextChange} state={state} />
    </BoxNameWrap>
  );
};

export default BoxName;
