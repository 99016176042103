import React from "react";
import { SpacerWrap, SpacerView } from "./styles";
import { useDrop } from "react-dnd";
import { ItemTypes } from "../itemTypes";
import { RowSpacerProps } from "./types";

export const RowSpacer: React.FC<RowSpacerProps> = ({ pos, last }) => {
  const [{ isOver }, drop] = useDrop({
    accept: [`${ItemTypes.ITEM}0`, `${ItemTypes.NEW_ITEM}0`],
    drop: () => ({ pos, type: `${ItemTypes.POS}_row` }),
    collect: (monitor) => ({
      isOver: monitor.isOver({ shallow: true }),
    }),
  });

  return (
    <SpacerWrap isOver={isOver} last={last}>
      <SpacerView ref={drop} isOver={isOver} last={last}></SpacerView>
    </SpacerWrap>
  );
};

export default RowSpacer;
