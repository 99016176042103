import React from "react";
import styled from "styled-components";
import { Icons } from "../../assets/Icons";

const StyledCard = styled.div<{ delta: number; isSelected: boolean }>`
  display: flex;
  flex-direction: column;
  color: #0f1a28;
  border-radius: 2px;
  border-style: solid;
  border-color: ${(p) => (p.isSelected ? "#4a90e2" : "#d4d4d4")};
  border-width: ${(p) => (p.isSelected ? "2px" : "1px")};
  padding: 12px;
  height: 152px;
  line-height: 1;
  font-size: 16px;
  position: relative;
  cursor: pointer;

  h3 {
    margin: 0;
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 500;
  }

  p {
    margin: 0;
    letter-spacing: 0.035em;
    line-height: 1.5;
    overflow: hidden;
    word-break: break-all;
    margin-bottom: 4px;

    &.card-base,
    &.card-target,
    &.card-delta {
      font-size: 15px;

      & > span {
        padding-right: 6px;
      }
    }

    &.card-delta {
      margin-top: 6px;
      border-top: 1px solid #d4d4d4;
      width: max-content;
      padding-right: 12px;
      padding-top: 4px;
    }

    & > svg {
      color: ${(p) => (p.delta > 0 ? "#7ED321" : "#D0021B")};
    }
  }
`;

const SummaryCard = (props: any) => {
  const {
    id,
    name,
    base,
    target,
    delta,
    isSelected,
    setSelectedKind,
    plural,
  } = props;

  const newName = plural ? plural : name;
  return (
    <StyledCard
      delta={delta.net}
      isSelected={isSelected}
      onClick={() => setSelectedKind({ kind: id, plural: newName })}
    >
      <h3>{newName}</h3>

      <p className="card-base">
        <span>As-is: </span>
        {base}
      </p>
      <p className="card-target">
        <span>Target:</span> {target}
      </p>
      <p className="card-delta">
        <span>Delta:</span> {delta.net !== 0 ? delta.net : "-"}
        {delta.net !== 0 ? (
          delta.net > 0 ? (
            <Icons.ArrowUp size={25} />
          ) : (
            <Icons.ArrowDown size={25} />
          )
        ) : null}
      </p>
    </StyledCard>
  );
};
export default SummaryCard;
