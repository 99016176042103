import * as React from "react";
import {useEffect, useState} from "react";
import {ComponentsResult, CubeViewProps} from "../types";
import {SankeyPanel} from "../panels/SankeyPanel";
import {STreeMap} from "../../../../@satsui/components/STreeMap/STreeMap";
import useModelsApi from "../../../api/useModelsApi";
import {Chart} from "react-google-charts";
import SChart from "../../../../@satsui/components/SChart/SChart";

export const ComponentsTab = ({modelUuid, query}: CubeViewProps) => {
    const modelsApi = useModelsApi();

    const [result, setResult] = useState<ComponentsResult>()

    useEffect(() => {
        modelsApi.cubeComponents(modelUuid, query).then((response) => {
            setResult(response?.data);
        });
    }, [modelsApi, query]);

    return <div>
        {JSON.stringify(result?.corporate, null, 2)}
        <SChart {...result?.corporate} />

        {
            result?.byDivision.map((divisionChart, index) => {
                return <div key={index}>
                 {/*<pre>
                     {JSON.stringify(divisionChart,null,2)}
                 </pre>*/}
                    <SChart {...divisionChart} />
                </div>
            })
        }
    </div>
}