import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";

const BreadcrumbWrap = styled.div`
  display: flex;
  flex-direction: column;
  font-size: 24px;
  margin: 0 32px;
  font-weight: bold;

  .breadcrumb-list {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
    color: inherit;

    a {
      color: inherit;

      &:hover {
        text-decoration: underline;
      }
    }

    .breadcrumb-sep {
      padding: 0 4px;
    }
  }
`;

const ClientSwitcher = (props: any) => {
  const { projectData } = props;
  return (
    <BreadcrumbWrap>
      <ul className="breadcrumb-list">
        {projectData && (
          <li>
            <Link to={`/`}>{projectData?.name}</Link>
          </li>
        )}
      </ul>
    </BreadcrumbWrap>
  );
};
export default ClientSwitcher;
