import ModelsApi from "../../../models/api/ModelsApi";
import { SET_MODEL_UUID } from "./modelTypes";

export function setModelUuid(uuid: string, modelApi: ModelsApi) {
  return {
    type: SET_MODEL_UUID,
    uuid,
    modelApi,
  };
}
