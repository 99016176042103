import React, { useState } from "react";
import { ReactComponent as Logo } from "../../../assets/logo.svg";
import { AuthWrap, Form, Error } from "../../components/AuthForms";
import useLogin from "../../context/useLogin";
import { ButtonText, Button } from "../../../components";
import { Icons } from "../../../assets/Icons";
import { InputGroup, FormGroup, Card, Elevation } from "@blueprintjs/core";

export const View: React.FC = () => {
  const [email, setEmail] = useState("john@smith.com");
  const [password, setPassword] = useState("pass1234!!");

  const [errors, setErrors] = useState<{ [key: string]: any }>();

  const login = useLogin();

  function doLogin(e: any) {
    e.preventDefault(); // don't refresh page on form submit

    return login({ email, password }).catch((error) => {
      if (error?.response?.data) {
        setErrors(error.response.data);
      }
    });
  }

  return (
    <AuthWrap>
      <Logo className="opteq-logo" />

      <Card className="auth-card" elevation={Elevation.THREE}>
        <Icons.SecurityLock size={21} className="card-icon" />

        <Form onSubmit={doLogin}>
          <FormGroup
            label="Email address"
            labelFor="input-email"
            className="auth-label"
          >
            <InputGroup
              id="input-email"
              type="email"
              value={email}
              onChange={(e: any) => {
                setEmail(e.target.value);
              }}
              className="auth-input"
              large
              fill
            />
          </FormGroup>
          <FormGroup
            helperText={
              errors &&
              errors?.errors?.password && (
                <Error>{errors.errors.password}</Error>
              )
            }
            label="Password"
            labelFor="input-password"
            className="auth-label"
          >
            <InputGroup
              id="input-password"
              type="password"
              value={password}
              onChange={(e: any) => {
                setPassword(e.target.value);
              }}
              className="auth-input"
              large
              fill
            />
          </FormGroup>

          <Button
            onClick={doLogin}
            disabled={!email || !password}
            type="submit"
          >
            Log In
          </Button>
        </Form>
      </Card>

      <p style={{ fontSize: "1rem", padding: "1em 3em" }}>
        Don't have an account?
        <br />
        <ButtonText to="/signup" boldtext>
          Sign up
        </ButtonText>
      </p>
    </AuthWrap>
  );
};

export default View;
