import styled from "styled-components";
import { animated } from "react-spring";
import { ComponentStyle } from "../../../../../store/referencePage/reference/commonTypes";
import { LooseComponentWrapProps } from "./types";

export const TextLabelWrapper = styled.div`
  width: 100%;
  height: 100%;
  max-width: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
`;

const checkColor = "#B8860B";
export const dashSize = 20;
export const circleRadius = 5;
const extraSize = dashSize + circleRadius * 2 + 20;

export const TextViewWrap = styled(animated.div).attrs<LooseComponentWrapProps>(
  (props) => ({
    style: {
      transform: props.temprotation.interpolate((r) => `rotate(${r}deg)`),
      border: props.selected ? "1px dashed blue" : "none",
    },
  })
)<LooseComponentWrapProps>`
  position: relative;
`;

export const LooseComponentContent = styled.div<{ isactive: boolean }>`
  width: 100%;
  height: 100%;
  box-sizing: border-box;
  border: ${(props) => (props.isactive ? `2px ${checkColor} solid` : "none")};

  &:hover {
    border: ${(props) =>
      props.isactive ? `2px ${checkColor} solid` : `1px dashed black`};
  }
`;

export const DashCircleWrap = styled.div`
  display: flex;
  justify-content: center;
  align-items: center;
  position: relative;
  max-width: calc(100% - ${extraSize}px);
  width: calc(100% - ${extraSize}px);
  height: 100%;
`;

export const Dash = styled.div`
  left: 100%;
  top: 50%;
  position: absolute;
  width: ${dashSize}px;
  height: 0px;
  border: 0.5px dashed ${checkColor};
`;

export const Circle = styled.div`
  left: calc(100% + ${dashSize}px);
  top: calc(50% - ${circleRadius}px);
  position: absolute;
  width: ${circleRadius * 2}px;
  height: ${circleRadius * 2}px;
  border-radius: ${circleRadius}px;
  background-color: lightgray;
  border: 1px solid ${checkColor};
`;

export const TextView = styled.div.attrs<ComponentStyle>((props) => ({
  style: {
    backgroundColor: props.backgroundColor,
    color: props.textColor,
    textDecoration: props.underlined ? "underline" : "initial",
    fontWeight: props.bold ? "bold" : "initial",
    fontStyle: props.italic ? "italic" : "initial",
  },
}))<ComponentStyle>`
  width: 100%;
  height: 100%;
  text-align: center;
  white-space: nowrap;
  // overflow-wrap: normal;
  overflow: hidden;
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
  & textarea {
    max-height: calc(100% - 30px);
    max-width: calc(100% - 30px);
    height: calc(100% - 30px);
    width: calc(100% - 30px);
  }
`;
