import React, { useState } from "react";
import {
  Dropdown,
  DropdownItem,
  ButtonText,
  SimpleModal,
  AppToaster,
} from "../../../components";
import useProjectsApi from "../../api/useProjectsApi";

const MemberRow = (props: any) => {
  const { name, email, role, admin, projectID, refresh, userID } = props;
  const [deleteModal, setDeleteModal] = useState(false);
  const projectsApi = useProjectsApi();

  function doDelete() {
    projectsApi
      .deleteProjectMember(projectID, userID)
      .then((response) => {
        if (response.statusText === "OK") {
          AppToaster.show({
            message: "Member was successfully deleted!",
            intent: "success",
          });

          refresh();
        }
      })
      .catch((error) => {
        AppToaster.show({
          message: "Something went wrong, please try again!",
          intent: "danger",
        });

        console.log(error);
      });
  }

  function updateRole(role: string) {
    projectsApi
      .updateRole(projectID, userID, role)
      .then((response) => {
        if (response.statusText === "OK") {
          AppToaster.show({
            message: "Role was successfully changed!",
            intent: "success",
          });

          refresh();
        }
      })
      .catch((error) => {
        AppToaster.show({
          message: "Something went wrong, please try again!",
          intent: "danger",
        });

        console.log(error);
      });
  }

  return (
    <>
      <tr className="members-row">
        <td className="member-name">{name}</td>
        <td className="member-email">
          {email}
          {admin && (
            <ButtonText
              as="button"
              className="member-remove"
              onClick={() => setDeleteModal((deleteModal) => !deleteModal)}
            >
              Remove
            </ButtonText>
          )}
        </td>
        <td className="member-role">
          {admin ? (
            <Dropdown
              className="member-role-dropdown"
              title={role}
              left={true}
              right={true}
              toggleIcon={true}
            >
              <DropdownItem onClick={() => updateRole("Admin")}>
                ADMIN
              </DropdownItem>
              <DropdownItem onClick={() => updateRole("Manager")}>
                MANAGER
              </DropdownItem>
              <DropdownItem onClick={() => updateRole("Viewer")}>
                VIEWER
              </DropdownItem>
            </Dropdown>
          ) : (
            role
          )}
        </td>
      </tr>
      {/* 
				TOOD: fix validateDOMNesting(...): <div> cannot appear as a child of <tbody> 
			*/}
      {deleteModal && (
        <SimpleModal
          title="Delete member"
          closeIcon={true}
          closeHandler={() => setDeleteModal((deleteModal) => !deleteModal)}
          closeText="Cancel"
          acceptHandler={doDelete}
          acceptText="Delete"
          text={`Are you sure you want to remove ${name} from this project?`}
        />
      )}
    </>
  );
};
export default MemberRow;
