import React, { useState } from "react";
import { Button, TextArea, AppToaster } from "../../../components";
import useModelsApi from "../../api/useModelsApi";
import { Dialog } from "@blueprintjs/core";
import styled from "styled-components";

const StyledDialog = styled(Dialog)`
  background-color: #fff;
  background: #fff;

  & > .bp3-dialog-header {
    box-shadow: none;
    padding: 16px 5px 16px 24px;

    & > h4 {
      font-size: 21px;
    }
  }

  .dialog-content {
    padding: 16px 24px;
  }
`;

const NewPrinciple = (props: any) => {
  const [designPrinciple, setDesignPrinciple] = useState("");
  const modelsApi = useModelsApi();

  function doNewPrinciple(e: any) {
    e.preventDefault();

    return modelsApi
      .createPrinciple(props.modelID, "Principle", designPrinciple)
      .then((response) => {
        if (response.statusText === "OK") {
          AppToaster.show({
            message: "Design Principle was successfully created!",
            intent: "success",
          });

          props.refresh();
          props.onClose();
        }
      })
      .catch((error) => {
        AppToaster.show({
          message: "Something went wrong, please try again!",
          intent: "danger",
        });

        // close modal and console log error
        props.onClose();
        console.log(error?.response.data);
      });
  }

  return (
    <StyledDialog
      title="New design principle"
      onClose={props.onClose}
      isOpen={props.isOpen}
    >
      <div className="dialog-content">
        <form>
          <TextArea
            label="Design principle"
            id="input-principle"
            value={designPrinciple}
            onChange={(e: any) => setDesignPrinciple(e.target.value)}
          />
          {/* 
					TODO: error for max 100 characters
				*/}
          <Button
            type="submit"
            disabled={!designPrinciple}
            onClick={doNewPrinciple}
          >
            Save design principle
          </Button>
        </form>
      </div>
    </StyledDialog>
  );
};
export default NewPrinciple;
