import styled from "styled-components";
import { Button } from "@blueprintjs/core";

export const ToolbarView = styled.div`
  margin: 0;
  margin-left: 40px;
  display: flex;
  flex-direction: row;
  justify-content: center;
  align-items: center;
`;

const borderRadius = 5;
export const ToolBarContent = styled.div<{ checked?: boolean }>`
  display: flex;
  flex-direction: row;
  justify-content: space-around;
  align-items: center;
  border: 1px solid gray;
  margin-left: 20px;
  margin-right: 20px;
  border-radius: ${borderRadius}px;
  & > *:first-child {
    border-top-left-radius: ${borderRadius}px;
    border-bottom-left-radius: ${borderRadius}px;
  }
  & > *:last-child {
    border-top-right-radius: ${borderRadius}px;
    border-bottom-right-radius: ${borderRadius}px;
  }

  &.edit-content {
    padding: 4px 8px;
    background-color: ${(props) => (props.checked ? "#091d36" : "#4a90e2")};
    border: ${(props) => (props.checked ? "#091d36" : "#4a90e2")};
    color: #fff;

    &:hover {
      opacity: 0.9;
    }
  }
`;

export const ToolbarOptionView = styled.div<{ checked?: boolean }>`
  display: flex;
  justify-content: center;
  align-items: center;
  margin: 0px auto;
  width: 21px;
  height: 21px;
  padding: 3px;
  background-color: ${(props) => (props.checked ? "gray" : "none")};
  &:hover {
    background-color: ${(props) => (props.checked ? "gray" : "lightblue")};
  }

  &.edit-btn-view {
    min-width: 76px;
    width: 100%;
    padding: 0;
    background-color: initial;

    :hover {
      background-color: initial;
    }
  }

  .edit-btn {
    min-width: 76px;
    width: 100%;
    padding: 0;
    background-color: initial;

    :hover {
      background-color: initial;
    }
  }
`;

export const ColorContainer = styled.div`
  position: relative;
  width: 100%;
  height: 100%;
`;

export const ColorOptionContent = styled.div`
  width: 100%;
  height: 100%;
  display: flex;
  justify-content: center;
  align-items: center;
  flex-direction: column;

  .color-option-tooltip svg {
    outline: none !important;
  }

  .bp3-popover-wrapper:focus,
  .bp3-popover-target:focus {
    outline: none !important;
  }
`;

export const PickerWrap = styled.div`
  position: absolute;
  top: calc(100% + 10px);
  left: 0;
  z-index: 5;
`;

export const ColorUnderline = styled.div<{ color: string }>`
  background-color: ${(props) => props.color};
  width: 80%;
  height: 2px;
  margin: 0 auto;
`;

export const EditBtn = styled(Button)`
  min-width: 76px;
  background: transparent !important;
  padding: 0 !important;
  min-height: 27px;
  height: 27px;
  box-shadow: none !important;
  color: #fff !important;
  font-weight: 500;
`;
