import React from "react";
import styled from "styled-components";

const InputMain = styled.div<{ w?: string }>`
  width: ${(p) => p.w || "100%"};
`;

const InputWrap = styled.div`
  display: flex;
  flex-direction: column;
  position: relative;
  height: 100%;
`;

const StyledInput = styled.input<{ mbottom: string; psize: string }>`
  border: 1px solid transparent;
  margin-bottom: ${(p) => p.mbottom || "16px"};
  font-size: 16px;
  background-color: #ebebeb;
  border-radius: 4px;
  padding: ${(p) => p.psize || "8px 12px"};
  outline: none;

  &:focus,
  &:active,
  &:hover {
    border-color: #061d38;
  }
`;

const InputLabel = styled.label`
  font-size: 18px;
  color: #3b3b3b;
  text-align: left;
  font-weight: 500;
  letter-spacing: 0.025em;
`;

export const Input = (props: any) => {
  return (
    <InputMain w={props.w}>
      {props.label && <InputLabel htmlFor={props.id}>{props.label}</InputLabel>}
      <InputWrap>
        <StyledInput
          id={props.id}
          type={props.type}
          value={props.value}
          onChange={props.onChange}
          mbottom={props.mbottom}
          psize={props.psize}
          readOnly={props?.readonly}
          placeholder={props?.placeholder}
          className={props?.className}
        />
        {props.children}
      </InputWrap>
    </InputMain>
  );
};

const StyledTextArea = styled.textarea`
  border: 1px solid transparent;
  margin-bottom: 16px;
  font-size: 16px;
  background-color: #ebebeb;
  border-radius: 4px;
  padding: 8px 12px;
  outline: none;
  color: inherit;
  max-width: 100%;
  resize: none;

  &:focus,
  &:active,
  &:hover {
    border-color: #061d38;
  }
`;

export const TextArea = (props: any) => {
  return (
    <>
      {props.label && <InputLabel htmlFor={props.id}>{props.label}</InputLabel>}
      <InputWrap>
        <StyledTextArea
          id={props.id}
          maxLength={props.maxlength}
          placeholder={props.placeholder}
          value={props.value}
          onChange={props.onChange}
          readOnly={props?.readonly}
          onClick={props?.onClick}
          onBlur={props?.onBlur}
          onKeyDown={props?.onKeyDown}
        />
      </InputWrap>
    </>
  );
};
