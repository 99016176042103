import React, { useEffect, useState } from "react";
import AuthContext, { Auth } from "./index";
import AuthApi from "../api";
import { UserApi, UserProfile } from "../../user/api";
import { signupProfile } from "../index";
import { clientspaceHomeUrl } from "../../clientspaces";

export const defaultAuthParams = {
  loginUrl: "/login",
  afterLoginUrl: clientspaceHomeUrl,
  afterSignupUrl: signupProfile,
};

const AuthProvider: React.FC = ({ children }) => {
  const token = localStorage.getItem("token");

  const [profile, setProfile] = useState<UserProfile | undefined>(undefined);

  useEffect(() => {
    if (token) {
      const userApi = new UserApi(token);

      userApi
        .getProfile()
        .then((r) => {
          setProfile(r.data);
        })
        .catch((error) => {
          return logout();
        });
    }
  }, [token]);

  const authApi = new AuthApi();

  const signup = (
    email: string,
    password: string,
    firstName: string,
    lastName: string
  ) =>
    authApi
      .signup({ email, password, firstName, lastName })
      .then((response) => {
        localStorage.setItem("token", response.data.token);
        setProfile(response.data.profile);
        return response;
      });

  const login = (email: string, password: string) =>
    authApi.login({ email, password }).then((response) => {
      localStorage.setItem("token", response.data.token);
      setProfile(response.data.profile);
      return response.data;
    });

  const logout = () => {
    localStorage.removeItem("token");
    return Promise.resolve();
  };

  const auth: Auth = {
    signup,
    login,
    logout,
    token,
    profile,
  };

  return <AuthContext.Provider value={auth}>{children}</AuthContext.Provider>;
};

export default AuthProvider;
