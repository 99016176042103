import React from "react";
import { Icons } from "../../../../../assets/Icons";
import { MenuComponentWrap } from "./styles";
import { MenuComponentProps } from "./types";
import { useDrag, DragSourceMonitor } from "react-dnd";
import { ComponentTypesByName } from "../../../../../store/referencePage/reference/commonTypes";
import {
  createNewBox,
  createNewItem,
  transferBoxToRow,
  transferBoxToNewRow,
  transferItemToParent,
  tryCreateFirstComponent,
} from "../../stateManager";
import { ItemTypes } from "../itemTypes";
const itemsTypeMap = {
  [ComponentTypesByName.Division]: `${ItemTypes.NEW_ITEM}0`,
  [ComponentTypesByName.BusinessUnit]: `${ItemTypes.NEW_ITEM}1`,
  [ComponentTypesByName.Function]: `${ItemTypes.NEW_ITEM}2`,
  [ComponentTypesByName.SubFunction]: `${ItemTypes.NEW_ITEM}3`,
  [ComponentTypesByName.TextLabel]: `${ItemTypes.NEW_ITEM}t`,
};

export const MenuComponent: React.FC<MenuComponentProps> = ({
  type,
  state,
}) => {
  const [, drag] = useDrag({
    item: { type: itemsTypeMap[type] },
    end: (item, monitor: DragSourceMonitor) => {
      const dropResult = monitor.getDropResult();
      if (item && dropResult) {
        console.log(item, dropResult);
        switch (dropResult.type) {
          case `${ItemTypes.POS}_row`: {
            const boxId = createNewBox(state);
            transferBoxToNewRow(state, boxId, dropResult.pos);
            break;
          }
          case `${ItemTypes.POS}0`: {
            const boxId = createNewBox(state);
            transferBoxToRow(state, boxId, dropResult.rowId, dropResult.pos);
            break;
          }
          case `${ItemTypes.POS}1`: {
            const itemId = createNewItem(state, 1);
            transferItemToParent(
              state,
              1,
              itemId,
              dropResult.id,
              dropResult.pos
            );
            break;
          }
          case `${ItemTypes.POS}2`: {
            const itemId = createNewItem(state, 2);
            transferItemToParent(
              state,
              2,
              itemId,
              dropResult.id,
              dropResult.pos
            );
            break;
          }
          case `${ItemTypes.POS}3`: {
            const itemId = createNewItem(state, 3);
            transferItemToParent(
              state,
              3,
              itemId,
              dropResult.id,
              dropResult.pos
            );
            break;
          }
          case ItemTypes.COAP: {
            tryCreateFirstComponent(state, type);
            break;
          }
          case `${ItemTypes.ITEM}0`: {
            const newItemId = createNewItem(state, 1);
            transferItemToParent(state, 1, newItemId, dropResult.id, 0);
            break;
          }
          case `${ItemTypes.ITEM}1`: {
            const newItemId = createNewItem(state, 2);
            transferItemToParent(state, 2, newItemId, dropResult.id, 0);
            break;
          }
          case `${ItemTypes.ITEM}2`: {
            const newItemId = createNewItem(state, 3);
            transferItemToParent(state, 3, newItemId, dropResult.id, 0);
            break;
          }
        }
      }
    },
    collect: (monitor) => ({
      isDragging: monitor.isDragging(),
    }),
  });
  const getComponentIcon = () => {
    switch (type) {
      case ComponentTypesByName.Division:
        return (
          <>
            <Icons.Division size={15} />
            <span>Division</span>
          </>
        );
      case ComponentTypesByName.BusinessUnit:
        return (
          <>
            <Icons.BusinessUnit size={15} />
            <span>Business Unit</span>
          </>
        );
      case ComponentTypesByName.Function:
        return (
          <>
            <Icons.Function size={15} />
            <span>Function</span>
          </>
        );
      case ComponentTypesByName.SubFunction:
        return (
          <>
            <Icons.SubFunction size={15} />
            <span>Sub Function</span>
          </>
        );
      case ComponentTypesByName.TextLabel:
        return (
          <>
            <Icons.TextBox size={15} />
            <span>Text box</span>
          </>
        );
    }
  };
  return <MenuComponentWrap ref={drag}>{getComponentIcon()}</MenuComponentWrap>;
};

export default MenuComponent;
