import { ComponentTypesByName, ComponentStyle } from "./commonTypes";
import {
  State,
  BoxInterface,
  RowInterface,
  getID,
  ItemInterface,
  initialStyle,
} from "../../../models/views/DraggableReference/stateManager";
import { Component } from "../../../models/api/model";

export function apiState2CoapState(apiState: Record<string, Component>) {
  console.log(apiState);
  const state: State = {
    rowsOrder: [],
    rows: {},
    components: {
      [ComponentTypesByName.Division]: {},
      [ComponentTypesByName.BusinessUnit]: {},
      [ComponentTypesByName.Function]: {},
      [ComponentTypesByName.SubFunction]: {},
    },
    selected: { nestingLevel: -1, content: [] },
    dirty: {},
  };
  let types = [
    ComponentTypesByName.Division,
    ComponentTypesByName.BusinessUnit,
    ComponentTypesByName.Function,
    ComponentTypesByName.SubFunction,
  ];
  let keys = Object.keys(apiState);
  const rows: Record<string, RowInterface> = {};
  const wieghtsByRows: Record<string, Record<string, number>> = {};
  const wieghtsByBoxes: Record<string, Record<string, number>> = {};
  const wieghtsByItems: Record<string, Record<string, number>> = {};
  const wieghtsBySubItems: Record<string, Record<string, number>> = {};

  for (let i = 0; i < types.length; i++) {
    const currentType = types[i];
    keys.forEach((key) => {
      const data = apiState[key];
      const properties = data.properties;
      if (data.kind === currentType) {
        switch (data.kind) {
          case ComponentTypesByName.Division: {
            const nRow = Math.max(0, properties.position?.y || 0);
            let row: RowInterface;
            if (!rows[nRow]) {
              row = { id: getID(), content: [] };
              wieghtsByRows[row.id] = {};
              rows[nRow] = row;
            } else {
              row = rows[nRow];
            }
            const box: BoxInterface = {
              id: data.id,
              content: [],
              grow: data.properties.position?.width || 1,
              name: properties.name,
              parent: undefined,
              type: data.kind,
              style: { ...data.properties.style },
            };
            wieghtsByRows[row.id][box.id] = data.weight;
            wieghtsByBoxes[box.id] = {};
            state.components[ComponentTypesByName.Division][box.id] = box;
            break;
          }
          case ComponentTypesByName.BusinessUnit: {
            const parentBox =
              state.components[ComponentTypesByName.Division][
                data.refs.parent as string
              ];
            const item: ItemInterface = {
              id: data.id,
              content: [],
              name: properties.name,
              style: { ...(data.properties.style || initialStyle) },
              type: data.kind,
              parent: data.refs.parent as string,
            };
            wieghtsByBoxes[parentBox.id][item.id] = data.weight;
            wieghtsByItems[item.id] = {};
            state.components[ComponentTypesByName.BusinessUnit][item.id] = item;
            break;
          }
          case ComponentTypesByName.Function: {
            const parentItem =
              state.components[ComponentTypesByName.BusinessUnit][
                data.refs.parent as string
              ];
            const subItem: ItemInterface = {
              id: data.id,
              name: properties.name,
              style: { ...(data.properties.style || initialStyle) },
              parent: data.refs.parent as string,
              type: data.kind,
              content: [],
            };
            wieghtsByItems[parentItem.id][subItem.id] = data.weight;
            wieghtsBySubItems[subItem.id] = {};
            state.components[ComponentTypesByName.Function][
              subItem.id
            ] = subItem;
            break;
          }
          case ComponentTypesByName.SubFunction: {
            const parentItem =
              state.components[ComponentTypesByName.Function][
                data.refs.parent as string
              ];
            const subItem: ItemInterface = {
              id: data.id,
              name: properties.name,
              style: { ...(data.properties.style || initialStyle) },
              parent: data.refs.parent as string,
              type: data.kind,
              content: [],
            };
            wieghtsBySubItems[parentItem.id][subItem.id] = data.weight;
            state.components[ComponentTypesByName.SubFunction][
              subItem.id
            ] = subItem;
            break;
          }
        }
      }
    });
  }
  console.log(wieghtsByRows, wieghtsByBoxes, wieghtsByItems);
  let rowsKeys = Object.keys(rows).sort();
  console.log(rowsKeys);
  rowsKeys.forEach((key) => {
    const row = rows[key];
    state.rowsOrder.push(row.id);
    state.rows[row.id] = row;
  });

  Object.keys(wieghtsByRows).forEach((rowId) => {
    const rowWeights = wieghtsByRows[rowId];
    const orederedKeys = Object.keys(rowWeights).sort(
      (key1, key2) => rowWeights[key1] - rowWeights[key2]
    );
    orederedKeys.forEach((boxId) => {
      state.rows[rowId].content.push(boxId);
    });
  });

  Object.keys(wieghtsByBoxes).forEach((boxId) => {
    const boxWeights = wieghtsByBoxes[boxId];
    const orederedKeys = Object.keys(boxWeights).sort(
      (key1, key2) => boxWeights[key1] - boxWeights[key2]
    );
    orederedKeys.forEach((itemId) => {
      state.components[ComponentTypesByName.Division][boxId].content.push(
        itemId
      );
    });
  });

  Object.keys(wieghtsByItems).forEach((itemId) => {
    const itemWeights = wieghtsByItems[itemId];
    const orederedKeys = Object.keys(itemWeights).sort(
      (key1, key2) => itemWeights[key1] - itemWeights[key2]
    );
    orederedKeys.forEach((subItemId) => {
      state.components[ComponentTypesByName.BusinessUnit][itemId].content.push(
        subItemId
      );
    });
  });

  Object.keys(wieghtsBySubItems).forEach((itemId) => {
    const itemWeights = wieghtsBySubItems[itemId];
    const orederedKeys = Object.keys(itemWeights).sort(
      (key1, key2) => itemWeights[key1] - itemWeights[key2]
    );
    orederedKeys.forEach((subItemId) => {
      state.components[ComponentTypesByName.Function][itemId].content.push(
        subItemId
      );
    });
  });
  return state;
}

export function coapSate2apiState(state: State) {
  const apiState: Array<Component> = [];
  let keys = Object.keys(state.components[ComponentTypesByName.Division]);
  keys.forEach((id) => {
    const comp = state.components[ComponentTypesByName.Division][id];
    apiState.push(coapComponent2apiComponent(state, comp));
    let itemKeys = comp.content;
    itemKeys.forEach((idd) => {
      const item = state.components[ComponentTypesByName.BusinessUnit][idd];
      apiState.push(coapComponent2apiComponent(state, item));

      let subItemKeys = item.content as string[];
      subItemKeys.forEach((iddd) => {
        const subItem = state.components[ComponentTypesByName.Function][iddd];
        apiState.push(coapComponent2apiComponent(state, subItem));
      });
    });
  });

  return apiState;
}

export function coapComponent2apiComponent(
  state: State,
  comp: BoxInterface | ItemInterface
): Component {
  let apiModelComp: Component = {
    id: comp.id,
    kind: comp.type,
    properties: {
      style: comp.style as ComponentStyle,
      name: comp.name,
      description: "description",
    },
    refs: {
      parent: comp.parent,
    },
    weight: 0,
  };
  switch (comp.type) {
    case ComponentTypesByName.Division: {
      const rowKey = Object.keys(state.rows).find(
        (key) => state.rows[key].content.indexOf(comp.id) >= 0
      );
      const nRow = state.rowsOrder.indexOf(rowKey as string);
      apiModelComp.properties.position = {
        y: nRow,
        width: (comp as BoxInterface).grow,
      };
      apiModelComp.weight = state.rows[rowKey as string].content.indexOf(
        comp.id
      );
      break;
    }
    case ComponentTypesByName.BusinessUnit: {
      apiModelComp.weight = state.components[ComponentTypesByName.Division][
        comp.parent as string
      ].content.indexOf(comp.id);
      break;
    }
    case ComponentTypesByName.Function: {
      apiModelComp.weight = state.components[ComponentTypesByName.BusinessUnit][
        comp.parent as string
      ].content.indexOf(comp.id);
      break;
    }
    case ComponentTypesByName.SubFunction: {
      apiModelComp.weight = state.components[ComponentTypesByName.Function][
        comp.parent as string
      ].content.indexOf(comp.id);
      break;
    }
  }
  console.log(apiModelComp);
  return apiModelComp;
}
