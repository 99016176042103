import * as React from "react";
import { useEffect, useState, useRef } from "react";
import useAuth from "../../../auth/context/useAuth";
import { RouteComponentProps } from "react-router";
import { Pagination } from "../../../components";
import {
  ClientsList,
  CardsWrap,
  ClientsTools,
} from "../../../clientspaces/components/CsListView";
import AttributesCard from "../../../projectspaces/components/AttributesCard";
import { Icons } from "../../../assets/Icons";
import { DataWrap } from "../../../projectspaces/components/ProjectsView";
import { Model, AttributeSummary } from "../../../models/api/model";
import useModelsApi from "../../../models/api/useModelsApi";
import {
  ButtonGroup,
  Popover,
  Menu,
  Position,
  Button,
  MenuItem,
  InputGroup,
} from "@blueprintjs/core";
import AttributeModel from "../../../components/AttributeModel";
import DashBoardConstant from "../Reference/DashBoardConstant";
import { HotTable } from "@handsontable/react";
import Handsontable from "handsontable";
import Core from "handsontable";
import AutoSizer from "react-virtualized-auto-sizer";
import { useProjectsApi } from "../../../projectspaces/api/useProjectsApi";
import {useSchema} from "../../../app/context/queries/useSchema";

type ViewProps = RouteComponentProps<{
  modelUuid: string;
}>;

interface ColumnObject {
  name: string;
  key: string;
  type?: string;
}

interface AttributeDisplay {
  display: boolean;
  uuid: string;
  allocation?: boolean;
  defaultSelectedTabId?: string | undefined;
}

type TableColumnsType = {
  data: string;
  type: string;
  source?: Array<string>;
};

type TableRowsType = {
  labels: Array<{ [key: string]: any }>;
  properties: Array<{ [key: string]: any }>;
};

export const View: React.FC<ViewProps> = ({ match, location, history }) => {
  const auth = useAuth();
  const modelsApi = useModelsApi();
  const hot = useRef<HotTable>(null);

  const {data: schemas, isLoading} = useSchema()

  const [attributesSummary, setAttributesSummary] = useState<
    Array<AttributeSummary>
  >([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchString, setSearchString] = useState<string>();
  const [totalPages, setTotalPages] = useState(1);

  const [tableRows, setTableRows] = useState<TableRowsType>();
  const [tableColumns, setTableColumns] = useState<Array<TableColumnsType>>([]);
  const [tableHeaders, setTableHeaders] = useState<
    Array<{ name: string; key: string; type: string; target?: string }>
  >([]);
  const [tableSource, setTableSource] = useState<{
    [key: string]: Array<string>;
  }>();
  const [tableLinkSource, setTableLinkSource] = useState<{
    [key: string]: Array<string>;
  }>();

  const [filterColumn, setFilterColumn] = useState<ColumnObject>();
  const [filterOp, setFilterOp] = useState<ColumnObject>();
  const [filterSearch, setFilterSearch] = useState<string>();
  const [filterString, setFilterString] = useState<string>(); // used as a param for the endpoint

  const [sortColumn, setSortColumn] = useState<ColumnObject>();
  const [sortOp, setSortOp] = useState<ColumnObject>();
  const [sortString, setSortString] = useState<string>();

  const [displayAttributeModel, setDisplayAttributeModel] = useState<
    AttributeDisplay
  >({
    display: false,
    uuid: "",
  });
  const [modelInfo, setModelInfo] = useState<Model>();
  const [refresh, setRefresh] = useState<boolean>(false);

  const params = new URLSearchParams(location?.search);
  const ATTRIBUTE = params.get("kind");
  const OFFSET = parseInt(params.get("offset") || "0", 10);

  const projectsApi = useProjectsApi();

  useEffect(() => {
    modelsApi.getModel(match?.params.modelUuid).then((response) => {
      setModelInfo(response?.data);
    });
  }, [modelsApi, match, auth]);

  useEffect(() => {
    if (!ATTRIBUTE) {
      modelsApi
        .getAttributesSummary(match?.params.modelUuid)
        .then((response) => setAttributesSummary(response?.data));
    }
  }, [modelsApi, match, ATTRIBUTE]);

  useEffect(() => {
    if (ATTRIBUTE && modelInfo && tableHeaders.length > 0) {
      modelsApi
        .getCandidates(match?.params.modelUuid, ATTRIBUTE)
        .then((response) => {
          let obj: { [key: string]: Array<string> } = {};
          if (response?.data?.results.length > 0) {
            const keys: Array<string> = Object.keys(
              response?.data?.results[0]?.labels
            );
            obj = keys.reduce(
              (o, key) => ({
                ...o,
                [key]: [],
              }),
              {}
            );

            for (let i = 0; i < keys.length; i++) {
              response?.data?.results.map((result: any) => {
                const value = result.properties[keys[i]];
                if (
                  value &&
                  value !== undefined &&
                  value !== null &&
                  value !== "Null" &&
                  value !== "" &&
                  obj[keys[i]].indexOf(value) <= -1
                ) {
                  obj[keys[i]].push(value);
                }
              });
            }
          }

          setTableSource(obj);
        });

      for (let i = 0; i < tableHeaders.length; i++) {
        if (
          tableHeaders[i]?.type === "link" &&
          tableHeaders[i]?.key !== "split"
        ) {
          projectsApi
            .getAttribute(modelInfo?.project?.uuid, tableHeaders[i]?.target)
            .then((response) => {
              let obj: { [key: string]: Array<string> } = {};
              const key = tableHeaders[i]?.key;
              if (response?.data?.results.length > 0) {
                obj[key] = [];

                response?.data?.results.map((result: any) => {
                  const value = result.properties["name"];
                  if (
                    value &&
                    value !== undefined &&
                    value !== null &&
                    value !== "Null" &&
                    value !== "" &&
                    obj[key].indexOf(value) <= -1
                  ) {
                    obj[key].push(value);
                  }
                });
              }

              setTableLinkSource((prevState: any) => {
                return { ...prevState, [key]: obj[key] };
              });
            });
        }
      }
    }
  }, [modelsApi, ATTRIBUTE, match, modelInfo, projectsApi, tableHeaders]);

  useEffect(() => {
    if (ATTRIBUTE && schemas) {
      // modelsApi.getSchemaAttributes().then((response) => {
        const temp_arr = [];
        temp_arr.push(schemas);

        const attributeIndex = temp_arr[0].findIndex(
          (attr) => attr?.kind === ATTRIBUTE
        );

        if (attributeIndex > -1) {
          const columns = schemas[attributeIndex]?.properties;
          const columnsKeys = Object.keys(columns);

          const headersName = columnsKeys.map((key: string) => ({
            name: columns[key]?.meta.name,
            key: key,
            type: columns[key].type,
            target: columns[key]?.target?.kind,
          }));

          // add model allocations column
          headersName.push({
            name: "Model Allocations",
            key: "split",
            type: "link",
            target: undefined,
          });

          setTableHeaders(headersName);
        }
      // });
    }
  }, [modelsApi, ATTRIBUTE]);

  useEffect(() => {
    if (ATTRIBUTE && tableSource && tableLinkSource) {
      modelsApi
        .getAttributes(
          match?.params.modelUuid,
          ATTRIBUTE,
          undefined,
          500,
          OFFSET,
          filterString,
          sortString,
          searchString
        )
        .then((response) => {
          setTotalPages(Math.ceil(response?.data.total / 500));
          const rows: {
            properties: Array<{ [key: string]: any }>;
            labels: Array<{ [key: string]: any }>;
          } = { properties: [], labels: [] };

          rows.properties = response?.data?.results.map((result: any) => {
            const obj = result.properties;
            obj.uuid = result.uuid;

            const linkKeys = Object.keys(result.links);
            linkKeys.map((key: string) => {
              obj[key] = result.links[key];
              return null;
            });

            obj["split"] = result.split.map((model: any) => ({
              name: model.name,
              uuid: result.uuid,
            }));

            obj["partial"] = result.partial;

            return obj;
          });

          rows.labels = response?.data?.results.map((result: any) => {
            const obj = result.labels;
            obj.uuid = result.uuid;
            obj.id = result.id;
            obj.attributeUuid = result.attributeUuid;

            const linkKeys = Object.keys(result.links);
            linkKeys.map((key: string) => {
              obj[key] = result.links[key];
              return null;
            });

            obj["split"] = result.split.map((model: any) => ({
              name: model.name,
              uuid: result.uuid,
            }));

            obj["partial"] = result.partial;

            return obj;
          });

          setTableRows(rows);
        });

      // modelsApi.getSchemaAttributes().then((response) => {
      if(schemas) {
        const temp_arr = [];
        temp_arr.push(schemas);

        const attributeIndex = temp_arr[0].findIndex(
            (attr) => attr?.kind === ATTRIBUTE
        );

        if (attributeIndex > -1) {
          const columns = schemas[attributeIndex]?.properties;
          const columnsKeys = Object.keys(columns);

          const defaultColumns = columnsKeys.map((key: string) => ({
            data: key,
            type:
                columns[key].type === "link"
                    ? "autocomplete"
                    : columns[key].type !== "number"
                    ? "autocomplete"
                    : "numeric",
            renderer:
                columns[key].type === "link" ? "link.render" : "display.render",
            editor:
                columns[key].type === "link"
                    ? "autocomplete"
                    : columns[key].type === "number"
                    ? "text"
                    : key === "name"
                        ? "text"
                        : "autocomplete",
            source:
                columns[key].type === "link"
                    ? tableLinkSource[key]
                    : tableSource[key],
            readOnly: false,
          }));

          defaultColumns.push({
            data: "split",
            renderer: "allocation.render",
            type: "text",
            editor: "dropdown",
            source: [],
            readOnly: true,
          });

          setTableColumns(defaultColumns);
        }
      }
      // });
    }
  }, [
    modelsApi,
    ATTRIBUTE,
    OFFSET,
    filterString,
    sortString,
    searchString,
    match,
    refresh,
    tableSource,
    tableLinkSource,
  ]);

  // used for filtering
  function selectOpHandler(key: string, name: string) {
    setFilterOp({
      name: name,
      key: key,
    });
  }

  function selectColumnHandler(column: any) {
    setFilterColumn({
      name: column.name,
      key: column.key,
    });
  }

  function resetFilterHandler() {
    setFilterOp(undefined);
    setFilterColumn(undefined);
    setFilterString(undefined);
  }

  function doFilter() {
    const searchParam = !filterSearch ? "null" : "'" + filterSearch + "'";
    setFilterString(
      filterColumn?.key + "." + filterOp?.key + "." + searchParam
    );
  }

  // used for sorting
  function selectSortColumnHandler(column: any) {
    setSortColumn({
      name: column.name,
      key: column.key,
      type: column.type,
    });
  }

  function selectSortOpHandler(key: string, name: string) {
    setSortOp({
      name: name,
      key: key,
    });
  }

  function resetSortHandler() {
    setSortColumn(undefined);
    setSortOp(undefined);
    setSortString(undefined);
  }

  function doSort() {
    setSortString(sortColumn?.key + "+" + sortOp?.key);
  }

  function doSearch(e: any) {
    e.preventDefault();
    if (searchValue.length <= 0) {
      setSearchString(undefined);
    }

    setSearchString(searchValue);
  }

  function handlePageChange(page: number) {
    history.push(`${match.url}?kind=${ATTRIBUTE}&offset=${page * 500 - 500}`);
  }

  (function (Handsontable) {
    function customRenderer(
      instance: Core,
      td: HTMLTableCellElement,
      row: number,
      col: number,
      prop: React.ReactText,
      value: any,
      cellProperties: Handsontable.CellProperties
    ) {
      let button;
      if (tableRows?.labels[row]?.partial) {
        td.classList.add("partial-cell");
        cellProperties.className = "partial-cell";
        cellProperties.readOnly = true;
      }

      if (
        value?.label !== "" &&
        value?.label !== null &&
        value?.label !== undefined
      ) {
        button = document.createElement("button");
        button.innerHTML = value?.label;
        button.classList.add("link-btn");

        if (value?.label !== "Null") {
          Handsontable.dom.addEvent(button, "mousedown", function (e: any) {
            e.preventDefault(); // prevent selection quirk
            setDisplayAttributeModel({
              uuid: value?.uuid,
              display: true,
            });
          });
        }

        Handsontable.dom.empty(td);
        td.appendChild(button);
        td.classList.add("link-cell");
        cellProperties.readOnly = true;
      } else {
        value = "";
        Handsontable.renderers.AutocompleteRenderer(
          instance,
          td,
          row,
          col,
          prop,
          value,
          cellProperties
        );
      }
    }

    // Register an alias
    Handsontable.renderers.registerRenderer("link.render", customRenderer);
  })(Handsontable);

  (function (Handsontable) {
    function customRenderer(
      instance: Core,
      td: HTMLTableCellElement,
      row: number,
      col: number,
      prop: React.ReactText,
      value: any,
      cellProperties: Handsontable.CellProperties
    ) {
      if (value && value.length > 0) {
        Handsontable.dom.empty(td);
        for (let i = 0; i < value.length; i++) {
          if (value[i].name !== null) {
            let button;
            button = document.createElement("button");
            button.innerHTML = value[i]?.name;
            button.classList.add("link-btn");

            Handsontable.dom.addEvent(button, "mousedown", function (e: any) {
              e.preventDefault(); // prevent selection quirk
              setDisplayAttributeModel({
                uuid: value[i]?.uuid,
                display: true,
                allocation: true,
              });
            });

            td.appendChild(button);
            td.classList.add("link-cell");
            cellProperties.readOnly = true;
          } else {
            td.innerHTML = "";
          }
        }
      } else {
        td.innerHTML = value;
      }

      if (tableRows?.labels[row]?.partial) {
        td.classList.add("partial-cell");
        cellProperties.className = "partial-cell";
        cellProperties.readOnly = true;
      }
    }

    // Register an alias
    Handsontable.renderers.registerRenderer(
      "allocation.render",
      customRenderer
    );
  })(Handsontable);

  (function (Handsontable) {
    function customRenderer(
      instance: Core,
      td: HTMLTableCellElement,
      row: number,
      col: number,
      prop: React.ReactText,
      value: any,
      cellProperties: Handsontable.CellProperties
    ) {
      if (tableRows && row < tableRows?.labels.length) {
        if (tableRows.labels[row]) {
          const value = tableRows.labels[row][prop];
          td.innerHTML = value;

          if (tableRows.labels[row].partial) {
            td.classList.add("partial-cell");
            cellProperties.className = "partial-cell";
            cellProperties.readOnly = true;
          }
        }
      } else {
        td.innerHTML = value;
      }
    }

    // Register an alias
    Handsontable.renderers.registerRenderer("display.render", customRenderer);
  })(Handsontable);

  function handleCellEdit(changes: any, source: string) {
    if (source === "edit") {
      changes.map((row: any) => {
        if (tableRows) {
          const cellUuid =
            row[0] >= tableRows?.labels.length
              ? null
              : tableRows?.labels[row[0]].uuid;
          const cellName = row[1];
          let cellNewValue = row[3].length > 0 ? row[3] : null;

          if (cellUuid !== null && cellUuid !== undefined && ATTRIBUTE) {
            const cellId = tableRows?.labels[row[0]].id;
            const cellObj = {
              id: cellId,
              kind: ATTRIBUTE,
              properties: { [cellName]: cellNewValue },
            };

            modelsApi
              .updateAttributeCell(
                match?.params.modelUuid,
                tableRows?.labels[row[0]].attributeUuid,
                cellObj
              )
              .then((response) => {
                console.log(response?.status);
                if (response.statusText === "OK") {
                  setRefresh((refresh) => !refresh);
                }
              })
              .catch((error) => console.log(error));
          } else {
            if (ATTRIBUTE) {
              const newRow = {
                id: "" + Math.random().toString(36).substr(2, 9),
                kind: ATTRIBUTE,
                properties: { [cellName]: cellNewValue },
              };

              if (cellNewValue && cellNewValue.length > 0) {
                modelsApi
                  .createAttributeRow(match?.params.modelUuid, newRow)
                  .then((response) => {
                    console.log(response?.status);
                    if (response.statusText === "OK") {
                      setRefresh((refresh) => !refresh);
                    }
                  })
                  .catch((error) => console.log(error));
              }
            }
          }
        }

        return null;
      });
    }
  }

  function handleRowRemove(
    index: number,
    ammount: number,
    physicalRows: Array<number>,
    source: string | undefined
  ) {
    if (source === "ContextMenu.removeRow") {
      physicalRows.map((index: number) => {
        const cellUuid =
          tableRows?.labels[index] && tableRows?.labels[index].uuid
            ? tableRows?.labels[index].attributeUuid
            : null;
        if (cellUuid !== null && cellUuid !== undefined) {
          modelsApi
            .removeRowAttribute(match?.params?.modelUuid, cellUuid)
            .then((response) => {
              console.log(response?.status);
              if (response.statusText === "OK") {
                setRefresh((refresh) => !refresh);
              }
            })
            .catch((error) => console.log(error));
        }
        return null;
      });
    }
  }

  function handlePaste(data: any, coords: Array<{ [key: string]: number }>) {
    const newCoords = coords[0];
    const startRow = newCoords?.startRow;
    const endRow = newCoords?.endRow;
    const startCol = newCoords?.startCol;
    const endCol = newCoords?.endCol;
    let dataArr = [];
    let rowIndex = 0;
    for (let i = startRow; i <= endRow; i++) {
      let valueIndex = 0;
      dataArr.push({
        uuid: null,
        kind: ATTRIBUTE,
        properties: {},
      });

      for (let ii = startCol; ii <= endCol; ii++) {
        if (
          tableHeaders[ii]?.type !== "link" &&
          tableHeaders[ii]?.type !== "split"
        ) {
          if (
            tableRows?.labels[i] &&
            tableRows?.labels[i].uuid &&
            tableRows?.labels[i].id
          ) {
            dataArr[rowIndex].uuid = tableRows?.labels[i].attributeUuid;
            Object.assign(dataArr[rowIndex].properties, {
              [tableHeaders[ii].key]: data[valueIndex][0],
            });
          } else {
            Object.assign(dataArr[rowIndex].properties, {
              [tableHeaders[ii].key]: data[valueIndex][0],
            });
          }
        }

        valueIndex =
          valueIndex + 1 >= data.length - 1 ? data.length - 1 : valueIndex++;
      }

      rowIndex++;
    }

    if (dataArr.length > 0) {
      modelsApi
        .updateManyAttributeCells(match?.params?.modelUuid, dataArr)
        .then((response) => {
          console.log(response?.status);
          if (response?.statusText === "OK") {
            setRefresh((refresh) => !refresh);
          }
        })
        .catch((error) => console.log(error));
    }
  }

  function handleAutoFill(
    start: any,
    end: any,
    data: Array<Array<string | number>>
  ) {
    const colIndex = start?.col;
    const startRow = start?.row;
    const endRow = end?.row;
    const value = data[0][0];

    let dataArr = [];
    let rowIndex = 0;
    for (let i = startRow; i <= endRow; i++) {
      dataArr.push({
        uuid: null,
        kind: ATTRIBUTE,
        properties: {},
      });

      if (
        tableHeaders[colIndex]?.type !== "link" &&
        tableHeaders[colIndex]?.type !== "split"
      ) {
        if (
          tableRows?.labels[i] &&
          tableRows?.labels[i].uuid &&
          tableRows?.labels[i].id
        ) {
          dataArr[rowIndex].uuid = tableRows?.labels[i].attributeUuid;
          Object.assign(dataArr[rowIndex].properties, {
            [tableHeaders[colIndex].key]: value,
          });
        } else {
          Object.assign(dataArr[rowIndex].properties, {
            [tableHeaders[colIndex].key]: value,
          });
        }
      }

      rowIndex++;
    }

    if (dataArr.length > 0) {
      modelsApi
        .updateManyAttributeCells(match?.params?.modelUuid, dataArr)
        .then((response) => {
          console.log(response?.status);
          if (response?.statusText === "OK") {
            setRefresh((refresh) => !refresh);
          }
        })
        .catch((error) => console.log(error));
    }
  }

  return (
    <DashBoardConstant match={match} modelInfo={modelInfo} hideToolbar>
      <ClientsList className="projectdata-table">
        {!ATTRIBUTE ? (
          <CardsWrap className="attr-wrap">
            {attributesSummary.length > 0
              ? attributesSummary.map((attr, i: number) => {
                  return (
                    <AttributesCard
                      key={i}
                      kind={attr.kind}
                      createdAt={attr.createdAt}
                      total={attr.total}
                    />
                  );
                })
              : null}
          </CardsWrap>
        ) : (
          <>
            <ClientsTools className="project-data-tools">
              <h2>{ATTRIBUTE}</h2>
              <form onSubmit={(e: any) => doSearch(e)}>
                <InputGroup
                  className="project-data-search"
                  value={searchValue}
                  onChange={(e: any) => setSearchValue(e.target.value)}
                  type="search"
                />
              </form>

              <ButtonGroup style={{ minWidth: 120 }}>
                <Popover
                  position={Position.BOTTOM_LEFT}
                  minimal
                  usePortal={false}
                >
                  <Button
                    icon={<Icons.Filter size={18} />}
                    text="Filter"
                    minimal
                    style={{
                      backgroundColor:
                        filterColumn || filterOp ? "#4a90e2" : "inherit",
                      color: filterColumn || filterOp ? "#fff" : "inherit",
                    }}
                    className="filter-btn"
                  />

                  <ButtonGroup
                    style={{ minWidth: 120 }}
                    className="filter-wrap"
                  >
                    {filterColumn || filterOp ? (
                      <Button
                        icon={<Icons.Close size={12} />}
                        minimal
                        onClick={resetFilterHandler}
                      />
                    ) : null}

                    <Popover position={Position.BOTTOM_LEFT}>
                      <Button
                        text={filterColumn ? filterColumn?.name : "Column:"}
                        minimal
                      />

                      <Menu>
                        {tableHeaders.length > 0 &&
                          tableHeaders.map((col: any) => (
                            <MenuItem
                              text={col.name}
                              key={col.key}
                              onClick={() => selectColumnHandler(col)}
                              active={filterColumn?.key === col.key}
                              shouldDismissPopover={false}
                            />
                          ))}
                      </Menu>
                    </Popover>

                    <Popover position={Position.BOTTOM_LEFT}>
                      <Button
                        text={filterOp ? filterOp?.name : "Filter by:"}
                        minimal
                      />
                      <Menu>
                        <MenuItem
                          text="contains"
                          onClick={() =>
                            selectOpHandler("contains", "contains")
                          }
                          active={filterOp?.key === "contains"}
                          shouldDismissPopover={false}
                        />
                        <MenuItem
                          text="does not contain"
                          onClick={() =>
                            selectOpHandler("!contains", "does not contain")
                          }
                          active={filterOp?.key === "!contains"}
                          shouldDismissPopover={false}
                        />
                        <MenuItem
                          text="is empty"
                          onClick={() => selectOpHandler("is", "is empty")}
                          active={filterOp?.key === "is"}
                          shouldDismissPopover={false}
                        />
                        <MenuItem
                          text="is not empty"
                          onClick={() => selectOpHandler("!is", "is not empty")}
                          active={filterOp?.key === "!is"}
                          shouldDismissPopover={false}
                        />
                      </Menu>
                    </Popover>

                    <InputGroup
                      small
                      placeholder="Start typing"
                      className="filter-search"
                      value={filterSearch}
                      onChange={(e: any) => setFilterSearch(e.target.value)}
                    />
                    {filterColumn || filterOp ? (
                      <Button
                        icon={<Icons.Check size={21} />}
                        minimal
                        onClick={doFilter}
                      />
                    ) : null}
                  </ButtonGroup>
                </Popover>
                <Popover
                  position={Position.BOTTOM_LEFT}
                  minimal
                  usePortal={false}
                >
                  <Button
                    icon={<Icons.Sort size={18} />}
                    text="Sort"
                    minimal
                    className="sort-btn"
                    style={{
                      backgroundColor:
                        sortColumn || sortOp ? "#4a90e2" : "inherit",
                      color: sortColumn || sortOp ? "#fff" : "inherit",
                    }}
                  />
                  <ButtonGroup
                    style={{ minWidth: 120 }}
                    className="filter-wrap"
                  >
                    {sortColumn || sortOp ? (
                      <Icons.Close
                        size={12}
                        onClick={resetSortHandler}
                        className="filter-reset"
                      />
                    ) : null}

                    <Popover position={Position.BOTTOM_LEFT}>
                      <Button
                        text={sortColumn?.name ? sortColumn?.name : "Column:"}
                        minimal
                      />

                      <Menu>
                        {tableHeaders.length > 0 &&
                          tableHeaders.map((col: any) => (
                            <MenuItem
                              text={col.name}
                              key={col.key}
                              onClick={() => selectSortColumnHandler(col)}
                              active={sortColumn?.key === col.key}
                              shouldDismissPopover={false}
                            />
                          ))}
                      </Menu>
                    </Popover>

                    <Popover position={Position.BOTTOM_LEFT}>
                      <Button
                        text={sortOp ? sortOp?.name : "Sort by:"}
                        minimal
                      />
                      <Menu>
                        {sortColumn?.type === "number" ? (
                          <>
                            <MenuItem
                              text="Lowest to Highest"
                              onClick={() =>
                                selectSortOpHandler("asc", "Lowest to Highest")
                              }
                              active={sortOp?.key === "asc"}
                              shouldDismissPopover={false}
                            />
                            <MenuItem
                              text="Highest to Lowest"
                              onClick={() =>
                                selectSortOpHandler("desc", "Highest to Lowest")
                              }
                              active={sortOp?.key === "desc"}
                              shouldDismissPopover={false}
                            />
                          </>
                        ) : (
                          <>
                            <MenuItem
                              text="A to Z"
                              onClick={() =>
                                selectSortOpHandler("asc", "A to Z")
                              }
                              active={sortOp?.key === "asc"}
                              shouldDismissPopover={false}
                            />
                            <MenuItem
                              text="Z to A"
                              onClick={() =>
                                selectSortOpHandler("desc", "Z to A")
                              }
                              active={sortOp?.key === "desc"}
                              shouldDismissPopover={false}
                            />
                          </>
                        )}
                      </Menu>
                    </Popover>

                    {sortColumn && sortOp ? (
                      <Button
                        icon={<Icons.Check size={21} />}
                        minimal
                        onClick={doSort}
                      />
                    ) : null}
                  </ButtonGroup>
                </Popover>
              </ButtonGroup>

              <Button className="edit-data">Edit</Button>
              <Pagination
                handlePageChange={handlePageChange}
                totalPages={totalPages}
              />
            </ClientsTools>

            {tableHeaders && (
              <DataWrap className="data-with-table">
                <AutoSizer>
                  {({ height, width }) => (
                    <HotTable
                      ref={hot}
                      className="data-table"
                      columns={tableColumns}
                      data={tableRows?.properties}
                      colHeaders={(index: any) => {
                        return tableHeaders[index]?.name;
                      }}
                      rowHeaders={true}
                      width={width}
                      licenseKey="non-commercial-and-evaluation"
                      stretchH="all"
                      contextMenu={[
                        "remove_row",
                        "alignment",
                        "cut",
                        "copy",
                        "row_above",
                        "row_below",
                      ]}
                      minSpareRows={500}
                      maxRows={500}
                      manualColumnResize
                      height={height}
                      afterChange={(changes, source) =>
                        handleCellEdit(changes, source)
                      }
                      beforeRemoveRow={(index, ammount, physicalRow, source) =>
                        handleRowRemove(index, ammount, physicalRow, source)
                      }
                      rowHeights={28}
                      afterPaste={(data: any, coords: any) =>
                        handlePaste(data, coords)
                      }
                      fillHandle={{
                        direction: "vertical",
                        autoInsertRow: false,
                      }}
                      afterAutofill={(start, end, data) =>
                        handleAutoFill(start, end, data)
                      }
                    />
                  )}
                </AutoSizer>
              </DataWrap>
            )}
            {displayAttributeModel?.display === true &&
              match.params?.modelUuid && (
                <AttributeModel
                  modelUuid={match.params?.modelUuid}
                  uuid={displayAttributeModel?.uuid}
                  isOpen={displayAttributeModel?.display}
                  defaultSelectedTabId={
                    displayAttributeModel?.defaultSelectedTabId
                  }
                  allocation={displayAttributeModel?.allocation}
                  setDisplayAttributeModel={setDisplayAttributeModel}
                  columnNames={tableHeaders}
                />
              )}
          </>
        )}
      </ClientsList>
    </DashBoardConstant>
  );
};

export default View;
