import styled from "styled-components";

export const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid #d4d4d4;
  padding: 24px 16px;
  height: auto;
  min-height: 15px;
  line-height: 1;
  font-size: 16px;

  h3 {
    margin: 0;
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 500;
  }

  & > div {
    display: flex;
    flex-direction: column;
    text-align: center;
    padding: 21px 0;
  }

  .total-num {
    font-size: 36px;
    font-weight: bold;
    line-height: 1.625;
    letter-spacing: 0;
  }

  .total-text {
    font-weight: 500;
    line-height: 1.35;
    letter-spacing: 0.025em;
  }

  .total-model {
    color: #9c9c9c;
  }
`;
