import React from "react";
import { ComponentsMenuWrap } from "./styles";
import { ComponentNames } from "../../../../../store/referencePage/reference/commonTypes";
import { NewComponentsProps } from "./types";
import MenuComponent from "./MenuComponent";

const nestingLevelsComponentMap = [[0, 1, 2], [1, 2], [2, 3], [3], []];

export const ComponentsMenu: React.FC<NewComponentsProps> = ({
  state,
  editable,
  nestingLevel,
}) => {
  const getMenuComponents = () => {
    nestingLevel = Math.max(0, Math.min(4, nestingLevel));
    return nestingLevelsComponentMap[nestingLevel].map((compIndex) => (
      <MenuComponent
        type={ComponentNames[compIndex]}
        state={state}
        key={compIndex}
      />
    ));
  };
  return (
    <ComponentsMenuWrap editable={editable}>
      {getMenuComponents()}
    </ComponentsMenuWrap>
  );
};

export default ComponentsMenu;
