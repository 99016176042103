import React, { useEffect, useState } from "react";
import useAuth from "../../../auth/context/useAuth";
import { RouteComponentProps } from "react-router";
import {
  Button,
  Input,
  SimpleModal,
  TextArea,
  AppToaster,
} from "../../../components";
import {
  ListWrap,
  ListContent,
  ClientsTopbar,
  TopbarLeft,
} from "../../../clientspaces/components/CsListView";
import {
  SettingsHeader,
  DetailsWrap,
  DetailsHeader,
  DetailsValue,
  SettingsWrap,
  SettingsView,
  SettingsSubHeader,
} from "../../components/ProjectsView";
import UserProfile from "../../../components/UserProfile";
import ClientSwitcher from "../../../components/ClientSwitcher";
import dayjs from "dayjs";
import useProjectsApi from "../../api/useProjectsApi";
import { Project } from "../../api/model";
import ProjectsNav from "../../components/ProjectsNav";

type ViewProps = RouteComponentProps<{
  project: string;
}>;

export const View: React.FC<ViewProps> = ({ match, history }) => {
  const auth = useAuth();
  const projectsApi = useProjectsApi();

  const [projectName, setProjectName] = useState("");
  const [isAdmin, setIsAdmin] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [activeProject, setActiveProject] = useState<Project>();
  const [projectDesc, setProjectDesc] = useState("");
  const [permission, setPermission] = useState("");

  function doDelete() {
    setDeleteModal((deleteModal) => !deleteModal);

    projectsApi
      .deleteProject(match?.params?.project)
      .then((response) => {
        if (response.statusText === "OK") {
          AppToaster.show({
            message: `${activeProject?.name} was successfully deleted!`,
            intent: "success",
          });

          setTimeout(() => {
            history.push(`/${activeProject?.clientspace.uuid}`);
          }, 200);
        }
      })
      .catch((error) => {
        AppToaster.show({
          message: "Something went wrong, please try again!",
          intent: "danger",
        });

        console.log(error);
      });
  }

  function saveChanges() {
    projectsApi
      .updateProject(match?.params?.project, {
        name: projectName,
        description:
          projectDesc.length > 0 ? projectDesc : activeProject?.description,
        inviteLevel: permission,
      })
      .then((response) => {
        if (response.statusText === "OK") {
          AppToaster.show({
            message: `${activeProject?.name} was successfully updated!`,
            intent: "success",
          });

          setTimeout(() => {
            history.push(`/projects/${match?.params?.project}`);
          }, 200);
        }
      })
      .catch((error) => {
        AppToaster.show({
          message: "Something went wrong, please try again!",
          intent: "danger",
        });

        console.log(error);
      });
  }

  useEffect(() => {
    projectsApi
      .getProject(match?.params?.project)
      .then((response) => {
        response.data.createdAt = dayjs(response.data.createdAt).format(
          "DD MMMM YYYY"
        );

        setActiveProject(response?.data);
        if (!response.data.users) return;
        let test: any = {};
        test = response.data.users?.filter(
          (u: any) => u.id === auth?.profile?.id
        );
        if (test[0]?.role === "Owner") setIsAdmin(true);

        setProjectName(response?.data?.name);

        if (!response.data.settings?.inviteLevel) return;
        setPermission(response?.data?.settings?.inviteLevel);
      })
      .catch((error) => console.log(error));
  }, [projectsApi, match, auth]);

  return (
    <ListWrap>
      <ProjectsNav match={match} active="project-settings" />

      <ListContent>
        <ClientsTopbar>
          <ClientSwitcher
            clientspaceUuid={activeProject?.clientspace?.uuid}
            projectData={activeProject}
          />

          <TopbarLeft>
            {auth.profile && <UserProfile profile={auth.profile} />}
          </TopbarLeft>
        </ClientsTopbar>
        <SettingsView>
          <SettingsWrap>
            <SettingsHeader>Project name</SettingsHeader>
            <Input
              readonly={!isAdmin}
              w="420px"
              id="input-projectName"
              type="text"
              value={projectName}
              onChange={(e: any) => {
                setProjectName(e.target.value);
              }}
            />
            <SettingsHeader>Project description</SettingsHeader>
            <TextArea
              readonly={!isAdmin}
              placeholder={activeProject?.description}
              id="project-description"
              value={projectDesc}
              onChange={(e: any) => setProjectDesc(e.target.value)}
              maxlength="300"
            />
          </SettingsWrap>

          <SettingsWrap>
            <SettingsHeader>Details</SettingsHeader>
            <DetailsWrap>
              <DetailsHeader>Created by</DetailsHeader>
              <DetailsValue>{activeProject?.createdBy.name}</DetailsValue>
            </DetailsWrap>
            <DetailsWrap>
              <DetailsHeader>Date created</DetailsHeader>
              <DetailsValue>{activeProject?.createdAt}</DetailsValue>
            </DetailsWrap>
            <DetailsWrap>
              <DetailsHeader>Models</DetailsHeader>
              <DetailsValue>{activeProject?.modelCount}</DetailsValue>
            </DetailsWrap>
            <DetailsWrap>
              <DetailsHeader>Total members</DetailsHeader>
              <DetailsValue>{activeProject?.userCount}</DetailsValue>
            </DetailsWrap>
          </SettingsWrap>

          <SettingsWrap>
            <SettingsHeader>Permissions</SettingsHeader>
            <SettingsSubHeader>Invitation permission</SettingsSubHeader>
            <p>
              Set up who can invite users to the {activeProject?.name} project.
            </p>

            <div className="settings-permissions">
              <label>
                <input
                  checked={permission === "Admin" ? true : false}
                  type="radio"
                  value={permission}
                  onChange={() => setPermission("Admin")}
                  name="permission"
                />
                Only project admins
              </label>

              <label>
                <input
                  checked={permission === "User" ? true : false}
                  type="radio"
                  value={permission}
                  onChange={() => setPermission("User")}
                  name="permission"
                />
                All team members
              </label>
            </div>
          </SettingsWrap>
          {isAdmin && (
            <div className="settings-btn-wrap">
              <Button
                type="button"
                w="100px"
                className="settings-delete"
                onClick={() => setDeleteModal((deleteModal) => !deleteModal)}
                bgcolor="#d0021b"
              >
                Delete
              </Button>
              <Button
                type="button"
                w="100px"
                className="settings-update"
                onClick={saveChanges}
              >
                Update
              </Button>
            </div>
          )}
        </SettingsView>
      </ListContent>
      {deleteModal && (
        <SimpleModal
          title="Delete project"
          closeIcon={true}
          closeHandler={() => setDeleteModal((deleteModal) => !deleteModal)}
          closeText="Cancel"
          acceptHandler={doDelete}
          acceptText="Delete"
          text={`Are you sure you want to delete the ${activeProject?.name}? All models will be permanently deleted and team members informed.`}
        />
      )}
    </ListWrap>
  );
};
export default View;
