import React from "react";
import styled from "styled-components";
import MemberRow from "./MemberRow";

const StyledTable = styled.table`
  width: 100%;
  text-align: left;
  border-collapse: collapse;

  tbody {
    height: 68vh;
    overflow-y: auto;
    display: block;
  }

  thead > tr {
    display: grid;
    grid-template-columns:
      minmax(auto, 210px)
      minmax(auto, 310px)
      minmax(90px, 1fr);
  }

  .members-row {
    width: 100%;
    display: grid;
    grid-template-columns:
      minmax(auto, 210px)
      minmax(auto, 310px)
      minmax(90px, 1fr);
    word-break: break-all;
  }

  th {
    padding: 32px 21px 0;
    text-transform: uppercase;
    color: #989898;
    font-size: 16px;
  }

  .members-row:nth-child(even) {
    background-color: #f9fafc;
  }

  .members-row > td {
    padding: 38px 21px;
    font-weight: 500;
  }

  .member-role {
    text-transform: uppercase;
  }

  .member-role-dropdown {
    display: inline-flex;
    bottom: 0;
    width: 120px;
  }

  .member-remove {
    color: #d0021b;
    margin: 0;
    text-decoration: none;
  }

  .member-email {
    text-align: left;
    display: inline-flex;
    flex-direction: column;
    align-items: flex-start;
  }
`;

const Table = (props: any) => {
  const { users, admin, projectID, refresh } = props;

  return (
    <StyledTable className="members-table">
      <thead>
        <tr>
          <th>Name</th>
          <th>Email</th>
          <th>Role</th>
        </tr>
      </thead>
      <tbody>
        {users.map((user: any, i: number) => (
          <MemberRow
            name={user.name}
            userID={user.id}
            email={user.email}
            projects={user.projects}
            role={user.role}
            key={i}
            admin={admin}
            projectID={projectID}
            refresh={refresh}
          />
        ))}
      </tbody>
    </StyledTable>
  );
};
export default Table;
