import React, { useState, useCallback, useLayoutEffect, useRef } from "react";
import {
  ToolbarOptionView,
  ColorContainer,
  ColorOptionContent,
  PickerWrap,
} from "./styles";
import { ColorOptionProps } from "./types";
import { GithubPicker, ColorChangeHandler, ColorResult } from "react-color";
import { Tooltip, Position } from "@blueprintjs/core";

const View: React.FC<ColorOptionProps> = (Props) => {
  const [checked, setChecked] = useState(false);
  const node = useRef<HTMLDivElement>(null);

  const handleChangeComplete: ColorChangeHandler = (color: ColorResult) => {
    Props.onchange(color.hex);
    setChecked(false);
  };
  const onMouseUp = (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
    event.preventDefault();
    setChecked(!checked);
  };
  const preventDefault = useCallback(
    (event: React.MouseEvent<HTMLDivElement, MouseEvent>) => {
      event.preventDefault();
    },
    []
  );

  const clickOutside = (e: any) => {
    // inside click
    if (node?.current?.contains(e.target)) return;
    // outside click
    setChecked(false);
  };

  useLayoutEffect(() => {
    if (checked) {
      document.addEventListener("mousedown", clickOutside);
    } else {
      document.removeEventListener("mousedown", clickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", clickOutside);
    };
  }, [checked]);

  return (
    <ToolbarOptionView checked={false} ref={node}>
      <ColorContainer>
        <ColorOptionContent
          onClick={onMouseUp}
          style={{
            color: Props.color,
            fill: Props.color,
            backgroundColor:
              Props.color === "#ffffff" ? "lightgray" : "inherit",
          }}
        >
          <Tooltip
            content={Props.tooltipText}
            position={Position.BOTTOM}
            className="color-option-tooltip"
          >
            {Props.children}
          </Tooltip>
        </ColorOptionContent>
        {checked && (
          <PickerWrap onClick={preventDefault}>
            <GithubPicker
              colors={[
                "#b80000",
                "#db3e00",
                "#fccb00",
                "#008b02",
                "#006b76",
                "#1273de",
                "#004dcf",
                "#5300eb",
                "#eb9694",
                "#fad0c3",
                "#fef3bd",
                "#c1e1c5",
                "#bedadc",
                "#c4def6",
                "#bed3f3",
                "#d4c4fb",
                "#000000",
                "#4a4a4a",
                "#9b9b9b",
                "#ffffff",
                "#f59d23",
                "#ef5d87",
                "#5def90",
                "#e10606",
              ]}
              color={Props.color}
              onChangeComplete={handleChangeComplete}
            />
          </PickerWrap>
        )}
      </ColorContainer>
    </ToolbarOptionView>
  );
};

export default View;
