import React, { useState } from "react";
import useSignup from "../../context/useSignup";
import {
  AuthWrap,
  Form,
  NameGrid,
  StepDiv,
  StepWrap,
  Error,
} from "../../components/AuthForms";
import { Button } from "../../../components";
import { ReactComponent as Logo } from "../../../assets/logo.svg";
import AuthFooter from "../../components/AuthFooter";
import { Icons } from "../../../assets/Icons";
import { Card, InputGroup, FormGroup, Elevation } from "@blueprintjs/core";

export const View: React.FC = () => {
  const [email, setEmail] = useState("");
  const [password, setPassword] = useState("");
  const [firstName, setFirstName] = useState("");
  const [lastName, setLastName] = useState("");

  const [showPassword, setShowPassword] = useState(false);

  const [errors, setErrors] = useState<{ [key: string]: any }>();

  const signup = useSignup();

  function doSignup(e: any) {
    e.preventDefault(); // don't refresh page on form submit

    return signup({ email, password, firstName, lastName }).catch((error) => {
      setErrors(error.response.data);
    });
  }

  return (
    <AuthWrap>
      <Logo className="opteq-logo" />

      <Card className="auth-card" elevation={Elevation.THREE}>
        <Icons.SecurityLock className="card-icon" size={21} />

        <StepDiv>
          <StepWrap className="active">
            <i>1</i>
            <span>Create an account</span>
          </StepWrap>

          <StepWrap>
            <i>2</i>
            <span>Setup profile</span>
          </StepWrap>
        </StepDiv>

        <Form onSubmit={doSignup}>
          <NameGrid>
            <FormGroup
              label="First name"
              labelFor="input-firstName"
              className="auth-label"
            >
              <InputGroup
                id="input-firstName"
                type="text"
                value={firstName}
                onChange={(e: any) => {
                  setFirstName(e.target.value);
                }}
                className="auth-input"
                large
                fill
              />
            </FormGroup>

            <FormGroup
              label="Last name"
              labelFor="input-lastName"
              className="auth-label"
            >
              <InputGroup
                id="input-lastName"
                type="text"
                value={lastName}
                onChange={(e: any) => {
                  setLastName(e.target.value);
                }}
                className="auth-input"
                large
                fill
              />
            </FormGroup>
          </NameGrid>

          <FormGroup
            label="Email address"
            labelFor="input-email"
            className="auth-label"
            helperText={
              errors &&
              errors?.errors?.email && <Error>{errors.errors.email}</Error>
            }
          >
            <InputGroup
              id="input-email"
              type="email"
              value={email}
              onChange={(e: any) => {
                setEmail(e.target.value);
              }}
              className="auth-input"
              large
              fill
            />
          </FormGroup>
          <FormGroup
            label="Password"
            labelFor="input-password"
            className="auth-label"
            helperText={
              errors &&
              errors?.errors?.password.map((error: any) => (
                <Error>{error}</Error>
              ))
            }
          >
            <InputGroup
              id="input-password"
              type={showPassword ? "text" : "password"}
              value={password}
              onChange={(e: any) => {
                setPassword(e.target.value);
              }}
              className="auth-input"
              large
              fill
              rightElement={
                <Icons.Eye
                  style={{ display: password ? "flex" : "none" }}
                  className="password-toggle"
                  onClick={() => {
                    setShowPassword(!showPassword);
                  }}
                />
              }
            />
          </FormGroup>

          <Button
            onClick={doSignup}
            disabled={!email || !password || !firstName || !lastName}
            type="submit"
          >
            Save and continue
          </Button>
        </Form>
      </Card>

      <AuthFooter />
    </AuthWrap>
  );
};

export default View;
