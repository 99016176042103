import styled from "styled-components";
import { ComponentStyle } from "../../../store/referencePage/reference/commonTypes";

export const DragReferenceWrap = styled.div`
  user-select: none;
  flex-grow: 1;
`;

export const ComponentDragReferenceWrap = styled.div`
  display: grid;
  padding: 0 5px 0 5px;
  grid-template-columns: repeat(2, 1fr);
  grid-auto-rows: min-content;
  grid-column-gap: 5px;
  grid-row-gap: 5px;
  user-select: none;
`;

export const DragReferenceContent = styled.div<{ isOver?: boolean }>`
  padding: 0 32px;
  overflow: auto;
  background: ${(props) =>
    props.isOver
      ? `background: repeating-linear-gradient(
    -55deg,
    #4a90e2,
    #4a90e2 5px,
    #fff 5px,
    #fff 10px
  );`
      : "none"};
`;

export const DataWrap = styled.div`
  display: grid;
  grid-template-columns: 1fr;
  padding-bottom: 32px;

  .table-no-data {
    text-align: center;
    font-size: 24px;
    color: #6d7278;
    font-weight: 500;
  }

  &.table-wrap {
    display: flex;
    margin: 0 16px;
    flex-direction: column;
    padding-bottom: 32px;
    margin-top: 86px;

    .table-pagination {
      margin-left: auto;
      margin-bottom: 6px;
    }
  }
`;

export const TopText = styled.div`
  margin: 21px 8px;
  padding: 0 8px;

  &.two-col {
    display: flex;
    align-items: center;
  }

  .back-btn {
    color: inherit;
    margin-bottom: 18px;
    font-size: 16px;
    font-weight: 500;
    margin-left: -12px;
    display: block;

    &:hover {
      text-decoration: underline;
    }
  }

  .component-name {
    margin: 16px 0;

    & > input {
      font-size: 32px !important;
      font-weight: bold;
      padding: 0 !important;
      box-shadow: none !important;
      outline: none !important;
    }
  }

  & > div > h1 {
    margin: 0;
    font-size: 32px;
  }

  & > div > h2 {
    margin: 0;
    font-size: 28px;
    font-weight: 500;
    color: #6d7278;
  }

  .desc-area {
    border: none;
    box-shadow: none;
    resize: none;
    padding: 0;
    width: 100%;
    outline: none;
  }

  .editmode-btn-wrap {
    margin-left: auto;
    margin-top: 24px;
    align-self: baseline;
    display: flex;
    flex-direction: column;
    align-items: flex-end;

    & > div {
      margin-top: 24px;
      display: flex;
      flex-direction: column;
    }

    .editmode-btn {
      width: 82px;
      box-shadow: none !important;
      color: #fff;
    }

    .editoption-btn {
      min-width: 94px;
      background: #4a90e2;
      color: #fff;
      box-shadow: none !important;

      &:first-of-type {
        margin-bottom: 6px;
      }
    }
  }
`;

export const ReferenceBreadcrumb = styled.ul`
  display: flex;
  flex-wrap: wrap;
  list-style: none;
  color: inherit;
  overflow: hidden;
  word-break: break-all;
  padding: 0;
  margin: 0;
  margin-bottom: 16px;

  a {
    color: inherit;

    &:hover {
      text-decoration: underline;
    }
  }

  .breadcrumb-sep {
    padding: 0 4px;
  }
`;

export const ApplyStyles = styled.div.attrs<ApplyStylesProps>((props) => {
  if (!props.styles) return;
  return {
    style: {
      color: props.styles.textColor,
      backgroundColor: props.styles.backgroundColor,
      border: `2px solid ${props.styles.borderColor}`,
      textDecoration: props.styles.underlined ? "underline" : "initial",
      fontWeight: props.styles.bold ? "bold" : "300",
      fontStyle: props.styles.italic ? "italic" : "initial",
    },
  };
})<ApplyStylesProps>`
  &:hover {
    -webkit-filter: brightness(90%);
  }
  ${(props) => props.selected && `box-shadow: inset 0 0 10px #000000;`}
`;

export interface ApplyStylesProps {
  styles?: ComponentStyle;
  selected: boolean;
}

export const AttrReferenceContent = styled.div`
  width: 100%;
  max-width: 100%;
  overflow: hidden;
  display: flex;
  flex-direction: column;
`;
