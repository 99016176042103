import * as React from "react";
import { useEffect, useState } from "react";
import { RouteComponentProps } from "react-router";
import {
  DashboardContent,
  ContentText,
  ContentCards,
  ModelInfo,
  OwnerInfo,
  BtnWrap,
  CardsWrap,
} from "../../components/DashboardView";
import { Button, TextArea, AppToaster } from "../../../components";
import useAuth from "../../../auth/context/useAuth";
import useModelsApi from "../../api/useModelsApi";
import { Model, Principle, User } from "../../api/model";
import NewPrinciple from "./NewPrinciple";
import PrincipleCard from "../../components/PrincipleCard";
import DashBoardConstant from "../Reference/DashBoardConstant";

type ViewProps = RouteComponentProps<{
  modelUuid: string;
  projectId?: string;
}>;

export const View: React.FC<ViewProps> = ({ match, location, history }) => {
  const auth = useAuth();
  const modelsApi = useModelsApi();

  const [modelInfo, setModelInfo] = useState<Model>();
  const [owner, setOwner] = useState<User>();
  const [isAdmin, setIsAdmin] = useState(false);
  const [editDesc, setEditDesc] = useState(false);
  const [modelDesc, setModelDesc] = useState("");
  const [refresh, setRefresh] = useState(false);

  const [newPrincipleModal, setNewPrincipleModal] = useState(false);

  const [principleList, setPrincipleList] = useState<Array<Principle>>([]);

  const params = new URLSearchParams(location?.search);
  const attribute = params.get("attr");

  useEffect(() => {
    modelsApi.getModel(match?.params.modelUuid).then((response) => {
      // find an owner of this model, save user object in a state
      setOwner(
        response?.data.users.filter((user: any) => user.role === "Owner")[0]
      );

      let userObj: any = {};
      userObj = response?.data.users.filter(
        (u: any) => u.id === auth?.profile?.id
      );
      if (userObj[0]?.role === "Owner" || userObj[0]?.role === "Admin") {
        setIsAdmin(true);
      }

      setModelDesc(response?.data.description);
      setModelInfo(response?.data);
    });
  }, [modelsApi, match, auth, refresh]);

  useEffect(() => {
    if (!attribute) {
      modelsApi.getPrinciples(match?.params.modelUuid).then((response) => {
        setPrincipleList(response?.data);
      });
    }
  }, [modelsApi, match, refresh, attribute]);

  function doEditDesc(e: any) {
    const description = e;
    if (description === modelInfo?.description) {
      return null;
    }

    if (description.length <= 0) {
      return null;
    }

    modelsApi
      .updateModel(match?.params.modelUuid, description)
      .then((response) => {
        if (response.statusText === "OK") {
          AppToaster.show({
            message: "Description was successfully updated!",
            intent: "success",
          });

          setRefresh((refresh) => !refresh);
          setEditDesc(false);
        }
      })
      .catch((error) => {
        AppToaster.show({
          message: "Something went wrong, please try again!",
          intent: "danger",
        });

        console.log(error);
      });
  }

  function handleDescriptionChange(e: any) {
    // don't make a new-line in a textarea when user clicks `ENTER` button
    // but make an API call to update the description
    // to make new-line create ENTER with SHIFT
    if (e.keyCode === 13 && e.shiftKey === false) {
      e.preventDefault();
      doEditDesc(e.target.value);
    }
  }

  return (
    <DashBoardConstant match={match} modelInfo={modelInfo} hideToolbar>
      <DashboardContent>
        <ContentText>
          <ModelInfo>
            <label>Description</label>
            <TextArea
              readonly={!isAdmin || !editDesc}
              placeholder={
                modelInfo?.description
                  ? modelInfo?.description
                  : "Add description..."
              }
              id="model-description"
              value={modelDesc}
              onChange={(e: any) => setModelDesc(e.target.value)}
              maxlength="300"
              onClick={() => setEditDesc(true)}
              onBlur={(e: any) => doEditDesc(e.target.value)}
              onKeyDown={handleDescriptionChange}
            />
          </ModelInfo>

          <OwnerInfo>
            <h3>Model Owner</h3>
            <div className="owner-card">
              {owner?.name && (
                <img
                  src={
                    "https://eu.ui-avatars.com/api/?background=0f1a28&color=fff&name=" +
                    owner?.name +
                    `&rounded=true&size=24&bold=true`
                  }
                  alt=""
                  className="user-img"
                />
              )}
              <span>{owner?.name}</span>
            </div>
          </OwnerInfo>
        </ContentText>
        <ContentCards>
          <BtnWrap>
            <div>
              <h3>Design Principles</h3>
              <Button
                type="button"
                className="dashboard-btn"
                w="85px"
                bgcolor="#061d38"
                onClick={() => setNewPrincipleModal(true)}
              >
                Add
              </Button>
            </div>
          </BtnWrap>
          <CardsWrap>
            <div className="principles-cards">
              {principleList.length > 0 &&
                principleList.map((dp: any, i: number) => {
                  return (
                    <PrincipleCard
                      name={dp.properties.text}
                      createdAt={dp.createdAt}
                      met={dp.properties.met}
                      principleID={dp.id}
                      modelUuid={match?.params.modelUuid}
                      key={i}
                      number={i + 1}
                      refresh={() => setRefresh((refresh) => !refresh)}
                    />
                  );
                })}
            </div>
          </CardsWrap>
        </ContentCards>
      </DashboardContent>

      {newPrincipleModal && (
        <NewPrinciple
          onClose={() => setNewPrincipleModal(false)}
          refresh={() => setRefresh((refresh) => !refresh)}
          isOpen={newPrincipleModal}
          modelID={match?.params?.modelUuid}
        />
      )}
    </DashBoardConstant>
  );
};

export default View;
