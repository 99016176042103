import { State } from "../../../models/views/DraggableReference/stateManager";

export const SET_STATE = "SET_STATE";
export const CLEAR_DIRTY = "CLEAR_DIRTY";
export const ADD_COMPONENT = "ADD_COMPONENT";
export const SET_SELECTED = "SET_SELECTED";
export const SET_SELECTED_STYLES = "SET_SELECTED_STYLES";
export const SET_GHOST = "SET_GHOST";
export const REMOVE_GHOST = "REMOVE_GHOST";
export const OPEN_CONFIRM_DELETE = "OPEN_CONFIRM_DELETE";
export const CLOSE_CONFIRM_DELETE = "CLOSE_CONFIRM_DELETE";
export const SET_CONFIRM_DELETE = "SET_CONFIRM_DELETE";

export interface AddBoxAction {
  type: typeof ADD_COMPONENT;
  rowId: string;
  pos: number;
}

export interface SetState {
  type: typeof SET_STATE;
  state: State;
}

export interface ClearDirty {
  type: typeof CLEAR_DIRTY;
}

export interface ConfirmDeleteState {
  components: Array<string>;
  associations: Array<string>;
  open: boolean;
  ids: Array<string>;
  nestingLevel: number;
}

export interface SetConfirmDeleteState {
  type: typeof SET_CONFIRM_DELETE;
  state: ConfirmDeleteState;
}

export interface CloseConfirmDelete {
  type: typeof CLOSE_CONFIRM_DELETE;
}

export interface OpenConfirmDelete {
  type: typeof OPEN_CONFIRM_DELETE;
}

export type ConfirmDeleteAction =
  | SetConfirmDeleteState
  | CloseConfirmDelete
  | OpenConfirmDelete;
export type StateAction = AddBoxAction | SetState | ClearDirty;
