export const dashboardUrl = `/models/:modelUuid`;
export const pyramidUrl = `/models/:modelUuid/pyramid`;
export const referenceUrl = `/models/:modelUuid/reference`;
export const draggableReferenceUrl = `/models/:modelUuid/components/:childId`;
export const dashboardAttributesUrl = `/models/:modelUuid/attributes`;
export const modelInsightsUrl = `/models/:modelUuid/insights`;
// export const modelGeoUrl = `/models/:modelUuid/geo`;
export const modelNetworkUrl = `/models/:modelUuid/network`;
export const modelChartsUrl = `/models/:modelUuid/charts`;
export const modelSatsuiUrl = `/models/:modelUuid/satsui`;
