export const ItemTypes = {
  ITEM: "ITEM",
  POS: "POS",
  NEW_ITEM: "NEW_ITEM",
  NEW_POS: "NEW_POS",

  TEXT_LABEL: "TEXT_LABEL",
  NEW_TEXT_LABEL: "NEW_TEXT_LABEL",

  COAP: "COAP",
};
