import {useContext, useEffect, useMemo, useState} from "react";
import {SchemaAttribute} from "../../../models/api";
import {Loading} from "../types";
import useModelsApi from "../../../models/api/useModelsApi";
import {CacheContext} from "../CacheContext";

export function useSchema(): Loading<SchemaAttribute[]> {
    const modelsApi = useModelsApi()

    const {state, dispatch} = useContext(CacheContext)

    const data = useMemo(
        () => Object.values(state.schemas),
        [state.schemas]
    )

    const [isLoading, setLoading] = useState<boolean>(false)

    function get() {
        setLoading(true)

        modelsApi.getSchemaAttributes()
            .then(response => {
                dispatch({type: 'SET_SCHEMAS', data: response.data})

                setLoading(false)
            })
    }

    useEffect(() => {
        get()
    }, [])

    return useMemo(
        () => ({data, isLoading}), [data, isLoading]
    )
}