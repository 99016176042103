import React, { useState, useCallback } from "react";
import { Tab, Tabs, Button, Classes, Dialog } from "@blueprintjs/core";
import { ConfirmDeleteProps } from "./types";
import { getComponentById } from "../../stateManager";
import { Props } from "./";

export const ConfirmDelete: React.FC<Props & ConfirmDeleteProps> = (props) => {
  const [tabId, setTabId] = useState("components");
  const handleTabChange = useCallback(
    (newTabId: string) => {
      setTabId(newTabId);
    },
    [setTabId]
  );
  const handleDelete = () => {
    props.closeConfirmDelete();
    props.onDelete(props.state.ids, props.state.nestingLevel);
    props.removeChildren(props.state.ids, props.state.nestingLevel);
  };
  const renderTable = (ass: boolean = false) => {
    return (
      <table className="bp3-html-table .modifier bp3-html-table-condensed bp3-html-table-striped bp3-html-table-bordered bp3-interactive">
        <thead>
          <tr>
            <th>Component Name</th>
            <th>Component Type</th>
          </tr>
        </thead>
        <tbody>
          {(ass ? props.state.associations : props.state.components).map(
            (id) => {
              const component = getComponentById(props.reference, id);
              return (
                <tr key={id}>
                  <td>{component?.name}</td>
                  <td>{component?.type}</td>
                </tr>
              );
            }
          )}
        </tbody>
      </table>
    );
  };
  return (
    <Dialog
      icon="info-sign"
      onClose={props.closeConfirmDelete}
      title="Attention"
      isOpen={props.state.open}
    >
      <div className={Classes.DIALOG_BODY}>
        <p>
          <strong>
            The components you are trying to delete contain sub-components and
            associations. Please make sure you know what you are doing.
          </strong>
        </p>
        <Tabs id="TabsExample" onChange={handleTabChange} selectedTabId={tabId}>
          <Tab
            id="components"
            title="Components"
            panel={renderTable()}
            panelClassName="ember-panel"
          />
          <Tab id="ass" title="Associations" panel={renderTable(true)} />
        </Tabs>
      </div>
      <div className={Classes.DIALOG_FOOTER}>
        <div className={Classes.DIALOG_FOOTER_ACTIONS}>
          <Button onClick={props.closeConfirmDelete}>Cancel</Button>
          <Button onClick={handleDelete}>Delete</Button>
        </div>
      </div>
    </Dialog>
  );
};

export default ConfirmDelete;
