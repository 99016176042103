import { connect, ConnectedProps } from "react-redux";
import { StoreType } from "../../../store";
import {
  setState,
  thunkGetModel,
} from "../../../store/referencePage/reference/actions";
import { State } from "../DraggableReference/stateManager";
import { setModelUuid } from "../../../store/referencePage/model/modelAction";
import ModelsApi from "../../api/ModelsApi";
import Reference from "./Reference";

const mapStateToProps = (state: StoreType) => ({
  reference: state.reference,
  editable: state.toolbar.editMode,
  isResizing: state.toolbar.resizingMode,
});

const mapDispatchToProps = {
  setState: (state: State) => setState(state),
  thunkGetModel: () => thunkGetModel(),
  setModelUuid: (uuid: string, modelApi: ModelsApi) =>
    setModelUuid(uuid, modelApi),
};

const connector = connect(mapStateToProps, mapDispatchToProps);

export type Props = ConnectedProps<typeof connector>;

export default connector(Reference);
