import React from "react";
import styled from "styled-components";

const RadioWrap = styled.label`
  position: relative;
  color: #061d38;
  user-select: none;
  cursor: pointer;
  font-size: 14px;
  letter-spacing: 0.025em;
  text-align: center;

  span {
    background-color: #f1f1f0;
    padding: 12px 21px;
    display: block;
    border-radius: 4px;

    &:hover {
      background-color: #061d38;
      color: #fff;
    }
  }

  input:checked + span {
    background-color: #061d38;
    color: #fff;
  }
`;

const StyledRadioCard = styled.input`
  display: none;
`;

const RadioCard = (props: any) => {
  return (
    <RadioWrap>
      <StyledRadioCard
        type="radio"
        name={props.name}
        value={props.value}
        onChange={props.onChange}
      />
      <span>{props.value}</span>
    </RadioWrap>
  );
};
export default RadioCard;
