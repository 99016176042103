import useAuth from "../../auth/context/useAuth";
import ModelsApi from "./ModelsApi";
import { useMemo } from "react";

export function useModelsApi(): ModelsApi {
  const auth = useAuth();

  return useMemo(() => {
    const token = auth.token as string;

    return new ModelsApi(token);
  }, [auth]);
}

export default useModelsApi;
