import * as React from "react";
import {ReactNode, useEffect, useMemo, useState} from "react";
import {RouteComponentProps} from "react-router";

import styled from "styled-components";

import './style.css'
import useModelsApi from "../../api/useModelsApi";
import {useModel} from "../../../app/context/queries/useModel";
import DashBoardConstant from "../Reference/DashBoardConstant";
import {By, CubeOptions, CubeQuery} from "./types";
import {defaultInsightOptions, defaultQuery} from "./index";
import {QueryPanel} from "./query/QueryPanel";

import {Tabs} from "antd";
import {tabs} from "./query/defaults";
import {GeographyTab} from "./tabs/GeographyTab";
import {AttributesTab} from "./tabs/AttributesTab";
import {ComponentsTab} from "./tabs/ComponentsTab";
import CubeViewLayout from "./tabs";
import {DrilldownPanel} from "./panels/DrilldownPanel";
import {FlowTab} from "./tabs/FlowTab";
import {TreemapTab} from "./tabs/TreemapTab";
import {ChartsTab} from "./tabs/ChartsTab";


type ViewProps = RouteComponentProps<{
    modelUuid: string;
}>;

export const TabsWrap = styled.div<{ isOver?: boolean }>`
  padding-left: 8px;
  padding-right: 8px;
`

export const ResultContent = styled.div<{ isOver?: boolean }>`
  width: 100%;
 
  padding-top: 1rem;
  
  display: grid;
  grid-template-columns: 2fr;
  grid-gap: 8px;
  
  background: ${(props) =>
    props.isOver
        ? `background: repeating-linear-gradient(
    -55deg,
    #4a90e2,
    #4a90e2 5px,
    #fff 5px,
    #fff 10px
  );`
        : "none"};
`;

export const MapWrapper = styled.div<{ isOver?: boolean }>`
  width: 75%;
  margin: 0 auto;
`;

const TabTitle = ({title}: { title: string }) => <span>
    {title}
</span>

const {TabPane} = Tabs;

export type InsightsPage = "geography" | "components" | "attributes" | "sankey" | "drilldown" | "treemap"

export const CubeView: React.FC<ViewProps> = ({match}) => {
    const modelsApi = useModelsApi();

    const model = useModel(match?.params.modelUuid)
    const {data: rootDetails, isLoading} = model

    const [options, setOptions] = useState<CubeOptions>(defaultInsightOptions);
    const [query, setQuery] = useState<CubeQuery>(defaultQuery)
    // const [breakdown, setBreakdown] = useState<BreakdownResult | undefined>(undefined);

    useEffect(() => {
        modelsApi.cubeOptions(match?.params.modelUuid).then((response) => {
            const options = response?.data

            setOptions(options);
            setQuery({
                drilldown: "",
                // breakdown: options.breakdown.selection as string,
                // drilldown: options.drilldown.selection as string,
                filters: {
                    component: options.components.selection,
                    location: options.locations.selection,
                    attribute: options.attributes.selection
                }
            })
        });
    }, [modelsApi, match]);

    /*    useEffect(() => {
            modelsApi.cubeGeography(match?.params.modelUuid, query).then((response) => {
                setBreakdown(response?.data);
            });
        }, [modelsApi, match, query]);*/

    const [selectedTab, setSelectedTab] = useState<InsightsPage>("geography")

    const tabStyle = {
        fontSize: 18,
        fontWeight: 400
    }

    const queryPanel = useMemo(
        () => <QueryPanel page={selectedTab} tabs={tabs} setSelectedPage={setSelectedTab} options={options}
                          queryChanged={setQuery}/>,
        [selectedTab, options]
    )

    const drilldownPanel = <DrilldownPanel modelUuid={match?.params.modelUuid} query={query}/>

    const panels: { [key: string]: ReactNode } = {
        geography: <GeographyTab modelUuid={match.params.modelUuid} query={query}/>,
        components: <ComponentsTab modelUuid={match.params.modelUuid} query={query}/>,
        attributes: <AttributesTab modelUuid={match.params.modelUuid} query={query}/>,
        flow: <FlowTab modelUuid={match.params.modelUuid} query={query}/>,
        treemap: <TreemapTab modelUuid={match.params.modelUuid} query={query}/>,
        charts: <ChartsTab modelUuid={match.params.modelUuid} query={query}/>
        // sankey: <SankeyPanel modelUuid={match.params.modelUuid} query={query}/>,
        // treemap: <BreakdownPanel modelUuid={match.params.modelUuid} query={query}/>,
        // drilldown: <DrilldownPanel modelUuid={match.params.modelUuid} query={query}/>
    }


    return (
        <DashBoardConstant match={match} modelInfo={rootDetails}>
            {/*<TreeMenu ID={match?.params.modelUuid}/>*/}

            <TabsWrap>
                <Tabs activeKey={selectedTab}
                      onChange={tab => setSelectedTab(tab as InsightsPage)}
                      centered size={'large'}>
                    {tabs.map((tab, index) => {
                        return (
                            <TabPane key={tab.value} tab={tab.label}>
                                <CubeViewLayout queryPanel={queryPanel} drilldownPanel={drilldownPanel} >
                                    <div style={{width: "100%"}}>
                                        {panels[tab.value]}
                                    </div>
                                </CubeViewLayout>
                            </TabPane>
                        )
                    })}
                </Tabs>
            </TabsWrap>

        </DashBoardConstant>
    );
};

export default CubeView;