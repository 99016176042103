import * as React from "react";
import { useEffect, useState } from "react";
import useAuth from "../../../auth/context/useAuth";
import { RouteComponentProps } from "react-router";
import {
  ClientSwitcher,
  UserProfile,
  Pagination,
  NavLogo,
} from "../../../components";
import {
  ListWrap,
  ListContent,
  ClientsTopbar,
  ClientsList,
  TopbarLeft,
  CardsWrap,
  ClientsTools,
} from "../../../clientspaces/components/CsListView";
import useProjectsApi from "../../api/useProjectsApi";
import { Project, AttributesSummary } from "../../api/model";
import ProjectsNav from "../../components/ProjectsNav";
import AttributesCard from "../../components/AttributesCard";
import { Icons } from "../../../assets/Icons";
import { DataWrap } from "../../components/ProjectsView";
import { Model } from "../../../models/api/model";
import useModelsApi from "../../../models/api/useModelsApi";
import {
  ButtonGroup,
  Popover,
  Menu,
  Position,
  Button,
  MenuItem,
  InputGroup,
} from "@blueprintjs/core";
import { ItemRenderer, Select } from "@blueprintjs/select";
import AttributeModel from "../../../components/AttributeModel";
import { Link } from "react-router-dom";
import { HotTable } from "@handsontable/react";
import Handsontable from "handsontable";
import Core from "handsontable";
import AutoSizer from "react-virtualized-auto-sizer";

type ViewProps = RouteComponentProps<{
  project: string;
}>;

interface ColumnObject {
  name: string;
  key: string;
  type?: string;
}

interface AttributeDisplay {
  display: boolean;
  uuid: string;
  defaultSelectedTabId?: string | undefined;
}

type TableColumnsType = {
  data: string;
  type: string;
  source?: Array<string>;
};

export const View: React.FC<ViewProps> = ({ match, location, history }) => {
  const auth = useAuth();
  const projectsApi = useProjectsApi();
  // find "kind" query parameter
  const params = new URLSearchParams(location?.search);
  const KIND_STRING = params.get("kind");
  const OFFSET = parseInt(params.get("offset") || "0", 10);

  const modelsApi = useModelsApi();

  const [activeProject, setActiveProject] = useState<Project>();
  const [attributesSummary, setAttributesSummary] = useState<
    Array<AttributesSummary>
  >([]);
  const [searchValue, setSearchValue] = useState("");
  const [searchString, setSearchString] = useState<string>();
  const [totalPages, setTotalPages] = useState(1);

  const [tableRows, setTableRows] = useState<Array<{ [key: string]: any }>>([]);
  const [tableColumns, setTableColumns] = useState<Array<TableColumnsType>>([]);
  const [tableHeaders, setTableHeaders] = useState<
    Array<{ name: string; key: string; type: string }>
  >([]);

  const [filterColumn, setFilterColumn] = useState<ColumnObject>();
  const [filterOp, setFilterOp] = useState<ColumnObject>();
  const [filterSearch, setFilterSearch] = useState<string>();
  const [filterString, setFilterString] = useState<string>(); // used as a param for the endpoint

  const [sortColumn, setSortColumn] = useState<ColumnObject>();
  const [sortOp, setSortOp] = useState<ColumnObject>();
  const [sortString, setSortString] = useState<string>();

  const [modelsList, setModelsList] = useState<Array<Model>>([]);

  const [selectedModel, setSelectedModel] = useState<Model>();
  const [displayAttributeModel, setDisplayAttributeModel] = useState<
    AttributeDisplay
  >({
    display: false,
    uuid: "",
  });

  useEffect(() => {
    projectsApi
      .getProject(match?.params?.project)
      .then((response) => {
        setActiveProject(response?.data);
      })
      .catch((error) => console.log(error));
  }, [projectsApi, match]);

  useEffect(() => {
    projectsApi.getModels(match?.params?.project).then((response) => {
      const allModels: Model = {
        name: "Display all models",
        uuid: "all-models",
        description: "",
        project: {},
        clientspace: {},
        divisions: [],
        tenets: {},
        createdAt: "",
        users: {},
        principles: {},
      };
      response?.data.push(allModels);
      setModelsList(response?.data);
    });
  }, [projectsApi, match]);

  useEffect(() => {
    projectsApi.getAttributesSummary(match?.params.project).then((response) => {
      setAttributesSummary(response?.data);
    });
  }, [projectsApi, match]);

  useEffect(() => {
    if (KIND_STRING) {
      const attributesWithoutAllocation = () => {
        projectsApi
          .getAttribute(
            match?.params.project,
            KIND_STRING,
            50,
            OFFSET,
            filterString,
            sortString,
            searchString
          )
          .then((response) => {
            const RESULTS = response?.data?.results;
            RESULTS.map((obj: any) => {
              return obj?.models.map(
                (model: any) => (model.attributeUuid = obj?.uuid)
              );
            });

            setTotalPages(Math.ceil(response?.data.total / 50));
            const rows = response?.data?.results.map((result: any) => {
              return result.properties;
            });
            setTableRows(rows);
          });
      };

      if (selectedModel) {
        projectsApi
          .getAttributesWithAllocations(
            selectedModel?.uuid,
            KIND_STRING,
            50,
            OFFSET,
            filterString,
            sortString,
            searchString
          )
          .then((response) => {
            const RESULTS = response?.data?.results;
            RESULTS.map((obj: any) => {
              return obj?.models.map(
                (model: any) => (model.attributeUuid = obj?.attributeUuid)
              );
            });

            setTotalPages(Math.ceil(response?.data.total / 50));
            const rows = response?.data?.results.map((result: any) => {
              return result.properties;
            });
            setTableRows(rows);
          });
      } else {
        attributesWithoutAllocation();
      }

      modelsApi.getSchemaAttributes().then((response) => {
        const temp_arr = [];
        temp_arr.push(response?.data);

        const attributeIndex = temp_arr[0].findIndex(
          (attr) => attr?.kind === KIND_STRING
        );

        if (attributeIndex > -1) {
          const columns = response?.data[attributeIndex]?.properties;
          const columnsKeys = Object.keys(columns);

          const defaultColumns = columnsKeys.map((key: string) => ({
            data: key,
            type:
              columns[key].type === "link"
                ? "dropdown"
                : columns[key].type !== "number"
                ? "text"
                : "numeric",
            renderer: columns[key].type === "link" ? "my.custom" : undefined,
            source: [
              "yellow",
              "red",
              "orange",
              "green",
              "blue",
              "gray",
              "black",
              "white",
            ],
          }));

          const headersName = columnsKeys.map((key: string) => ({
            name: columns[key]?.meta.name,
            key: key,
            type: columns[key].type,
          }));

          setTableHeaders(headersName);
          setTableColumns(defaultColumns);
        }
      });
    }
  }, [
    projectsApi,
    match,
    KIND_STRING,
    modelsApi,
    OFFSET,
    filterString,
    sortString,
    searchString,
    selectedModel,
  ]);

  function handlePageChange(page: number) {
    history.push(`${match.url}?kind=${KIND_STRING}&offset=${page * 50 - 50}`);
  }

  // used for filtering
  function selectOpHandler(key: string, name: string) {
    setFilterOp({
      name: name,
      key: key,
    });
  }

  function selectColumnHandler(column: any) {
    setFilterColumn({
      name: column.name,
      key: column.key,
    });
  }

  function resetFilterHandler() {
    setFilterOp(undefined);
    setFilterColumn(undefined);
    setFilterString(undefined);
  }

  function doFilter() {
    const searchParam = !filterSearch ? "null" : "'" + filterSearch + "'";
    setFilterString(
      filterColumn?.key + "." + filterOp?.key + "." + searchParam
    );
  }

  // used for sorting
  function selectSortColumnHandler(column: any) {
    setSortColumn({
      name: column.name,
      key: column.key,
      type: column.type,
    });
  }

  function selectSortOpHandler(key: string, name: string) {
    setSortOp({
      name: name,
      key: key,
    });
  }

  function resetSortHandler() {
    setSortColumn(undefined);
    setSortOp(undefined);
    setSortString(undefined);
  }

  function doSort() {
    setSortString(sortColumn?.key + "+" + sortOp?.key);
  }

  const renderModel: ItemRenderer<Model> = (
    model,
    { handleClick, modifiers }
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }

    return (
      <MenuItem
        active={selectedModel?.uuid === model.uuid}
        key={model.uuid}
        onClick={handleClick}
        text={model.name}
      />
    );
  };

  function doSearch(e: any) {
    e.preventDefault();
    if (searchValue.length <= 0) {
      setSearchString(undefined);
    }

    setSearchString(searchValue);
  }

  (function (Handsontable) {
    function customRenderer(
      instance: Core,
      td: HTMLTableCellElement,
      row: number,
      col: number,
      prop: React.ReactText,
      value: any,
      cellProperties: Handsontable.CellProperties
    ) {
      let button;
      if (value !== "-") {
        button = document.createElement("button");
        button.innerHTML = value;
        button.className = "aa";

        Handsontable.dom.addEvent(button, "mousedown", (e) => {
          e.preventDefault(); // prevent selection quirk
          //console.log(row);
          //setDisplayAttributeModel({ uuid: "aaa", display: true });
          alert("clicked button");
        });

        Handsontable.dom.empty(td);
        td.appendChild(button);
        cellProperties.readOnly = true;
      } else {
        Handsontable.renderers.TextRenderer(
          instance,
          td,
          row,
          col,
          prop,
          value,
          cellProperties
        );
      }
    }

    // Register an alias
    Handsontable.renderers.registerRenderer("my.custom", customRenderer);
  })(Handsontable);

  return (
    <ListWrap
      style={{ display: KIND_STRING ? "block" : "grid" }}
      className="projectdata-view"
    >
      {!KIND_STRING && <ProjectsNav match={match} active="project-data" />}

      <ListContent className="projectdata-content">
        <ClientsTopbar>
          {KIND_STRING && (
            <Link to="/">
              <NavLogo column />
            </Link>
          )}

          <ClientSwitcher
            clientspaceUuid={activeProject?.clientspace?.uuid}
            projectData={activeProject}
          />

          <TopbarLeft>
            {auth.profile && <UserProfile profile={auth.profile} />}
          </TopbarLeft>
        </ClientsTopbar>
        <ClientsList className="projectdata-table">
          {!KIND_STRING ? (
            <CardsWrap className="attr-wrap">
              {attributesSummary.length > 0
                ? attributesSummary.map((attr, i: number) => {
                    return (
                      <AttributesCard
                        key={i}
                        kind={attr.kind}
                        createdAt={attr.createdAt}
                        total={attr.total}
                      />
                    );
                  })
                : null}
            </CardsWrap>
          ) : (
            <>
              <ClientsTools className="project-data-tools">
                <h2>{KIND_STRING}</h2>
                <form onSubmit={(e: any) => doSearch(e)}>
                  <InputGroup
                    className="project-data-search"
                    value={searchValue}
                    onChange={(e: any) => setSearchValue(e.target.value)}
                    type="search"
                  />
                </form>

                <ButtonGroup style={{ minWidth: 120 }}>
                  <Popover
                    position={Position.BOTTOM_LEFT}
                    minimal
                    usePortal={false}
                  >
                    <Button
                      icon={<Icons.Filter size={18} />}
                      text="Filter"
                      minimal
                      style={{
                        backgroundColor:
                          filterColumn || filterOp ? "#4a90e2" : "inherit",
                        color: filterColumn || filterOp ? "#fff" : "inherit",
                      }}
                      className="filter-btn"
                    />

                    <ButtonGroup
                      style={{ minWidth: 120 }}
                      className="filter-wrap"
                    >
                      {filterColumn || filterOp ? (
                        <Button
                          icon={<Icons.Close size={12} />}
                          minimal
                          onClick={resetFilterHandler}
                        />
                      ) : null}

                      <Popover position={Position.BOTTOM_LEFT}>
                        <Button
                          text={filterColumn ? filterColumn?.name : "Column:"}
                          minimal
                        />

                        <Menu>
                          {tableHeaders.length > 0 &&
                            tableHeaders.map((col: any) => (
                              <MenuItem
                                text={col.name}
                                key={col.key}
                                onClick={() => selectColumnHandler(col)}
                                active={filterColumn?.key === col.key}
                                shouldDismissPopover={false}
                              />
                            ))}
                        </Menu>
                      </Popover>

                      <Popover position={Position.BOTTOM_LEFT}>
                        <Button
                          text={filterOp ? filterOp?.name : "Filter by:"}
                          minimal
                        />
                        <Menu>
                          <MenuItem
                            text="contains"
                            onClick={() =>
                              selectOpHandler("contains", "contains")
                            }
                            active={filterOp?.key === "contains"}
                            shouldDismissPopover={false}
                          />
                          <MenuItem
                            text="does not contain"
                            onClick={() =>
                              selectOpHandler("!contains", "does not contain")
                            }
                            active={filterOp?.key === "!contains"}
                            shouldDismissPopover={false}
                          />
                          <MenuItem
                            text="is empty"
                            onClick={() => selectOpHandler("is", "is empty")}
                            active={filterOp?.key === "is"}
                            shouldDismissPopover={false}
                          />
                          <MenuItem
                            text="is not empty"
                            onClick={() =>
                              selectOpHandler("!is", "is not empty")
                            }
                            active={filterOp?.key === "!is"}
                            shouldDismissPopover={false}
                          />
                        </Menu>
                      </Popover>

                      <InputGroup
                        small
                        placeholder="Start typing"
                        className="filter-search"
                        value={filterSearch}
                        onChange={(e: any) => setFilterSearch(e.target.value)}
                      />
                      {filterColumn || filterOp ? (
                        <Button
                          icon={<Icons.Check size={21} />}
                          minimal
                          onClick={doFilter}
                        />
                      ) : null}
                    </ButtonGroup>
                  </Popover>
                  <Popover
                    position={Position.BOTTOM_LEFT}
                    minimal
                    usePortal={false}
                  >
                    <Button
                      icon={<Icons.Sort size={18} />}
                      text="Sort"
                      minimal
                      className="sort-btn"
                      style={{
                        backgroundColor:
                          sortColumn || sortOp ? "#4a90e2" : "inherit",
                        color: sortColumn || sortOp ? "#fff" : "inherit",
                      }}
                    />
                    <ButtonGroup
                      style={{ minWidth: 120 }}
                      className="filter-wrap"
                    >
                      {sortColumn || sortOp ? (
                        <Icons.Close
                          size={12}
                          onClick={resetSortHandler}
                          className="filter-reset"
                        />
                      ) : null}

                      <Popover position={Position.BOTTOM_LEFT}>
                        <Button
                          text={sortColumn?.name ? sortColumn?.name : "Column:"}
                          minimal
                        />

                        <Menu>
                          {tableHeaders.length > 0 &&
                            tableHeaders.map((col: any) => (
                              <MenuItem
                                text={col.name}
                                key={col.key}
                                onClick={() => selectSortColumnHandler(col)}
                                active={sortColumn?.key === col.key}
                                shouldDismissPopover={false}
                              />
                            ))}
                        </Menu>
                      </Popover>

                      <Popover position={Position.BOTTOM_LEFT}>
                        <Button
                          text={sortOp ? sortOp?.name : "Sort by:"}
                          minimal
                        />
                        <Menu>
                          {sortColumn?.type === "number" ? (
                            <>
                              <MenuItem
                                text="Lowest to Highest"
                                onClick={() =>
                                  selectSortOpHandler(
                                    "asc",
                                    "Lowest to Highest"
                                  )
                                }
                                active={sortOp?.key === "asc"}
                                shouldDismissPopover={false}
                              />
                              <MenuItem
                                text="Highest to Lowest"
                                onClick={() =>
                                  selectSortOpHandler(
                                    "desc",
                                    "Highest to Lowest"
                                  )
                                }
                                active={sortOp?.key === "desc"}
                                shouldDismissPopover={false}
                              />
                            </>
                          ) : (
                            <>
                              <MenuItem
                                text="A to Z"
                                onClick={() =>
                                  selectSortOpHandler("asc", "A to Z")
                                }
                                active={sortOp?.key === "asc"}
                                shouldDismissPopover={false}
                              />
                              <MenuItem
                                text="Z to A"
                                onClick={() =>
                                  selectSortOpHandler("desc", "Z to A")
                                }
                                active={sortOp?.key === "desc"}
                                shouldDismissPopover={false}
                              />
                            </>
                          )}
                        </Menu>
                      </Popover>

                      {sortColumn && sortOp ? (
                        <Button
                          icon={<Icons.Check size={21} />}
                          minimal
                          onClick={doSort}
                        />
                      ) : null}
                    </ButtonGroup>
                  </Popover>
                </ButtonGroup>
                <Select
                  items={modelsList}
                  noResults={<MenuItem disabled={true} text="No results." />}
                  onItemSelect={(e: any) =>
                    e.uuid !== selectedModel?.uuid
                      ? e.uuid !== "all-models"
                        ? setSelectedModel(e)
                        : setSelectedModel(undefined)
                      : setSelectedModel(undefined)
                  }
                  filterable={false}
                  itemRenderer={renderModel}
                  popoverProps={{ minimal: true }}
                >
                  <Button
                    text={
                      selectedModel
                        ? `${selectedModel?.name}`
                        : "Display all models"
                    }
                    rightIcon={<Icons.ArrowDown size={21} />}
                    minimal
                  />
                </Select>

                <Button className="edit-data">Edit</Button>
                <Pagination
                  handlePageChange={handlePageChange}
                  totalPages={totalPages}
                />
              </ClientsTools>

              {tableRows && tableRows.length > 0 && (
                <DataWrap className="data-with-table">
                  <AutoSizer>
                    {({ height, width }) => (
                      <HotTable
                        className="table-test"
                        columns={tableColumns}
                        data={tableRows}
                        colHeaders={(index: any) => {
                          return tableHeaders[index].name;
                        }}
                        rowHeaders={true}
                        width={width}
                        licenseKey="non-commercial-and-evaluation"
                        stretchH="all"
                        contextMenu
                        minSpareRows={100 - tableRows.length}
                        //maxRows={100}
                        manualColumnResize
                        allowInsertColumn={false}
                        allowRemoveColumn={false}
                        //numericFormat
                        readOnly
                        readOnlyCellClassName="not-editable-table-cell"
                        height={height}
                      />
                    )}
                  </AutoSizer>
                </DataWrap>
              )}
              {displayAttributeModel?.display === true &&
                selectedModel?.uuid && (
                  <AttributeModel
                    modelUuid={selectedModel?.uuid}
                    uuid={displayAttributeModel?.uuid}
                    isOpen={displayAttributeModel?.display}
                    defaultSelectedTabId={
                      displayAttributeModel?.defaultSelectedTabId
                    }
                    setDisplayAttributeModel={setDisplayAttributeModel}
                    columnNames={tableHeaders}
                  />
                )}
            </>
          )}
        </ClientsList>
      </ListContent>
    </ListWrap>
  );
};

export default View;
