import * as React from "react";
import { useEffect, useState } from "react";
import useAuth from "../../../auth/context/useAuth";
import { Button, Input, Select, UserProfile } from "../../../components";
import {
  ListWrap,
  ListContent,
  ClientsTopbar,
  ClientsList,
  TopbarLeft,
  ClientsTools,
  ToolsWrap,
  CardsWrap,
  ProjectBtnWrap,
} from "../../components/CsListView";
import ProjectCard from "../../components/ProjectCard";
import NewProject from "./NewProject";
import { Icons } from "../../../assets/Icons";
import { ClientspacesNav } from "../../components/ClientspacesNav";
import useProjectsApi from "../../../projectspaces/api/useProjectsApi";
import {Project} from "../../../projectspaces/api";

export const View: React.FC = () => {
  const auth = useAuth();
  const projectsApi = useProjectsApi();

  const [openSearch, setOpenSearch] = useState(false);
  const [searchValue, setSearchValue] = useState("");
  const [sortValue, setSortValue] = useState("");

  const [projectsList, setProjectsList] = useState<Array<Project>>([]);
  const [newProjectModal, setNewProjectModal] = useState(false);
  const [refreshProjects, setRefreshProjects] = useState(false);

  useEffect(() => {
    projectsApi
      .getProjects()
      .then((response) => setProjectsList(response?.data));
  }, [projectsApi, refreshProjects]);

  const doSearch = (value: string, e?: any) => {
    e?.preventDefault();

    projectsApi
      .getProjects(value)
      .then((response) => setProjectsList(response?.data));
  };

  const doSort = (value: string) => {
    let sort = {};

    if (value === "Date created") {
      sort = {
        createdAt: "desc",
      };
    } else if (value === "Alphabetically") {
      sort = {
        name: "asc",
      };
    }

    projectsApi
      .getProjects(undefined, sort)
      .then((response) => setProjectsList(response?.data));
  };

  return (
    <ListWrap>
      {newProjectModal && (
        <NewProject
          refresh={() =>
            setRefreshProjects((refreshProjects) => !refreshProjects)
          }
          onClose={() => setNewProjectModal(false)}
          isOpen={newProjectModal}
          owner={auth?.profile}
        />
      )}

      <ClientspacesNav active="clientspace-home" />

      <ListContent>
        <ClientsTopbar>
          <h2>Projects</h2>

          <TopbarLeft>
            {auth.profile && <UserProfile profile={auth.profile} />}
          </TopbarLeft>
        </ClientsTopbar>
        <ClientsList>
          <ClientsTools>
            <ProjectBtnWrap>
              <Button
                type="button"
                bgcolor="#4a90e2"
                w="130px"
                className="new-client-btn tools-btn"
                onClick={() =>
                  setNewProjectModal((newProjectModal) => !newProjectModal)
                }
              >
                New project
              </Button>

              <Button type="button" w="75px" className="share-btn tools-btn">
                Share
              </Button>
            </ProjectBtnWrap>
            <ToolsWrap>
              <Select
                label="Sort by:"
                placeholder="not selected"
                data={["Date created", "Alphabetically"]}
                direction="row"
                absolute={true}
                hideable={true}
                value={sortValue}
                onClick={(value: any) => {
                  setSortValue(value);
                  doSort(value);
                }}
              />

              <Icons.Search
                size={21}
                onClick={() => setOpenSearch((openSearch) => !openSearch)}
              />
              {openSearch && (
                <form onSubmit={(e: any) => doSearch(searchValue, e)}>
                  <Input
                    mbottom="none"
                    value={searchValue}
                    onChange={(e: any) => {
                      setSearchValue(e.target.value);
                      doSearch(searchValue);
                    }}
                  />
                </form>
              )}
            </ToolsWrap>
          </ClientsTools>

          <CardsWrap
            style={{ height: projectsList.length > 0 ? "auto" : "100%" }}
          >
            {projectsList.length > 0 ? (
              projectsList.map((project) => {
                return (
                  <ProjectCard
                    projectDetails={project}
                    key={project.uuid}
                    profileID={auth?.profile?.id}
                    refresh={() =>
                      setRefreshProjects((refreshProjects) => !refreshProjects)
                    }
                  />
                );
              })
            ) : (
              <h3 className="no-clients">
                To get started, click the
                <br />
                New project button
              </h3>
            )}
          </CardsWrap>
        </ClientsList>
      </ListContent>
    </ListWrap>
  );
};

export default View;
