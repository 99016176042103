import React from "react";
import { SpacerWrap, OverSpacerWrap } from "./styles";
import { useDrop } from "react-dnd";
import { ItemTypes } from "../itemTypes";
import { ItemSpacerProps } from "./types";

export const ItemSpacer: React.FC<ItemSpacerProps> = ({
  pos,
  boxId,
  nestingLevel,
  first = false,
}) => {
  const [{ isOver }, drop] = useDrop({
    accept: [
      `${ItemTypes.ITEM}${nestingLevel}`,
      `${ItemTypes.NEW_ITEM}${nestingLevel}`,
    ],
    drop: () => ({ pos, id: boxId, type: `${ItemTypes.POS}${nestingLevel}` }),
    collect: (monitor) => ({
      isOver: monitor.isOver({ shallow: true }),
    }),
  });

  return isOver ? (
    <OverSpacerWrap
      ref={drop}
      isOver={isOver}
      pos={pos}
      first={first}
    ></OverSpacerWrap>
  ) : (
    <SpacerWrap ref={drop} isOver={isOver} pos={pos} first={first} />
  );
};

export default ItemSpacer;
