import { TreeState, TreeAction, SET_TREE_TOUPDATE } from "./types";

const initialState: TreeState = {
  toupdate: true,
};

export function treeReducer(
  state = initialState,
  action: TreeAction
): TreeState {
  switch (action.type) {
    case SET_TREE_TOUPDATE:
      return { toupdate: action.toupdate };
    default:
      return state;
  }
}
