import styled from "styled-components";

export const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  border-radius: 4px;
  border: 1px solid #d4d4d4;
  padding: 24px 16px;
  height: auto;
  min-height: 15px;
  line-height: 1;
  font-size: 16px;

  h3 {
    margin: 0;
    font-size: 18px;
    margin-bottom: 10px;
    font-weight: 500;
  }

  & > div {
    display: flex;
    flex-direction: column;
    text-align: center;
  }

  .salary-table-wrap {
    overflow-y: auto;
    height: 100%;

    & > table {
      width: 100%;
      border-collapse: collapse;
      border-style: hidden;
    }

    tr {
      border: solid 2px #f3f3f3;
    }

    th {
      font-weight: 500;
      text-transform: capitalize;
    }

    td,
    th {
      text-align: left;
      padding: 8px 4px;
    }
  }
`;
