import styled from "styled-components";

export const SearchInputWrap = styled.div`
  display: flex;
  align-items: center;

  & > svg {
    min-width: 16px;
    margin-right: 4px;
  }

  #tree-search {
    padding: 3px 5px;
    background-color: transparent;
  }
`;

export const Levels = styled.div`
  padding: 28px 32px;
  display: flex;
  flex-direction: column;
  overflow: auto;
`;

export const LevelContent = styled.div`
  width: 92%;
  min-width: 320px;
  margin: 0 auto;
  margin-bottom: 32px;

  .root-btn {
    font-size: 28px;
    min-width: 180px;
    width: auto;
  }
`;

export const LevelHeader = styled.h3`
  width: 100%;
  text-align: center;
  border-bottom: 2px solid #f3f3f3;
  line-height: 0.1em;
  margin: 10px 0 20px;
  font-weight: 500;

  & > span {
    background: #fff;
    font-size: 20px;
    color: #4a90e2;
    padding: 0 6px;
  }
`;

export const LevelCards = styled.div`
  padding-top: 6px;
  display: grid;
  grid-template-columns: repeat(auto-fill, 140px);
  grid-column-gap: 21px;
  grid-row-gap: 21px;
`;
