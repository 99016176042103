import styled from "styled-components";

const AuthWrap = styled.div`
  & > .opteq-logo {
    margin-bottom: 16px;
  }

  .auth-label label {
    font-weight: 500;
  }

  .auth-input input {
    background: #f1f1f0;
    box-shadow: none;
    height: 34px !important;
    line-height: 34px !important;
    box-sizing: border-box;
  }

  .auth-card {
    padding: 40px 64px;
    border-radius: 4px;
    max-width: 540px;
    min-width: 410px;
    position: relative;

    .card-icon {
      position: absolute;
      top: 0;
      right: 0;
      padding: 12px;
      opacity: 0.35;
    }
  }
`;

const StepDiv = styled.div`
  display: flex;
  padding: 0 4px;
  margin-bottom: 32px;
`;

const StepWrap = styled.div`
  display: flex;
  align-items: center;
  opacity: 0.5;
  padding: 0 12px;

  &:last-of-type {
    margin-left: auto;
  }

  &.active {
    opacity: 1;
  }

  i {
    font-size: 14px;
    font-style: normal;
    font-weight: bold;
    color: #fff;
    background-color: #061d38;
    display: flex;
    border-radius: 99999px;
    padding: 4px 4px;
    display: flex;
    height: 16px;
    width: 16px;
    align-items: center;
    justify-content: center;
  }

  span {
    font-size: 14px;
    margin-left: 6px;
    color: #061d38;
    font-weight: bold;
  }
`;

const Form = styled.form`
  display: flex;
  flex-direction: column;
  width: 100%;

  .password-toggle {
    position: absolute;
    top: 0;
    height: 34px;
    left: auto;
    right: 0;
    margin: auto 0;
    padding: 0 8px;
    cursor: pointer;

    &:hover {
      opacity: 0.5;
    }
  }
`;

const Label = styled.label`
  font-size: 16px;
  color: #3b3b3b;
  text-align: left;
  font-weight: 500;
  margin-top: 12px;
  letter-spacing: 0.025em;
  line-height: 1.35;
`;

const NameGrid = styled.div`
  display: grid;
  grid-template-columns: repeat(2, minmax(0, 1fr));
  grid-column-gap: 32px;
`;

const Error = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  color: red;
`;

const RadioGroup = styled.div`
  margin-top: 4px;
  display: grid;
  grid-template-columns: repeat(4, minmax(0, 1fr));
  grid-gap: 12px;
  margin-bottom: 12px;

  &.specs-group {
    grid-template-columns: repeat(3, minmax(0, 1fr));
  }
`;

export {
  AuthWrap,
  Form,
  NameGrid,
  Label,
  Error,
  StepDiv,
  StepWrap,
  RadioGroup,
};
