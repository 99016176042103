import {RouteComponentProps} from "react-router";
import * as React from "react";
import {useEffect, useState} from "react";
import TreeMenu from "../../components/TreeMenu/TreeMenu";
import DashBoardConstant from "../Reference/DashBoardConstant";
import useModelsApi from "../../api/useModelsApi";
import {Model} from "../../api";
import {DonutChart} from "./components/DonutChart";
import {ColumnChart} from "./components/ColumnChart";
import styled from "styled-components";
import {GeoChart} from "./components/GeoChart";

type ViewProps = RouteComponentProps<{
    modelUuid: string;
}>;

export const ChartContent = styled.div<{ isOver?: boolean }>`
  padding: 32px 128px;
  overflow: auto;
  background: ${(props) =>
    props.isOver
        ? `background: repeating-linear-gradient(
    -55deg,
    #4a90e2,
    #4a90e2 5px,
    #fff 5px,
    #fff 10px
  );`
        : "none"};
`;

export const ChartView: React.FC<ViewProps> = ({ match }) => {
    const modelsApi = useModelsApi();

    const [rootDetails, setRootDetails] = useState<Model>();

    useEffect(() => {
        modelsApi.getModel(match?.params.modelUuid).then((response) => {
            setRootDetails(response?.data);
        });
    }, [modelsApi, match]);

    return (
        <DashBoardConstant match={match} withTree modelInfo={rootDetails}>
            <TreeMenu ID={match?.params.modelUuid} />

            <ChartContent>
                <GeoChart />
                {/*<TreemapChart />*/}
                <DonutChart />
                <ColumnChart />
            </ChartContent>
        </DashBoardConstant>
    )
}