import styled from "styled-components";

const Error = styled.p`
  margin: 0;
  font-size: 14px;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  color: red;
`;
export default Error;
