import React, { useState, useEffect, useLayoutEffect, useRef } from "react";
import styled from "styled-components";
import { Icons } from "../assets/Icons";

const SelectWrap = styled.div`
  position: relative;
`;

const SelectTop = styled.div<{ direction?: string }>`
  display: flex;
  flex-direction: ${(p) => p.direction || "column"};
  align-items: ${(p) => (p.direction === "row" ? "center" : "left")};

  & > .select-label {
    font-size: 18px;
    color: #3b3b3b;
    text-align: left;
    font-weight: 500;
    letter-spacing: 0.025em;
    display: block;
    margin-right: ${(p) => (p.direction === "row" ? "16px" : "none")};
  }
`;

const SelectHeader = styled.button`
  margin: 0;
  background: #f1f1f0;
  color: inherit;
  border: none;
  font-family: inherit;
  cursor: pointer;
  outline: inherit;
  min-width: 180px;
  padding: 12px 16px;
  font-size: 16px;
  text-align: left;
  border-radius: 4px;

  & > .select-placeholder {
    opacity: 0.35;
  }

  & > .header-icon {
    position: absolute;
    right: 0;
    left: auto;
    margin-right: 16px;
    opacity: 0.35;
  }
`;

const SelectData = styled.ul<{ absolute: boolean }>`
  font-size: 16px;
  margin: 0;
  margin-top: 10px;
  padding: 0;
  list-style: none;
  background-color: #f1f1f0;
  border-radius: 4px;
  overflow-y: auto;
  max-height: 160px;
  position: ${(p) => (p.absolute ? "absolute" : "relative")};
  right: ${(p) => p.absolute && 0};
  min-width: 180px;
  z-index: ${(p) => p.absolute && 99999};
  box-shadow: ${(p) =>
    p.absolute &&
    "0 10px 15px -3px rgba(0, 0, 0, 0.1), 0 4px 6px -2px rgba(0, 0, 0, 0.05)"};
`;

const SelectItem = styled.li`
  padding: 8px;
  cursor: pointer;

  &:hover {
    background-color: #dedede;
  }
`;

const Select = (props: any) => {
  const [openSelect, setOpenSelect] = useState(false);
  const node = useRef<HTMLDivElement>(null);

  const clickOutside = (e: any) => {
    // inside click
    if (node?.current?.contains(e.target)) return;
    // outside click
    setOpenSelect(false);
  };

  useLayoutEffect(() => {
    if (props.hideable) {
      if (openSelect) {
        document.addEventListener("mousedown", clickOutside);
      } else {
        document.removeEventListener("mousedown", clickOutside);
      }
    }

    return () => {
      document.removeEventListener("mousedown", clickOutside);
    };
  }, [openSelect, props.hideable]);

  const handleClick = (e: any) => {
    if (props.hideable) {
      setOpenSelect((openSelect) => !openSelect);
    }

    props.onClick(e.target.innerHTML);
  };

  const toggleList = (e: any) => {
    e.preventDefault();

    if (props.hideable) {
      setOpenSelect((openSelect) => !openSelect);
    }
  };

  useEffect(() => {
    if (!props.hideable) setOpenSelect(true);
  }, [props.hideable]);

  return (
    <SelectWrap ref={node}>
      <SelectTop direction={props.direction}>
        <span className="select-label">{props.label}</span>

        <SelectHeader onClick={toggleList}>
          {props.value ? (
            props.value
          ) : (
            <span className="select-placeholder">{props.placeholder}</span>
          )}
          {!openSelect ? (
            <Icons.ArrowDown className="header-icon" />
          ) : (
            <Icons.ArrowUp className="header-icon" />
          )}
        </SelectHeader>
      </SelectTop>

      <SelectData absolute={props.absolute}>
        {props.data &&
          openSelect &&
          props.data.map((e: any, i: number) => (
            <SelectItem key={i} onClick={(e) => handleClick(e)}>
              {e}
            </SelectItem>
          ))}
      </SelectData>
    </SelectWrap>
  );
};
export default Select;
