import React from "react";
import {
  ToolbarView,
  ToolBarContent,
  ToolbarOptionView,
  EditBtn,
} from "./styles";
import { Icons } from "../../../../../assets/Icons/Font";
import { Icons as MainIcons } from "../../../../../assets/Icons";
import { dispatchSetToolbar } from "../../../../../store/referencePage/toolbarActions";
import { State } from "../../../DraggableReference/stateManager";
import { Props } from "./";
import SwitchOption from "./SwitchOption";
import ColorOption from "./ColorOption";
import { Tooltip, Position } from "@blueprintjs/core";

const iconSize = 21;
const iconArray = [Icons.Bold, Icons.Italic, Icons.Underline];
const nameMap = ["bold", "italic", "underlined"];
const tooltipMap = ["Bold", "Italic", "Underline"];
// let coppied: Array<ComponentType | LooseTextComponent> = [];

const View: React.FC<
  Props & {
    onCopy?: (state: State) => void;
    onPaste?: (state: State) => void;
    onStyle?: (state: State, style: any) => void;
  }
> = ({
  state,
  toolbarProps,
  setEditMode,
  onCopy,
  onPaste,
  onStyle,
  removeChildren,
}) => {
  const onTextColorChange = (color: string) => {
    const deltaStyle = { textColor: color };
    onStyle && onStyle(state, deltaStyle);
    dispatchSetToolbar({ ...toolbarProps.style, ...deltaStyle });
  };
  const onSwitchChange = (name: string, value: boolean) => {
    const deltaStyle = { [name]: value };
    onStyle && onStyle(state, deltaStyle);
    dispatchSetToolbar({ ...toolbarProps.style, ...deltaStyle });
  };
  const onBgColorChange = (color: string) => {
    const deltaStyle = { backgroundColor: color };
    onStyle && onStyle(state, deltaStyle);
    dispatchSetToolbar({ ...toolbarProps.style, ...deltaStyle });
  };
  const onBorderColorChange = (color: string) => {
    const deltaStyle = { borderColor: color };
    onStyle && onStyle(state, deltaStyle);
    dispatchSetToolbar({ ...toolbarProps.style, ...deltaStyle });
  };
  const onRemove = () => {
    removeChildren(state.selected.content, state.selected.nestingLevel);
    // removeSelectedLabel();
  };
  const handleCopy = () => {
    onCopy && onCopy(state);
  };
  const handlePaste = () => {
    onPaste && onPaste(state);
  };
  const onEditChange = (name: string, value: boolean) => {
    setEditMode(value);
  };
  const handleMouseDown = (
    event: React.MouseEvent<HTMLDivElement, MouseEvent>
  ) => {
    event.preventDefault();
  };
  return (
    <ToolbarView onClick={handleMouseDown}>
      <ToolBarContent>
        <Tooltip content="Copy" position={Position.BOTTOM}>
          <ToolbarOptionView>
            <MainIcons.Copy onMouseUp={handleCopy} size={iconSize} />
          </ToolbarOptionView>
        </Tooltip>
        <Tooltip content="Paste" position={Position.BOTTOM}>
          <ToolbarOptionView>
            <MainIcons.Paste onMouseUp={handlePaste} size={iconSize} />
          </ToolbarOptionView>
        </Tooltip>
        <Tooltip content="Delete" position={Position.BOTTOM}>
          <ToolbarOptionView>
            <MainIcons.Trash onMouseUp={onRemove} size={iconSize} />
          </ToolbarOptionView>
        </Tooltip>
      </ToolBarContent>
      <ToolBarContent>
        {iconArray.map((Icon, index) => (
          <Tooltip content={tooltipMap[index]} position={Position.BOTTOM}>
            <SwitchOption
              onchange={onSwitchChange}
              name={nameMap[index]}
              value={(toolbarProps.style as any)[nameMap[index]]}
              key={index}
            >
              <Icon size={iconSize} />
            </SwitchOption>
          </Tooltip>
        ))}

        <ColorOption
          onchange={onBorderColorChange}
          color={toolbarProps.style.borderColor}
          tooltipText="Border colour"
        >
          <MainIcons.BorderColor size={iconSize - 6} />
        </ColorOption>

        <ColorOption
          onchange={onTextColorChange}
          color={toolbarProps.style.textColor}
          tooltipText="Text colour"
        >
          <Icons.LetterA size={iconSize - 2} />
        </ColorOption>

        <ColorOption
          onchange={onBgColorChange}
          color={toolbarProps.style.backgroundColor}
          tooltipText="Fill colour"
        >
          <Icons.Fill size={iconSize - 6} />
        </ColorOption>
      </ToolBarContent>
      <ToolBarContent className="edit-content" checked={toolbarProps.editMode}>
        <ToolbarOptionView className="edit-btn-view">
          <SwitchOption
            onchange={onEditChange}
            name="Edit"
            value={toolbarProps.editMode}
            className="edit-btn"
          >
            <EditBtn
              text={
                toolbarProps.editMode
                  ? "Finish Editing"
                  : "Edit Reference Model"
              }
              fill
            />
          </SwitchOption>
        </ToolbarOptionView>
      </ToolBarContent>
    </ToolbarView>
  );
};

export default View;
