import ModelsApi from "../../../models/api/ModelsApi";
export const SET_MODEL_UUID = "SET_MODEL_UUID";

export interface setModelUuidAction {
  type: typeof SET_MODEL_UUID;
  uuid: string;
  modelApi: ModelsApi;
}

export interface ModelState {
  uuid: string;
  modelApi: ModelsApi | null;
}

export type ModelAction = setModelUuidAction;
