import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";

interface Props {
  readonly boldtext: string;
  readonly to?: any;
}

const StyledLink = styled(Link)<Props>`
  background: none;
  border: none;
  color: inherit;
  line-height: 2.25;
  font-weight: ${(p) => p.boldtext};
  cursor: pointer;
  text-decoration: underline;
  padding: 0;
  font-weight: 500;

  :hover {
    opacity: 0.8;
  }
`;

const ButtonText = (props: any) => {
  return (
    <StyledLink
      to={props?.to}
      boldtext={props.boldtext ? "500" : "normal"}
      as={props?.as}
      className={props?.className}
      onClick={props?.onClick}
    >
      {props.children}
    </StyledLink>
  );
};
export default ButtonText;
