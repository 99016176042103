import React from "react";
import { DragReferenceContent } from "./styles";
import { useDrop } from "react-dnd";
import { ItemTypes } from "./components/itemTypes";
import { hasContent } from "./stateManager";
import { State } from "./stateManager";

const ReferenceContent: React.FC<{
  state: State;
  nestingLevel: number;
  parentId?: string;
}> = ({ nestingLevel, children, parentId, state }) => {
  const [{ isOver }, drop] = useDrop({
    accept: [`${ItemTypes.NEW_ITEM}${nestingLevel}`],
    drop: () => ({
      id: parentId,
      type:
        nestingLevel <= 0 && !hasContent(state)
          ? ItemTypes.COAP
          : `${ItemTypes.ITEM}${nestingLevel - 1}`,
    }),
    canDrop: () => (nestingLevel > 0 ? true : !hasContent(state)),
    collect: (monitor) => ({
      isOver: monitor.isOver({ shallow: true }),
    }),
  });
  return (
    <DragReferenceContent isOver={isOver} ref={drop}>
      {children}
    </DragReferenceContent>
  );
};

export default ReferenceContent;
