import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {RouteComponentProps} from "react-router";
import useModelsApi from "../../api/useModelsApi";
import {GraphView, Model} from "../../api/model";
import TreeMenu from "../../components/TreeMenu/TreeMenu";
import DashBoardConstant from "../Reference/DashBoardConstant";

import styled from "styled-components";
import cytoscape, {Core, ElementDefinition, LayoutOptions, NodeSingular} from "cytoscape";
import Dagre from 'cytoscape-dagre';
import fcose from 'cytoscape-fcose';
import CytoscapeComponent from 'react-cytoscapejs';
import {useModel} from "../../../app/context/queries/useModel";
import {model, modelHead, snapshot, snapshotPrev} from "./cy.style";
import {SatsuiControl} from "./SatsuiControl/SatsuiControl";

cytoscape.use( fcose );

Dagre(cytoscape)

type ViewProps = RouteComponentProps<{
    modelUuid: string;
}>;

export type GraphProps = {
    view: GraphView
}

export const NetworkContent = styled.div<{ isOver?: boolean }>`
  padding: 32px 32px;
  overflow: auto;
  background: ${(props) =>
    props.isOver
        ? `background: repeating-linear-gradient(
    -55deg,
    #4a90e2,
    #4a90e2 5px,
    #fff 5px,
    #fff 10px
  );`
        : "none"};
`;

const orgChartStyle = [
    model(),
    modelHead(),
    snapshot(),
    snapshotPrev()
]

const gridLayout = {
    name: 'grid',
    cols: 8
};

const dagreLayout = {
    name: 'dagre',
    rankDir: 'TB', // 'TB' for top to bottom flow, 'LR' for left to right. default is undefined, making it plot top-bottom
};

const fcoseLayout = {
    name: 'fcose'
}

const layout = fcoseLayout


export const SatsuiView: React.FC<ViewProps> = ({match}) => {
    const modelsApi = useModelsApi();

    const {data: model, isLoading} = useModel(match?.params.modelUuid)

    const [graphView, setGraphView] = useState<GraphView>();
    const [rootDetails, setRootDetails] = useState<Model>();

    // const [elements, setElements] = useState<ElementDefinition[]>(Array());

    const myCyRef = useRef<Core | undefined>(undefined)

    useEffect(() => {
        if(!model) return;

        modelsApi.getSatsui(model?.project.uuid).then((response) => {
            setGraphView(response?.data);

            const cy = myCyRef.current
        });
    }, [model,  modelsApi, match, myCyRef]);

    const elements: ElementDefinition[] = graphView ? CytoscapeComponent.normalizeElements({
        nodes: graphView.nodes.map(node => ({
                ...node,
                data: node.data,
            })
        ),
        edges: graphView.edges.map(edge => ({
                ...edge,
                data: edge.data
            })
        )
    }) : []

    function doLayout(opts: LayoutOptions, compId?: string) {
        const cy = myCyRef.current
        if(cy) {
            const eles = cy.elements().filter((ele: NodeSingular) => {
                return compId ? ele.data('parent') === compId : true
            })

            // window.alert(`layouting, ${eles.length}`)

            eles.layout(opts).run()
        }
    }

    return (
        <DashBoardConstant match={match} withTree modelInfo={rootDetails}>
            <TreeMenu ID={match?.params.modelUuid}/>

            <NetworkContent>

                <SatsuiControl projectUuid={model?.project.uuid} />

                {
                    graphView ? <>
                        <CytoscapeComponent
                            elements={elements}
                            layout={dagreLayout}
                            style={{width: '100%', height: '100%'}}
                            stylesheet={orgChartStyle}
                            cy={(cy) => {
                                myCyRef.current = cy
                            }}
                        />
                    </> : "Loading..."
                }
            </NetworkContent>
        </DashBoardConstant>
    );
};

export default SatsuiView;
