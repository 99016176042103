import {
  DeltaAction,
  DeltaState,
  SET_SUMMARY,
  SET_APIS,
  SET_MODELS,
  SET_TARGET_MODEL,
  SET_SOURCE_MODEL,
  SET_SELECTED_KIND,
  SET_SELECTED_DETAILS,
} from "./types";

const initialState: DeltaState = { summary: { byKind: {} }, models: [] };

export function deltaReducer(
  state = initialState,
  action: DeltaAction
): DeltaState {
  switch (action.type) {
    case SET_SUMMARY:
      return { ...state, summary: action.summary };
    case SET_APIS:
      return {
        ...state,
        projectApi: action.projectApi,
        deltaApi: action.deltaApi,
      };
    case SET_MODELS:
      return { ...state, models: action.models };
    case SET_TARGET_MODEL:
      return {
        ...state,
        tgtModelId: action.modelId,
        tgtModelName: action.modelName,
      };
    case SET_SOURCE_MODEL:
      return {
        ...state,
        srcModelId: action.modelId,
        srcModelName: action.modelName,
      };
    case SET_SELECTED_KIND:
      return {
        ...state,
        selectedKind: action.selectedKind,
      };
    case SET_SELECTED_DETAILS:
      return { ...state, kindDetails: action.details };
    default:
      return state;
  }
}
