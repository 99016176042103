import React from "react";
import {Chart} from "react-google-charts";

const data = [
    ['Country', 'Popularity'],
    // ['Germany', 200],
    ['United States', 300],
    // ['Brazil', 400],
    ['Canada', 500],
    // ['France', 600],
    // ['RU', 700],
]

export function GeoChart() {
    return <Chart
        width={'80%'}
        // height={'300px'}
        chartType="GeoChart"
        data={data}

        options={{

        }}
    />
}