import React from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import dayjs from "dayjs";

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  color: #061d38;
  box-shadow: 0px 0px 9px -2px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  padding: 40px 24px 12px;
  height: 110px;
  line-height: 1;
  font-size: 16px;
  position: relative;
  box-sizing: content-box;

  h3 {
    margin: 0;
    font-size: 24px;
    margin-bottom: 10px;
  }

  a {
    text-decoration: none;
    color: inherit;
    overflow: hidden;

    :hover {
      opacity: 0.7;
    }
  }

  p {
    margin: 0;
    color: #6d7278;
    letter-spacing: 0.035em;
    line-height: 1.5;
    overflow: hidden;
    word-break: break-all;

    &.card-date,
    &.card-total {
      font-weight: 500;
      font-size: 14px;
    }
  }
`;

const AttributesCard = (props: any) => {
  const { kind, createdAt, total } = props;

  const newDate = createdAt ? dayjs(createdAt).format("DD MMMM YYYY") : "-";
  return (
    <StyledCard>
      <Link to={`?kind=${kind}`}>
        <h3>{kind}</h3>
        <p className="card-date">Created on: {newDate}</p>
        <p className="card-total">Total values: {total}</p>
      </Link>
    </StyledCard>
  );
};
export default AttributesCard;
