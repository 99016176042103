import React, {useEffect, useState} from "react";
import {Row, TreeState, TreeTable} from "cp-react-tree-table";
import useModelsApi from "../../../api/useModelsApi";
import {CubeViewProps} from "../types";

export const DrilldownPanel = ({modelUuid, query}: CubeViewProps) => {
    const modelsApi = useModelsApi();

    const tableRef = React.createRef<TreeTable>()

    const [table, setTable] = useState<TreeState>(TreeState.createEmpty)

    const handleOnChange = (newValue: TreeState) => {
        setTable(newValue)
    }

    useEffect(() => {
        modelsApi.cubeTable(modelUuid, query).then((response) => {
            setTable(TreeState.create(response.data.children))
        });
    }, [modelsApi, modelUuid, query]);

/*    useEffect(
        () => {
            if (!table) return;

            setTreeState(TreeState.create(table.children))
        },
        [table]
    )*/



    const renderNameCell = (row: Row) => {
        return (
            <span>{JSON.stringify(row.data)}</span>
        );
    }

    const renderIndexCell = (name: string) => (row: Row) => {
        return (
            <div style={{paddingLeft: (row.metadata.depth * 18) + 'px'}}
                 className={row.metadata.hasChildren ? 'with-children' : 'without-children'}>

                {(row.metadata.hasChildren)
                    ? (
                        <button className="toggle-button" onClick={row.toggleChildren}/>
                    )
                    : ''
                }

                <span>{row.data[name]}</span>
            </div>
        );
    }

    const renderCell = (name: string) => (row: Row) => {
        return (
            <span>{row.data[name]}</span>
        );
    }

    const renderCell2 = (fn: (arg0: any) => any) => (row: Row) => {
        const v = fn(row.data)

        return (
            <span>{v ? v : '-'}</span>
        );
    }

    const renderCellHeader = (text: string) => () => {
        return (
            <span>{text}</span>
        );
    }

    const cols = [
        <TreeTable.Column
            renderCell={renderIndexCell("name")}
            renderHeaderCell={renderCellHeader("Name")}
            grow={3}
        />,
        /*        <TreeTable.Column
                    renderCell={renderCell("depth")}
                    renderHeaderCell={renderCellHeader("Depth")}
                />,*/
/*        <TreeTable.Column
            renderCell={renderCell("kind")}
            renderHeaderCell={renderCellHeader("Type")}
        />,*/
        <TreeTable.Column
            renderCell={renderCell2(c => c.summary.count)}
            renderHeaderCell={renderCellHeader("Count")}
            grow={1}
        />,
/*        <TreeTable.Column
            renderCell={renderCell2(row => row.attrCounts.Person)}
            renderHeaderCell={renderCellHeader("Person (count)")}
        />,
        <TreeTable.Column
            renderCell={renderCell2(row => row.attrCounts.Process)}
            renderHeaderCell={renderCellHeader("Process (count)")}
        />,
        <TreeTable.Column
            renderCell={renderCell2(row => row.attrCounts.Tech)}
            renderHeaderCell={renderCellHeader("Tech (count)")}
        />*/
    ]

    return <div>
        <h3>
            Drilldown
        </h3>
        <TreeTable
            ref={tableRef}
            height={400}
            value={table}
            onChange={handleOnChange}
            children={cols}
        />
        {/*<pre>
            {JSON.stringify(table, null, 2)}
        </pre>*/}
    </div>
}