import { useHistory, useLocation } from "react-router";
import { useAuth } from "./useAuth";
import { useCallback } from "react";
import { defaultAuthParams } from "./provider";

export const useLogin = (): Login => {
  const auth = useAuth();
  const location = useLocation();
  const locationState = location.state as any;
  const history = useHistory();
  const nextPathName = locationState && locationState.nextPathname;

  return useCallback(
    ({ email, password }, pathName = defaultAuthParams.afterLoginUrl) =>
      auth.login(email, password).then((ret) => {
        const p = nextPathName || pathName;
        console.log("Pushing " + p);
        history.push(nextPathName || pathName);
        return ret;
      }),
    [auth, history, nextPathName]
  );
};

type Login = (params: any, pathName?: string) => Promise<any>;

export default useLogin;
