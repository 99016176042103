import React from "react";
import styled from "styled-components";

const SVG = (props: Props): JSX.Element => {
  return (
    <svg viewBox="0 0 24 24" width="1em" height="1em" {...props}>
      <path fill="none" d="M0 0h24v24H0z" />
      <path d="M10 18h4v-2h-4v2zM3 6v2h18V6H3zm3 7h12v-2H6v2z" />
    </svg>
  );
};

type Props = {
  onClick?: any;
  size?: number;
} & React.SVGProps<SVGSVGElement>;
type StyledProps = {
  size?: number;
};
const SvgPyramid = styled(SVG)<StyledProps>`
  vertical-align: middle;
  height: ${(props) => (props.size ? props.size + "px" : "16px")};
  width: ${(props) => (props.size ? props.size + "px" : "16px")};
  fill: currentColor;
`;
export default SvgPyramid;
