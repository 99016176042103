import React, { useState, useRef, useLayoutEffect } from "react";
import styled from "styled-components";

const StyledDropdown = styled.div<{ ref?: any }>`
  position: relative;
  width: 100%;
  cursor: pointer;
  user-select: none;
  margin-top: 4px;
`;

const DropdownHeader = styled.div<{ menuOpen: boolean; minimal: boolean }>`
  display: flex;
  font-size: 15px;
  font-weight: 500;
  background-color: ${(p) =>
    (!p.minimal && (p.menuOpen ? "#f3f3f3" : "#fff")) || "#fff"};
  height: 32px;
  line-height: 32px;
  box-sizing: border-box;
  border-radius: 4px;
  display: flex;
  align-items: center;
  border: ${(p) => p.minimal && "2px solid #f3f3f3"};
`;

const DropdownList = styled.div<{
  menuOpen: boolean;
  direction: string;
  minimal: boolean;
}>`
  display: flex;
  flex-direction: ${(p) => p.direction || "column"};
  position: absolute;
  top: 8px;
  margin-top: 28px;
  overflow: hidden;
  white-space: nowrap;
  background-color: ${(p) =>
    (!p.minimal && (p.menuOpen ? "#f3f3f3" : "#fff")) || "#fff"};
  left: 0;
  border-radius: 4px;
  font-size: 15px;
  padding: 4px 0;
  z-index: 99999;
  box-shadow: 0 1px 2px 0 rgba(0, 0, 0, 0.05);
  min-width: 160px;
  border: ${(p) => p.minimal && "2px solid #f3f3f3"};
`;

const DropdownItem = styled.div`
  padding: 4px 16px;
  display: flex;
  align-items: center;
  justify-content: space-between;
  & > .dropdown-item {
    display: flex;
    align-items: center;
    justify-content: space-between;
    width: 100%;
  }
`;

export const NewDropdown = (props: any) => {
  const { title, className, direction, minimal, content } = props;
  const [menuOpen, setMenuOpen] = useState(false);
  const node = useRef<HTMLDivElement>(null);

  const clickOutside = (e: any) => {
    // inside click
    if (node?.current?.contains(e.target)) return;
    // outside click
    setMenuOpen(false);
  };

  useLayoutEffect(() => {
    if (menuOpen) {
      document.addEventListener("mousedown", clickOutside);
    } else {
      document.removeEventListener("mousedown", clickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", clickOutside);
    };
  }, [menuOpen]);

  return (
    <StyledDropdown className={className} ref={node}>
      <DropdownHeader
        menuOpen={menuOpen}
        onClick={() => setMenuOpen((menuOpen) => !menuOpen)}
        minimal={minimal}
      >
        {title}
      </DropdownHeader>

      {menuOpen && (
        <DropdownList
          direction={direction}
          menuOpen={menuOpen}
          minimal={minimal}
        >
          {content && <DropdownItem>{content}</DropdownItem>}
        </DropdownList>
      )}
    </StyledDropdown>
  );
};
export default NewDropdown;
