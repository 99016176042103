import { AnyAction } from "redux";
import { setConfirmDelete } from "./confirmDeleteActions";
import { ThunkAction, ThunkDispatch } from "redux-thunk";
import {
  State,
  getComponentById,
} from "../../../models/views/DraggableReference/stateManager";
import { CLEAR_DIRTY, SET_STATE, StateAction } from "./types";
import {
  apiState2CoapState,
  coapSate2apiState,
  coapComponent2apiComponent,
} from "./apiTranslator";
import store from "../../";
import { Component } from "../../../models/api/model";
import { setTreeToupdate } from "../tree/actions";

export function setState(state: State): StateAction {
  return {
    type: SET_STATE,
    state,
  };
}

export function clearDirty(): StateAction {
  return {
    type: CLEAR_DIRTY,
  };
}

export const thunkGetModel = (): ThunkAction<
  void,
  State,
  unknown,
  AnyAction
> => async (dispatch) => {
  const modelApi = store.getState().modelApi;
  if (modelApi.modelApi === null) return;
  const asyncResp = await modelApi.modelApi.getModelChildren(modelApi.uuid);
  //   console.log(asyncResp.data);
  dispatch(setState(apiState2CoapState(asyncResp.data)));
};

export const thunkSetModel = (): ThunkAction<
  void,
  State,
  unknown,
  AnyAction
> => async (dispatch) => {
  const state = store.getState();
  const modelApi = state.modelApi;
  if (modelApi.modelApi === null) return;
  await modelApi.modelApi.setModelChildren(
    modelApi.uuid,
    coapSate2apiState(state.reference)
  );
  // dispatch(setState(apiState2CoapState(asyncResp.data)));
};

export const thunkAddChild = (
  id: string
): ThunkAction<void, State, unknown, AnyAction> => async (dispatch) => {
  const modelApi = store.getState().modelApi;
  console.log(id);
  if (modelApi.modelApi === null) return;
  const state = store.getState().reference;
  const response = await modelApi.modelApi.addModelChild(
    modelApi.uuid,
    coapComponent2apiComponent(state, getComponentById(state, id))
    // ids.map((id) =>
    //   coapComponent2apiComponent(
    //     state.boxes[id] || state.items[id] || state.subItems[id]
    //   )
    // )
  );
  const data = response.data as any;
  console.log(data);
};

export const thunkRemoveChildren = (
  children: Array<string>,
  nestingLevel: number,
  execute: boolean = false
): ThunkAction<void, State, unknown, AnyAction> => async (dispatch) => {
  console.log(children);
  const state = store.getState();
  const modelApi = state.modelApi;
  if (modelApi.modelApi === null) return;
  const response = await modelApi.modelApi.deleteModelChildren(
    modelApi.uuid,
    children,
    execute
  );
  const data = response.data as any;
  console.log(data);
  if (!data.executed)
    dispatch(
      setConfirmDelete({
        open: true,
        components: data.components || [],
        associations: data.associations || [],
        ids: children,
        nestingLevel,
      })
    );
  else dispatch(thunkPutDirty(state.reference));
};

export const thunkPutDirty = (
  passedState: State | null = null
): ThunkAction<void, State, unknown, AnyAction> => async (dispatch) => {
  const mainState = store.getState();
  const state = passedState || mainState.reference;
  console.log(state);
  dispatch(thunkPutChildren(Object.keys(state.dirty), state));
  dispatch(clearDirty());
};

export const thunkPutChildren = (
  children: Array<string>,
  passedState: State | null = null
): ThunkAction<void, State, unknown, AnyAction> => async (dispatch) => {
  const mainState = store.getState();
  const modelApi = mainState.modelApi;
  if (modelApi.modelApi === null) return;
  const state = passedState || mainState.reference;
  console.log(children);
  const response = await modelApi.modelApi.putModelChildren(
    modelApi.uuid,
    children.reduce<Array<Component>>((total, id) => {
      const comp = getComponentById(state, id);
      if (comp) total.push(coapComponent2apiComponent(state, comp));
      return total;
    }, [])
  );
  const data = response.data as any;
  console.log(data);
  await new Promise((r) => setTimeout(r, 1000));
  dispatch(setTreeToupdate(true));
};

export function dispatchGetModel() {
  (store.dispatch as ThunkDispatch<State, void, AnyAction>)(thunkGetModel());
}

export function dispatchSetModel() {
  (store.dispatch as ThunkDispatch<State, void, AnyAction>)(thunkSetModel());
}

export function dispatchAddChild(id: string) {
  (store.dispatch as ThunkDispatch<State, void, AnyAction>)(thunkAddChild(id));
}

export function dispatchRemoveChildren(
  ids: string[],
  nestingLevel: number,
  execute: boolean = false
) {
  (store.dispatch as ThunkDispatch<State, void, AnyAction>)(
    thunkRemoveChildren(ids, nestingLevel, execute)
  );
}

export function dispatchPutChildren(
  children: string[],
  state: State | null = null
) {
  (store.dispatch as ThunkDispatch<State, void, AnyAction>)(
    thunkPutChildren(children, state)
  );
}

export function dispatchPutDirty(passedState: State | null = null) {
  (store.dispatch as ThunkDispatch<State, void, AnyAction>)(
    thunkPutDirty(passedState)
  );
}
