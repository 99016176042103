import styled from "styled-components";

export const ComponentsMenuWrap = styled.div<{ editable: boolean }>`
  display: flex;
  justify-content: center;
  width: 100%;
  margin-bottom: 15px;
  display: ${(props) => (props.editable ? "auto" : "none")};
`;

export const MenuComponentWrap = styled.div`
  cursor: grab;
  height: 54px;
  width: 54px;
  margin: 4px;
  background-color: white;
  display: flex;
  flex-direction: column;
  align-items: center;
  padding: 4px;
  text-align: center;

  svg {
    min-height: 15px;
  }

  span {
    margin-top: 4px;
    font-size: 12px;
  }
`;
