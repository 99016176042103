import {
  SET_CONFIRM_DELETE,
  OPEN_CONFIRM_DELETE,
  CLOSE_CONFIRM_DELETE,
  ConfirmDeleteAction,
  ConfirmDeleteState,
} from "./types";

const initialState: ConfirmDeleteState = {
  components: [],
  associations: [],
  open: false,
  ids: [],
  nestingLevel: -1,
};

export function confirmDeleteReducer(
  state = initialState,
  action: ConfirmDeleteAction
): ConfirmDeleteState {
  switch (action.type) {
    case SET_CONFIRM_DELETE:
      return { ...action.state };
    case OPEN_CONFIRM_DELETE:
      return { ...state, open: true };
    case CLOSE_CONFIRM_DELETE:
      return { ...state, open: false };
    default:
      return state;
  }
}
