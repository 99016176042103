import styled from "styled-components";

export const AttributesColumnWrap = styled.div`
  margin: 24px 16px;
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  grid-gap: 18px;

  .attr-card {
    display: flex;
    flex-direction: column;
    padding: 8px 12px;
    width: 200px;

    & > h2 {
      margin: 0;
      text-align: center;
      font-size: 18px;
    }

    .attr-data {
      display: flex;
      justify-content: center;
      margin: auto 0;
      padding: 2px 0;
    }

    .data-wrap {
      display: flex;
      font-size: 14px;
      text-align: center;

      & > .data-header {
        font-weight: 500;
        text-transform: uppercase;
        color: #6d7278;
        margin-right: 4px;
      }

      & > .data-value {
        font-weight: 500;
        text-transform: uppercase;
        color: #6d7278;
      }
    }
  }
`;
