import React from "react";
import styled from "styled-components";

const SVG = (props: Props): JSX.Element => {
  return (
    <svg viewBox="0 0 24 24" height="1em" width="1em" {...props}>
      <g fillRule="nonzero" fill="currentColor">
        <path d="M11.2 16.941L5.6 24 0 16.941h4.2V0H7v16.941zM19.8 7.059V24H17V7.059h-4.2L18.4 0 24 7.059z" />
      </g>
    </svg>
  );
};

type Props = {
  onClick?: any;
  size?: number;
} & React.SVGProps<SVGSVGElement>;
type StyledProps = {
  size?: number;
};
const SvgSort = styled(SVG)<StyledProps>`
  vertical-align: middle;
  height: ${(props) => (props.size ? props.size + "px" : "16px")};
  width: ${(props) => (props.size ? props.size + "px" : "16px")};
  fill: currentColor;
`;
export default SvgSort;
