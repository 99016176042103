import { useContext, useMemo } from "react";
import AuthContext, { Auth } from "./index";

export function useAuth(): Auth {
  const ctx = useContext(AuthContext);

  return useMemo(() => {
    return ctx as Auth;
  }, [ctx]);
}

export default useAuth;
