import { SynthesisSummary, SelectedKind } from "../../projectspaces/api/model";
import { AnyAction } from "redux";
import { ProjectsApi } from "../../projectspaces/api";
import SynthesisApi from "../../projectspaces/api/SynthesisApi";
import { ThunkAction } from "redux-thunk";
import store from "../";
import {
  DeltaAction,
  SET_SUMMARY,
  DeltaState,
  SET_TARGET_MODEL,
  SET_SOURCE_MODEL,
  SET_MODELS,
  SET_APIS,
  SET_SELECTED_KIND,
  SET_SELECTED_DETAILS,
} from "./types";
import { Model } from "../../models/api";

export function setSummary(summary: SynthesisSummary): DeltaAction {
  return {
    type: SET_SUMMARY,
    summary,
  };
}

export function setTarget(modelId: string, modelName: string) {
  return {
    type: SET_TARGET_MODEL,
    modelId,
    modelName,
  };
}

export function setSource(modelId: string, modelName: string) {
  return {
    type: SET_SOURCE_MODEL,
    modelId,
    modelName,
  };
}

export function setModels(models: Array<Model>) {
  return {
    type: SET_MODELS,
    models,
  };
}

export function setApis(projectApi: ProjectsApi, deltaApi: SynthesisApi) {
  return {
    type: SET_APIS,
    projectApi,
    deltaApi,
  };
}

export function setSelectedKind(selectedKind: SelectedKind) {
  return {
    type: SET_SELECTED_KIND,
    selectedKind,
  };
}

export function setSelectedDetails(details: SynthesisSummary) {
  return {
    type: SET_SELECTED_DETAILS,
    details,
  };
}

export const loadSummary = (
  srcId: string,
  tgtId: string
): ThunkAction<void, DeltaState, unknown, AnyAction> => async (dispatch) => {
  const mainState = store.getState();
  mainState.delta.deltaApi?.getSummary(srcId, tgtId).then((response) => {
    console.log(response);
    dispatch(setSummary(response.data));
  });
};

export const loadModels = (
  prjId: string
): ThunkAction<void, DeltaState, unknown, AnyAction> => async (dispatch) => {
  const mainState = store.getState();
  mainState.delta.projectApi?.getModels(prjId).then((response) => {
    console.log(response);
    dispatch(setModels(response.data));
    if (response.data.length > 1) {
      dispatch(setSource(response.data[0]?.uuid, response.data[0]?.name));
      dispatch(setTarget(response.data[1]?.uuid, response.data[1]?.name));
    }
  });
};

export const loadSelectedDetails = (): ThunkAction<
  void,
  DeltaState,
  unknown,
  AnyAction
> => async (dispatch) => {
  const delta = store.getState().delta;
  if (!delta.selectedKind) return;
  delta.deltaApi
    ?.getDetailed(
      delta.srcModelId as string,
      delta.tgtModelId as string,
      delta.selectedKind?.kind
    )
    .then((response) => {
      console.log(response);
      dispatch(setSelectedDetails(response.data));
    });
};
