import React, { useState, useEffect } from "react";
import { Link } from "react-router-dom";
import styled from "styled-components";
import {
  Dropdown,
  DropdownItem,
  SimpleModal,
  AppToaster,
} from "../../components";
import dayjs from "dayjs";
import InfoProject from "../views/ClientspaceHome/InfoProject";
import { Icons } from "../../assets/Icons";
import useProjectsApi from "../../projectspaces/api/useProjectsApi";

const StyledCard = styled.div`
  display: flex;
  flex-direction: column;
  color: #061d38;
  box-shadow: 0px 0px 9px -2px rgba(0, 0, 0, 0.4);
  border-radius: 4px;
  padding: 32px 24px 12px;
  height: 160px;
  line-height: 1;
  font-size: 16px;
  position: relative;
  box-sizing: content-box;

  h3 {
    margin: 0;
    font-size: 24px;
    margin-bottom: 10px;
  }

  a {
    text-decoration: none;
    color: inherit;
    overflow: hidden;

    :hover {
      opacity: 0.7;
    }
  }

  p {
    margin: 0;
    color: #6d7278;
    letter-spacing: 0.035em;
    line-height: 1.5;
    overflow: hidden;
    word-break: break-all;

    &.card-date {
      font-weight: 500;
      font-size: 14px;
    }
  }

  a > div {
    margin-top: 12px;
    gap: 8px;
  }

  & > .more-i {
    position: absolute;
    top: 0;
    right: 0;
    cursor: pointer;
    padding: 6px 12px 0;
  }
`;

const BottomCard = styled.div`
  display: flex;
  margin-top: auto;
`;

const ProjectMembers = styled.ul`
  margin: 0;
  padding: 0;
  margin-top: auto;
  list-style: none;
  display: grid;

  & > li {
    margin: 0;
    padding: 0;
  }
`;

const ProjectCard = (props: any) => {
  const { name, createdAt, description, users, uuid } = props?.projectDetails;

  const newDate = dayjs(createdAt).format("DD MMMM YYYY");
  const projectsApi = useProjectsApi();

  const [infoModal, setInfoModal] = useState(false);
  const [deleteModal, setDeleteModal] = useState(false);
  const [isAdmin, setIsAdmin] = useState(false);

  useEffect(() => {
    const userObj = users.filter((user: any) => user.id === props.profileID);
    if (userObj[0]?.role === "Owner" || userObj[0]?.role === "Admin")
      setIsAdmin(true);
  }, [users, props.profileID]);

  function doDelete() {
    projectsApi
      .deleteProject(uuid)
      .then((response) => {
        if (response.statusText === "OK") {
          AppToaster.show({
            message: "Project was successfully deleted!",
            intent: "success",
          });

          setDeleteModal(false);
          props.refresh();
        }
      })
      .catch((error) => {
        AppToaster.show({
          message: "Something went wrong, please try again!",
          intent: "danger",
        });

        setDeleteModal(false);
        console.log(error);
      });
  }

  return (
    <>
      <StyledCard>
        <Link to={`/projects/${uuid}`}>
          <h3>{name}</h3>
          <p className="card-date">Created on {newDate}</p>

          <div>
            <p>{description}</p>
          </div>
        </Link>

        <Dropdown
          left={true}
          textAlign="left"
          className="more-i"
          title={<Icons.More size={21} />}
        >
          <DropdownItem onClick={() => setInfoModal(true)}>Info</DropdownItem>
          {isAdmin && (
            <DropdownItem onClick={() => setDeleteModal(true)}>
              Delete
            </DropdownItem>
          )}
        </Dropdown>

        <BottomCard>
          <ProjectMembers
            style={{ gridTemplateColumns: `repeat(${users?.length}, 20px)` }}
          >
            {users?.length > 0 &&
              users.map((e: any, i: number) => {
                return (
                  <li key={i}>
                    <img
                      src={
                        "https://eu.ui-avatars.com/api/?background=0F1A28&color=fff&name=" +
                        e.name +
                        "&rounded=true&size=32&bold=true"
                      }
                      alt=""
                      className="project-member"
                      title={e.name}
                    />
                  </li>
                );
              })}
          </ProjectMembers>
        </BottomCard>
      </StyledCard>

      {infoModal && (
        <InfoProject
          ID={uuid}
          title={name}
          projectDetails={props.projectDetails}
          onClose={() => setInfoModal(false)}
          isOpen={infoModal}
        />
      )}

      {deleteModal && (
        <SimpleModal
          title="Delete project"
          refresh={props.refresh}
          closeIcon={true}
          closeHandler={() => setDeleteModal((deleteModal) => !deleteModal)}
          closeText="Cancel"
          acceptHandler={doDelete}
          acceptText="Delete"
          text="Are you sure you want to delete this Project? All components will be permanently deleted and team members informed"
        />
      )}
    </>
  );
};
export default ProjectCard;
