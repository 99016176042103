import React, { useState, useLayoutEffect, useRef } from "react";
import styled from "styled-components";
import { Icons } from "../assets/Icons";

const StyledPagination = styled.div`
  display: flex;
  align-items: center;

  &.components-pagination {
    margin-left: auto;
    padding-right: 34px;
  }

  svg {
    cursor: pointer;
  }

  &.attr-ref-pages {
    margin-left: auto;
    margin-right: 16px;
    margin-bottom: 8px;
  }
`;

const PaginationText = styled.button`
  backgroud: none;
  border: none;
  background-color: inherit;
  font-size: 16px;
  padding: 0;
  cursor: text;

  input {
    width: 82px;
  }
`;

const Pagination = (props: any) => {
  const [editByInput, setEditByInput] = useState(false);
  const [userPage, setUserPage] = useState(1);
  const { totalPages, handlePageChange, className } = props;
  const node = useRef<HTMLButtonElement>(null);

  const clickOutside = (e: any) => {
    // inside click
    if (node?.current?.contains(e.target)) return;
    // outside click
    handlePageChange(userPage);
    setEditByInput(false);
  };
  useLayoutEffect(() => {
    if (editByInput) {
      document.addEventListener("mousedown", clickOutside);
    } else {
      document.removeEventListener("mousedown", clickOutside);
    }

    return () => {
      document.removeEventListener("mousedown", clickOutside);
    }; // eslint-disable-next-line
  }, [editByInput]);

  return (
    <StyledPagination className={className}>
      {totalPages > 1 && (
        <Icons.ArrowLeft
          size={24}
          onClick={() => {
            handlePageChange(userPage > 1 ? userPage - 1 : userPage);
            setUserPage(userPage > 1 ? userPage - 1 : userPage);
          }}
        />
      )}
      <PaginationText onClick={() => setEditByInput(true)} ref={node}>
        {editByInput ? (
          <form
            onSubmit={(e: any) => {
              e.preventDefault();
              setEditByInput(false);
              handlePageChange(userPage);
            }}
          >
            <input
              id={props.id}
              type="number"
              min={1}
              value={userPage}
              onChange={(e: any) => {
                setUserPage(parseInt(e.target.value, 10));
              }}
              autoFocus
              max={totalPages || 1}
            />
          </form>
        ) : (
          `${userPage || 1} of ${totalPages || "1"}`
        )}
      </PaginationText>
      {totalPages > 1 && (
        <Icons.ArrowRight
          size={24}
          onClick={() => {
            handlePageChange(userPage < totalPages ? userPage + 1 : totalPages);
            setUserPage(userPage < totalPages ? userPage + 1 : totalPages);
          }}
        />
      )}
    </StyledPagination>
  );
};
export default Pagination;
