import { ComponentStyle } from "./reference/commonTypes";
import {
  SET_TOOLBAR,
  SET_EDIT_MODE,
  ToolbarAction,
  SET_RESIZE_MODE,
} from "./toolbarTypes";
// import { setSelectedStyles, dispatchSetModel } from "./coap/coapActions";
import store from "../";

export function setToolbar(style: ComponentStyle): ToolbarAction {
  return {
    type: SET_TOOLBAR,
    style,
  };
}

export function setEditMode(editable: boolean): ToolbarAction {
  return {
    type: SET_EDIT_MODE,
    editable,
  };
}

export function setResizingMode(resizing: boolean): ToolbarAction {
  return {
    type: SET_RESIZE_MODE,
    resizing,
  };
}

export function dispatchSetToolbar(style: ComponentStyle) {
  store.dispatch(setToolbar(style));
  // store.dispatch(setSelectedStyles(style));
  // if (store.getState().coap.hasSelectedComponent()) {
  //   dispatchSetModel(store.getState().coap);
  // }
}

export function dispatchSetResizingMode(resizing: boolean) {
  store.dispatch(setResizingMode(resizing));
  // store.dispatch(setSelectedStyles(style));
  // if (store.getState().coap.hasSelectedComponent()) {
  //   dispatchSetModel(store.getState().coap);
  // }
}
