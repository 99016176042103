import React from "react";
import styled from "styled-components";
import { Link } from "react-router-dom";
import { Icons } from "../../assets/Icons";

const StyledCard = styled(Link)`
  display: block;
  color: inherit;
  text-decoration: none;
  text-align: center;
  box-sizing: content-box;

  & > svg {
    margin-right: 4px;
    min-width: 16px;
    min-height: 16px;
    display: inline;
  }
`;

const PyramidCard = (props: any) => {
  const { name, icon, to } = props;
  let stepIcon = null;

  switch (icon) {
    case "division":
      stepIcon = <Icons.Division />;
      break;

    case "businessUnit":
      stepIcon = <Icons.BusinessUnit />;
      break;

    case "function":
      stepIcon = <Icons.Function />;
      break;

    case "subFunction":
      stepIcon = <Icons.SubFunction />;
      break;
  }

  return (
    <StyledCard to={to}>
      {stepIcon}
      {name}
    </StyledCard>
  );
};
export default PyramidCard;
