import React from "react";
import styled from "styled-components";
import { Button, NavLogo } from "../../components";
import { Link } from "react-router-dom";
import { Icons } from "../../assets/Icons";

const ListNav = styled.nav`
  background-color: #fefdfe;
  border-right: 2px solid #f3f3f3;
  text-align: center;
  padding-bottom: 16px;
`;

const LinkList = styled.ul`
  list-style: none;
  margin: 0;
  padding: 0;

  & > li {
    margin: 0;
    padding: 0;
    font-size: 21px;
    letter-spacing: 0.025em;
    display: flex;
    align-items: center;
    margin-bottom: 4px;
    cursor: pointer;

    &.active {
      background-color: #061d38;
      color: #fff;
    }

    &:hover {
      background-color: #061d38;
      color: #fff;
    }

    &:last-of-type {
      margin-bottom: 0;
    }
  }
`;

const NavLink = styled(Link)`
  color: inherit;
  text-decoration: none;
  font-family: inherit;
  display: flex;
  align-items: center;
  font-size: inherit;
  padding: 16px;
  padding-right: 8px;
  width: 100%;

  & > span {
    margin-left: 12px;
    text-align: left;
  }
`;

const ProjectsNav = (props: any) => {
  const { match, active } = props;

  return (
    <ListNav>
      <Link to="/">
        <NavLogo />
      </Link>

      <LinkList>
        <li className={active === "project-home" ? "active" : ""}>
          <NavLink to={`/projects/${match?.params.project}`}>
            <Icons.Models size={24} />
            <span>Models</span>
          </NavLink>
        </li>
        <li className={active === "project-data" ? "active" : ""}>
          <NavLink to={`/projects/${match?.params.project}/data`}>
            <Icons.ProjectData size={24} />
            <span>Project Data</span>
          </NavLink>
        </li>
        <li className={active === "delta" ? "active" : ""}>
          <NavLink to={`/projects/${match?.params.project}/delta-data`}>
            <Icons.Delta size={24} />
            <span>Delta</span>
          </NavLink>
        </li>
        {/*
        <li className={active === "project-activity" ? "active" : ""}>
          <NavLink to={`${match.url}`}>
            <Icons.Activity size={24} />
            <span>Activity</span>
          </NavLink>
        </li>*/}
        <li className={active === "project-members" ? "active" : ""}>
          <NavLink to={`/projects/${match?.params.project}/members`}>
            <Icons.Team size={24} />
            <span>Team members</span>
          </NavLink>
        </li>
        <li className={active === "project-settings" ? "active" : ""}>
          <NavLink to={`/projects/${match?.params.project}/settings`}>
            <Icons.Settings size={24} />
            <span>Project settings</span>
          </NavLink>
        </li>
      </LinkList>

      <Button
        href="mailto:feedback@opteq.co"
        w="150px"
        as="a"
        bgcolor="#52B788"
      >
        Give feedback
      </Button>
    </ListNav>
  );
};
export default ProjectsNav;
