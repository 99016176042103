import styled from "styled-components";

// TODO: we should probably move other components used only by the Synthesis view here
export const SummaryCardWrap = styled.div`
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  grid-gap: 16px;
`;

export const ChartsWrap = styled.div`
  margin-top: 48px;
  margin-bottom: 24px;
  display: grid;
  grid-template-columns: repeat(6, minmax(0, 1fr));
  grid-template-rows: repeat(4, 180px);
  grid-gap: 16px;

  & > .total-attr {
    grid-column: 5/7;
  }

  & > .total-left-attr {
    grid-column: 1/5;
  }
`;
