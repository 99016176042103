import Axios, { AxiosInstance, AxiosPromise } from "axios";

import { UpdateUserProfile, UserProfile } from "./model";
import { API_URI } from "../../config";

class UserApi {
  static context = API_URI + "api/v1/user";

  client: AxiosInstance;

  constructor(token: string) {
    this.client = Axios.create({
      headers: {
        Authorization: `Bearer ${token}`,
      },
    });
  }

  updateProfile(payload: UpdateUserProfile): AxiosPromise<UserProfile> {
    return this.client.patch(`${UserApi.context}`, payload);
  }

  getProfile(): AxiosPromise<UserProfile> {
    return this.client.get(`${UserApi.context}`);
  }
}

export default UserApi;
