import React, { useEffect, useState, useRef } from "react";
import DashBoardConstant from "../Reference/DashBoardConstant";
import { DragReferenceWrap } from "../DraggableReference/styles";
import TreeMenu from "../../components/TreeMenu/TreeMenu";
import { BoxRow } from "../DraggableReference/components/Row";
import { Model } from "../../../models/api/model";
import NewComponentsMenu from "../DraggableReference/components/NewComponentsMenu";
import ReferenceContent from "../DraggableReference/ReferenceContent";
import ConfirmDelete from "../DraggableReference/components/ConfirmDelete";
import { Props } from "./";

// import useAuth from "../../../auth/context/useAuth";
import useModelsApi from "../../api/useModelsApi";

import { RouteComponentProps } from "react-router";
import {
  setOnStateChange,
  setDivReference,
  removeSelected,
  copySelected,
  pasteCopied,
  setSelectedStyle,
  OnMount,
  OnUnmount,
} from "../DraggableReference/stateManager";

// import { dispatchGetModel } from "../../../store/referencePage/coap/coapActions";
type ViewProps = Props &
  RouteComponentProps<{
    modelUuid: string;
    childId: string;
  }>;

export const View: React.FC<ViewProps> = (props) => {
  const { reference, setState } = props;
  const [modelData, setModelData] = useState<Model>();
  const getModel = props.thunkGetModel;
  const divRef = useRef<HTMLDivElement>(null);
  const modelsApi = useModelsApi();

  useEffect(() => {
    props.setModelUuid(props.match.params.modelUuid, modelsApi);
  }, [props, modelsApi]);

  useEffect(() => {
    modelsApi.getModel(props.match?.params.modelUuid).then((response) => {
      setModelData(response?.data);
    });
  }, [modelsApi, props.match]);

  useEffect(() => {
    getModel();
  }, [getModel]);

  useEffect(() => {
    setOnStateChange(setState);
    setDivReference(divRef);
    OnMount(undefined);
    return () => {
      OnUnmount();
    };
  }, [setState]);

  const onDelete = (ids: string[], nestingLevel: number) => {
    removeSelected(reference, ids, nestingLevel);
  };

  return (
    <DashBoardConstant
      match={props.match}
      onCopy={copySelected}
      onPaste={pasteCopied}
      onStyle={setSelectedStyle}
      withTree
      modelInfo={modelData}
    >
      <TreeMenu
        ID={props.match?.params.modelUuid}
        childId={props.match?.params.childId}
        fromCOAP
      >
        <NewComponentsMenu
          state={props.reference}
          editable={props.editable}
          nestingLevel={0}
        />
      </TreeMenu>
      <ReferenceContent state={props.reference} nestingLevel={0}>
        <ConfirmDelete onDelete={onDelete} />
        <DragReferenceWrap ref={divRef}>
          {reference &&
            reference.rowsOrder.map((id, i) => {
              const rowData = reference.rows[id];
              return (
                <BoxRow
                  id={rowData.id}
                  key={rowData.id}
                  content={rowData.content}
                  state={reference}
                  pos={i}
                  editable={props.editable}
                  isResizing={props.isResizing}
                  last={i >= reference.rowsOrder.length - 1}
                />
              );
            })}
        </DragReferenceWrap>
      </ReferenceContent>
    </DashBoardConstant>
  );
};

export default View;
