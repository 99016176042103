import React, {DependencyList, MutableRefObject, ReactNode, useEffect, useRef, useState} from 'react';
import * as d3 from 'd3';
import {Selection} from "d3-selection";

export function useHover<T>(): [MutableRefObject<T>, boolean] {
    const [value, setValue] = useState<boolean>(false);
    const ref: any = useRef<T | null>(null);
    const handleMouseOver = (): void => setValue(true);
    const handleMouseOut = (): void => setValue(false);
    useEffect(
        () => {
            const node: any = ref.current;
            if (node) {
                node.addEventListener("mouseover", handleMouseOver);
                node.addEventListener("mouseout", handleMouseOut);
                return () => {
                    node.removeEventListener("mouseover", handleMouseOver);
                    node.removeEventListener("mouseout", handleMouseOut);
                };
            }
        },
        [ref.current] // Recall only if ref changes
    );
    return [ref, value];
}

export const useD3 = (renderChartFn: (el: any) => ReactNode, deps?: DependencyList) => {
    const ref = React.useRef<SVGGElement | undefined>(undefined);

    React.useEffect(() => {
        renderChartFn(d3.select(ref.current as SVGGElement));
        return () => {
        };
    }, deps);
    return ref;
}