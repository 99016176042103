import {CubeOptions, CubeQuery} from "./types";

export {default as ModelCubeView} from "./CubeView";

export const defaultInsightOptions: CubeOptions = {
    /*breakdown: {
        selection: [],
        options: []
    },*/
    drilldown: {
        selection: [],
        options: []
    },
    components: {
        selection: [],
        options: []
    },
    locations: {
        selection: [],
        options: []
    },
    attributes: {
        selection: [],
        options: []
    }
}

export const defaultQuery: CubeQuery = {
    // breakdown: "World",
    drilldown: undefined,
    filters: {
        component: [],
        location: [],
        attribute: []
    }
}