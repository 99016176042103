import React from "react";
import { useDrag } from "react-use-gesture";
import { Circle } from "./styles";
import { CircleProps } from "./types";

const View: React.FC<CircleProps> = ({
  rotateCallback,
  initPosition,
  onRotateEnd,
}) => {
  const dragBind = useDrag(({ movement: [x, y], last }) => {
    if (last) {
      onRotateEnd();
      return;
    }
    x += initPosition[0];
    y += initPosition[1];
    if (Math.pow(x, 2) + Math.pow(y, 2) < 250) return;
    rotateCallback(Math.atan2(y, x) * (180 / Math.PI));
  });
  return <Circle {...dragBind()} />;
};

export default View;
