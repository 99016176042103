import React, { useEffect, useState } from "react";
import {
  Tabs,
  Tab,
  FormGroup,
  InputGroup,
  Button,
  MenuItem,
} from "@blueprintjs/core";
import { StyledDialog } from "./styles";
import { SchemaAttribute } from "../../../../api/model";
import useModelsApi from "../../../../api/useModelsApi";
import useProjectsApi from "../../../../../projectspaces/api/useProjectsApi";
import {
  KindAttributeArray,
  Attribute,
} from "../../../../../projectspaces/api/model";
import { Select, ItemRenderer } from "@blueprintjs/select";
import NumberFormat from "react-number-format";
import { Icons } from "../../../../../assets/Icons";
import { AppToaster } from "../../../../../components";

const SyntheticDataDialog: React.FC<{
  isOpen: any;
  _ATTRIBUTE: string;
  onClose: any;
  projectUuid: string;
  modelUuid: string | undefined;
  childId: string;
}> = (props) => {
  const [columnNames, setColumnNames] = useState<Array<SchemaAttribute>>([]);
  const modelsApi = useModelsApi();

  useEffect(() => {
    modelsApi.getSchemaAttributes().then((response) => {
      const temp_arr = [];
      temp_arr.push(response?.data);

      const INDEX = temp_arr[0].findIndex(
        (attr) => attr?.kind === props._ATTRIBUTE
      );

      if (INDEX > -1) {
        let COL_NAMES = temp_arr[0][INDEX]?.properties
          ? Object.values(temp_arr[0][INDEX]?.properties) || []
          : [];
        let COL_KEYS = temp_arr[0][INDEX]?.properties
          ? Object.keys(temp_arr[0][INDEX]?.properties)
          : [];

        for (let i = 0; i < COL_NAMES.length; i++) {
          COL_NAMES[i].meta.key = COL_KEYS[i];
        }

        setColumnNames(COL_NAMES);
      }
    });
  }, [modelsApi, props._ATTRIBUTE]);

  return (
    <StyledDialog
      isOpen={props.isOpen}
      title={
        <>
          <span>Generate synthetic data</span>
          <span>{" / " + props._ATTRIBUTE}</span>
        </>
      }
      onClose={props.onClose}
    >
      <div className="dialog-wrap">
        <Tabs id="TabsExample" animate={false}>
          <Tab
            id="info-p"
            className="custom-tab-btn"
            title="Info"
            panel={
              <InfoPanel
                columnNames={columnNames}
                projectUuid={props.projectUuid}
                modelUuid={props.modelUuid}
                _ATTRIBUTE={props._ATTRIBUTE}
                childId={props.childId}
                onClose={props.onClose}
              />
            }
            panelClassName="info-panel-wrap"
          />
        </Tabs>
      </div>
    </StyledDialog>
  );
};
export default SyntheticDataDialog;

const InfoPanel = (props: any) => {
  const { columnNames, modelUuid, _ATTRIBUTE, childId } = props;
  const [countNumber, setCountNumber] = useState<string>();
  const [propertiesValues, setPropertiesValues] = useState<{
    [key: string]: string | number;
  }>();
  const modelsApi = useModelsApi();

  function handleCreatingSyntheticData() {
    if (propertiesValues) {
      const POST_OBJ = {
        id: "" + Math.random().toString(36).substr(2, 9),
        kind: _ATTRIBUTE,
        partial: true,
        properties: propertiesValues,
        split: {
          [childId]: 1,
        },
        count: countNumber ? parseInt(countNumber) : 1,
      };

      modelsApi
        .createPartialAllocations(modelUuid, POST_OBJ)
        .then((response) => {
          if (response.statusText === "OK") {
            AppToaster.show({
              message: "Synthentic Data was successfully created",
              intent: "success",
            });

            props.onClose();
          }
        })
        .catch((error) => {
          AppToaster.show({
            message: "Something went wrong, please try again",
            intent: "danger",
          });

          console.log(error);
          props.onClose();
        });
    }
  }

  useEffect(() => {
    const properties: { [key: string]: any } = {};

    Object.values(columnNames).map((col: any) => {
      if (col?.type === "number") {
        properties[col?.meta?.key] = 1;
      }

      if (col?.type === "link") {
        properties[col?.meta?.key] = null;
      }

      return col;
    });

    setPropertiesValues(properties);
  }, [columnNames]);

  function handleInput(key: string, value: string, type?: any) {
    setPropertiesValues({
      ...propertiesValues,
      [key]: value,
    });
  }

  return propertiesValues ? (
    <>
      <div className="info-content">
        {columnNames.map((col: any) =>
          col?.type === "number" ? (
            <FormGroup
              label={col?.meta?.name}
              labelFor={`${col?.meta?.name}-input`}
            >
              <NumberFormat
                value={propertiesValues[col?.meta?.key]}
                displayType={"input"}
                thousandSeparator={true}
                onValueChange={(values: any) => {
                  handleInput(col?.meta?.key, values?.floatValue, col?.type);
                }}
                isNumericString
                className="formatted-input"
                id={`${col?.meta?.name}-input`}
                defaultValue="1"
              />
            </FormGroup>
          ) : col?.type === "link" && props.projectUuid ? (
            <DropdownInput
              projectUuid={props.projectUuid}
              kind={col?.target?.kind}
              label={col?.meta?.name}
              colKey={col?.meta?.key}
              handleInput={handleInput}
              propertiesValues={propertiesValues}
            />
          ) : (
            <FormGroup
              label={col?.meta?.name}
              labelFor={`${col?.meta?.name}-input`}
            >
              <InputGroup
                id={`${col?.meta?.name}-input`}
                value="Null"
                type="string"
                readOnly
              />
            </FormGroup>
          )
        )}
      </div>
      <div className="info-content">
        <FormGroup label="Count" labelFor="count-input">
          <NumberFormat
            value={countNumber}
            displayType={"input"}
            thousandSeparator={true}
            onChange={(e: any) => setCountNumber(e.target.value)}
            isNumericString
            className="formatted-input"
            id="count-input"
            defaultValue="1"
            allowNegative={false}
          />
        </FormGroup>

        <Button
          className="save-info-btn"
          text="Save"
          fill
          onClick={handleCreatingSyntheticData}
        />
      </div>
    </>
  ) : (
    <span></span> // todo: loading in here?
  );
};

const DropdownInput = (props: any) => {
  const [dropdownValues, setDropdownValues] = useState<KindAttributeArray>();
  const [selectedValue, setSelectedValue] = useState<string>("Null");
  const projectsApi = useProjectsApi();

  useEffect(() => {
    projectsApi.getAttribute(props.projectUuid, props.kind).then((response) => {
      setDropdownValues(response?.data);
    });
  }, [projectsApi, props.kind, props.projectUuid]);

  const renderItem: ItemRenderer<Attribute> = (
    attribute,
    { handleClick, modifiers }
  ) => {
    if (!modifiers.matchesPredicate) {
      return null;
    }

    return (
      <MenuItem
        text={attribute?.properties?.name}
        onClick={handleClick}
        key={attribute?.id}
        active={props.propertiesValues[props?.colKey] === attribute?.id}
      />
    );
  };

  return dropdownValues ? (
    <Select
      items={dropdownValues?.results}
      noResults={<MenuItem disabled={true} text="No results." />}
      onItemSelect={(e: any) => {
        setSelectedValue(e?.properties?.name);
        props.handleInput(props?.colKey, e.id);
      }}
      filterable={false}
      itemRenderer={renderItem}
      popoverProps={{
        minimal: true,
        popoverClassName: "dropdown-input-portal",
      }}
      className="dropdown-input"
    >
      <FormGroup label={props?.label} labelFor={`${props?.label}-input`}>
        <Button
          text={selectedValue}
          minimal
          icon={<Icons.ArrowDown />}
          className="input-btn"
        />
      </FormGroup>
    </Select>
  ) : (
    <span></span>
  );
};
