// import { coapReducer as coap } from "./coap/coapReducer";
import { toolbarReducer as toolbar } from "./toolbarReducer";
import { modelReducer as modelApi } from "./model/modelReducer";
import { referenceReducer as reference } from "./reference/reducer";
import { confirmDeleteReducer as confirmDelete } from "./reference/confirmDeleteReducer";

// !! CHANGE THIS LATER !! //
import { treeReducer as tree } from "./tree/reducer";

export default { toolbar, modelApi, reference, confirmDelete, tree };
