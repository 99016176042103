import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Tree, ITreeNode } from "@blueprintjs/core";
import { TreeModel } from "../../models/api/model";
import useModelsApi from "../../models/api/useModelsApi";
import { Icons } from "../../assets/Icons";
import traverse from "traverse";

const TreeWrap = styled.div`
  display: flex;
  flex-direction: column;
  padding: 0 16px;

  .bp3-tree {
    color: #061d38;
  }

  .bp3-tree-node-content {
    width: auto;
  }

  .bp3-icon {
    display: flex;
    justify-content: center;
    color: #061d38;
  }

  .bp3-tree-node-caret {
    min-width: auto;
    padding: 6px;
  }

  .custom-tree-icon {
    width: 14px;
    height: 14px;
    margin-right: 4px;
    min-width: 14px;
    min-height: 14px;
  }

  /* used only for changing caret color when a tree-node is selected to maintain correct contrast  */
  .bp3-tree-node.bp3-tree-node-selected > .bp3-tree-node-content,
  .bp3-tree-node.bp3-tree-node-selected > .bp3-tree-node-content .bp3-icon,
  .bp3-tree-node.bp3-tree-node-selected
    > .bp3-tree-node-content
    .bp3-icon-standard,
  .bp3-tree-node.bp3-tree-node-selected
    > .bp3-tree-node-content
    .bp3-icon-large {
    color: #061d38;
  }

  .bp3-tree-node.bp3-tree-node-selected > .bp3-tree-node-content {
    background-color: #e8e7e5;
    color: #061d38;
  }

  .bp3-tree-node-content:hover {
    background-color: #e8e7e5;
    color: #061d38;
  }
`;

const DeltaTree = (props: any) => {
  const {
    ID,
    setSelectedComponent,
    selectedComponent,
    setBreadcrumbComponent,
    refreshTree,
  } = props;
  const modelsApi = useModelsApi();
  const [treeView, setTreeView] = useState<Array<TreeModel>>([]);

  const handleNodeClick = (
    nodeData: ITreeNode,
    _nodePath: number[],
    e: React.MouseEvent<HTMLElement>
  ) => {
    const originallySelected = nodeData.isSelected;
    if (!e.shiftKey) {
      forEachNode(treeView, (n) => (n.isSelected = false));
    }
    nodeData.isSelected =
      originallySelected == null ? true : !originallySelected;
    const nextState = [...treeView];
    setTreeView(nextState);

    // unselect component when it's currently selected and clicked again by the user
    if (nodeData.id === selectedComponent) {
      setSelectedComponent(undefined);
      return;
    }
    setSelectedComponent(nodeData.id);
    setBreadcrumbComponent(nodeData.label);
  };

  const handleNodeCollapse = (nodeData: ITreeNode) => {
    nodeData.isExpanded = false;
    const nextState = [...treeView];
    setTreeView(nextState);
  };

  const handleNodeExpand = (nodeData: ITreeNode) => {
    nodeData.isExpanded = true;
    const nextState = [...treeView];
    setTreeView(nextState);
  };

  function forEachNode(
    nodes: ITreeNode[],
    callback: (node: ITreeNode) => void
  ) {
    if (nodes == null) {
      return;
    }

    for (const node of nodes) {
      callback(node);
      if (node.childNodes) {
        forEachNode(node.childNodes, callback);
      }
    }
  }

  useEffect(() => {
    // call the getTreeView endpoint on the page load
    // and to reset the tree state when users deletes what they searched for
    modelsApi.getTreeView(ID).then((response) => {
      const tree = traverse(response?.data).map(function (node: any) {
        // transfer every node with an icon name to a proper JSX icon element
        if (node?.icon) {
          switch (node.icon) {
            case "division":
              node.icon = <Icons.Division className="custom-tree-icon" />;
              node.hasCaret = node?.childNodes.length > 0 ? true : false;
              break;

            case "businessUnit":
              node.icon = <Icons.BusinessUnit className="custom-tree-icon" />;
              node.hasCaret = node?.childNodes.length > 0 ? true : false;
              break;

            case "function":
              node.icon = <Icons.Function className="custom-tree-icon" />;
              node.hasCaret = node?.childNodes.length > 0 ? true : false;
              break;

            case "subFunction":
              node.icon = <Icons.SubFunction className="custom-tree-icon" />;
              node.hasCaret = node?.childNodes.length > 0 ? true : false;
              break;
          }
        }
        return node;
      });
      setTreeView(tree);
    });
  }, [ID, modelsApi, refreshTree]);

  return (
    <TreeWrap>
      <Tree
        contents={treeView}
        onNodeClick={handleNodeClick}
        onNodeCollapse={handleNodeCollapse}
        onNodeExpand={handleNodeExpand}
      />
    </TreeWrap>
  );
};
export default DeltaTree;
