import { SET_MODEL_UUID, ModelAction, ModelState } from "./modelTypes";

const initialState: ModelState = {
  uuid: "",
  modelApi: null,
};

export function modelReducer(
  state = initialState,
  action: ModelAction
): ModelState {
  switch (action.type) {
    case SET_MODEL_UUID:
      return { uuid: action.uuid, modelApi: action.modelApi };
    default:
      return state;
  }
}
