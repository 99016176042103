import React from "react";
import { StyledCard } from "./styles";

const SalaryCard = (props: any) => {
  const { data, srcModel, tgtModel } = props;
  const srcLoadedCost = data?.total.src?.aggregates?.loadedCost;
  const tgtLoadedCost = data?.total.tgt?.aggregates?.loadedCost;

  return (
    <StyledCard className="total-attr">
      <h3>Salary</h3>
      <div className="salary-table-wrap">
        {srcLoadedCost || tgtLoadedCost ? (
          <table>
            <thead>
              <tr>
                <th>Model:</th>
                <th>Count:</th>
              </tr>
            </thead>
            <tbody>
              <tr>
                <td>Source: {srcModel}</td>
                <td>{srcLoadedCost}</td>
              </tr>
              <tr>
                <td>Target: {tgtModel}</td>
                <td>{tgtLoadedCost}</td>
              </tr>
            </tbody>
          </table>
        ) : null}
      </div>
    </StyledCard>
  );
};

export default SalaryCard;
