import styled from "styled-components";
import { SpacerWrapProps } from "./types";
export const spacerHeight = 30;

export const RowView = styled.div`
  display: flex;
  align-content: flex-start;
  flex-flow: row nowrap;
`;

export const SpacerWrap = styled.div<SpacerWrapProps>`
  position: relative;
  grow: 1;
  height: ${(props) => (props.last ? "200px" : "6px")};
`;

export const SpacerView = styled.div<SpacerWrapProps>`
  position: absolute;
  z-index: 999;
  width: 100%;
  height: ${spacerHeight}px;
  min-height: ${spacerHeight}px;
  background: repeating-linear-gradient(
    -55deg,
    ${(props) => (props.isOver ? "#4a90e2" : "white")},
    ${(props) => (props.isOver ? "#4a90e2" : "white")} 5px,
    #fff 5px,
    #fff 10px
  );
  top: ${-spacerHeight * 0.5}px;
  opacity: ${(props) => +props.isOver};
`;
