import React from "react";
import "./App.css";
import Routes from "./routes/AppRoutes";
import {API_URI, APP_MOUNT_URI} from "../config";
import {CacheContextProvider} from "./context/CacheContext";

function App() {
    console.log("Opteq");
    console.log(`REACT_APP_MOUNT_URI: ${APP_MOUNT_URI}`);
    console.log(`REACT_APP_API_URI: ${API_URI}`);

    return (
        <div key="app" className="App">
            <CacheContextProvider>
                <Routes/>
            </CacheContextProvider>
        </div>
    );
}

export default App;
