////// ----------------- VERY BAD HACKY APPROACH ----------------- ///////

export const SET_TREE_TOUPDATE = "SET_TREE_TOUPDATE";

export interface SetTreeToupdateAction {
  type: typeof SET_TREE_TOUPDATE;
  toupdate: boolean;
}

export interface TreeState {
  toupdate: boolean;
}

export type TreeAction = SetTreeToupdateAction;
