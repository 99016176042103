import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Input, Button, TextArea } from "../../../components";
import { MembersGrid } from "../../components/CsListView";
import { Icons } from "../../../assets/Icons";
import { Dialog } from "@blueprintjs/core";
import useProjectsApi from "../../../projectspaces/api/useProjectsApi";

const StyledDialog = styled(Dialog)`
  background-color: #fff;
  background: #fff;

  & > .bp3-dialog-header {
    box-shadow: none;
    padding: 16px 5px 16px 24px;

    & > h4 {
      font-size: 21px;
    }
  }

  .dialog-content {
    padding: 16px 24px;
  }
`;

const MemberCard = styled.div`
  font-size: 12px;
  display: inline-flex;

  align-items: center;
  word-break: break-all;
  cursor: pointer;

  & > span {
    color: #4a90e2;
    font-weight: bold;
    margin-left: 8px;
  }

  & > svg {
    min-width: 14px;
  }
`;

const NewProject = (props: any) => {
  const [projectName, setProjectName] = useState("");
  const [newMember, setNewMember] = useState("");
  const [projectDesc, setProjectDesc] = useState("");
  const [members, setMembers] = useState<Array<string>>([]);
  const [projectOwner, setProjectOwner] = useState("");
  //const [errors, setErrors] = useState();

  useEffect(() => {
    setProjectOwner(props.owner?.name);
  }, [props.owner]);

  const projectsApi = useProjectsApi();

  const memberToList = (e: any) => {
    e.preventDefault(); // don't refresh page
    if (newMember.length > 0 && !members.includes(newMember)) {
      setMembers((members) => members.concat(newMember));
    }

    setNewMember("");
  };

  const deleteMemberFromList = (target: string) => {
    const INDEX = members.indexOf(target);

    if (INDEX >= 0) {
      setMembers(members.filter((item) => item !== target));
    }
  };

  function doNewProject(e: any) {
    e.preventDefault();

    const API_OBJ = {
      name: projectName,
      invites: members,
      description: projectDesc,
    };

    return projectsApi
      .createProject(API_OBJ)
      .then((response) => {
        if (response.statusText === "OK") {
          props.onClose();
          props.refresh();
        }
      })
      .catch((error) => {
        //setErrors(error.response.data);
        console.log(error.response.data);
      });
  }

  return (
    <StyledDialog
      title="New project"
      onClose={props.onClose}
      isOpen={props.isOpen}
    >
      <div className="dialog-content">
        <Input
          label="Project name"
          id="input-projectName"
          type="text"
          value={projectName}
          onChange={(e: any) => {
            setProjectName(e.target.value);
          }}
        />

        <form onSubmit={memberToList}>
          <Input
            label="Invite team members"
            id="input-members"
            type="email"
            value={newMember}
            onChange={(e: any) => {
              setNewMember(e.target.value);
            }}
          />
        </form>
        <MembersGrid>
          {members.map((member: string, i: number) => {
            return (
              <MemberCard key={i} onClick={() => deleteMemberFromList(member)}>
                <Icons.Close size={14} />
                <span>{member}</span>
              </MemberCard>
            );
          })}
        </MembersGrid>

        <TextArea
          label="Project description"
          id="project-description"
          value={projectDesc}
          onChange={(e: any) => setProjectDesc(e.target.value)}
          maxlength="300"
        />

        {
          // TODO: add error for 300 characters limit for description
        }

        <Input
          label="Project owner"
          id="input-projectOwner"
          type="text"
          value={projectOwner}
          readonly
        />

        <Button
          type="submit"
          onClick={doNewProject}
          disabled={!projectName || !projectDesc || !projectOwner}
        >
          Create project
        </Button>
      </div>
    </StyledDialog>
  );
};
export default NewProject;
