import * as React from "react";
import {useEffect, useState} from "react";
import {RouteComponentProps} from "react-router";
import {LevelCards, LevelContent, LevelHeader, Levels,} from "../../components/PyramidView";
import {Button} from "../../../components";
import useModelsApi from "../../api/useModelsApi";
import {Model, Pyramid} from "../../api/model";
import PyramidCard from "../../components/PyramidCard";
import TreeMenu from "../../components/TreeMenu/TreeMenu";
import DashBoardConstant from "../Reference/DashBoardConstant";

type ViewProps = RouteComponentProps<{
  modelUuid: string;
}>;

export const View: React.FC<ViewProps> = ({ match }) => {
  const modelsApi = useModelsApi();

  const [pyramidDetails, setPyramidDetails] = useState<Pyramid>();
  const [rootDetails, setRootDetails] = useState<Model>();

  useEffect(() => {
    modelsApi.getPyramidView(match?.params.modelUuid).then((response) => {
      setPyramidDetails(response?.data);
    });

    modelsApi.getModel(match?.params.modelUuid).then((response) => {
      setRootDetails(response?.data);
    });
  }, [modelsApi, match]);

  return (
    <DashBoardConstant match={match} withTree modelInfo={rootDetails}>
      <TreeMenu ID={match?.params.modelUuid} />

      <Levels>
        <LevelContent>
          <LevelHeader>
            <span>Level 1</span>
          </LevelHeader>
          <div>
            <Button
              type="button"
              bgcolor="#f3f3f3"
              textcolor="#061D38"
              bold={true}
              className="root-btn"
            >
              {rootDetails?.name}
            </Button>
          </div>
        </LevelContent>
        <LevelContent>
          <LevelHeader>
            <span>Level 2</span>
          </LevelHeader>
          <LevelCards>
            {pyramidDetails?.pyramid?.Division &&
              pyramidDetails?.pyramid?.Division.map((e: any, i: number) => (
                <PyramidCard
                  to={match?.params.modelUuid}
                  key={i}
                  name={e.properties.name}
                  icon="division"
                />
              ))}
          </LevelCards>
        </LevelContent>
        <LevelContent>
          <LevelHeader>
            <span>Level 3</span>
          </LevelHeader>
          <LevelCards>
            {pyramidDetails?.pyramid?.BusinessUnit &&
              pyramidDetails?.pyramid?.BusinessUnit.map((e: any, i: number) => (
                <PyramidCard
                  to={match?.params.modelUuid}
                  key={i}
                  name={e.properties.name}
                  icon="businessUnit"
                />
              ))}
          </LevelCards>
        </LevelContent>
        <LevelContent>
          <LevelHeader>
            <span>Level 4</span>
          </LevelHeader>
          <LevelCards>
            {pyramidDetails?.pyramid?.Function &&
              pyramidDetails?.pyramid?.Function.map((e: any, i: number) => (
                <PyramidCard
                  to={match?.params.modelUuid}
                  key={i}
                  name={e.properties.name}
                  icon="function"
                />
              ))}
          </LevelCards>
        </LevelContent>
        <LevelContent>
          <LevelHeader>
            <span>Level 5</span>
          </LevelHeader>
          <LevelCards>
            {pyramidDetails?.pyramid?.SubFunction &&
              pyramidDetails?.pyramid?.SubFunction.map((e: any, i: number) => (
                <PyramidCard
                  to={match?.params.modelUuid}
                  key={i}
                  name={e.properties.name}
                  icon="subFunction"
                />
              ))}
          </LevelCards>
        </LevelContent>
      </Levels>
    </DashBoardConstant>
  );
};

export default View;
