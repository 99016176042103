import {SChartProps} from "./types";
import {Chart} from "react-google-charts";
import * as React from "react";
import {useResizeDetector} from "react-resize-detector";
import {useMemo} from "react";
import styled from "styled-components";

const Title = styled.h3`
        text-align: center;
        font-size: 1.25rem;
        padding: 1rem 0 0 0;
    `

const SChart = ({name, data}: SChartProps) => {
    const {width, height, ref} = useResizeDetector();

    const El =
        () => <Chart
            width={width}
            height={height}
            chartType="ColumnChart"
            loader={<div>Loading Chart</div>}
            data={data}
            options={{
                // title: name,
                chartArea: {width: '60%'},
                hAxis: {
                    title: 'Business Units',
                    minValue: 0,
                },
                vAxis: {
                    title: 'Count',
                },
            }}
            // For tests
            rootProps={{'data-testid': '1'}}
        />

    return <div style={{border: "1px solid lightgrey", marginBottom: "2rem"}}>
        <Title>
            {name}
        </Title>
        <div ref={r => ref.current = r} style={{width: "100%", height: "400px"}}>
        {data ? <El /> : <></>}
        </div>
    </div>
}

export default SChart