import useAuth from "../../auth/context/useAuth";
import ProjectsApi from "./ProjectsApi";
import { useMemo } from "react";

export function useProjectsApi(): ProjectsApi {
  const auth = useAuth();

  return useMemo(() => {
    const token = auth.token as string;

    return new ProjectsApi(token);
  }, [auth]);
}

export default useProjectsApi;
