export const model = () => ({
    selector: 'node.model',
    style: {
        'content': 'data(label)',
        textWrap: 'wrap',
        textValign: 'center',
        textHalign: 'center',
        'width': '90px',
        'border-width': '1.5px',
        borderColor: "black",
        shape: 'rectangle',
        'font-size': 8,

        'background-color': '#dddddd'
    }
})

export const modelHead = () => ({
    selector: 'edge.model-head',
    style: {
        'content': 'data(label)',
        'font-size': 8,

        'width': 2,
        'line-color': 'gray',
        'target-arrow-color': 'gray',
        'target-arrow-shape': 'triangle',
        'curve-style': 'taxi'
    }
})

export const snapshot = () => ({
    selector: 'node.snapshot',
    style: {
        'content': 'data(label)',
        textWrap: 'wrap',
        textValign: 'center',
        textHalign: 'center',
        'width': '90px',
        'border-width': '1.5px',
        borderColor: "black",
        shape: 'rectangle',
        'font-size': 8,

        'background-color': '#dddddd'
    }
})

export const snapshotPrev = () => ({
    selector: 'edge.snapshot-prev',
    style: {
        'content': 'data(label)',
        'font-size': 8,

        'width': 2,
        'line-color': 'gray',
        'target-arrow-color': 'gray',
        'target-arrow-shape': 'triangle',
        'curve-style': 'taxi'
    }
})