import * as React from "react";
import {useMemo, useState} from "react";
import CheckboxTree, {Node} from 'react-checkbox-tree';
import 'react-checkbox-tree/lib/react-checkbox-tree.css';

import '@fortawesome/fontawesome-free/css/all.css'
import {TreeSelect} from "antd";
import {TreeProps} from "../../../../@satsui/components/types";

const tidyTree = (nodes: Node[]) => {
    /*return traverse(nodes).map(function (x) {
        if(x?.showCheckbox) {
            x.showCheckbox = false
            this.update(x)
        }
    })*/

    return nodes;
}

export const NodeTreeView2: React.FC<TreeProps> = ({selection, nodes, onChange}) => {
    const nodes2 = useMemo(
        () => nodes ? tidyTree(nodes) : [],
        [nodes]
    )

    /*useEffect(
        () => {
            setChecked(nodes.map(n => n.value))
        },
        [nodes]
    )*/

    const [checked, setChecked] = useState<Array<string>>(selection)
    const [expanded, setExpanded] = useState<Array<string>>([])

    const handleChecked = (checked: Array<string>) => {
        setChecked(checked)
        onChange(checked)
    }

    /*const Tree = () => <CheckboxTree
        // ref={(r) => {ref.current = r}}

        nodes={nodes2}
        // targetDepth={2}

        iconsClass="fa5"

        // onlyLeafCheckboxes={true}

        nativeCheckboxes={true}

        checkModel={"all"}

        showNodeIcon={false}
        showNodeTitles={false}
        // optimisticToggle={true}

        // icons={icons}
        checked={checked}
        onCheck={handleChecked}

        expanded={expanded}
        onExpand={setExpanded}
    />*/

    // return useMemo(
    //     () =>
    return <>

        <TreeSelect
            treeData={nodes2}
            value={checked}
            onChange={handleChecked}
            placeholder="any"

            multiple={false}

            treeCheckable
            showCheckedStrategy={"SHOW_PARENT"}
            // treeLine={true}

            style={{width: "100%"}}
        />

        {/*{JSON.stringify(checked, null, 2)}*/}
    </>
    // [checked, expanded]
    // )

    // return (
    // <div className="bp3-input" style={{borderWidth: 0, boxShadow: "none", paddingLeft: 8, paddingRight: 0, height: "auto"}}>

    // </div>
    // )*/
}