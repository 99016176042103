import React, { useState } from "react";
import { Treemap, ResponsiveContainer, Text } from "recharts";
import { StyledCard } from "./styles";
import {
  Button,
  Popover,
  PopoverInteractionKind,
  Position,
  Classes,
} from "@blueprintjs/core";
import { useEffect } from "react";

const TotalByLevel = ({ data, kindPlural }: any) => {
  const [level, setLevelId] = useState<string>("");
  const [levels, setLevels] = useState<string[]>([]);
  const COLORS = [
    "#4a90e2",
    "#64AAFC",
    "#70B6FF",
    "#7DC3FF",
    "#8AD0FF",
    "#97DDFF",
    "#A3E9FF",
  ];

  useEffect(() => {
    setLevelId(data ? Object.keys(data.byLevel)[0] : "");
    setLevels(data ? Object.keys(data.byLevel) : []);
  }, [data]);

  const getData = () => {
    if (!data) return [];
    const byLevel = data.byLevel[level];
    if (byLevel) {
      const treeData = [
        {
          name: level,
          children: byLevel.map((divComp: any) => ({
            name: divComp.label,
            size: divComp.tgt?.count,
            value: divComp.tgt?.count,
          })),
        },
      ];
      console.log(treeData);
      return treeData;
    }
    return [];
  };

  const handleLevelChange = (
    event: React.MouseEvent<HTMLElement, MouseEvent>
  ) => {
    setLevelId((event.currentTarget as any).id);
  };

  const CustomizedContent = (props: any) => {
    const {
      root,
      depth,
      x,
      y,
      width,
      height,
      index,
      colors,
      name,
      value,
    } = props;

    return (
      <g>
        <rect
          x={x}
          y={y}
          width={width}
          height={height}
          style={{
            fill:
              depth <= 2
                ? colors[Math.floor((index / root?.children?.length) * 6)]
                : "none",
            stroke: "#fff",
            strokeWidth: 2 / (depth + 1e-10),
            strokeOpacity: 1 / (depth + 1e-10),
          }}
        />
        {depth > 1 && value > 0 && (
          <Text
            x={x + width / 2}
            y={y + height / 2 + 7}
            textAnchor="middle"
            fill="#000"
            className="level-name"
            width={width}
          >
            {name}
          </Text>
        )}
        {depth === 1 && value <= 0 && (
          <Text
            x={x + width / 2}
            y={y + height / 2 + 7}
            textAnchor="middle"
            fill="#000"
            className="level-name"
            width={width}
          >
            {name.replace(/([A-Z])/g, " $1").trim()}
          </Text>
        )}
      </g>
    );
  };

  return (
    <StyledCard className="tree-chart">
      <h3>
        Total {kindPlural} by:
        {level && (
          <Popover
            interactionKind={PopoverInteractionKind.CLICK}
            className="level-popover"
            position={Position.BOTTOM}
          >
            <Button
              minimal
              fill
              text={level?.replace(/([A-Z])/g, " $1").trim()}
              className="popover-btn"
            />
            <div style={{ padding: "12px 15px" }}>
              {levels.map((key) => (
                <Button
                  key={`byLevel-${key}`}
                  id={key}
                  onClick={handleLevelChange}
                  active={key === level ? true : false}
                  minimal
                  style={{ marginBottom: 6 }}
                  fill
                  className={Classes.POPOVER_DISMISS}
                >
                  {key?.replace(/([A-Z])/g, " $1").trim()}
                </Button>
              ))}
            </div>
          </Popover>
        )}
      </h3>

      <div className="treemap-chart-wrap" style={{ marginBottom: 16 }}>
        <ResponsiveContainer width="100%" height="100%" className="test">
          <Treemap
            data={getData()}
            animationDuration={400}
            fill="#4a90e2"
            content={<CustomizedContent colors={COLORS} />}
          />
        </ResponsiveContainer>
      </div>
    </StyledCard>
  );
};

export default TotalByLevel;
