import React from "react";
import { useDrag, useDrop, DragSourceMonitor } from "react-dnd";
import {
  addSelectedComponent,
  setSelectedComponent,
  swapItems,
  transferItemToParent,
  setCompName,
  isSelected,
  getNestingLevelContent,
} from "../../stateManager";
import { ItemTypes } from "../itemTypes";
import ItemSpacer from "./ItemSpacer";
import { ItemView, ItemContentWrap, ItemContent } from "./styles";
import TextView from "../TextView";
import { useHistory } from "react-router";
import { ItemWrap } from "./styles";
import { ItemProps } from "./types";

export const Item: React.FC<ItemProps> = React.memo(
  ({
    boxId,
    id,
    pos,
    name,
    state,
    content,
    styles,
    editable,
    isResizing,
    nestingLevel,
  }) => {
    const history = useHistory();
    const [{ isDragging }, drag] = useDrag({
      item: { id, type: `${ItemTypes.ITEM}${nestingLevel}` },
      end: (item: { id: string } | undefined, monitor: DragSourceMonitor) => {
        const dropResult = monitor.getDropResult();
        if (item && dropResult) {
          console.log(item, dropResult);
          switch (dropResult.type) {
            case `${ItemTypes.ITEM}${nestingLevel}`:
              swapItems(state, nestingLevel, item.id, dropResult.id);
              break;
            case `${ItemTypes.ITEM}${nestingLevel - 1}`:
              transferItemToParent(
                state,
                nestingLevel,
                item.id,
                dropResult.id,
                0
              );
              break;
            case `${ItemTypes.POS}${nestingLevel}`:
              transferItemToParent(
                state,
                nestingLevel,
                item.id,
                dropResult.id,
                dropResult.pos
              );
              break;
          }
        }
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      canDrag: editable && !isResizing,
    });
    const [{ isOver }, drop] = useDrop({
      accept: [
        `${ItemTypes.ITEM}${nestingLevel}`,
        `${ItemTypes.ITEM}${nestingLevel + 1}`,
        `${ItemTypes.NEW_ITEM}${nestingLevel + 1}`,
      ],
      drop: (item, monitor) => {
        if (monitor.didDrop()) return;
        return { id, type: `${ItemTypes.ITEM}${nestingLevel}` };
      },
      collect: (monitor) => ({
        isOver: monitor.isOver({ shallow: true }),
      }),
    });
    const selected = isSelected(state, id);
    const handleOnClick = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      if (event.defaultPrevented) return;
      if (!editable) {
        if (!history.location.pathname.includes("components"))
          history.push(`components/${id}`);
        else history.push(`${id}`);
        event.preventDefault();
        return;
      }
      if (event.shiftKey) {
        addSelectedComponent(state, id, nestingLevel);
      } else {
        setSelectedComponent(state, id, nestingLevel);
      }
      event.preventDefault();
    };
    const onTextChange = (text: string) => {
      setCompName(state, nestingLevel, id, text);
    };
    const backgroundColor = isOver ? "yellow" : "lightgray";
    const opacity = isDragging || isOver ? 0.4 : 1.0;
    return (
      <ItemWrap>
        {pos === 0 && (
          <ItemSpacer
            pos={pos}
            boxId={boxId}
            nestingLevel={nestingLevel}
            first={true}
          />
        )}
        <ItemView
          ref={drag}
          onClick={handleOnClick}
          style={{ opacity, backgroundColor, flexGrow: 1 }}
          selected={selected}
          styles={styles}
        >
          <ItemContentWrap ref={drop}>
            <TextView text={name} onBlur={onTextChange} state={state} />
            <ItemContent>
              {content &&
                content.map((itemId, i) => {
                  const item = getNestingLevelContent(state, nestingLevel + 1)[
                    itemId
                  ];
                  return (
                    <Item
                      key={item.id}
                      boxId={id}
                      id={item.id}
                      pos={i}
                      name={item.name}
                      state={state}
                      styles={item.style}
                      editable={editable}
                      isResizing={isResizing}
                      nestingLevel={nestingLevel + 1}
                    />
                  );
                })}
            </ItemContent>
          </ItemContentWrap>
        </ItemView>
        <ItemSpacer pos={pos + 1} boxId={boxId} nestingLevel={nestingLevel} />
      </ItemWrap>
    );
  }
);

export default Item;
