import React from "react";
import { useHistory } from "react-router-dom";
import { useDrag, DragSourceMonitor, useDrop } from "react-dnd";
import { ItemTypes } from "../itemTypes";
import Item from "../Item";
import {
  swapBoxes,
  transferBoxToRow,
  transferBoxToNewRow,
  addSelectedComponent,
  setSelectedComponent,
  isSelected,
  getNestingLevelContent,
} from "../../stateManager";
import BoxName from "./BoxName";
import BoxSpacer from "./BoxSpacer";
import TextBox from "../TextLabel";
import { BoxWrap, BoxView } from "./styles";
import { BoxProps } from "./types";
import { ItemContent } from "../Item/styles";

export const Box: React.FC<BoxProps> = React.memo(
  ({
    name,
    content,
    id,
    state,
    rowId,
    position,
    width,
    last,
    style,
    editable,
    isTextBox,
    isResizing,
  }) => {
    const history = useHistory();
    const selected = isSelected(state, id);
    const [{ isDragging }, drag] = useDrag({
      item: { id, type: `${ItemTypes.ITEM}${0}` },
      end: (item: { id: string } | undefined, monitor: DragSourceMonitor) => {
        const dropResult = monitor.getDropResult();
        if (item && dropResult) {
          switch (dropResult.type) {
            case `${ItemTypes.ITEM}${0}`:
              swapBoxes(state, item.id, dropResult.id);
              break;
            case `${ItemTypes.POS}${0}`:
              transferBoxToRow(
                state,
                item.id,
                dropResult.rowId,
                dropResult.pos
              );
              break;
            case `${ItemTypes.POS}_row`:
              transferBoxToNewRow(state, item.id, dropResult.pos);
              break;
          }
        }
      },
      collect: (monitor) => ({
        isDragging: monitor.isDragging(),
      }),
      canDrag: !(isTextBox && selected) && editable && !isResizing,
    });
    const [{ isOver }, drop] = useDrop({
      accept: [
        `${ItemTypes.ITEM}${0}`,
        `${ItemTypes.ITEM}${1}`,
        `${ItemTypes.NEW_ITEM}${1}`,
      ],
      drop: (item, monitor) => {
        if (monitor.didDrop()) return;
        return { id, type: `${ItemTypes.ITEM}${0}` };
      },
      collect: (monitor) => ({
        isOver: monitor.isOver({ shallow: true }),
      }),
    });
    const handleOnClick = (
      event: React.MouseEvent<HTMLDivElement, MouseEvent>
    ) => {
      if (event.defaultPrevented) return;
      if (!editable) {
        history.push(`components/${id}`);
        event.preventDefault();
        return;
      }
      if (event.shiftKey) {
        addSelectedComponent(state, id, 0);
      } else {
        setSelectedComponent(state, id, 0);
      }
      event.preventDefault();
    };
    const opacity = isDragging || isOver ? 0.4 : 1.0;
    return (
      <React.Fragment>
        {position === 0 && (
          <BoxSpacer rowId={rowId} pos={position} state={state} />
        )}
        <BoxWrap
          selected={selected}
          ref={drag}
          onClick={handleOnClick}
          style={{ opacity, width: `${width}px` }}
          styles={style}
        >
          {isTextBox ? (
            <TextBox
              id={id}
              state={state}
              text={name}
              selected={selected}
            ></TextBox>
          ) : (
            <BoxView ref={drop}>
              <BoxName
                selected={selected}
                id={id}
                name={name}
                state={state}
                thick={content.length > 0}
              />
              <ItemContent>
                {content.map((itemId, i) => {
                  const item = getNestingLevelContent(state, 1)[itemId];
                  return (
                    <Item
                      key={item.id}
                      boxId={id}
                      id={item.id}
                      pos={i}
                      name={item.name}
                      content={item.content}
                      state={state}
                      styles={item.style}
                      editable={editable}
                      isResizing={isResizing}
                      nestingLevel={1}
                    />
                  );
                })}
              </ItemContent>
            </BoxView>
          )}
        </BoxWrap>
        <BoxSpacer
          rowId={rowId}
          pos={position + 1}
          resizable={!last && editable}
          state={state}
        />
      </React.Fragment>
    );
  }
);

export default Box;
