import React, { useState, useEffect } from "react";
import styled from "styled-components";
import { Tree, ITreeNode, InputGroup } from "@blueprintjs/core";
import { TreeModel } from "../../api/model";
import useModelsApi from "../../api/useModelsApi";
import { Icons } from "../../../assets/Icons";
import traverse from "traverse";
import { useHistory } from "react-router-dom";

interface AttrTreeNode extends ITreeNode {
  kind?: string;
}

const StyledTreeMenu = styled.div<{ collapsed: boolean }>`
  max-width: 100%;
  position: relative;
  border-right: ${(p) => (p.collapsed ? "none" : "2px solid #f3f3f3")};
  box-shadow: ${(p) =>
    p.collapsed
      ? "none"
      : "0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06)"};
  padding: ${(p) => (p.collapsed ? "0 2px" : "64px 12px 0")};
  width: ${(p) => (p.collapsed ? "24px" : "224px")};
  overflow-y: auto;

  .collapse-icon {
    position: absolute;
    top: 0;
    right: 0;
    padding: 4px;
    cursor: pointer;

    &.text-icon {
      padding: 7px 9px;
      font-size: 15px;
      font-weight: bold;
    }
  }
`;

const TreeWrap = styled.div`
  display: flex;
  flex-direction: column;

  .tree-search {
    max-width: 100%;
    margin: 0 auto 12px;

    & > input {
      width: auto;
    }
  }

  & > h4 {
    text-transform: uppercase;
    font-size: 12px;
    font-weight: bold;
    margin: 0;
    margin-top: 12px;
    padding: 0 6px;
    color: #6d7278;
    letter-spacing: 0.035em;
  }

  .bp3-tree {
    color: #061d38;
  }

  .bp3-tree-node-content {
    width: auto;
  }

  .bp3-icon {
    display: flex;
    justify-content: center;
    color: #061d38;
  }

  .bp3-tree-node-caret {
    min-width: auto;
    padding: 6px;
  }

  .custom-tree-icon {
    width: 14px;
    height: 14px;
    margin-right: 4px;
    min-width: 14px;
    min-height: 14px;
  }

  /* used only for changing caret color when a tree-node is selected to maintain correct contrast  */
  .bp3-tree-node.bp3-tree-node-selected > .bp3-tree-node-content,
  .bp3-tree-node.bp3-tree-node-selected > .bp3-tree-node-content .bp3-icon,
  .bp3-tree-node.bp3-tree-node-selected
    > .bp3-tree-node-content
    .bp3-icon-standard,
  .bp3-tree-node.bp3-tree-node-selected
    > .bp3-tree-node-content
    .bp3-icon-large {
    color: #061d38;
  }

  .bp3-tree-node.bp3-tree-node-selected > .bp3-tree-node-content {
    background-color: #e8e7e5;
    color: #061d38;
  }

  .bp3-tree-node-content:hover {
    background-color: #e8e7e5;
    color: #061d38;
  }
`;

const TreeMenu: React.FC<any> = (props: any) => {
  const {
    ID,
    childId,
    children,
    setBreadcrumbData,
    withAttribute,
    setBreadcrumbKeys,
    fromCOAP,
  } = props;
  const modelsApi = useModelsApi();
  const [treeView, setTreeView] = useState<Array<TreeModel>>([]);
  const [treeSearch, setTreeSearch] = useState("");
  const [collapsedTree, setCollapsedTree] = useState(false);

  const history = useHistory();

  const handleNodeClick = (
    nodeData: AttrTreeNode,
    _nodePath: number[],
    e: React.MouseEvent<HTMLElement>
  ) => {
    const originallySelected = nodeData.isSelected;

    if (!e.shiftKey) {
      forEachNode(treeView, (n) => (n.isSelected = false));
    }
    nodeData.isSelected =
      originallySelected == null ? true : !originallySelected;
    const nextState = [...treeView];
    setTreeView(nextState);

    if (fromCOAP) {
      history.push(`/models/${ID}/components/${nodeData?.id}`);
    }

    if (setBreadcrumbData) {
      setTreeSearch("");

      if (withAttribute) {
        history.push(
          `/models/${ID}/components/${nodeData?.id}?attribute=${withAttribute}`
        );
      } else {
        history.push(`/models/${ID}/components/${nodeData?.id}`);
      }
    }
  };

  const handleNodeCollapse = (nodeData: AttrTreeNode) => {
    nodeData.isExpanded = false;
    const nextState = [...treeView];
    setTreeView(nextState);
  };

  const handleNodeExpand = (nodeData: AttrTreeNode) => {
    nodeData.isExpanded = true;
    const nextState = [...treeView];
    setTreeView(nextState);
  };

  function forEachNode(
    nodes: AttrTreeNode[],
    callback: (node: AttrTreeNode) => void
  ) {
    if (nodes == null) {
      return;
    }

    for (const node of nodes) {
      callback(node);
      if (node.childNodes) {
        forEachNode(node.childNodes, callback);
      }
    }
  }

  useEffect(() => {
    function handleIcons(node: any) {
      switch (node.icon) {
        case "division":
          node.icon = <Icons.Division className="custom-tree-icon" />;
          node.hasCaret = node?.childNodes.length > 0 ? true : false;
          break;

        case "businessUnit":
          node.icon = <Icons.BusinessUnit className="custom-tree-icon" />;
          node.hasCaret = node?.childNodes.length > 0 ? true : false;
          break;

        case "function":
          node.icon = <Icons.Function className="custom-tree-icon" />;
          node.hasCaret = node?.childNodes.length > 0 ? true : false;
          break;

        case "subFunction":
          node.icon = <Icons.SubFunction className="custom-tree-icon" />;
          node.hasCaret = node?.childNodes.length > 0 ? true : false;
          break;
      }
    }

    let childId_PATH: Array<string> = [];

    if (treeSearch.length > 0) {
      const timer = setTimeout(() => {
        modelsApi.searchTreeView(ID, treeSearch).then((response) => {
          const tree = traverse(response?.data).map(function (node: any) {
            // transfer every node with an icon name to a proper JSX icon element
            if (node?.icon) {
              // expand all nodes that match query and user typed at least 1 character to search for
              if (treeSearch.length > 0) {
                node.isExpanded = true;
              }

              if (node?.match) {
                node.isSelected = true;
              }

              if (node?.id === childId) {
                childId_PATH = this.path;
              }

              handleIcons(node);

              if (childId && node?.id === childId) {
                node.isSelected = true;
              }
            }
            return node;
          });

          // expand tree to the point where selected component is visible
          for (let i = 1; i < childId_PATH.length; i++) {
            const NODE = traverse(tree).get(childId_PATH.slice(0, i));

            if (NODE.label) {
              NODE.isExpanded = true;

              setBreadcrumbData([]);
              if (setBreadcrumbData) {
                for (let i = 1; i < childId_PATH.length; i++) {
                  const NODE = traverse(tree).get(childId_PATH.slice(0, i));

                  if (NODE.label) {
                    NODE.isExpanded = true;
                    setBreadcrumbData((breadcrumbData: any) => [
                      ...breadcrumbData,
                      NODE?.label,
                    ]);
                    setBreadcrumbKeys((breadcrumbKeys: any) => [
                      ...breadcrumbKeys,
                      NODE?.id,
                    ]);
                  }
                }
              }
            }
          }

          // save traversed response to a state
          setTreeView(tree);
        });
      }, 600);
      return () => clearTimeout(timer);
    } else {
      // NEEDS FIXING
      // if (!props.toupdate) return;

      // call the getTreeView endpoint on the page load
      // and to reset the tree state when users deletes what they searched for
      modelsApi.getTreeView(ID).then((response) => {
        const tree = traverse(response?.data).map(function (node: any) {
          // transfer every node with an icon name to a proper JSX icon element
          if (node?.icon) {
            handleIcons(node);
          }

          if (node?.id === childId) {
            childId_PATH = this.path;
          }

          if (childId && node?.id === childId) {
            node.isSelected = true;
          }
          return node;
        });

        // expand tree to the point where selected component is visible
        setBreadcrumbData && setBreadcrumbData([]);
        if (setBreadcrumbData) {
          for (let i = 1; i < childId_PATH.length; i++) {
            const NODE = traverse(tree).get(childId_PATH.slice(0, i));

            if (NODE.label) {
              NODE.isExpanded = true;
              setBreadcrumbData((breadcrumbData: any) => [
                ...breadcrumbData,
                NODE?.label,
              ]);
              setBreadcrumbKeys((breadcrumbKeys: any) => [
                ...breadcrumbKeys,
                NODE?.id,
              ]);
            }
          }
        }

        //setTreeToupdate(false); //uncomment when fixes are ready, otherwise the tree menu is not rendered
        setTreeView(tree);
      });
    }
    // eslint-disable-next-line
  }, [ID, modelsApi, treeSearch, childId]);

  return (
    <StyledTreeMenu collapsed={collapsedTree}>
      {collapsedTree ? (
        <Icons.ArrowRight
          size={32}
          className="collapse-icon"
          onClick={() => setCollapsedTree(false)}
        />
      ) : (
        <span
          className="collapse-icon text-icon"
          onClick={() => setCollapsedTree(true)}
        >
          Close
        </span>
      )}

      {!collapsedTree && (
        <TreeWrap>
          {children}
          <InputGroup
            leftIcon={<Icons.Search className="bp3-icon" size={14} />}
            onChange={(e: any) => setTreeSearch(e.target.value)}
            placeholder="Search"
            value={treeSearch}
            className="tree-search"
          />

          <h4>CORPORATE</h4>
          <Tree
            contents={treeView}
            onNodeClick={handleNodeClick}
            onNodeCollapse={handleNodeCollapse}
            onNodeExpand={handleNodeExpand}
            className="testa"
          />
        </TreeWrap>
      )}
    </StyledTreeMenu>
  );
};
export default TreeMenu;
