import React from "react";
import styled from "styled-components";
import { ButtonText } from "../../components";

const FooterWrap = styled.div`
  margin-top: 32px;
  font-size: 16px;
  padding: 0 64px;
`;

export const AuthFooter: React.FC = () => {
  return (
    <FooterWrap>
      <p>
        By creating an account, you agree to the{" "}
        <ButtonText to="/">Terms of Service</ButtonText>
      </p>

      <p>
        Already have an account?
        <br />
        <ButtonText to="/login" boldtext>
          Sign in
        </ButtonText>
      </p>
    </FooterWrap>
  );
};
export default AuthFooter;
