import * as React from "react";
import {useEffect, useState} from "react";
import {CubeViewProps, SankeyResult} from "../types";
import {SankeyPanel} from "../panels/SankeyPanel";
import useModelsApi from "../../../api/useModelsApi";

export const FlowTab = ({modelUuid, query}: CubeViewProps) => {
    const modelsApi = useModelsApi();

    const [result, setResult] = useState<SankeyResult>()

    useEffect(() => {
        modelsApi.cubeSankey(modelUuid, "Component", query).then((response) => {
            setResult(response?.data);
        });
    }, [modelsApi, query]);

    return <div>
        <SankeyPanel modelUuid={modelUuid} breakdown={"Component"} query={query}/>
    </div>
}