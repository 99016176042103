import * as React from "react";
import {useEffect, useRef, useState} from "react";
import {RouteComponentProps} from "react-router";
import useModelsApi from "../../api/useModelsApi";
import {GraphView, Model} from "../../api/model";
import TreeMenu from "../../components/TreeMenu/TreeMenu";
import DashBoardConstant from "../Reference/DashBoardConstant";

import styled from "styled-components";
import cytoscape, {Core, ElementDefinition, LayoutOptions, NodeSingular} from "cytoscape";
import Dagre from 'cytoscape-dagre';
import fcose from 'cytoscape-fcose';
import CytoscapeComponent from 'react-cytoscapejs';

cytoscape.use( fcose );

Dagre(cytoscape)

type ViewProps = RouteComponentProps<{
    modelUuid: string;
}>;

export type GraphProps = {
    view: GraphView
}

export const NetworkContent = styled.div<{ isOver?: boolean }>`
  padding: 32px 32px;
  overflow: auto;
  background: ${(props) =>
    props.isOver
        ? `background: repeating-linear-gradient(
    -55deg,
    #4a90e2,
    #4a90e2 5px,
    #fff 5px,
    #fff 10px
  );`
        : "none"};
`;

const orgChartStyle = [
    {
        selector: 'node.person',
        style: {
            'content': 'data(label)',
            textWrap: 'wrap',
            textValign: 'center',
            textHalign: 'center',
            'width': '90px',
            'border-width': '1.5px',
            borderColor: "black",
            shape: 'rectangle',
            'font-size': 8,

            'background-color': '#dddddd'
        }
    },
    {
        selector: 'node.component',
        style: {
            shape: 'rectangle',
            'content': 'data(label)',

            textWrap: 'wrap',
            fontWeight: 700,
            fontSize: 12,
            marginTop: "-16px",
            textValign: 'top',
            textHalign: 'center',
            'width': '120px',
            'border-width': '1px',
            borderColor: "black",
        }
    },
    {
        selector: 'node.Division',
        style: {
            borderColor: '#252F2C',
            borderWidth: '1px',
            backgroundColor: 'rgb(131, 158, 170)',

        }
    },
    {
        selector: 'node.BusinessUnit',
        style: {
            borderWidth: '1px',
            borderColor: '#252F2C',
            // backgroundColor: 'rgb(131, 158, 170)',
            backgroundColor: 'rgb(157, 177, 186)',
            // backgroundColor: '#adabae',
            width: '140px'
        }
    },
    {
        selector: 'node.Function',
        style: {
            borderWidth: '1px',
            borderColor: '#151F1C',
            //backgroundColor: 'rgb(157, 177, 186)',
            backgroundColor: 'rgb(192, 200, 204)',
            // backgroundColor: '#d4d4d4',
            width: '140px'
        }
    },
    {
        selector: 'node.SubFunction',
        style: {
            borderWidth: '2px',
            borderColor: 'black',
            backgroundColor: 'rgb(192, 200, 204)',
            width: '140px'
        }
    },
    {
        selector: 'edge.belongs-to',
        style: {
            'width': 1,
            'target-arrow-color': 'red',
            'target-arrow-shape': 'triangle',
            'line-color': 'red',
            'curve-style': 'taxi'
        }
    },
    {
        selector: 'edge.reports-to',
        style: {
            'width': 1.5,
            'target-arrow-color': 'black',
            'target-arrow-shape': 'triangle',
            'line-color': 'black',
            'curve-style': 'taxi'
        }
    }
]

const gridLayout = {
    name: 'grid',
    cols: 8
};

const dagreLayout = {
    name: 'dagre',
    rankDir: 'BT', // 'TB' for top to bottom flow, 'LR' for left to right. default is undefined, making it plot top-bottom
};

const fcoseLayout = {
    name: 'fcose'
}

const layout = fcoseLayout

function toElements(graphView : GraphView): cytoscape.ElementDefinition[] {
    return graphView ? CytoscapeComponent.normalizeElements({
        nodes: graphView.nodes.map(node => ({
                ...node,
                data: node.data,
            })
        ),
        edges: graphView.edges.map(edge => ({
                ...edge,
                data: edge.data
            })
        )
    }) : []
}

export const NetworkView: React.FC<ViewProps> = ({match}) => {
    const modelsApi = useModelsApi();

    const [graphView, setGraphView] = useState<GraphView>();
    const [rootDetails, setRootDetails] = useState<Model>();

    // const [elements, setElements] = useState<ElementDefinition[]>(Array());

    const myCyRef = useRef<Core | undefined>(undefined)

    useEffect(() => {
        modelsApi.getOrgChart(match?.params.modelUuid).then((response) => {
            setGraphView(response?.data);

            const cy = myCyRef.current

            // cy?.layout(gridLayout)
            // window.alert(cy)
            // myCyRef?.$('.component').layout(gridLayout)
            // myCyRef?.$('.component').layout(dagreLayout)
        });

        modelsApi.getModel(match?.params.modelUuid).then((response) => {
            setRootDetails(response?.data);
        });
    }, [modelsApi, match, myCyRef]);

    const elements: ElementDefinition[] = graphView ? CytoscapeComponent.normalizeElements({
        nodes: graphView.nodes.map(node => ({
                ...node,
                data: node.data,
            })
        ),
        edges: graphView.edges.map(edge => ({
                ...edge,
                data: edge.data
            })
        )
    }) : []

    function doLayout(opts: LayoutOptions, compId?: string) {
        const cy = myCyRef.current
        if(cy) {
            const eles = cy.elements().filter((ele: NodeSingular) => {
                return compId ? ele.data('parent') === compId : true
            })

            // window.alert(`layouting, ${eles.length}`)

            eles.layout(opts).run()
        }
    }

    return (
        <DashBoardConstant match={match} withTree modelInfo={rootDetails}>
            <TreeMenu ID={match?.params.modelUuid}/>

            <NetworkContent>
                <button onClick={() => doLayout(fcoseLayout)} >
                    Run fCose
                </button>

                <button onClick={() => doLayout(dagreLayout)} >
                    Run Dagre
                </button>

                <button onClick={() => doLayout(dagreLayout, "marketing")} >
                    Run Dagre on Marketing
                </button>

                {
                    graphView ? <>
                        <CytoscapeComponent
                            elements={elements}
                            layout={dagreLayout}
                            style={{width: '100%', height: '100%'}}
                            stylesheet={orgChartStyle}
                            cy={(cy) => {
                                myCyRef.current = cy
                            }}
                        />
                    </> : "Loading..."
                }
            </NetworkContent>
        </DashBoardConstant>
    );
};

export default NetworkView;
