import React, { useEffect, useState, useRef } from "react";
import { TopText, ReferenceBreadcrumb, AttrReferenceContent } from "./styles";
import { AttributeSummary, Model, Component } from "../../../models/api/model";
import useModelsApi from "../../api/useModelsApi";
import { withRouter, Link } from "react-router-dom";
import { Button } from "@blueprintjs/core";
import ValueSelectorDialog from "./components/ValueSelectorDialog";
import SyntheticDataDialog from "./components/SyntheticDataDialog";
import { Icons } from "../../../assets/Icons";
import AttributeModel from "../../../components/AttributeModel";
import { HotTable } from "@handsontable/react";
import Handsontable from "handsontable";
import Core from "handsontable";
import AutoSizer from "react-virtualized-auto-sizer";
import { useAllocationsApi } from "../../api/useAllocationsApi";
import { DataWrap } from "../../../projectspaces/components/ProjectsView";
import { useProjectsApi } from "../../../projectspaces/api/useProjectsApi";
import { Pagination } from "../../../components";

interface ViewProps {
  modelUuid: string;
  childId: string;
  attribute: string;
}

type TableColumnsType = {
  data: string;
  type: string;
  source?: Array<string>;
};

type TableRowsType = {
  labels: Array<{ [key: string]: any }>;
  properties: Array<{ [key: string]: any }>;
};

interface AttributeDisplay {
  display: boolean;
  uuid: string;
  allocation?: boolean;
  defaultSelectedTabId?: string | undefined;
}

export const AttributeReference: React.FC<ViewProps & any> = (props) => {
  const breadcrumbData: Array<string> = props.breadcrumbData;
  const breadcrumbKeys: Array<string> = props.breadcrumbKeys;

  const [modelData, setModelData] = useState<Model>();
  const [componentData, setComponentData] = useState<Component>();
  const [selectedAttribute, setSelectedAttribute] = useState<
    AttributeSummary
  >();
  const [editMode, setEditMode] = useState(false);
  const [valueSelectorDialog, setValueSelectorDialog] = useState(false);
  const [displaySyntheticDialog, setDisplaySyntheticDialog] = useState(false);
  const [forceRender, setForceRender] = useState(false);

  const [tableRows, setTableRows] = useState<TableRowsType>();
  const [tableColumns, setTableColumns] = useState<Array<TableColumnsType>>([]);
  const [tableHeaders, setTableHeaders] = useState<
    Array<{ name: string; key: string; type: string; target?: string }>
  >([]);
  const [tableSource, setTableSource] = useState<{
    [key: string]: Array<string>;
  }>();
  const [tableLinkSource, setTableLinkSource] = useState<{
    [key: string]: Array<string>;
  }>();
  const [displayAttributeModel, setDisplayAttributeModel] = useState<
    AttributeDisplay
  >({
    display: false,
    uuid: "",
  });

  const [totalPages, setTotalPages] = useState(1);

  //const [totalPages, setTotalPages] = useState(1);
  const params = new URLSearchParams(props.location?.search);
  const OFFSET = parseInt(params.get("offset") || "0", 10);
  const ATTRIBUTE = params.get("attribute");
  const modelsApi = useModelsApi();

  const allocationsApi = useAllocationsApi();
  const projectsApi = useProjectsApi();
  const hot = useRef<HotTable>(null);

  useEffect(() => {
    setEditMode(false);

    if (props.match?.params?.childId) {
      modelsApi
        .getAttributesSummary(
          props.match?.params.modelUuid,
          props.match?.params?.childId
        )
        .then((response) => {
          const INDEX = response?.data.findIndex(
            (attr) => attr.kind === ATTRIBUTE
          );
          setSelectedAttribute(response?.data[INDEX]);
        });
    }
  }, [modelsApi, props.match, ATTRIBUTE]);

  useEffect(() => {
    modelsApi.getModel(props.match?.params.modelUuid).then((response) => {
      const DATA = response?.data;

      if (DATA?.children) {
        const component = DATA?.children[props.match?.params.childId];
        setComponentData(component);
      }

      setModelData(DATA);
    });
  }, [modelsApi, props.match]);

  useEffect(() => {
    if (ATTRIBUTE && modelData && tableHeaders.length > 0) {
      modelsApi
        .getCandidates(props.match?.params.modelUuid, ATTRIBUTE)
        .then((response) => {
          let obj: { [key: string]: Array<string> } = {};
          if (response?.data?.results.length > 0) {
            const keys: Array<string> = Object.keys(
              response?.data?.results[0]?.labels
            );
            obj = keys.reduce(
              (o, key) => ({
                ...o,
                [key]: [],
              }),
              {}
            );

            for (let i = 0; i < keys.length; i++) {
              response?.data?.results.map((result: any) => {
                const value = result.properties[keys[i]];
                if (
                  value &&
                  value !== undefined &&
                  value !== null &&
                  value !== "Null" &&
                  value !== "" &&
                  obj[keys[i]].indexOf(value) <= -1
                ) {
                  obj[keys[i]].push(value);
                }
              });
            }
          }

          setTableSource(obj);
        });

      for (let i = 0; i < tableHeaders.length; i++) {
        if (
          tableHeaders[i]?.type === "link" &&
          tableHeaders[i]?.key !== "split"
        ) {
          projectsApi
            .getAttribute(modelData?.project?.uuid, tableHeaders[i]?.target)
            .then((response) => {
              let obj: { [key: string]: Array<string> } = {};
              const key = tableHeaders[i]?.key;
              if (response?.data?.results.length > 0) {
                obj[key] = [];

                response?.data?.results.map((result: any) => {
                  const value = result.properties["name"];
                  if (
                    value &&
                    value !== undefined &&
                    value !== null &&
                    value !== "Null" &&
                    value !== "" &&
                    obj[key].indexOf(value) <= -1
                  ) {
                    obj[key].push(value);
                  }
                });
              }

              setTableLinkSource((prevState: any) => {
                return { ...prevState, [key]: obj[key] };
              });
            });
        }
      }
    }
  }, [modelsApi, ATTRIBUTE, modelData, props.match, projectsApi, tableHeaders]);

  useEffect(() => {
    if (ATTRIBUTE) {
      modelsApi.getSchemaAttributes().then((response) => {
        const temp_arr = [];
        temp_arr.push(response?.data);

        const attributeIndex = temp_arr[0].findIndex(
          (attr) => attr?.kind === ATTRIBUTE
        );

        if (attributeIndex > -1) {
          const columns = response?.data[attributeIndex]?.properties;
          const columnsKeys = Object.keys(columns);

          const headersName = columnsKeys.map((key: string) => ({
            name: columns[key]?.meta.name,
            key: key,
            type: columns[key].type,
            target: columns[key]?.target?.kind,
          }));

          // add model allocations column
          headersName.push({
            name: "Model Allocations",
            key: "split",
            type: "link",
            target: undefined,
          });

          setTableHeaders(headersName);
        }
      });
    }
  }, [modelsApi, ATTRIBUTE]);

  useEffect(() => {
    if (ATTRIBUTE) {
      if (tableSource && tableLinkSource) {
        modelsApi
          .getAttributes(
            props.match?.params.modelUuid,
            ATTRIBUTE,
            props.match?.params.childId,
            500,
            OFFSET
          )
          .then((response) => {
            setTotalPages(Math.ceil(response?.data.total / 500));
            const rows: {
              properties: Array<{ [key: string]: any }>;
              labels: Array<{ [key: string]: any }>;
            } = { properties: [], labels: [] };

            rows.properties = response?.data?.results.map((result: any) => {
              const obj = result.properties;
              obj.uuid = result.uuid;
              obj.patchUuid = result.patchUuid;

              const linkKeys = Object.keys(result.links);
              linkKeys.map((key: string) => {
                obj[key] = result.links[key];
                return null;
              });

              obj["split"] = result.split.map((model: any) => ({
                name: model.name,
                uuid: result.uuid,
              }));

              obj["partial"] = result.partial;

              return obj;
            });

            rows.labels = response?.data?.results.map((result: any) => {
              const obj = result.labels;
              obj.uuid = result.uuid;
              obj.id = result.id;
              obj.attributeUuid = result.attributeUuid;
              obj.patchUuid = result.patchUuid;

              const linkKeys = Object.keys(result.links);
              linkKeys.map((key: string) => {
                obj[key] = result.links[key];
                return null;
              });

              obj["split"] = result.split.map((model: any) => ({
                name: model.name,
                uuid: result.uuid,
              }));

              obj["partial"] = result.partial;

              return obj;
            });

            setTableRows(rows);
          });

        modelsApi.getSchemaAttributes().then((response) => {
          const temp_arr = [];
          temp_arr.push(response?.data);

          const attributeIndex = temp_arr[0].findIndex(
            (attr) => attr?.kind === ATTRIBUTE
          );

          if (attributeIndex > -1) {
            const columns = response?.data[attributeIndex]?.properties;
            const columnsKeys = Object.keys(columns);
            const defaultColumns = columnsKeys.map((key: string) => ({
              data: key,
              type:
                columns[key].type === "link"
                  ? "autocomplete"
                  : columns[key].type !== "number"
                  ? "autocomplete"
                  : "numeric",
              renderer:
                columns[key].type === "link" ? "link.render" : "display.render",
              editor:
                columns[key].type === "link"
                  ? "autocomplete"
                  : columns[key].type === "number"
                  ? "text"
                  : key === "name"
                  ? "text"
                  : "autocomplete",
              source:
                columns[key].type === "link"
                  ? tableLinkSource[key]
                  : tableSource[key],
              readOnly: false,
            }));

            defaultColumns.push({
              data: "split",
              renderer: "allocation.render",
              type: "text",
              editor: "dropdown",
              source: [],
              readOnly: true,
            });

            setTableColumns(defaultColumns);
          }
        });
      }
    }
  }, [
    modelsApi,
    OFFSET,
    props.match,
    forceRender,
    tableSource,
    ATTRIBUTE,
    tableLinkSource,
  ]);

  (function (Handsontable) {
    function customRenderer(
      instance: Core,
      td: HTMLTableCellElement,
      row: number,
      col: number,
      prop: React.ReactText,
      value: any,
      cellProperties: Handsontable.CellProperties
    ) {
      let button;
      if (tableRows?.labels[row]?.partial) {
        td.classList.add("partial-cell");
        cellProperties.className = "partial-cell";
        cellProperties.readOnly = true;
      }

      if (
        value?.label !== "-" &&
        value?.label !== null &&
        value?.label !== undefined
      ) {
        button = document.createElement("button");
        button.innerHTML = value?.label;
        button.className = "link-btn";

        if (value?.label !== "Null") {
          Handsontable.dom.addEvent(button, "mousedown", function (e: any) {
            e.preventDefault(); // prevent selection quirk
            setDisplayAttributeModel({
              uuid: value?.uuid,
              display: true,
            });
          });
        }

        Handsontable.dom.empty(td);
        td.appendChild(button);
        td.className = "link-cell";
        cellProperties.readOnly = true;
      } else {
        value = "";
        Handsontable.renderers.AutocompleteRenderer(
          instance,
          td,
          row,
          col,
          prop,
          value,
          cellProperties
        );
      }
    }

    // Register an alias
    Handsontable.renderers.registerRenderer("link.render", customRenderer);
  })(Handsontable);

  (function (Handsontable) {
    function customRenderer(
      instance: Core,
      td: HTMLTableCellElement,
      row: number,
      col: number,
      prop: React.ReactText,
      value: any,
      cellProperties: Handsontable.CellProperties
    ) {
      if (value && value.length > 0) {
        Handsontable.dom.empty(td);
        for (let i = 0; i < value.length; i++) {
          if (value[i].name !== null) {
            let button;
            button = document.createElement("button");
            button.innerHTML = value[i]?.name;
            button.className = "link-btn";

            Handsontable.dom.addEvent(button, "mousedown", function (e: any) {
              e.preventDefault(); // prevent selection quirk
              setDisplayAttributeModel({
                uuid: value[i]?.uuid,
                display: true,
                allocation: true,
              });
            });

            td.appendChild(button);
            td.className = "link-cell";
            cellProperties.readOnly = true;
          } else {
            td.innerHTML = "";
          }
        }
      } else {
        td.innerHTML = value;
      }

      if (tableRows?.labels[row]?.partial) {
        td.classList.add("partial-cell");
        cellProperties.className = "partial-cell";
        cellProperties.readOnly = true;
      }
    }

    // Register an alias
    Handsontable.renderers.registerRenderer(
      "allocation.render",
      customRenderer
    );
  })(Handsontable);

  (function (Handsontable) {
    function customRenderer(
      instance: Core,
      td: HTMLTableCellElement,
      row: number,
      col: number,
      prop: React.ReactText,
      value: any,
      cellProperties: Handsontable.CellProperties
    ) {
      if (tableRows && row < tableRows?.labels.length) {
        if (tableRows.labels[row]) {
          const value = tableRows.labels[row][prop];
          td.innerHTML = value;

          if (tableRows.labels[row].partial) {
            td.classList.add("partial-cell");
            cellProperties.className = "partial-cell";
            cellProperties.readOnly = true;
          }
        }
      } else {
        td.innerHTML = value;
      }
    }

    // Register an alias
    Handsontable.renderers.registerRenderer("display.render", customRenderer);
  })(Handsontable);

  function handleCellEdit(changes: any, source: string) {
    if (source === "edit") {
      changes.map((row: any) => {
        if (tableRows) {
          const cellUuid =
            row[0] >= tableRows?.labels.length
              ? null
              : tableRows?.labels[row[0]].uuid;
          const cellName = row[1];
          let cellNewValue = row[3].length > 0 ? row[3] : null;

          if (cellUuid !== null && cellUuid !== undefined && ATTRIBUTE) {
            const cellId = tableRows?.labels[row[0]].id;
            const cellObj = {
              id: cellId,
              kind: ATTRIBUTE,
              properties: { [cellName]: cellNewValue },
            };

            modelsApi
              .updateAttributeCell(
                props.match?.params.modelUuid,
                tableRows?.labels[row[0]].attributeUuid,
                cellObj
              )
              .then((response) => {
                console.log(response?.status);
                if (response.statusText === "OK") {
                  setForceRender((forceRender) => !forceRender);
                }
              })
              .catch((error) => console.log(error));
          } else {
            if (ATTRIBUTE) {
              const newRow = {
                id: "" + Math.random().toString(36).substr(2, 9),
                kind: ATTRIBUTE,
                properties: { [cellName]: cellNewValue },
                split: {
                  [props?.match?.params?.childId]: 1,
                },
              };

              if (cellNewValue && cellNewValue.length > 0) {
                modelsApi
                  .createAttributeRow(props.match?.params.modelUuid, newRow)
                  .then((response) => {
                    console.log(response?.status);
                    if (response.statusText === "OK") {
                      setForceRender((forceRender) => !forceRender);
                    }
                  })
                  .catch((error) => console.log(error));
              }
            }
          }
        }

        return null;
      });
    }
  }

  function handleRowRemove(
    index: number,
    ammount: number,
    physicalRows: Array<number>,
    source: string | undefined
  ) {
    if (source === "ContextMenu.removeRow") {
      physicalRows.map((index: number) => {
        const cellUuid =
          tableRows?.labels[index] && tableRows?.labels[index].patchUuid
            ? tableRows?.labels[index].patchUuid
            : null;
        console.log(tableRows?.labels[index]);
        if (cellUuid !== null && cellUuid !== undefined) {
          allocationsApi
            .deallocateFromComponent(cellUuid, props?.match?.params?.childId)
            .then((response) => {
              console.log(response?.status);
              if (response?.statusText === "OK") {
                setForceRender((forceRender) => !forceRender);
              }
            })
            .catch((error) => console.log(error));
        }
        return null;
      });
    }
  }

  function handlePageChange(page: number) {
    props.history.push(
      `${props.match.url}?kind=${ATTRIBUTE}&offset=${page * 500 - 500}`
    );
  }

  function handlePaste(data: any, coords: Array<{ [key: string]: number }>) {
    const newCoords = coords[0];
    const startRow = newCoords?.startRow;
    const endRow = newCoords?.endRow;
    const startCol = newCoords?.startCol;
    const endCol = newCoords?.endCol;
    let dataArr: Array<{
      uuid?: string;
      kind: string | null;
      properties: { [key: string]: any };
      split?: { [key: string]: number };
    }> = [];
    let rowIndex = 0;
    for (let i = startRow; i <= endRow; i++) {
      let valueIndex = 0;
      dataArr.push({
        kind: ATTRIBUTE,
        properties: {},
      });

      for (let ii = startCol; ii <= endCol; ii++) {
        if (
          tableHeaders[ii]?.type !== "link" &&
          tableHeaders[ii]?.type !== "split"
        ) {
          if (
            tableRows?.labels[i] &&
            tableRows?.labels[i].uuid &&
            tableRows?.labels[i].id
          ) {
            dataArr[rowIndex].uuid = tableRows?.labels[i].attributeUuid;
            Object.assign(dataArr[rowIndex].properties, {
              [tableHeaders[ii].key]: data[valueIndex][0],
            });
          } else {
            Object.assign(dataArr[rowIndex].properties, {
              [tableHeaders[ii].key]: data[valueIndex][0],
            });
            dataArr[rowIndex].split = {
              [props?.match?.params?.childId]: 1,
            };
          }
        }

        valueIndex =
          valueIndex + 1 >= data.length - 1 ? data.length - 1 : valueIndex++;
      }

      rowIndex++;
    }

    if (dataArr.length > 0) {
      modelsApi
        .updateManyAttributeCells(props.match?.params?.modelUuid, dataArr)
        .then((response) => {
          console.log(response?.status);
          if (response?.statusText === "OK") {
            setForceRender((forceRender) => !forceRender);
          }
        })
        .catch((error) => console.log(error));
    }
  }

  function handleAutoFill(
    start: any,
    end: any,
    data: Array<Array<string | number>>
  ) {
    const colIndex = start?.col;
    const startRow = start?.row;
    const endRow = end?.row;
    const value = data[0][0];
    let dataArr: Array<{
      uuid?: string;
      kind: string | null;
      properties: { [key: string]: any };
      split?: { [key: string]: number };
    }> = [];
    let rowIndex = 0;
    for (let i = startRow; i <= endRow; i++) {
      dataArr.push({
        kind: ATTRIBUTE,
        properties: {},
      });

      if (
        tableHeaders[colIndex]?.type !== "link" &&
        tableHeaders[colIndex]?.type !== "split"
      ) {
        if (
          tableRows?.labels[i] &&
          tableRows?.labels[i].uuid &&
          tableRows?.labels[i].id
        ) {
          dataArr[rowIndex].uuid = tableRows?.labels[i].attributeUuid;
          Object.assign(dataArr[rowIndex].properties, {
            [tableHeaders[colIndex].key]: value,
          });
        } else {
          Object.assign(dataArr[rowIndex].properties, {
            [tableHeaders[colIndex].key]: value,
          });
          dataArr[rowIndex].split = {
            [props?.match?.params?.childId]: 1,
          };
        }
      }

      rowIndex++;
    }

    if (dataArr.length > 0) {
      modelsApi
        .updateManyAttributeCells(props.match?.params?.modelUuid, dataArr)
        .then((response) => {
          console.log(response?.status);
          if (response?.statusText === "OK") {
            setForceRender((forceRender) => !forceRender);
          }
        })
        .catch((error) => console.log(error));
    }
  }

  return (
    <AttrReferenceContent>
      <TopText className="two-col">
        <div>
          <Link to={props.match.url} className="back-btn">
            <Icons.ArrowLeft size={21} />
            <span>Back</span>
          </Link>
          <ReferenceBreadcrumb>
            <li>
              <Link to={`/projects/${modelData?.project?.uuid}`}>
                {modelData?.project?.name}
              </Link>
            </li>
            <li className="breadcrumb-sep">/</li>
            {breadcrumbData &&
              breadcrumbData.map((name, index) => (
                <>
                  <li>
                    <Link
                      to={`/models/${modelData?.uuid}/components/${breadcrumbKeys[index]}?attribute=${ATTRIBUTE}&offset=0`}
                    >
                      {name}
                    </Link>
                  </li>
                  <li className="breadcrumb-sep">/</li>
                </>
              ))}

            <li>
              <Link to={props.location.pathname + props.location.search}>
                {selectedAttribute?.entityMeta?.plural
                  ? selectedAttribute?.entityMeta?.plural
                  : selectedAttribute?.entityMeta?.name}
              </Link>
            </li>
          </ReferenceBreadcrumb>

          <h1>{componentData?.properties.name}</h1>
          <h2>
            {selectedAttribute?.entityMeta?.plural
              ? selectedAttribute?.entityMeta?.plural
              : selectedAttribute?.entityMeta?.name}
          </h2>
        </div>

        <div className="editmode-btn-wrap">
          <Button
            className="editmode-btn"
            onClick={() => setEditMode((editMode) => !editMode)}
            style={{
              background: editMode ? "#061D38" : "#4a90e2",
            }}
          >
            {editMode ? "Save" : "Edit"}
          </Button>

          {editMode && (
            <div>
              <Button
                className="editoption-btn"
                text="Generate values"
                onClick={() =>
                  setDisplaySyntheticDialog(
                    (displaySyntheticDialog) => !displaySyntheticDialog
                  )
                }
              />
              <Button
                className="editoption-btn"
                text="Add values"
                onClick={() => {
                  setEditMode(false);
                  setValueSelectorDialog(
                    (valueSelectorDialog) => !valueSelectorDialog
                  );
                }}
              />
            </div>
          )}
        </div>
      </TopText>

      <Pagination
        className="attr-ref-pages"
        handlePageChange={handlePageChange}
        totalPages={totalPages}
      />

      {tableHeaders && (
        <DataWrap className="functional-md-table">
          <AutoSizer>
            {({ height, width }) => (
              <HotTable
                ref={hot}
                className="data-table"
                columns={tableColumns}
                data={tableRows?.properties}
                colHeaders={(index: any) => {
                  return tableHeaders[index]?.name;
                }}
                rowHeaders={true}
                width={width}
                licenseKey="non-commercial-and-evaluation"
                stretchH="all"
                contextMenu={[
                  "remove_row",
                  "alignment",
                  "cut",
                  "copy",
                  "row_above",
                  "row_below",
                ]}
                minSpareRows={500}
                maxRows={500}
                manualColumnResize
                height={height}
                afterChange={(changes, source) =>
                  handleCellEdit(changes, source)
                }
                beforeRemoveRow={(index, ammount, physicalRow, source) =>
                  handleRowRemove(index, ammount, physicalRow, source)
                }
                rowHeights={28}
                afterPaste={(data: any, coords: any) =>
                  handlePaste(data, coords)
                }
                fillHandle={{
                  direction: "vertical",
                  autoInsertRow: false,
                }}
                afterAutofill={(start, end, data) =>
                  handleAutoFill(start, end, data)
                }
              />
            )}
          </AutoSizer>
        </DataWrap>
      )}
      {displayAttributeModel?.display === true &&
        props.match.params?.modelUuid && (
          <AttributeModel
            modelUuid={props.match.params?.modelUuid}
            uuid={displayAttributeModel?.uuid}
            isOpen={displayAttributeModel?.display}
            allocation={displayAttributeModel?.allocation}
            defaultSelectedTabId={displayAttributeModel?.defaultSelectedTabId}
            setDisplayAttributeModel={setDisplayAttributeModel}
            columnNames={tableHeaders}
          />
        )}

      {ATTRIBUTE &&
        valueSelectorDialog &&
        modelData?.uuid &&
        componentData?.properties?.name && (
          <ValueSelectorDialog
            isOpen={valueSelectorDialog}
            _ATTRIBUTE={ATTRIBUTE}
            onClose={() => {
              setForceRender((forceRender) => !forceRender);
              setValueSelectorDialog(false);
            }}
            projectUuid={modelData?.project?.uuid}
            modelUuid={modelData?.uuid}
            childId={props?.match?.params?.childId}
            componentName={componentData?.properties?.name}
            editMode={editMode}
            setEditMode={setEditMode}
          />
        )}
      {ATTRIBUTE && displaySyntheticDialog && (
        <SyntheticDataDialog
          isOpen={displaySyntheticDialog}
          _ATTRIBUTE={ATTRIBUTE}
          onClose={() => {
            setForceRender((forceRender) => !forceRender);
            setDisplaySyntheticDialog(false);
          }}
          projectUuid={modelData?.project?.uuid}
          modelUuid={modelData?.uuid}
          childId={props?.match?.params?.childId}
        />
      )}
    </AttrReferenceContent>
  );
};

export default withRouter(AttributeReference);
