import styled from "styled-components";

export const DashboardWrap = styled.div`
  display: grid;
  grid-template-rows: auto 1fr;
  min-height: 100vh;
  width: 100vw;
  max-width: 100%;
  max-height: 100vh;
  overflow: hidden;

  #input-principle {
    margin-top: 16px;
  }

  .select-label {
    margin-bottom: 16px;
  }
`;

export const DashboardHeader = styled.header`
  display: flex;
  align-items: center;
  border-bottom: 2px solid #f3f3f3;
  box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
  padding: 16px 21px 16px 4px;
`;

export const HeaderBtnWrap = styled.div`
  margin-left: auto;
  display: flex;
  align-items: center;

  & > .dashboard-feedback {
    margin: 0;
    width: 100px;
    font-size: 14px;
    padding: 4px 8px;
    margin-right: 24px;
  }

  & > .user-profile {
    display: flex;
    align-items: center;

    & > :nth-child(1) {
      order: 2;
    }
    & > :nth-child(2) {
      order: 1;
      margin-right: 8px;
    }
  }
`;

export const DashboardTools = styled.div`
  padding-left: 6px;
  padding-right: 48px;
  display: flex;
  align-items: center;

  .opteq-logo {
    margin-right: 40px;
  }

  .breadcrumb-list {
    display: flex;
    padding: 0;
    margin: 0;
    list-style: none;
    color: inherit;
    line-height: 1.5;
    font-size: 18px;
    overflow: hidden;
    word-break: break-all;
    font-weight: bold;
    color: #061d38;

    a {
      color: inherit;

      &:hover {
        text-decoration: underline;
      }
    }

    .breadcrumb-sep {
      padding: 0 4px;
    }
  }
`;

export const DashboardMain = styled.div`
  display: grid;
  grid-template-columns: 72px 1fr;
  overflow: auto;

  &.dashboard-withtree {
    grid-template-columns: 72px auto 1fr;
  }
`;

export const DashboardNav = styled.nav`
  background-color: #061d38;
  color: #fff;
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 72px;

  & > ul {
    margin: 0;
    padding: 0;
    color: inherit;
    list-style: none;
    display: flex;
    flex-direction: column;
    align-items: center;
    text-align: center;

    li {
      margin: 0;
      margin-top: 8px;
      width: 72px;

      &:first-of-type {
        margin-top: 0;
      }
    }

    a {
      svg {
        margin-bottom: 4px;
      }

      padding: 12px 0;
      color: inherit;
      display: flex;
      justify-content: center;
      align-items: center;
      text-align: center;
      flex-direction: column;

      &:hover {
        background-color: #0e2a4a;
      }

      span {
        margin: 0 8px;
        font-size: 12px;
      }
    }
  }
`;

export const DashboardContent = styled.div`
  padding: 32px 48px;
  display: grid;
  grid-template-rows: 120px 1fr;
  grid-row-gap: 16px;
  overflow: auto;
`;

export const ContentText = styled.div`
  display: flex;
`;

export const ModelInfo = styled.div`
  width: 100%;
  margin-right: 21px;
  overflow: hidden;

  & > label {
    font-weight: bold;
    margin-bottom: 4px;
  }

  & > div {
    width: 100%;
  }

  #model-description {
    background-color: inherit;
    resize: none;
    border-color: transparent;
    color: #061d38;
    opacity: 1;
    padding: 0;
    line-height: 1.35;
    font-size: 16px;
    flex: 1;
    width: 100%;

    &:hover,
    &:touch {
      color: #061d38;
      border-color: transparent;
    }

    &::placeholder {
      color: #061d38;
      opacity: 1;
    }
  }
`;

export const OwnerInfo = styled.div`
  margin-left: auto;
  align-self: center;

  & > h3 {
    margin: 0;
    font-size: 18px;
    color: #9c9c9c;
    margin-bottom: 12px;
    overflow: hidden;
    word-break: break-all;
  }

  & > .owner-card {
    border: 1px solid #f3f3f3;
    box-shadow: 0 1px 3px 0 rgba(0, 0, 0, 0.1), 0 1px 2px 0 rgba(0, 0, 0, 0.06);
    border-radius: 4px;
    padding: 16px 12px;
    min-width: 280px;
    display: flex;
    align-items: center;
    overflow: hidden;
    word-break: break-all;

    & > img {
      margin-right: 10px;
    }
  }
`;

export const BtnWrap = styled.div`
  border-bottom: 2px solid #f3f3f3;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;
  padding-bottom: 24px;

  & > div {
    display: flex;
  }

  h3 {
    margin: 0;
    display: inline-flex;
    font-size: 20px;
  }

  .dashboard-btn {
    margin: 0;
    padding: 3px 0;
    font-size: 14px;
    margin-left: 12px;
  }
`;

export const ContentCards = styled.div``;

export const CardsWrap = styled.div`
  margin: 32px 0;
  display: grid;
  grid-template-columns: 1fr 1fr;
  grid-column-gap: 16px;

  .principles-cards,
  .attributes-cards {
    display: flex;
    grid-row-gap: 24px;
    flex-direction: column;
  }
`;
